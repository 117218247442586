import React from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

import { connect } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';

import Add from '@material-ui/icons/Add';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import {
  ConfirmPopUp,
  PopUpCustomUI
} from '../_components';
import { startCase, upperFirst, toUpper, toLower} from 'lodash';

import '../../css/messageAttachment.style.scss';

import DropzoneComponent from '../DropzoneComponent';
import '../../css/dropzone.scss';

import { authHeader, handleResponse } from '../_helpers';
import { API_URLS, REQUIRED_IMAGES } from '../_config';
import { patientRequestsActions  } from '../_actions';

import { confirmAlert } from 'react-confirm-alert';


const baseCssClassName = 'MessageAttachment';
const attachmentFileContainerCssClassName = `${baseCssClassName}__iconContainer`;
const attachmentFileCssClassName = `${attachmentFileContainerCssClassName}__icon`;
const attachmentRemoveIconCssClassName = `${attachmentFileContainerCssClassName}__removeIcon`;
const attachmentFileNameContainerCssClassName = `${baseCssClassName}__nameContainer`;
const attachmentFileNameCssClassName = `${attachmentFileNameContainerCssClassName}__fileName`;

const attachmentPreviewsCssClassName = `${baseCssClassName}__previews`;
const attachmentSinglePreviewCssClassName = `${attachmentPreviewsCssClassName}__preview`;

const editViewCssClassName = `${baseCssClassName}__edit`;
const editViewAttachmentCssClassName = `${editViewCssClassName}__attachment`;

const dropzoneRemoveiconCssClassName= 'removeIcon';

function createFileKey(file) {
  return String(file.dateKey) + "$" + String(file.lastModified) + "$" + String(file.name) || ""
}

class MessageAttachmentUpload extends React.Component {
  constructor(props){
    super(props);
  }

  render() {

    const { intl, requestId, onSuccess, onDelete } = this.props;

    const postUrl = API_URLS.createMessageUpload.url.replace("{requestId}", requestId);
    var componentConfig = {
      postUrl: postUrl
    };
    var djsConfig = {
      addRemoveLinks: false,
      //acceptedFiles: imageTypes, //"image/jpeg,image/png,image/gif, image/jfif", "",
      parallelUploads: 5,
      autoProcessQueue: true,
      //thumbnailWidth: 120,
      //thumbnailHeight: 120,
      headers: {"Cache-Control": "", "X-Requested-With": "", ...authHeader()},
      // renderToStaticMarkup uploads plain HTML, can't put event listeners
      // event listeners are added by dropzone component (3rd party source code)
      /*previewsContainer: `.${attachmentPreviewsCssClassName}`,*/
      previewTemplate: ReactDOMServer.renderToStaticMarkup(

          <div className={baseCssClassName}>
            <div className={cn("Loader", "dz-progress")} ></div>
            <div className={attachmentFileContainerCssClassName}>
              <div className={attachmentRemoveIconCssClassName}>
                <Cancel
                  data-dz-remove="true"
                />
              </div>
              <div className={attachmentFileCssClassName}>
                <SaveAlt />
              </div>
            </div>
            <div className={attachmentFileNameContainerCssClassName}>
              <div className={cn(attachmentFileNameCssClassName, "dz-filename__customFilename")} data-dz-name></div>
            </div>
          </div>
      ),
      /*previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className="dz-preview dz-file-preview">
          <div className="dz-image dz-image__customImagePreview">
            <img data-dz-thumbnail="true" />
          </div>
          <div className="dz-details dz-details__customImageDetails" >
            <div className={dropzoneRemoveiconCssClassName}>
              <div className="iconWrapper" data-dz-remove="true"><Cancel /></div>
            </div>
            <div className="dz-size dz-size__customImageSize" data-dz-size=""></div>
            <div className="dz-filename dz-filename__customFilename"><span data-dz-name="true"></span></div>
          </div>
          <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
          <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
          <div className="dz-success-mark dz-success-mark__customSuccessMark"><span>✔</span></div>
          <div className="dz-error-mark dz-success-mark__customErrorMark"><span>✘</span></div>
          <div className={cn("Loader", {"Loader__show": ( patientIsLoading || imageSetIsLoading || patientRequestsIsLoading) })} ></div>
        </div>
      )*/
    };
    var eventHandlers = {
      init: dz => this.dropzone = dz,
      addedfile: (file) => {
        file.request = requestId;
        file.dateKey = new Date().getTime();
      },
      //sending: (file, xhr, formData) => {
      //},
      success: (file, response) => {
        // store id in state or whatever
        // and on message save, add to attachments
        console.log(file);
        onSuccess(createFileKey(file), response);
      },
      removedfile: (file) => {
        console.log(file);
        // maybe find index or something? keep state of index
        onDelete(createFileKey(file));
      },
      error: (file, errorMessage, xhr) => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <PopUpCustomUI
                title={intl.messages['popUp.complete.title']}
                message={'There was an error trying to upload'}
                handleSubmit={null}
                onClose={onClose}
                cancelText={intl.messages['string.cancel']}
                confirmText={intl.messages['string.confirm']}
              />
            )
          }
        })
        this.dropzone.removeFile(file);
      }
    };

    return(
      <div className={this.props.className || ''}>
        <div className={attachmentPreviewsCssClassName}>
          <DropzoneComponent
            config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          >
            <div className={'dz-message'}>
              <Add /> <span> {toUpper(intl.messages["patient.addReportReply.dropzone"])}</span>
            </div>
          </DropzoneComponent>

        </div>

      </div>
    )
  }
}

class MessageAttachment extends React.Component {
  constructor(props){
    super(props);
  }

  // edit mode needs uploads

  render() {
    const { attachment, index,  isStaff, editView, editable, editViewLoading, onRemove } = this.props;
    if (isStaff === true && editView === true && editable === true){
      return (
          <div className={baseCssClassName}>
            <div className={cn("Loader", "dz-progress")} ></div>
            <div className={attachmentFileContainerCssClassName}>
              <div className={attachmentRemoveIconCssClassName}>
                <Cancel
                  onClick = {() => onRemove(attachment.id)}
                />
              </div>
              <div className={attachmentFileCssClassName}>
                <SaveAlt />
              </div>
            </div>
            <div className={attachmentFileNameContainerCssClassName}>
              <div className={attachmentFileNameCssClassName} tooltip={attachment.filename} tooltip-position="top"><span>{attachment.filename}</span></div>
            </div>
          </div>
      )
    } else {
      return (
          <div className={baseCssClassName}>
            <a href={attachment.url} target="_blank">
                <div className={attachmentFileContainerCssClassName}>
                  <div className={attachmentFileCssClassName}>
                    <SaveAlt />
                  </div>
                </div>
                <div className={attachmentFileNameContainerCssClassName}>
                  <div className={attachmentFileNameCssClassName} tooltip={attachment.filename} tooltip-position="top"><span>{attachment.filename}</span></div>
                </div>
            </a>
          </div>
      )
    }
  }
}

class NewAttachmentDropzone extends React.Component {
  static propTypes = {
      onSuccess: PropTypes.func.isRequired,
      requestId: PropTypes.string.isRequired,
      messageId: PropTypes.number.isRequired
  }

  constructor(props){
    super(props);
  }

  removePreview = () => {
    if (this.dropzone) {
      this.dropzone.removeAllFiles();
    }
  }

  onUploadSuccess = (response) => {
    const { messageId, onSuccess } = this.props;
    this.props.dispatch(patientRequestsActions.addMessageAttachment(messageId, response));
    this.removePreview();
  }

  render(){
    const { intl, requestId, messageId, onSuccess, reduxDispatch } = this.props;

    const postUrl = API_URLS.addMessageAttachment.url
                          .replace("{requestId}", requestId)
                          .replace("{messageId}", messageId);
    var componentConfig = {
      postUrl: postUrl
    };
    var djsConfig = {
      parallelUploads: 5,
      autoProcessQueue: true,
      thumbnailWidth: 100,
      thumbnailHeight: 100,
      headers: {"Cache-Control": "", "X-Requested-With": "", ...authHeader()},
    };
    var eventHandlers = {
      init: dz => {
        this.dropzone = dz;
      },
      addedfile: (file) => {
        file.request = requestId;
      },
      success: (file, response) => {
          try{
            console.log("SUCCESS response", response.url)
          } catch {
            console.log("can't find image url")
          }
          console.log("response from success", JSON.stringify(response))
          this.onUploadSuccess(response)
          //onSuccess(messageId, response)
          //this.removePreview();
      },
      error: (file, errorMessage, error) => {
        //pass
        console.log("ERROR response", errorMessage, error, file)
        try{
          console.log(errorMessage.url)
        } catch {
          console.log("can't find image url")
        }
      }
    };

    return (
      <div className={editViewAttachmentCssClassName}>
        <DropzoneComponent
          config={componentConfig}
          eventHandlers={eventHandlers}
          djsConfig={djsConfig}
        >
          <div className={'dz-message'}>
            <Add /> <span> {toUpper(intl.messages["patient.editReportReply.dropzone"])}</span>
          </div>
        </DropzoneComponent>
      </div>
    )
  }
}

function mapStateToProps(state){
  const { patientRequests } = state;
  return { patientRequests }
}

const connectedNewAttachmentDropzone = connect(mapStateToProps)(NewAttachmentDropzone);

export {MessageAttachment, MessageAttachmentUpload, connectedNewAttachmentDropzone as NewAttachmentDropzone};
