import { authHeader, handleResponse } from '../_helpers';
import { API_URLS, ADMIN_API_URLS, PAGE_SIZE } from '../_config';

export const adminService = {
    getPracticeDetails,
    updatePracticeDetails,
    getAccountDetails,
    updateAccountDetails,
    createNewAccount,
    doPracticeSearch,
    doAccountSearch,
    getResetUrl
};

function doPracticeSearch(query, offset=0, dpid=null) {
  const requestUrl = ADMIN_API_URLS.getPracticesList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url + '?q='+query+'&offset='+offset+'&pgsz='+PAGE_SIZE;
  if (dpid){
    url = url + '&dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
};

function doAccountSearch(query, offset=0, dpid=null) {
  const requestUrl = ADMIN_API_URLS.getAccountsList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url + '?q='+query+'&offset='+offset+'&pgsz='+PAGE_SIZE;
  if (dpid){
    url = url + '&dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
};


function getPracticeDetails(clinicId){
  const requestUrl = ADMIN_API_URLS.getPracticeDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{clinicId}', clinicId);
  return fetch(url, requestOptions).then(handleResponse);
}

function createNewAccount(data){
  const requestUrl = ADMIN_API_URLS.createNewAccount;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function getResetUrl(clinicId, data){
  const requestUrl = ADMIN_API_URLS.getResetUrl;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  return fetch(url, requestOptions).then(handleResponse);
}

function updatePracticeDetails(clinicId, data){
  const requestUrl = ADMIN_API_URLS.updatePracticeDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{clinicId}', clinicId);
  return fetch(url, requestOptions).then(handleResponse);
}

function getAccountDetails(accountId){
  const requestUrl = ADMIN_API_URLS.getAccountDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };
  let url = requestUrl.url.replace('{accountId}', accountId);
  return fetch(url, requestOptions).then(handleResponse);
}

function updateAccountDetails(accountId, data){
  const requestUrl = ADMIN_API_URLS.updateAccountDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{accountId}', accountId);
  return fetch(url, requestOptions).then(handleResponse);
}
