import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';


// chagen color of load... background-color of colorprimarybar

const styles = {
	root: {
		flexGrow: 1,
	},
};

class ProgressBar extends React.Component {
	timer = null;

	static getDerivedStateFromProps(props) {
		return {
			completed: props.completed
		}
	}

	componentDidMount() {
		//this.timer = setInterval(this.progress, 500);
	}
	componentWillUnmount() {
		//clearInterval(this.timer);
	}

	progress = () => {
		const { completed } = this.state;
    console.log("wtf progress, ", completed)
		if (completed === 100) {
			this.setState({ completed: 0 });
		} else {
			const diff = Math.random() * 10;
			const fakeProgress = Math.min(completed + diff, 100) ;//< 100;
      console.log("wtf progress, ", fakeProgress)
			if (fakeProgress < 100) {
				this.setState({ completed: Math.min(completed + diff, 100) });
			}
		}
	};

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<LinearProgress variant="indeterminate" value={this.state.completed} />
			</div>
		);
	}
}

export default withStyles(styles)(ProgressBar);
