import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import cn from 'classnames';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";

import { toUpper, upperFirst } from 'lodash';

import { LanguageBar } from '../HomePage';
import { URLS } from '../_config';

import { userConstants } from '../_constants';
import { userActions } from '../_actions';
import { userService } from '../_services';

import '../../css/resetPassword.style.scss';

// components are from ai project
import Spinner from '../_components/Spinner';
import Alignment from "../_components/Alignment/Alignment";

import Button from '../_components/Button/Button';

const baseCssClassName = 'ResetPassword';
const formCssClassName = `${baseCssClassName}__Form`;
const formHeaderCssClassName = `${formCssClassName}__Header`;
const formSubHeaderCssClassName = `${formCssClassName}__SubHeader`;
const formErrorCssClassName = `${formCssClassName}__FormError`;
const formLogoCssClassName = `${formCssClassName}__Logo`;
const formMainCssClassName = `${formCssClassName}__Form-main`;
const formSecondaryCssClassName = `${formCssClassName}__Form-secondary`;
const formButtonCssClassName = `${formCssClassName}__FormButton`;
const messageCssClassName = `${formCssClassName}__Message`;
const messageTextCssClassName = `${formCssClassName}__MessageText`;
const formHelpCssClassName = `${formCssClassName}__Help`;
const formForgotTextCssClassName = `${formCssClassName}__forgotText`;
const formRegisterTextCssClassName = `${formCssClassName}__registerText`;
const formRegisterTextOneCssClassName = `${formRegisterTextCssClassName}__LineOne`;
const formRegisterTextTwoCssClassName = `${formRegisterTextCssClassName}__LineTwo`;


/* form helpers */
const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

const required = value => (value ? undefined : 'required')
const mustBeNumber = value => (isNaN(value) ? 'must_be_number' : undefined)
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const noChineseCharacters = str => (REGEX_CHINESE.test(str) ? 'no_chinese_characters' : undefined)

const formName = 'resetForm';
class FormPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // 'isLoading' is for giving user feedback, gives user sense that website
      // is working and doing stuff
      isLoading: false,
      user: null,
      intialValues: {
        uid: this.props.match.params.uid || "",
        token: this.props.match.params.token || ""
      },
    };
  }

  // handles form submission
  handleFormSubmit = async (values) => {
    console.log(values)

    const { intl } = this.props;
    if (values.new_password1 !== values.new_password2){
      return {
        new_password2: 'passwordMatch'
      }
    }
    try{
      const user = await userService.resetPassword(values);
      this.props.history.push(URLS.login);
    } catch (err){
      var errType = 'general.error';
      if (err.token !== undefined || err.uid !== undefined){
        errType = 'token';
      }
      return {
        ...err,
        [FORM_ERROR] : errType

      }
    }
  }

  _renderForm(){
    const { intl } = this.props;
    const initValues = {
      uid: this.props.match.params.uid || "",
      token: this.props.match.params.token || ""
    }
    return (
      <Alignment
        horizontal={Alignment.horizontal.CENTER}
        vertical={Alignment.horizontal.CENTER}
      >
        <div className={formCssClassName}>
        <Form
          onSubmit={this.handleFormSubmit}
          initialValues={initValues}
          //mutators={{ setFieldData }}
          render = {({
            submitError,
            formError,
            handleSubmit,
            mutators,
            form,
            reset,
            submitting,
            pristine,
            validating,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              >
              <div className={formHeaderCssClassName} >
                <span>
                  {toUpper(intl.messages[`${formName}.header`])}
                </span>
              </div>
              <div className={formSubHeaderCssClassName}>
                <span>{upperFirst(intl.messages[`${formName}.subHeader`])}</span>
                <div className={cn(formErrorCssClassName)}>
                  {submitError && <div className="Error">{upperFirst(intl.messages[`form.error.${submitError}`])}</div>}
                </div>
              </div>
              <div className={formMainCssClassName}>
                {validating && <Spinner />}
                <Field name="token">
                  {({ input, meta}) => (
                    <div className="">
                      <input {...input} type="hidden" />
                    </div>
                  )}
                </Field>
                <Field name="uid">
                  {({ input, meta}) => (
                    <div className="">
                      <input {...input} type="hidden" />
                    </div>
                  )}
                </Field>
                <Field name="new_password1" validate={composeValidators(required)}>
                  {({ input, meta}) => (
                    <div className="">
                      <input {...input} className="form-control Input_Text" type="password" placeholder={upperFirst(intl.messages[`${formName}.password`])} />
                      <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                        {/*(meta.error || meta.submitError) && meta.touched && upperFirst(intl.messages[`form.error.${(meta.error || meta.submitError)}`])*/}
                        {(meta.error || meta.submitError) && meta.touched && upperFirst(meta.error || meta.submitError)}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="new_password2" validate={composeValidators(required)}>
                  {({ input, meta}) => (
                    <div className="">
                      <input {...input} className="form-control Input_Text" type="password" placeholder={upperFirst(intl.messages[`${formName}.password2`])} />
                      <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                        {/*(meta.error || meta.submitError) && meta.touched && upperFirst(intl.messages[`form.error.${(meta.error || meta.submitError)}`])*/}
                        {(meta.error || meta.submitError) && meta.touched && upperFirst(meta.error || meta.submitError)}
                      </div>
                    </div>
                  )}
                </Field>
                <div className={formButtonCssClassName}>
                  <Button
                      disabled={validating}
                      type={"submit"}
                    >
                    {toUpper(intl.messages[`${formName}.reset`])}
                  </Button>
                </div>
              </div>

            </form>
          )}
        />
        </div>
      </Alignment>
    )
  }

  render() {
    return (
      <div className={baseCssClassName}>
        <LanguageBar />
        {this._renderForm()}
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { intl } = state;
    return {
        intl
    };
}

const connectedFormPage = connect(mapStateToProps)(FormPage);
export { connectedFormPage as PasswordResetPage };
