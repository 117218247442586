import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { history, authHeader, handleResponse, convertToAPIValues} from '../_helpers';
import { PAGE_SIZE, URLS, AVAILABLE_LANGUAGES,
        required, noChineseCharacters, composeValidators} from '../_config';

import { NavBarPage, SideBarPage } from '../HomePage';

import { userService } from '../_services';
import { settingsActions } from '../_actions';

import cn from 'classnames';
import { upperFirst, toUpper } from 'lodash';

import Spinner from '../_components/Spinner';

import { SelectAdapter, SelectAdapterNonClearable, errorAlert } from '../_components';
import {default as AutoSave} from '../_components/AutoSave'

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";
import setFieldData from 'final-form-set-field-data'

import { FormattedMessage } from 'react-intl';
import localeData from '../../locales/data.json';
import { updateIntl } from 'react-intl-redux'

import '../../css/userProfile.style.scss';

const baseCssClassName = 'UserProfilePage';
const contentWrapperCssClassName = `${baseCssClassName}__ContentWrapper`;
const detailHeadingCssClassName = `${baseCssClassName}__heading`;
const detailHeadingTitleCssClassName = `${detailHeadingCssClassName}__title`;
const detailHeadingNameCssClassName = `${detailHeadingCssClassName}__name`;
const formCssClassName = `${baseCssClassName}__form`;
const detailsCssClassName = `${formCssClassName}__details`;
const rowFormCssClassName = `${detailsCssClassName}__row`;
const rowLanguageFormCssClassName = `${rowFormCssClassName}-language`;
const rowEmailFormCssClassName = `${rowFormCssClassName}-emailAddress`;
const rowAddressFormCssClassName = `${rowFormCssClassName}-address`;
const rowPhoneNumberFormCssClassName = `${rowFormCssClassName}-phoneNumber`;

const SavingIndicator = ({ name }) => (
  <Field
    name={name}
    subscription={{ data: true }}
    render={({
      meta: {
        data: { saving }
      }
    }) => (saving ? <div className="saving">Saving</div> : null)}
  />
)

class UserProfilePage extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){

  }

  onUpdateSettings = async (values) => {
    console.log(values);
    const { intl } = this.props;
    const convertedValues = convertToAPIValues(values)
    try {
      const result = await userService.updateSettings(convertedValues);
      this.props.dispatch(settingsActions.setLanguage(convertedValues.language))
      return
    } catch (err) {
      if (err.error && err.error.code) {
        return {[FORM_ERROR] : err.error.code }
      }else{
        errorAlert(intl)
        return err
      }
    }
  }

  onChangeLanguage = async (input) => {
    const { intl } = this.props;
    try {
      const result = await userService.updateSettings({language: input.value});
      this.props.dispatch(settingsActions.setLanguage(input.value))
      return
    } catch (err) {
      if (err.error && err.error.code) {
        return {[FORM_ERROR] : err.error.code }
      }else{
        errorAlert(intl)
        return err
      }
    }
  }

  render(){
    const { intl, settings, users } = this.props;
    const { userInfo } = users;
    const { first_name, last_name, email } = userInfo;
    let userAddress = userInfo.dental_practice && userInfo.dental_practice.address;
    let dentalPractice = userInfo.dental_practice && userInfo.dental_practice.practice_name;
    const initialValues = {language: userInfo.language || 'en', email_address: email, practice_name: dentalPractice , address: userAddress}
    return (
      <div className={baseCssClassName}>
        <NavBarPage />
        <SideBarPage location={this.props.location} />
        <div className={contentWrapperCssClassName}>
          <div className={detailHeadingCssClassName}>
            <div className={detailHeadingTitleCssClassName}>{toUpper(intl.messages['userProfile.heading.title'])}</div>
            <div className={detailHeadingNameCssClassName}>
              <FormattedMessage
                id={'string.usersFullName'}
                description={'given users name'}
                defaultMessage={intl.messages['string.usersFullName']}
                values={{
                  givenName: first_name,
                  familyName: last_name
                }}
              />
            </div>
          </div>
          <div className={formCssClassName}>
            <Form
              onSubmit={this.onUpdateSettings}
              save={this.onUpdateSettings}
              initialValues = {initialValues}
              mutators={{ setFieldData }}
              render = {({
                submitError,
                formError,
                handleSubmit,
                mutators,
                form,
                reset,
                submitting,
                pristine,
                validating,
                values
              }) => (
                  <form
                    id={'userSettingsForm'}
                    onSubmit={handleSubmit}
                  >
                    {/*<AutoSave setFieldData={form.mutators.setFieldData} activeOnChanges={['language']} save={this.onUpdateSettings} />*/}
                    <div className={detailsCssClassName}>
                      <div className={rowFormCssClassName}>
                        <Field
                          className={rowLanguageFormCssClassName}
                          name="language"
                          items={AVAILABLE_LANGUAGES}
                          intl={intl}
                          onChange={this.onChangeLanguage}
                          label={toUpper(intl.messages['userProfile.language'])}
                          component={SelectAdapterNonClearable}
                          placeholder={upperFirst(intl.messages['userProfile.language'])}
                          validate={required}
                          intl={intl}
                        />
                        <SavingIndicator name="language" />
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="email_address" validate={required}>
                        {({ input, meta}) => (
                          <div className={rowEmailFormCssClassName}>
                            <label className="Input__Label">{toUpper(intl.messages['userProfile.emailAddress'])}</label>
                            <input {...input} disabled className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl.messages['userProfile.emailAddress'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="address" validate={required}>
                        {({ input, meta}) => (
                          <div className={rowAddressFormCssClassName}>
                            <label className="Input__Label">{toUpper(intl.messages['userProfile.address'])}</label>
                            <input {...input} disabled className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl.messages['userProfile.address'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="phone_number" validate={required}>
                        {({ input, meta}) => (
                          <div className={rowPhoneNumberFormCssClassName}>
                            <label className="Input__Label">{toUpper(intl.messages['userProfile.phoneNumber'])}</label>
                            <input {...input} disabled className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl.messages['userProfile.phoneNumber'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                    </div>
                  </form>
              )}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { intl, users, settings } = state;
    return {
        intl,
        settings,
        users
    };
}

const connectedUserProfilePage = withRouter(connect(mapStateToProps)(UserProfilePage));
export { connectedUserProfilePage as UserProfilePage };
