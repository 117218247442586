import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
//import config from '../../config'

const classes = {
  switchBase: {
    height: 'auto'
  },
  iconChecked: {
    backgroundColor:'#ffffff'
  }
}
/*
const themeForamen = createMuiTheme({
  palette: {secondary: {main: config.REGION_COLORS.FORAMEN } },
  overrides: {MuiSwitch: {checked: {'& + $bar': {opacity: 1}}}}
})

const themeCanal = createMuiTheme({
  palette: {secondary: {main: config.REGION_COLORS.CANAL } },
  overrides: {MuiSwitch: {checked: {'& + $bar': {opacity: 1}}}}
})

const themeSinus = createMuiTheme({
  palette: {secondary: {main: config.REGION_COLORS.SINUS } },
  overrides: {MuiSwitch: {checked: {'& + $bar': {opacity: 1}}}}
})*/

class CustomSwitchBase extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
  }

	_handleChange = (event) => {
		const {
			onChange,
		} = this.props;
		if ( onChange ) {
			onChange(event);
		}
	}

  render() {
    const {classes} = this.props;
    let theme = "";
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <Switch
            disableRipple
            checked={this.props.checked}
            onChange={this._handleChange}
            value={this.props.value}
            classes={{
              switchBase: classes.switchBase,
              iconChecked: classes.iconChecked,
            }}
          />
        </MuiThemeProvider>
      </div>
    );
  }
};

class SwitchForamenBase extends React.Component {
  static propTypes = {
    value: PropTypes.String,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
  }

	_handleChange = (event) => {
		const {
			onChange,
		} = this.props;
		if ( onChange ) {
			onChange(event);
		}
	}

  render() {
    return (
      <div>
        <Switch
          checked={this.props.checked}
          onChange={this._handleChange}
          value={this.props.value}
          classes={this.props.classes}
          color="primary"
        />
      </div>
    );
  }
}

const CustomSwitchwithStyles = withStyles(classes)(CustomSwitchBase)
const CustomSwitch = CustomSwitchwithStyles;

export {CustomSwitch};
