import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';
import cn from 'classnames';
import { ADMIN_URLS } from '../_config';

//sidebar images
import sidebar1 from '../../images/shared/sidebar-1.png';
import sidebar2 from '../../images/shared/sidebar-2.png';
import sidebar3 from '../../images/shared/sidebar-3.png';
import sidebar4 from '../../images/shared/sidebar-4.png';
import sidebar5 from '../../images/shared/sidebar-5.png';

import {SchedulingIcon, AdminIcon, MessagingIcon, NotepadIcon, ReportIcon } from './SideBarParts';

class SideBarPage extends React.Component {
  constructor(props){
    super(props);
  }
  render() {
    const { location } = this.props;
    const { userInfo } = this.props.users;
    if (location){
      return (
        <nav className="Sidebar">
          <Link to="/">
            <button onClick={null/*() => history.push("/")*/} className={cn("Sidebar__link ", {"Sidebar__link--active": (location.pathname == "/") || (location.pathname.startsWith("/reports")) || (location.pathname.startsWith("/requests")) || (location.pathname.startsWith("/patient")) } )}>
              <NotepadIcon className={cn("Sidebar__icon--notepad ", {"Sidebar__icon--active": (location.pathname == "/") || (location.pathname.startsWith("/reports")) || (location.pathname.startsWith("/requests")) || (location.pathname.startsWith("/patient"))  } )}/>
            </button>
          </Link>
          {/*<Link to="/patient">
            <button className={cn("Sidebar__link ", {"Sidebar__link--active": (location.pathname.startsWith("/patient") )} )} >
              <MessagingIcon className={cn("Sidebar__icon-messaging", {"Sidebar__icon-messaging--active": (location.pathname.startsWith("/patient"))  } )}/>
            </button>
          </Link>
          <Link to="/billing">
            <button className={cn("Sidebar__link ", {"Sidebar__link--active": (location.pathname.startsWith("/billing") )} )} >
              <NotepadIcon className={cn("Sidebar__icon-notepad ", {"Sidebar__icon-notepad--active": (location.pathname.startsWith("/billing"))  } )}/>
            </button>
          </Link>
          <Link to="/billing">
            <button className={cn("Sidebar__link ", {"Sidebar__link--active": (location.pathname.startsWith("/reports") )} )} >
              <ReportIcon className={cn("Sidebar__icon ", {"Sidebar__icon--active": (location.pathname == "/") || (location.pathname.startsWith("/reports"))  } )}/>
            </button>
          </Link>*/}
          { userInfo.is_staff &&
            <Link to={ADMIN_URLS.home}>
              <button onClick={null} className={cn("Sidebar__link ", {"Sidebar__link--active": (location.pathname.startsWith("/admin") || (location.pathname.startsWith("/bd/admin")))} )} >
                <AdminIcon className={cn("Sidebar__icon ", {"Sidebar__icon--active": (location.pathname.startsWith("/admin")  || (location.pathname.startsWith("/bd/admin")) )} )} />
              </button>
            </Link>
          }
        </nav>
      )
    } else {
      return (<div></div>)
    }
  }
}

function mapStateToProps(state) {
    const { authentication, users } = state;
    const { user, loggedIn } = authentication;
    return {
        user,
        users,
        loggedIn
    };
}

const connectedSideBar= connect(mapStateToProps)(SideBarPage);
export { connectedSideBar as SideBarPage };
