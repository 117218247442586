import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import cn from 'classnames'

import { toUpper } from 'lodash';
import { URLS } from '../_config';

import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const baseCssClassName = 'Breadcrumb';
const activeCssClassName = `${baseCssClassName}-active`;
const textCssClassName = `${baseCssClassName}__text`;
const iconCssClassName = `${baseCssClassName}__icon`;

class Breadcrumb extends React.Component{

  render(){

    const { currentStep, intl } = this.props;

    return (
      <div className={baseCssClassName}>
        <div className={cn(textCssClassName, activeCssClassName)}>{toUpper(intl.messages['string.newRequestPage.detailTitle'])}</div>
        <div className={iconCssClassName}><KeyboardArrowRight style={{fontSize:20}} /></div>
        <div className={cn(textCssClassName, {[activeCssClassName]: currentStep === 1})}>{toUpper(intl.messages['string.newRequestPage.imagesTitle'])}</div>
        <div className={iconCssClassName}><KeyboardArrowRight style={{fontSize:20}} /></div>
        <div className={cn(textCssClassName)}>{toUpper(intl.messages['patient.patientSummary'])}</div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { intl } = state;
    return {
        intl
    };
}

const connectedBreadcrumb= connect(mapStateToProps)(Breadcrumb);
export { connectedBreadcrumb as Breadcrumb };
