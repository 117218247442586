import React from 'react';
import { Router, Route , withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'babel-polyfill';
import ReactTooltip from 'react-tooltip'
import { history } from '../_helpers';
import { alertActions, userActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { NavBarPage, SideBarPage } from '../HomePage';
import LoadingDialog from '../_components/LoadingDialog/LoadingDialog';
import { LoginPage } from '../LoginPage';
//import { RegisterPage } from '../RegisterPage';
//import { SchedulingPage } from '../SchedulingPage';
//import { AppointmentPage } from '../AppointmentPage';
//import { PatientSearchPage } from '../PatientSearchPage';
import { ConsultRequestsPage } from '../ConsultRequestsPage';
import { NewConsultRequestPage } from '../NewConsultRequestPage';
import { PatientPage } from '../PatientPage';
import { PasswordForgotPage } from '../PasswordForgotPage';
import { PasswordResetPage } from '../PasswordResetPage';
import { UserProfilePage } from '../UserProfilePage';
import { OverarchingDialog } from '../OverarchingDialog';
import { AdminPage,
        AdminDentalPracticePage,
        AdminCreateNewAccountPage,
        AdminDentalAccountPage
        } from '../AdminPage';
import { TestPage } from '../TestPage';

import 'moment/locale/zh-cn';
import 'moment/locale/th';

import { settingsActions } from '../_actions';
import { settingsService } from '../_services';
import Notifications from 'react-notification-system-redux';
import localeData from '../../locales/data.json';
import { updateIntl } from 'react-intl-redux'


import 'react-select/dist/react-select.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'react-phone-number-input/style.css';
import '../../css/bootstrap.min.css';
import '../../css/index.css';
import '../../css/home.style.scss';
import '../../css/admin.style.scss';

import PopupDialog, { getFooterCloseButton, PopupDialogContentText } from '../_components/Popup/PopupDialog';

const baseCssClassName = 'ImageUploading';
const progressCssClassName = `${baseCssClassName}__Progress`;

class App extends React.Component {
    constructor(props) {
        super(props);
        history.listen((location, action) => {
            // clear alert on location change
            this.props.dispatch(Notifications.removeAll());
        });
    }

    componentDidMount(){
      //this.props.dispatch(userActions.getInfo());
      // if there is no language detected in localstorage
      if (localStorage.lang === null || localStorage.lang === 'null' || localStorage.lang === 'undefined'){
        settingsService.getVersioning()
          .then(
            data => {
              this.props.dispatch(settingsActions.setVersioning(data.version, data.lang))
            },
            error => {
              // send notification about error

            }
          )
      }
    }

    render() {
        return (
          <Router history={history}>
            <div style={{fontFamily: "'Proxima Nova', Georgia, sans-serif"}} >
              <OverarchingDialog />
              <Route exact path="/test" component={TestPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/reset/:uid/:token" component={PasswordResetPage} />
              <Route exact path="/forgot" component={PasswordForgotPage} />
              <PrivateRoute exact path="/" component={ConsultRequestsPage} />
              <PrivateRoute exact path="/requests/new" component={NewConsultRequestPage} />
              <PrivateRoute exact path="/patient/:patient_id/:tab" component={PatientPage} />
              <PrivateRoute exact path="/patient/:patient_id" component={PatientPage} />
              <PrivateRoute exact path="/profile" component={UserProfilePage} />
              <PrivateRoute exact path="/bd/admin" component={AdminPage} />
              <PrivateRoute exact path="/bd/admin/:page_name(clinics|accounts)" component={AdminPage} />
              <PrivateRoute exact path="/bd/admin/newAccount" component={AdminCreateNewAccountPage} />
              <PrivateRoute exact path="/bd/admin/clinics/:clinic_id" component={AdminDentalPracticePage} />
              <PrivateRoute exact path="/bd/admin/accounts/:account_id" component={AdminDentalAccountPage} />
            </div>
          </Router>
        );
    }
}

function mapStateToProps(state) {
    const { users, alert, notifications } = state;
    return {
        alert,
        notifications,
        users
    };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
