import moment from 'moment';
import {MOMENT_DATE_FORMAT} from '../_config'

/**
 * moment requires language with region or something did
 * what do i need to do for region
 */
function localeFormatDate(time, locale, format){
  var regionFormat = format || MOMENT_DATE_FORMAT;
  if (!time){
    return "-"
  }
  //const actualTime = moment(time).locale(locale).format(regionFormat);
  return moment(time).locale(locale).format(regionFormat);
};

function localeFormatFromNow(time, locale){
  if (!time){
    return "-"
  }
  //const actualTime = moment(time).locale(locale).fromNow();
  return moment(time).locale(locale).fromNow();
};

export { localeFormatDate, localeFormatFromNow };
