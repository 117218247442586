import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import { range } from 'lodash';

import { Form, Field } from 'react-final-form';
import { SelectAdapter } from '../_components';
import Select from 'react-select';

const baseCssClassName = "Pagination";
const pageCssClassName = baseCssClassName + "__currentPage";
const controlsCssClassName = baseCssClassName + "__controls";
const prevPageCssClassName = "prevPageButton";
const nextPageCssClassName = "nextPageButton";
const activeCssClassName = "active";

const pageNumberCssClassName = `${baseCssClassName}__PageNumber`;

class PaginationDropdown extends React.Component{
  constructor(props){
    super(props);
  }

  pageClick = (inputValue) => {
    if (inputValue.value !== this.props.currentOffset){
      this.props.onPageNumberClick(inputValue.value);
    }
  }

  render(){
    const { totalPages, currentOffset } = this.props;
    const itemList = range(totalPages? totalPages : 1).map((pageNum) => {
      return {value: pageNum, label: pageNum+1}
    })
    console.log("item list", itemList)
    return(
      <Select
        className={pageNumberCssClassName}
        arrowRenderer={() => <UnfoldMore />}
        onChange={inputValue => this.pageClick(inputValue)}
        options={itemList}
        disabled={itemList.length < 2}
        clearable={false}
        searchable={false}
        onBlurResetsInput={false}
				onSelectResetsInput={false}
        value={currentOffset}
      />
    )
  }
};


class PaginationDropdown2 extends React.Component{
  constructor(props){
    super(props);
  }

  pageClick = (values) => {
    const { current_page } = values;
    this.props.onPageNumberClick(current_page);
  }

  render(){

    const { totalPages, currentOffset } = this.props;
    const itemList = range(totalPages).map((pageNum) => {
      return {value: pageNum, label: pageNum+1}
    })
    return(
      <Form
        onSubmit={this.pageClick}
        initialValues = {{current_page: currentOffset}}
        render = {({
          form,
          reset,
          submitting,
          validating,
          values
        }) => (
          <div className={''}>
            <Field
              className={''}
              name="current_page"
              items={itemList}
              component={SelectAdapter}
            />
          </div>
        )}
      />
    )
  }
};

class Pagination extends React.Component {
  static propTypes = {
    currentOffset: PropTypes.number,
    totalPages: PropTypes.number,
    onPageClick: PropTypes.func
  }

  constructor(props){
    super(props);
  }

  render() {
    // keyboard_arrow_right, keyboard_arrow_left icons
    // prev page is inactive when on first page
    // next page is inactive when currentoffset is on last page
    const { onPageClick, onPageNumberClick, currentOffset, totalPages, intl } = this.props;
    const prevPageActive = (currentOffset === 0) ? false : true;
    const nextPageActive = (currentOffset >= totalPages-1) ? false : true;
    const totalPagesMin = Math.max(totalPages, 1)
    return (
      <div className={baseCssClassName}>
        <div className={pageCssClassName}>
        <span>{intl.messages['string.paginationShowing']}</span>
          <PaginationDropdown
            currentOffset = {currentOffset}
            totalPages = {totalPages}
            onPageNumberClick = {onPageNumberClick}
          />
          <FormattedMessage
              id={'string.paginationOutOfTotal'}
              description= {'total pages'}
              defaultMessage={intl.messages['string.paginationOutOfTotal']}
              values={{
                totalPages: totalPagesMin,
                currentPage: currentOffset+1
              }}
          />
        </div>
        <div className={controlsCssClassName}>
          <button
            className={cn(prevPageCssClassName, {[`${prevPageCssClassName}--${activeCssClassName}`]: prevPageActive})}
            disabled = {!prevPageActive}
            onClick = {() => onPageClick(-1)}
          >
            <KeyboardArrowLeft />
          </button>
          <button
            className={cn(prevPageCssClassName, {[`${nextPageCssClassName}--${activeCssClassName}`]: nextPageActive})}
            disabled = {!nextPageActive}
            onClick = {() => onPageClick(1)}
          >
            <KeyboardArrowRight />
          </button>
        </div>
      </div>
    )
  }
}

export { Pagination };
