import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';

import { PAGE_SIZE, URLS } from '../_config';

import { NavBarPage, SideBarPage } from '../HomePage';
import { ActivityLog } from '../ActivityLog';
import { consultRequestsActions } from '../_actions';

import FontAwesome from 'react-fontawesome';
import TrackVisibility from 'react-on-screen';

import cn from 'classnames';
import { upperFirst, toUpper, toLower, startCase } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Popover from '@material-ui/core/Popover';
import { CustomButton, CustomDialogTitle, CustomDialogContentText, CustomDialogActions } from '../_components';

import { ConsultRequestCard } from './ConsultRequestCard';
import { Pagination, ConsultRequestListLoader } from '../_components';

import Add from '@material-ui/icons/Add';

import '../../css/consultRequests.style.scss';
import '../../css/consultRequestsTable.style.scss';

// TODO: maybe move to config
const requestStatusList = ['active', 'done', 'canceled']


// all, active, shipping, completed, canceled
const Tabs = ({intl, currentTab, handleTabChange}) => (
		<ul className="Tabs">
			<Tab
				tabKey = {"all"}
				type={ ( currentTab === "all") ? "active" : "inactive" }
				name={toUpper(intl.messages['string.all'])}
				onCompClick = {handleTabChange}
			/>
			<Tab
				tabKey = {"active"}
				type={ ( currentTab === "active") ? "active" : "inactive" }
				name={toUpper(intl.messages['string.active'])}
				onCompClick = {handleTabChange}
			/>
			<Tab
				tabKey = {"shipping"}
				type={ ( currentTab === "shipping") ? "active" : "inactive" }
				name={toUpper(intl.messages['string.shipping'])}
				onCompClick = {handleTabChange}
			/>
			<Tab
				tabKey = {"completed"}
				type={ ( currentTab === "completed") ? "active" : "inactive" }
				name={toUpper(intl.messages['string.done'])}
				onCompClick = {handleTabChange}
			/>
			<Tab
				tabKey = {"canceled"}
				type={ ( currentTab === "canceled") ? "active" : "inactive" }
				name={toUpper(intl.messages['string.canceled'])}
				onCompClick = {handleTabChange}
			/>
		</ul>
)

const Tab = ({type, name, tabKey, onCompClick}) => (
	<li
		className={ cn("ConsultRequestTab",
								{"ConsultRequestTab--inactive": type == "inactive"},
								{"ConsultRequestTab--active": type == "active"}) }
		onClick = {() => onCompClick(tabKey)}
	>
		<span className="ConsultRequestTab__time" >{name}</span>
	</li>
)

const baseCssClassName = 'ConsultRequest';
const contentWrapperCssClassname = 'ContentWrapper';
const newRequestBarCssClassName = `${baseCssClassName}__newRequestBar`;
const requestButtonCssClassName = `${newRequestBarCssClassName}__requestButton`;
const requestButtonTextCssClassName = `${requestButtonCssClassName}__text`;
const controlsBarCssClassName = `${baseCssClassName}__controlsBar`;
const consultRequestContent = 'ConsultRequestContent';
const consultRequestlist = 'ConsultRequestList';
const consultRequestListHeaders = `${consultRequestlist}__Headers`;

class RequestTableHeader extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    const { clinicType, intl } = this.props;

    if (clinicType == 'scanning_only') {
      return (
        <React.Fragment>
          <div className="cell requestPatientName">{startCase(toLower(intl.messages['string.requestPatientName']))}</div>
          <div className="cell requestPractice">{startCase(toLower(intl.messages['userProfile.phoneNumber']))}</div>
          <div className="cell requestPractice">{startCase(toLower(intl.messages['userProfile.emailAddress']))}</div>
          <div className="cell requestDate">{startCase(toLower(intl.messages['newRequestForm.appointment_date']))}</div>
          <div className="cell requestDescription">{startCase(toLower(intl.messages['string.lastMessage.message']))}</div>
          <div className="cell requestStartDate">{startCase(toLower(intl.messages['string.lastMessage.date']))}</div>
          <div className="cell requestStatus">{startCase(toLower(intl.messages['string.requestStatus']))}</div>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <div className="cell requestPatientName">{startCase(toLower(intl.messages['string.requestPatientName']))}</div>
          <div className="cell requestPractice">{startCase(toLower(intl.messages['string.requestPractice']))}</div>
          <div className="cell requestDate">{startCase(toLower(intl.messages['string.requestDate']))}</div>
          <div className="cell requestDescription">{startCase(toLower(intl.messages['string.lastMessage.message']))}</div>
          <div className="cell requestStartDate">{startCase(toLower(intl.messages['string.lastMessage.date']))}</div>
          <div className="cell requestSteps">{startCase(toLower(intl.messages['string.requestSteps']))}</div>
          <div className="cell paymentStatus">{startCase(toLower(intl.messages['string.requestDocumentStatus']))}</div>
          <div className="cell requestStatus">{startCase(toLower(intl.messages['string.requestStatus']))}</div>
        </React.Fragment>
      )
    }

  }

}

class ConsultRequestsPage extends React.Component {
	constructor(props){
		super(props);
    this.state = {
      currentTab: "active",
      pageOffset: 0
    }
	}

	componentDidMount() {
		this.props.dispatch(consultRequestsActions.doConsultRequestSearch())
	}

  componentDidUpdate(prevProps) {
    if (prevProps.settings.adminCurrentDentalPractice !== this.props.settings.adminCurrentDentalPractice){
		    this.props.dispatch(consultRequestsActions.doConsultRequestSearch());
    }
	}

  handleTabChange = (tabKey) => {
    // reload the request list and thats it.
    this.setState({currentTab: tabKey, pageOffset: 0})
  	this.props.dispatch(consultRequestsActions.doConsultRequestSearch(0, tabKey))
  }

	handleKeyPress = (e) => {
		if (e.key == 'Enter'){
      this.setState({pageOffset: 0})
			this.props.dispatch(consultRequestsActions.doConsultRequestSearch(0, this.state.currentTab));
			e.target.blur();
		}
	}

	handleSearchClick = (event) => {
    // seasrch by patient id
		event.preventDefault();
    this.setState({pageOffset: 0})
		this.props.dispatch(consultRequestsActions.doConsultRequestSearch(0));
	}

	handleSearchInputChange = (e) => {
		const {value } = e.target;
		this.props.dispatch(consultRequestsActions.searchInputChange(value));
	}

	handlePaginationClick = (direction) => {
      // dependednt on prev page or next page
      const offset = this.state.pageOffset;
      const newOffset = offset+direction
      this.setState({pageOffset: newOffset});
	    this.props.dispatch(consultRequestsActions.doConsultRequestSearch(newOffset, this.state.currentTab));
	}

  handlePaginationNumberClick = (newOffset) => {
    this.setState({pageOffset: newOffset});
    this.props.dispatch(consultRequestsActions.doConsultRequestSearch(newOffset, this.state.currentTab));
  }

  handleNewRequestClick = () => {
    history.push(URLS.newConsultRequest);
  }

	render(){
		const {
				consultRequestSearchInput, consultRequestFormLoading,
				consultRequestFormError, consultRequestLoadMoreOffset, consultRequestSearchResults,
        consultRequestTotalResults
		} = this.props.consultRequests;
    const { adminCurrentDentalPractice } = this.props.settings;
    const { userInfo } = this.props.users;
    // totalPages/pagesize
    const totalPages = Math.ceil(consultRequestTotalResults/PAGE_SIZE);
    console.log("total pages", totalPages)
    const { intl } = this.props;
		return (
			<div className="Admin ConsultRequest" key={adminCurrentDentalPractice || 'default'}>
				<NavBarPage />
				<SideBarPage location={this.props.location} />
        <div className={contentWrapperCssClassname}>
          <Tabs
            intl={intl}
            currentTab = { this.state.currentTab }
            handleTabChange = {this.handleTabChange}
          />
          <div className="TabContent">
            <div className="TabContentWrapper">
              <div className={newRequestBarCssClassName}>
                <button className={requestButtonCssClassName}
                  onClick = {this.handleNewRequestClick}
                >
                  <span className={requestButtonTextCssClassName}>
										{toUpper(intl.messages['string.addNewRequest'])}
									</span>
									<Add />
                </button>
              </div>
              <div className={controlsBarCssClassName}>
                <div className="SearchInput">
                  <form className="" onSubmit={this.handleSearchClick}>
                    <span className="SearchInput__search-wrapper">
                      <FontAwesome className="SearchInput__search-icon" name="search" />
                      <input
                        type="text"
                        className="form-control SearchInput__search"
                        onKeyPress={this.handleKeyPress}
                        placeholder= {intl.messages['consultRequests.search.placeholder']}
                        name="consultRequestSearchInput"
                        value={consultRequestSearchInput}
                        onChange={this.handleSearchInputChange}
                      />
                    </span>
                  </form>
                </div>
                <Pagination
                  intl = {intl}
                  currentOffset= {this.state.pageOffset}
                  totalPages= {totalPages}
                  onPageClick= {this.handlePaginationClick}
                  onPageNumberClick = {this.handlePaginationNumberClick}
                />
              </div>
              <div className={consultRequestContent}>
                <div className={cn(consultRequestlist, 'tbl')}>
                  <div className={cn(consultRequestListHeaders, 'row')}>
                    <RequestTableHeader
                      clinicType={userInfo && userInfo.dental_practice && userInfo.dental_practice.type }
                      intl = {intl}
                    />
                  </div>
                  { consultRequestFormLoading && <ConsultRequestListLoader />}
                  {consultRequestSearchResults.map( (request, index) =>
	                    <ConsultRequestCard
	                      key = {index}
	                      intl = {this.props.intl}
                        clinicType = {userInfo.dental_practice && userInfo.dental_practice.type}
	                      intlLocale = {intl.locale}
	                      request = {request.consult_request}
                        last_message = {request.last_message}
                        isNew = {request.is_new}
                        isStaff = {userInfo.is_staff}
	                    />
                  )}
                  {
                    consultRequestSearchResults.length == 0 &&
                    <div className="noResults">{intl.messages['consultRequests.noResults']}</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        { /* <ActivityLog /> */}
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { consultRequests, intl, users, settings } = state;
    return {
        consultRequests,
        intl,
        settings,
        users
    };
}

const connectedConsultRequestsPage = withRouter(connect(mapStateToProps)(ConsultRequestsPage));
export { connectedConsultRequestsPage as ConsultRequestsPage };
