import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { history, authHeader, handleResponse, convertToAPIValues} from '../_helpers';
import { PAGE_SIZE, URLS, AVAILABLE_LANGUAGES, ADMIN_API_URLS, AVAILABLE_COUNTRIES,
        GENDERS, MOMENT_DATE_FORMAT,
        required, noChineseCharacters, composeValidators} from '../_config';

import { NavBarPage, SideBarPage } from '../HomePage';

import { adminService } from '../_services';
import { adminActions } from '../_actions';

import cn from 'classnames';
import { upperFirst, toUpper } from 'lodash';

import Spinner from '../_components/Spinner';

import { SelectAdapter, SelectAdapterNonClearable, errorAlert } from '../_components';
import {default as AutoSave} from '../_components/AutoSave';

import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import setFieldData from 'final-form-set-field-data';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

import { FormattedMessage } from 'react-intl';
import localeData from '../../locales/data.json';
import { updateIntl } from 'react-intl-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput'

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';
import '../../css/userProfile.style.scss';
import '../../css/organizationSearch.style.scss';
import '../../css/admin.style.scss';

const baseCssClassName = 'UserProfilePage';
const contentWrapperCssClassName = `${baseCssClassName}__ContentWrapper`;
const detailsHeadingCssClassName = `${baseCssClassName}__heading`;
const detailsHeadingTitleCssClassName = `${detailsHeadingCssClassName}__title`;
const detailsHeadingNameCssClassName = `${detailsHeadingCssClassName}__name`;
const formCssClassName = `${baseCssClassName}__form`;
const detailsCssClassName = `${formCssClassName}__details`;
const rowFormCssClassName = `${detailsCssClassName}__row`;
const halfRowFormCssClassName = `${rowFormCssClassName}__halfrow`;
const rowLanguageFormCssClassName = `${rowFormCssClassName}-language`;
const rowEmailFormCssClassName = `${rowFormCssClassName}-emailAddress`;
const rowAddressFormCssClassName = `${rowFormCssClassName}-address`;
const rowPhoneNumberFormCssClassName = `${rowFormCssClassName}-phoneNumber`;

const SavingIndicator = ({ name }) => (
  <Field
    name={name}
    subscription={{ data: true }}
    render={({
      meta: {
        data: { saving }
      }
    }) => (saving ? <div className="saving">Saving</div> : null)}
  />
)

const DayPickerAdapter = ({input, meta, items, label, intl, className, disabled, ...rest }) => {
  return (
    <div className={className}>
      <label>{label}</label><br />
      <DayPickerInput
        inputProps = {{...input, autoComplete: "off",disabled: disabled, className:cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}}
        onDayChange={day=> input.onChange(day)}
        formatDate={formatDate}
        parseDate={parseDate}
        format={MOMENT_DATE_FORMAT || "L"}
        dayPickerProps={{
          locale: intl.locale,
          localeUtils: MomentLocaleUtils,
        }}
        locale = {intl.locale}
        value = {input.value || ""}
        className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
        placeholder={label}
      />
      <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
        {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
      </div>
    </div>
  )}


class PhoneAdapter extends React.Component{
  render(){
    const { input, meta, className, label, value, placeholder, intl, name, country, ...rest } = this.props;
    return(
      <div className={className}>
        <label className="Input__Label">{label}</label>
        <PhoneInput
          className={className}
          meta={meta}
          input={input}
          value={input.value}
          displayInitialValueAsLocalNumber={true}
          country={country}
          countries={AVAILABLE_COUNTRIES}
          onChange={value => input.onChange(value)}
          placeholder={placeholder}
          inputClassName={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )}
        />
        <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
          {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
        </div>
        </div>
    )
  }
}

class WholesalerSelect extends React.Component{

    loadOptions = () => {
      const requestOptions = {
          method: 'GET',
          headers: authHeader()
      };
      let url = ADMIN_API_URLS.getERPWholesalers.url;
      return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((json) => {
        return { options: json.search_list.map( (obj, indx) => {
          return {'value': obj.id, 'label': `${obj.name} (${obj.country[1]}) - ${obj.email} `}
        })};
      });
    }

    render(){
      const { input, meta, className, label, value, placeholder, intl, name, ...rest } = this.props;
      return(
        <div className={className}>
          <label>{label}</label>
          <Select.Async
            className={orgSearchInputCssClassName}
            name={name || "wholesaler"}
            placeholder={placeholder || "select..."}
            value={input.value}
            cacheOptions
            defaultOptions
            onChange={inputValue => input.onChange(inputValue)}
            multi={false}
            backspaceRemoves={true}
            loadOptions={this.loadOptions}
          />
          <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
            {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
          </div>
        </div>

      )
    }
}

class UserRoleSelect extends React.Component{

  loadOptions = () => {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = ADMIN_API_URLS.getUserRoles.url;
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
      return { options: json.search_list };
    });
  }

  render(){
    const { input, meta, className, label, value, placeholder, intl, name, ...rest } = this.props;
    return(
      <div className={className}>
        <label>{label}</label>
        <Select.Async
          className={orgSearchInputCssClassName}
          name={name || "user_role"}
          placeholder={placeholder || "select..."}
          value={input.value}
          cacheOptions
          defaultOptions
          onChange={inputValue => input.onChange(inputValue)}
          multi={false}
          backspaceRemoves={true}
          loadOptions={this.loadOptions}
        />
        <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
          {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
        </div>
      </div>

    )
  }
}

class BaseSearchInput extends React.Component{
  render(){
    return (
      <Select.Async
        {...this.props}
        multi={false}
        backspaceRemoves={true}
      />
    )
  }
}

const orgSearchInputCssClassName = 'OrganizationSearch';

class DentalPracticeSearchInput extends React.Component{

  handleSearchLoading = (input) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = ADMIN_API_URLS.dentalClinicSearch.url+"?q="+input;
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
      return { options: json.search_list };
    });
  }

  render() {
    const { input, meta, className, label, value, placeholder, intl, name, ...rest } = this.props;
    return (
      <div className={className}>
        <label>{label}</label>
        <BaseSearchInput
            className={orgSearchInputCssClassName}
            name={name || "dental_practice"}
            placeholder={placeholder || "search..."}
            value={input.value}
            autoload={false}
            cacheOptions
            defaultOptions
            loadOptions={this.handleSearchLoading}
            onChange={inputValue => input.onChange(inputValue)}
        />
        <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
          {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
        </div>
      </div>
    )
  }
}

const intl8 = {
  messages: {
    'admin.account.button.save': 'Save Changes',
    'admin.account.country': 'Country',
    'admin.account.heading.title': 'Dental Account',
    'admin.account.organization': 'Organization',
    'admin.account.dental_practice': 'Clinic',
    'admin.account.birthdate': 'Birth Date',
    'admin.account.email_address': 'Email Address',
    'admin.account.phone_number': 'Contact Phone Number',
    'admin.account.first_name': 'First Name',
    'admin.account.last_name': 'Last Name',
    'admin.account.user_role': 'Role',
  }
}

const adminBaseCssClassName = 'AdminSettings';
const adminCountryCssClassName = `${adminBaseCssClassName}__country`;
const adminButtonsCssClassName = `${adminBaseCssClassName}__buttons`;
const adminHalfRowCssClassName = `${adminBaseCssClassName}__halfRow`;
const genderInputCssClassName = `${adminBaseCssClassName}-gender`;
const adminFormSubmitCssClassName = `${adminButtonsCssClassName}__submitButton`;

class AdminDentalAccountPage extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    console.log("Admin account page mount")
    this.props.dispatch(adminActions.getAccountDetails(this.props.match.params.account_id))
  }

  onSave = async (values) => {
    console.log(values);
    const { intl } = this.props;
    const convertedValues = convertToAPIValues(values)
    try {
      const result = await adminService.updateAccountDetails( this.props.match.params.account_id, convertedValues)
      this.props.dispatch(adminActions.updateAccountDetails(result))
      console.log("no error")
    } catch (err) {
      console.log("error start")
      if (err.error && err.error.code) {
        return {[FORM_ERROR] : err.error.code }
      }else{
        console.log("wtf")
        errorAlert(intl)
        return err
      }
      console.log("error doen")
    }
  }

  render(){
    const { intl, dentalAdmin } = this.props;
    //const { userInfo } = users;
    const currentCountry = dentalAdmin.currentAccountDetails.dental_practice && dentalAdmin.currentAccountDetails.dental_practice.country
    const initialValues = {
      user_role: dentalAdmin.currentAccountDetails.user_role,
      birthdate: dentalAdmin.currentAccountDetails.birthdate,
      gender: dentalAdmin.currentAccountDetails.gender,
      first_name: dentalAdmin.currentAccountDetails.first_name,
      last_name: dentalAdmin.currentAccountDetails.last_name,
      phone_number: dentalAdmin.currentAccountDetails.phone_number,
      email_address: dentalAdmin.currentAccountDetails.email_address,
      dental_practice: {value: dentalAdmin.currentAccountDetails.dental_practice && dentalAdmin.currentAccountDetails.dental_practice.id,
                    label: dentalAdmin.currentAccountDetails.dental_practice && dentalAdmin.currentAccountDetails.dental_practice.practice_name
      }
    }
    return (
      <div className={cn(baseCssClassName, adminBaseCssClassName)}>
        <NavBarPage />
        <SideBarPage location={this.props.location} />
        <div className={contentWrapperCssClassName}>
          <div className={detailsHeadingCssClassName}>
            <div className={detailsHeadingTitleCssClassName}>{toUpper(intl8.messages['admin.practice.heading.title'])}</div>
            <div className={detailsHeadingNameCssClassName}>
            </div>
          </div>
          <div className={formCssClassName}>
            <Form
              onSubmit={this.onSave}
              initialValues = {initialValues}
              mutators={{ setFieldData }}
              render = {({
                submitError,
                formError,
                handleSubmit,
                mutators,
                form,
                reset,
                submitting,
                pristine,
                validating,
                values
              }) => (
                  <form
                    id={'dentalAccountForm'}
                    onSubmit={handleSubmit}
                  >
                    {/*<AutoSave setFieldData={form.mutators.setFieldData} activeOnChanges={['language']} save={this.onUpdateSettings} />*/}

                    <div className={detailsCssClassName}>
                      <div className={rowFormCssClassName}>
                        <label className={adminCountryCssClassName}>{toUpper(intl8.messages['admin.account.country'] + ": " +  currentCountry)}</label>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field
                          className={rowLanguageFormCssClassName}
                          name="dental_practice"
                          intl={intl}
                          label={toUpper(intl8.messages['admin.account.dental_practice'])}
                          component={DentalPracticeSearchInput}
                          placeholder={upperFirst(intl8.messages['admin.account.dental_practice'])}
                          autoload={false}
                          cache={false}
                          validate={required}
                          intl={intl}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="email_address" validate={required}>
                        {({ input, meta}) => (
                          <div className={rowEmailFormCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.account.email_address'])}</label>
                            <input {...input} disabled={true} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.account.email_address'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field
                          className={rowLanguageFormCssClassName}
                          name="user_role"
                          intl={intl}
                          label={toUpper(intl8.messages['admin.account.user_role'])}
                          component={UserRoleSelect}
                          placeholder={upperFirst(intl8.messages['admin.account.user_role'])}
                          autoload={false}
                          cache={false}
                          validate={required}
                          intl={intl}
                        />
                      </div>
                      <div className={cn(rowFormCssClassName)}>
                        <Field name="first_name" validate={required}>
                        {({ input, meta}) => (
                          <div className={adminHalfRowCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.account.first_name'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.account.first_name'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                        <Field name="last_name" validate={required}>
                        {({ input, meta}) => (
                          <div className={adminHalfRowCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.account.last_name'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.account.last_name'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field
                          className={rowLanguageFormCssClassName}
                          name="phone_number"
                          intl={intl}
                          country={currentCountry}
                          label={toUpper(intl8.messages['admin.account.phone_number'])}
                          component={PhoneAdapter}
                          placeholder={upperFirst(intl8.messages['admin.account.phone_number'])}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field
                          className={adminHalfRowCssClassName}
                          name="birthdate"
                          intl={intl}
                          autoComplete={"off"}
                          label={toUpper(intl8.messages['admin.account.birthdate'])}
                          component={DayPickerAdapter}
                          placeholder={upperFirst(intl8.messages['admin.account.birthdate'])}
                        />
                        <Field
                          className={cn(adminHalfRowCssClassName, genderInputCssClassName)}
                          name="gender"
                          items={GENDERS}
                          intl={intl}
                          label={toUpper(intl.messages['string.gender'])}
                          component={SelectAdapterNonClearable}
                          placeholder={upperFirst(intl.messages['string.gender'])}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <div className={adminButtonsCssClassName}>
                          <button
                            className={adminFormSubmitCssClassName}
                            disabled={submitting || pristine}
                            type="submit"
                          >
                            {toUpper(intl8.messages['admin.account.button.save'])}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
              )}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { intl, users, dentalAdmin } = state;
    return {
        intl,
        dentalAdmin,
        users
    };
}

const connectedUserAdminDentalAccountPage = withRouter(connect(mapStateToProps)(AdminDentalAccountPage));
export { connectedUserAdminDentalAccountPage as AdminDentalAccountPage };
