import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncPoll from 'react-async-poll';

import { activityPollingSelectors, activityPollingActions } from './polling';
import { activityNotificationsActions } from './actions';
import { localeFormatFromNow } from '../_helpers';
import PerfectScrollbar from 'perfect-scrollbar';
import { upperFirst, toUpper, startCase, reverse} from 'lodash';
import cn from 'classnames';

import { API_URLS, URLS } from '../_config';
import { getFullNameString } from '../_helpers';
import '../../css/activityLog.style.scss';
import '../../css/perfectScrollbar.style.css';

const baseCssClassName = 'ActivityLog';
const logCssClassName = `${baseCssClassName}__Message`;
const logReadCssClassName = `${logCssClassName}-read`;
const logDateCssClassName = `${logCssClassName}__Date`;

class ActivityNotificationsMessage extends Component{
  constructor(props){
    super(props);
    this.state = {
      isRead: false
    }
  }

  componentDidMount(){
    console.log("activity log message mounted...")
  }

  componentDidUpdate(prevProps){
    console.log("activity log message updated......")
  }

  onClick = () => {
    const { element } = this.props
    let url = "";
    let actor_name = element.data.message.sender.dental_practice.practice_name

    switch(element.data.message.type){
      case 'UPLOAD':
        url = URLS.patientPageImageSet.replace('{patientId}', element.data.consult_request.patient.id)
                                      .replace('{imageSetId}', element.data.consult_request.id);
        break;
      case 'REPORT':
        url = URLS.patientPageTabReports.replace('{patientId}', element.data.consult_request.patient.id);
        break;
      case null:
        url = URLS.patientPageReportMessage.replace('{patientId}', element.data.consult_request.patient.id)
                                          .replace('{requestId}', element.data.consult_request.id)
                                          .replace('{messageId}', element.data.message.id);
        break;
      default:
        url = URLS.patientPageTabReports.replace('{patientId}', element.data.consult_request.patient.id)
    }
    this.props.onClick(element.data.message.id, url)
  }

  render(){
    const { isStaff, intl, element } = this.props;

    let url = "";
    let actor_name = element.data.message.sender.dental_practice.practice_name
    let notificationMessage = "";

    switch(element.data.message.type){
      case 'UPLOAD':
        url = URLS.patientPageImageSet.replace('{patientId}', element.data.consult_request.patient.id)
                                      .replace('{imageSetId}', element.data.consult_request.id);
        notificationMessage = intl.messages[`activityLog.${element.data.message.type}`]
        break;
      case 'REPORT':
        url = URLS.patientPageTabReports.replace('{patientId}', element.data.consult_request.patient.id);
        notificationMessage = intl.messages[`activityLog.${element.data.message.type}`]
        break;
      case null:
        url = URLS.patientPageReportMessage.replace('{patientId}', element.data.consult_request.patient.id)
                                          .replace('{requestId}', element.data.consult_request.id)
                                          .replace('{messageId}', element.data.message.id);
        notificationMessage = intl.messages['activityLog.added_message']
        break;
      default:
        url = URLS.patientPageTabReports.replace('{patientId}', element.data.consult_request.patient.id)
    }

    if (isStaff){
      return (
        <div className={cn(logCssClassName, {[logReadCssClassName]: !element.data.message.unread})}  onClick={this.onClick}>
          <Link to={url}>
            <div>
              {notificationMessage
                  .replace('{patient}', getFullNameString(intl.messages['string.usersFullName'], element.data.consult_request.patient.first_name, element.data.consult_request.patient.last_name))
                  .replace('{actor}', actor_name)}
            </div>
          </Link>
          {/*<div className={logDateCssClassName}>
            {localeFormatFromNow(element.data.timestamp)}
          </div>*/}
        </div>
      )
    } else {
      return (
        <div className={cn(logCssClassName, {[logReadCssClassName]: !element.data.message.unread})}  onClick={this.onClick}>
          <Link to={url}>
            <div>
               {notificationMessage
                  .replace('{patient}', getFullNameString(intl.messages['string.usersFullName'], element.data.consult_request.patient.first_name, element.data.consult_request.patient.last_name))
                  .replace('{actor}', actor_name)}
            </div>
          </Link>
          {/*<div className={logDateCssClassName}>
            {localeFormatFromNow(element.data.timestamp)}
          </div>*/}
        </div>
      )
    }
  }
}

class ActivityNotifications extends Component{
  constructor(props){
    super(props);
    this.activityLog = React.createRef();
  }

  componentDidMount(){
    const ps = new PerfectScrollbar(this.activityLog.current);
    this.props.startPollingActivity();
  }

  componentWillUnmount(){
    this.props.stopPollingActivity();
    console.log("NOTIFICAIONT BAR WILL UNMOUNT")
  }

  onNotifClick = (id, url) => {
    this.props.markNotificationsRead(id);
    return
    this.props.history.push(url);
  }

  render(){
    const {
      isActivityPollingActive, lastPoint, lastChange, activityHistory,
      startPollingActivity, markNotificationRead, addPreviousEntries, intl, users
    } = this.props;
    const { userInfo } = users;
    return (
      <div className={baseCssClassName} ref={this.activityLog} id="ActivityLogNotification">
        {
          reverse(activityHistory).map((element, index) => {
            return (
              <ActivityNotificationsMessage
                key = {index}
                element = {element}
                intl = {intl}
                isStaff = {userInfo.is_staff}
                onClick = {this.onNotifClick}
              />
            )
          })
        }
        {
          activityHistory.length === 0 &&
          <div onClick={this.onNotifClick} className={logCssClassName} style={{textAlign:'center'}}>{startCase(intl.messages['activityLog.no_new'])}</div>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
    isActivityPollingActive: activityPollingSelectors.isActivityPollingActive(state),
    activityHistory: activityPollingSelectors.getActivityHistory(state),
    lastPoint: activityPollingSelectors.getLastPoint(state),
    intl: state.intl,
    users: state.users
});

const mapDispatchToProps = {
    startPollingActivity: activityPollingActions.start,
    stopPollingActivity: activityPollingActions.stop,
    resetPollingActivity: activityPollingActions.reset,
    markNotificationsRead: activityPollingActions.markRead
};

const connectedActivityNotifications = connect(mapStateToProps, mapDispatchToProps)(ActivityNotifications);
export { connectedActivityNotifications as ActivityNotifications };
