import React from 'react';
import {localeFormatDate, getFullNameString} from '../_helpers';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { URLS } from '../_config';
const baseCssClassName = 'ConsultRequestCard';
const newConsultRequestCssClassName = `${baseCssClassName}__New`

class ConsultRequestCard extends React.Component {
	/*
		Patient Card (Search Result)

		patient informatino and handle clicks (need id for patient page, patient messaging)
	*/

	constructor(props, refs){
		super(props, refs);
	}

	render() {
		const {	intl, intlLocale, request, last_message, isNew, isStaff, clinicType	} = this.props;
    let intlMessages = intl.messages;
    let url = "/";
    if (request.status === 'CANCELED' || request.status === 'COMPLETED')
    {
      url = URLS.patientPageReport
              .replace('{patientId}', request.patient.id )
              .replace('{requestId}', request.id)
    } else if ((isStaff && request.document_status === 'ADMIN_CHECK') ||
        (!isStaff && request.document_status === 'USER_CHECK')||
        (!isStaff && request.document_status === 'INIT') )
    {
      // go to image check page
      url = URLS.patientPageImageSet
              .replace('{patientId}', request.patient.id )
              .replace('{imageSetId}', request.id )
    } else {
      url = URLS.patientPageReport
              .replace('{patientId}', request.patient.id )
              .replace('{requestId}', request.id)
    }
    if (clinicType === 'scanning_only'){
  		return (
        <Link
          to = {url}
        >
  				<div
            className={cn(`${baseCssClassName} row clickable`, {[newConsultRequestCssClassName]: isNew})}
            >
  					<div className="cell requestPatientName">
  						<span>{getFullNameString(intlMessages['string.usersFullName'], request.patient.first_name, request.patient.last_name)}</span>
  					</div>
            <div className="cell requestPractice">
  						<span>{request.patient.phone_number}</span>
  					</div>
            <div className="cell requestPractice">
  						<span>{request.patient.email_address}</span>
  					</div>
            <div className="cell requestDate">
  						<span>{localeFormatDate(request.appointment_date, intl.locale)}</span>
  					</div>
            <div className="cell requestDescription">
  						<span>{last_message.body}</span>
  					</div>
            <div className="cell requestStartDate">
  						<span>{localeFormatDate(last_message.sent_at, intl.locale)}</span>
  					</div>
  					<div className="cell requestStatus">
  						<span>{ intlMessages[`patientRequest.status.${request.status}`] }</span>
  					</div>
  				</div>
        </Link>
      )
    } else {
  		return (
        <Link
          to = {url}
        >
  				<div
            className={cn(`${baseCssClassName} row clickable`, {[newConsultRequestCssClassName]: isNew})}
            >
  					<div className="cell requestPatientName">
  						<span>{getFullNameString(intlMessages['string.usersFullName'], request.patient.first_name, request.patient.last_name)}</span>
  					</div>
            <div className="cell requestPractice">
  						<span>{request.patient.practice_name}</span>
  					</div>
            <div className="cell requestDate">
  						<span>{localeFormatDate(request.created_at, intl.locale)}</span>
  					</div>
            <div className="cell requestDescription">
  						<span>{last_message.body}</span>
  					</div>
            <div className="cell requestStartDate">
  						<span>{localeFormatDate(last_message.sent_at, intl.locale)}</span>
  					</div>
            <div className="cell requestSteps">
  						<span>{request.steps}</span>
  					</div>
            <div className="cell paymentStatus">
  						<span>{ isStaff ? intlMessages[`patientRequest.bd.documentStatus.${request.document_status}`] : intlMessages[`patientRequest.documentStatus.${request.document_status}`] }</span>
  					</div>
  					<div className="cell requestStatus">
  						<span>{ intlMessages[`patientRequest.status.${request.status}`] }</span>
  					</div>
  				</div>
        </Link>
      )
    }
	}
}

export { ConsultRequestCard };
