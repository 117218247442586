import { consultRequestsConstants } from '../_constants';
import { consultRequestsService } from '../_services';

export const consultRequestsActions = {
  doConsultRequestSearch,
  searchInputChange
}

function searchInputChange(value){
  return dispatch => {
    dispatch(change(value))
  }
	function change(data){ return {type: consultRequestsConstants.CHANGE_CONSULTREQUESTINPUT, data}}
}

function doConsultRequestSearch(init=0, statusQuery="active") {
	return (dispatch, getState) => {
		const { consultRequestSearchInput, consultRequestLoadMoreOffset } = getState().consultRequests;
    const { adminCurrentDentalPractice } = getState().settings;
  	dispatch(request());
		const currentOffset = init;
		consultRequestsService.doConsultRequestSearch(consultRequestSearchInput, statusQuery, currentOffset, adminCurrentDentalPractice && adminCurrentDentalPractice.value)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	}
    function request() { return { type: consultRequestsConstants.SEARCH_CONSULTREQUESTS_REQUEST } }
    function success(data) {
    	console.log(data, "success");
    	return { type:consultRequestsConstants.SEARCH_CONSULTREQUESTS_SUCCESS, data}
    }
    function failure(error) { return { type:consultRequestsConstants.SEARCH_CONSULTREQUESTS_FAILURE, error} }
};
