import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import { AVAILABLE_LANGUAGES } from '../_config';
import { settingsActions } from '../_actions';

import localeData from '../../locales/data.json';
import { updateIntl } from 'react-intl-redux';

const baseCssClassName = 'LanguageBar';
const zhCssClassName = `${baseCssClassName}__zh`;
const dividerCssClassName = `${baseCssClassName}__divider`;
const enCssClassName = `${baseCssClassName}__en`;

class LanguageBar extends React.Component{

  onClickTH = () => {
    this.props.dispatch(updateIntl({locale: 'th', messages: localeData.th}))
  }

  onClickEN = () => {
    this.props.dispatch(updateIntl({locale: 'en', messages: localeData.en}))
  }

  onClickLanguage = (lang) => {
    this.props.dispatch(settingsActions.setLanguage(lang));
  }

  render(){
    return (
      <div className={baseCssClassName}>
        { AVAILABLE_LANGUAGES.map( (lang, indx) => {
          if (indx === AVAILABLE_LANGUAGES.length-1){
            return (
              <span key={lang.value} className={cn(enCssClassName, {[zhCssClassName]: lang === 'zh'})}
                    onClick={() => this.onClickLanguage(lang.value)}>
                {lang.label}
              </span>
            )
          } else {
            return (
              <Fragment key={lang.value}  >
                <span className={cn(enCssClassName, {[zhCssClassName]: lang === 'zh'})}
                      onClick={() => this.onClickLanguage(lang.value)}>
                  {lang.label}
                </span>
                <span key={`${lang.value}_div`}  className={dividerCssClassName}>|</span>
              </Fragment>
            )
          }
        }) }
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { intl } = state;
    return {
        intl
    };
}

const connectedLanguageBar= connect(mapStateToProps)(LanguageBar);
export { connectedLanguageBar as LanguageBar };
