'use strict';

var React = require('react');

export default class IconComponent extends React.Component{
    render() {
        return (
            <div data-filetype={this.props.filetype} className="filepicker-file-icon" />
        );
    }
};
