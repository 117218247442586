 import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL } from '../_config';

export const patientSearchService = {
	doPatientSearch,
};

function doPatientSearch(query, offset=0) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader()}
    };
    let url = '/api/d/dental_practice/search/patients?q='+query+'&offset='+offset;

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};
