import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../_helpers';

import { PAGE_SIZE, URLS, ADMIN_URLS } from '../_config';

import { NavBarPage, SideBarPage } from '../HomePage';
import { ActivityLog } from '../ActivityLog';
import { adminActions } from '../_actions';

import FontAwesome from 'react-fontawesome';
import TrackVisibility from 'react-on-screen';

import cn from 'classnames';
import { upperFirst, toUpper } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Popover from '@material-ui/core/Popover';
import { CustomButton, CustomDialogTitle, CustomDialogContentText, CustomDialogActions } from '../_components';

import { DentalPracticeCard } from './DentalPracticeCard';
import { DentalAccountCard } from './DentalAccountCard';
import { Pagination, ConsultRequestListLoader } from '../_components';

import Add from '@material-ui/icons/Add';

import '../../css/consultRequests.style.scss';
import '../../css/consultRequestsTable.style.scss';
import '../../css/admin.style.scss';

// TODO: maybe move to config
const requestStatusList = ['active', 'done', 'canceled']

const intl8 = {
  messages: {
    'admin.practices.search.placeholder': 'Search Clinics...',
    'admin.practice.heading.title': 'Dental Practice Settings',
    'admin.practice.organizationName': 'Organization',
    'admin.practice.practiceName': 'Practice name',
    'admin.practice.name': 'Name',
    'admin.practice.locationName': 'Branch Name',
    'admin.practice.address': 'Address',
    'admin.practice.emailAddress': 'Contact Email',
    'admin.practice.phoneNumber': 'Contact Phone Number',
    'admin.practice.createdDate': 'Created at',
    'admin.main.clinics': 'Clinics',
    'admin.main.accounts': 'Accounts',
    'admin.main.requests': 'Requests',
    'admin.main.requests.noResults': 'No Results Found',
    'admin.addNew.clinics': 'Add New Clinic',
    'admin.addNew.accounts': 'Add New Account',
    'admin.addNew': 'Add New Account'
  }
}

const modelTypes = [
  'clinics',
  'accounts',
  'requests'
]


const Tab = ({type, name, tabKey}) => (
	<li
		className={ cn("ConsultRequestTab",
								{"ConsultRequestTab--inactive": type == "inactive"},
								{"ConsultRequestTab--active": type == "active"}) }
	>
    <Link className="ConsultRequestTab__time"  to={ADMIN_URLS.listPage.replace('{pageName}', tabKey)}>
		  <span>{name}</span>
    </Link>
	</li>
)

const Tabs = ({intl, currentTab}) => {
  return (
    <ul className="Tabs">
      { modelTypes.map((modelName,index) => (
        <Tab
          tabKey = {modelName}
          type={ (currentTab === modelName) ? "active" : "inactive" }
          name={toUpper(intl8.messages[`admin.main.${modelName}`])}
        />
      ))}
    </ul>
  )
}

const adminBaseCssClassName = 'AdminList';
const baseCssClassName = 'ConsultRequest';
const contentWrapperCssClassname = 'ContentWrapper';
const newRequestBarCssClassName = `${baseCssClassName}__newRequestBar`;
const requestButtonCssClassName = `${newRequestBarCssClassName}__requestButton`;
const requestButtonTextCssClassName = `${requestButtonCssClassName}__text`;
const controlsBarCssClassName = `${baseCssClassName}__controlsBar`;
const consultRequestContent = 'ConsultRequestContent';
const consultRequestlist = 'ConsultRequestList';
const consultRequestListHeaders = `${consultRequestlist}__Headers`;

const DentalModelCard = ({modelType, key, intl, object}) => {
  if (modelType === 'clinics') {
    return (
      <DentalPracticeCard
        key = {key}
        intl = {intl}
        intlLocale = {intl.locale}
        clinic = {object}
      />
    )
  } else { /// 'accounts'
    return(
      <DentalAccountCard
        key = {key}
        intl = {intl}
        intlLocale = {intl.locale}
        account = {object}
      />
    )
  }
}

class AdminPage extends React.Component {
	constructor(props){
		super(props);
    this.state = {
      currentTab: modelTypes[0],
      pageOffset: 0,
      searchInput: ""
    }
	}

	componentDidMount() {
    console.log("mounting Admin Page")
  	this.props.dispatch(adminActions.doModelSearch(this.props.match.params.page_name || 'clinics', this.state.searchInput));
	}

  componentDidUpdate(prevProps) {
    console.log("is gonna update", prevProps.match.params.page_name, this.props.match.params.page_name)
    if ((prevProps.match.params.page_name !== undefined) && (prevProps.match.params.page_name !== this.props.match.params.page_name)){
      console.log("previous props", prevProps)
      this.setState({pageOffset: 0, searchInput: ''})
		  this.props.dispatch(adminActions.doModelSearch(this.props.match.params.page_name, this.state.searchInput));
    }
	}

  componentWillUnmount(){
    console.log("will unmount")
    this.props.dispatch(adminActions.clearModelSearch());
  }

  handleTabChange = (tabKey) => {
    // reload the request list and thats it.
    this.setState({currentTab: tabKey, pageOffset: 0});
  	this.props.dispatch(adminActions.doModelSearch(this.props.match.params.page_name, this.state.searchInput, 0, tabKey));
  }

	handleKeyPress = (e) => {
		if (e.key == 'Enter'){
      this.setState({pageOffset: 0});
    	this.props.dispatch(adminActions.doModelSearch(this.props.match.params.page_name, this.state.searchInput,  0, this.state.currentTab));
			e.target.blur();
		}
	}

	handleSearchClick = (event) => {
    // seasrch by patient id
		event.preventDefault();
    this.setState({pageOffset: 0})
    this.props.dispatch(adminActions.doModelSearch(this.props.match.params.page_name, this.state.searchInput, 0))
	}

	handleSearchInputChange = (e) => {
		const { value } = e.target;
    this.setState({searchInput: value})
	}

	handlePaginationClick = (direction) => {
      // dependednt on prev page or next page
      const offset = this.state.pageOffset;
      const newOffset = offset+direction
      this.setState({pageOffset: newOffset});
      this.props.dispatch(adminActions.doModelSearch(this.props.match.params.page_name || 'clinics', this.state.searchInput, newOffset, this.state.currentTab))
	}

  handlePaginationNumberClick = (newOffset) => {
    this.setState({pageOffset: newOffset});
    this.props.dispatch(adminActions.doModelSearch(this.props.match.params.page_name || 'clinics', this.state.searchInput, newOffset, this.state.currentTab))
  }

  handleNewRequestClick = () => {
    history.push(ADMIN_URLS.createNewAccount);
  }

  handleNewPracticeClick = () => {
    history.push(URLS.newDentalPractice)
  }

  _getTableHeaders = (modelName) => {
    const { intl } = this.props;
    if (modelName == 'clinics'){
      return (
        <div className={cn(consultRequestListHeaders, 'row')}>
          <div className="cell organization">{intl8.messages['admin.practice.organizationName']}</div>
          <div className="cell practiceName">{intl8.messages['admin.practice.practiceName']}</div>
          <div className="cell email">{intl8.messages['admin.practice.emailAddress']}</div>
          <div className="cell phoneNumber">{intl8.messages['admin.practice.phoneNumber']}</div>
          <div className="cell date">{intl8.messages['admin.practice.createdDate']}</div>
        </div>
      )
    } else { // 'accounts'
      return (
        <div className={cn(consultRequestListHeaders, 'row')}>
          <div className="cell organization">{intl8.messages['admin.practice.emailAddress']}</div>
          <div className="cell practiceName">{intl8.messages['admin.practice.name']}</div>
          <div className="cell email">{intl8.messages['admin.practice.practiceName']}</div>
          <div className="cell phoneNumber">{intl8.messages['admin.practice.phoneNumber']}</div>
          <div className="cell date">{intl8.messages['admin.practice.createdDate']}</div>
        </div>
      )
    }
  }

	render(){
		const {
				searchResultsLoading, searchResultsError, searchResults,
        searchResultsTotalResults, searchResultsModel
		} = this.props.dentalAdmin;
    const { adminCurrentDentalPractice } = this.props.settings;
    const { userInfo } = this.props.users;
    // totalPages/pagesize
    const totalPages = Math.ceil(searchResultsTotalResults/PAGE_SIZE);
    const { intl } = this.props;
		return (
			<div className={cn("Admin ConsultRequest")} key={adminCurrentDentalPractice || 'default'}>
				<NavBarPage />
				<SideBarPage location={this.props.location} />
        <div className={contentWrapperCssClassname}>
          <Tabs
            intl={intl}
            currentTab = { this.props.match.params.page_name || 'clinics' }
            handleTabChange = {this.handleTabChange}
          />
          <div className="TabContent">
            <div className="TabContentWrapper">
              <div className={newRequestBarCssClassName}>
                <button className={requestButtonCssClassName}
                  onClick = {this.handleNewRequestClick}
                >
                  <span className={requestButtonTextCssClassName}>
										{toUpper(intl8.messages['admin.addNew'])}
									</span>
									<Add />
                </button>
              </div>
              <div className={controlsBarCssClassName}>
                <div className="SearchInput">
                  <form className="" onSubmit={this.handleSearchClick}>
                    <span className="SearchInput__search-wrapper">
                      <FontAwesome className="SearchInput__search-icon" name="search" />
                      <input
                        type="text"
                        className="form-control SearchInput__search"
                        onKeyPress={this.handleKeyPress}
                        placeholder= {intl.messages['admin.practices.search.placeholder']}
                        name="searchInput"
                        value={this.state.searchInput}
                        onChange={this.handleSearchInputChange}
                      />
                    </span>
                  </form>
                </div>
                <Pagination
                  intl = {intl}
                  currentOffset= {this.state.pageOffset}
                  totalPages= {totalPages}
                  onPageClick= {this.handlePaginationClick}
                  onPageNumberClick = {this.handlePaginationNumberClick}
                />
              </div>
              <div className={cn(adminBaseCssClassName, consultRequestContent)}>
                <div className={cn(consultRequestlist, 'tbl')}>
                  { this._getTableHeaders(this.props.match.params.page_name || 'clinics')}
                  { searchResultsLoading && <ConsultRequestListLoader />}
                  {searchResults && ( (searchResultsModel === this.props.match.params.page_name ||
                                      (this.props.match.params.page_name === undefined && searchResultsModel == 'clinics')) )
                      && searchResults.map( (obj, index) => {
                    return (
                      <DentalModelCard
	                      key = {index}
                        modelType = {this.props.match.params.page_name || 'clinics'}
	                      intl = {this.props.intl}
	                      object = {obj}
	                    />
                    )
                  })}
                  {
                    searchResults && searchResults.length == 0 &&
                    <div className="noResults">{intl.messages['admin.main.requests.noResults']}</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        { /* <ActivityLog /> */}
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { dentalAdmin, intl, users, settings } = state;
    return {
        intl,
        settings,
        users,
        dentalAdmin
    };
}

const connectedConsultAdminPage = withRouter(connect(mapStateToProps)(AdminPage));
export { connectedConsultAdminPage as AdminPage };
