import React from 'react';
import Select from 'react-select';
import cn from 'classnames';

import { find } from 'lodash';

const SelectAdapter = ({input, meta, items, label, intl, className, defaultValue, disabled, ...rest }) => (
  <div className={className}>
    <label>{label}</label>
    <Select
      {...input}
      className={ cn({"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
      onChange={inputValue => input.onChange(inputValue)}
      options={items}
      defaultValue={defaultValue|| find(items, {'value': input.value})}
      disabled={disabled}
      {...rest}
    />
    <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
      {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
    </div>
  </div>
)

const SelectAdapterNonClearable = ({input, meta, items, label, intl, className, defaultValue, disabled, ...rest }) => (
  <div className={className}>
    <label>{label}</label>
    <Select
      {...input}
      className={ cn({"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
      onChange={inputValue => input.onChange(inputValue)}
      options={items}
      disabled={disabled}
      clearable={false}
      searchable={false}
      defaultValue={defaultValue|| find(items, {'value': input.value})}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      {...rest}
    />
    <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
      {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
    </div>
  </div>
)


export {SelectAdapter, SelectAdapterNonClearable};
