import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL, API_URLS, PAGE_SIZE } from '../_config';

export const consultRequestsService = {
 doConsultRequestSearch,
 createNewRequest,
 removeUpload,
 updateRequest,
 updateRequestStatus,
 changeRequestStatus,
 getRequestDetails,
 getToothChart,
 updateToothChart,
 updateConsultRequestMessage,
 replyConsultRequestMessage,
 deleteMessageAttachment,
 confirmTreatmentPlan,
 rejectTreatmentPlan
};

function doConsultRequestSearch(query, statusQuery, offset=0, dpid) {
  const requestUrl = API_URLS.consultRequestList;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url + '?q='+query+'&offset='+offset+'&pgsz='+PAGE_SIZE+'&status='+statusQuery;
  if (dpid){
    url = url + '&dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
};

function createNewRequest(data, dpid){
  const requestUrl = API_URLS.createConsultRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;
  if (dpid){
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function removeUpload(data){
  const requestUrl = API_URLS.deleteConsultRequestUpload;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url;

  return fetch(url, requestOptions).then(handleResponse);
}

function updateRequest(requestId, data, dpid){
  const requestUrl = API_URLS.updateConsultRequest;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{requestId}', requestId);
  if (dpid){
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function getToothChart(requestId, chartType, dpid){
  const requestUrl = API_URLS.getConsultRequestToothChart;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url.replace('{requestId}', requestId).replace('{chartType}', chartType);
  if (dpid){
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}


function updateToothChart(requestId, data, chartType, dpid){
  const requestUrl = API_URLS.updateConsultRequestToothChart;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{requestId}', requestId).replace('{chartType}', chartType);
  if (dpid){
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function updateRequestStatus(requestId, data){
  const requestUrl = API_URLS.updateConsultRequestStatus;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{requestId}', requestId);

  return fetch(url, requestOptions).then(handleResponse);
}

function changeRequestStatus(requestId, data){
  const requestUrl = API_URLS.changeRequestStatus;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{requestId}', requestId);

  return fetch(url, requestOptions).then(handleResponse);
}

function getRequestImageUploads(requestId, dpid){
  const requestUrl = API_URLS.getConsultRequestUploads;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url;
  if (dpid){
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function getRequestDetails(requestId, dpid){
  const requestUrl = API_URLS.getConsultRequestDetails;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url.replace('{requestId}', requestId);
  if (dpid){
    url = url + '?dpid='+ dpid;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function updateConsultRequestMessage(requestId, messageId, data){
  const requestUrl = API_URLS.editConsultRequestMessage;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{requestId}', requestId).replace('{messageId}', messageId);

  return fetch(url, requestOptions).then(handleResponse);
}

function replyConsultRequestMessage(requestId, data){
  const requestUrl = API_URLS.createConsultRequestMessage;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{requestId}', requestId);

  return fetch(url, requestOptions).then(handleResponse);
}

function rejectTreatmentPlan(requestId, messageId, data){
  const requestUrl = API_URLS.rejectTreatmentPlan;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{requestId}', requestId).replace('{messageId}', messageId);

  return fetch(url, requestOptions).then(handleResponse);
}

function confirmTreatmentPlan(requestId, messageId, data){
  const requestUrl = API_URLS.confirmTreatmentPlan;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({status: "CONFIRMED"})
  };
  let url = requestUrl.url.replace('{requestId}', requestId).replace('{messageId}', messageId);

  return fetch(url, requestOptions).then(handleResponse);
}

function createMessageAttachment(requestId, data){
  // pass i think
}

function deleteMessageAttachment(requestId, data){
  const requestUrl = API_URLS.deleteMessageUpload;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  let url = requestUrl.url.replace('{requestId}', requestId);

  return fetch(url, requestOptions).then(handleResponse);
}
