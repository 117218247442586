import React, { Component } from 'react';

import cn from 'classnames';

export default class ToothChartNoActions extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { charting, intl} = this.props;
    const intlMessages = intl.messages;
    const toothRange = [...Array(5).keys()];
    toothRange.shift();
    const toothClassNameMapping = toothRange.map( toothNum => ({
      // key, value for new obj toothClassNameMapping
      [toothNum] : cn({
        'ToothChart__tooth-column':true,
        /*'ToothChart__top-view--fill-1': charting[toothNum].indexOf("B") != -1,
        'ToothChart__top-view--fill-2': charting[toothNum].indexOf("M") != -1,
        'ToothChart__top-view--fill-3': charting[toothNum].indexOf("L") != -1,
        'ToothChart__top-view--fill-4': charting[toothNum].indexOf("D") != -1,
        'ToothChart__top-view--fill-5': charting[toothNum].indexOf("O") != -1*/
      })

    }) );
    return (
      <svg className="ToothChart" viewBox="0 -20 900 520">
        <g transform="translate(0 -279.362) scale(.91)">
          <g transform="matrix(1.235 0 0 1.23346 4 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth1" >
            <g className="ToothChart__facial-view" transform="translate(-4.86)" stroke="#000" strokeWidth="2.022">
              <path className="ToothChart__facial-view-component" d="m 58.850434,382.49361 c -1.404813,-18.35804 -5.830549,-31.33768 -13.360459,-39.18264 -3.579661,-3.72969 -3.909166,-3.87329 -4.228693,-1.84252 -0.190429,1.21154 0.36338,4.59286 1.231026,7.51481 2.226989,7.4988 1.996139,24.15706 -0.334695,24.15706 -0.365908,0 -1.218926,-3.52626 -1.895598,-7.83652 -1.496692,-9.53291 -6.869891,-21.01033 -11.90647,-25.43266 -6.380859,-5.60253 -7.220328,-4.14105 -4.390985,7.64524 2.2791,9.49418 3.035584,26.95398 1.558509,35.97087 l -0.783923,4.78517 5.067488,-2.62904 c 7.612927,-3.9496 13.058929,-4.22902 19.642895,-1.00778 2.982263,1.4591 6.247551,3.56469 7.256223,4.67934 1.008637,1.11463 2.056206,2.02637 2.32787,2.02637 0.271667,0 0.189417,-3.98131 -0.183026,-8.8477 z"
                id="froot1" />
              <path className="ToothChart__facial-view-component" d="m 59.143976,413.18081 c 5.11173,-5.44143 3.282342,-16.8076 -3.690585,-22.92988 -7.39428,-6.49234 -15.316526,-7.66799 -24.102842,-3.57683 -7.199429,3.35269 -9.526323,6.6373 -9.526323,13.44883 0,6.22576 1.77827,10.3867 5.474145,12.80844 3.365124,2.20467 5.847232,2.10324 8.682167,-0.35558 3.069225,-2.66207 5.198166,-2.55085 9.702266,0.50558 4.932497,3.34729 10.372195,3.38739 13.461172,0.10113 z"
                id="fcrown1" />
              <path className="ToothChart__facial-view-component" d="m 42.739201,358.7528 c -0.912713,-10.88689 -9.048277,-26.05924 -13.97319,-26.05924 -2.110607,0 -0.571883,4.64056 2.762537,8.33132 4.344031,4.80826 8.046111,12.44897 9.55905,19.72867 0.6068,2.91991 1.364532,5.30862 1.683859,5.30862 0.319191,0 0.304697,-3.28931 -0.03371,-7.30937 z"
                id="frootsup1" />
            </g>
            <g className="ToothChart__tooth-label" id="label1">
              <circle   className="ToothChart__tooth-label-circle" r="11.797" cy="479.428" cx="35.954" id="labc1" strokeWidth=".5" />
              <text className="labelNumber" id="labt1" y="483.729" x="35.954"
                style={{lineHeight: "100%"}} fontWeight="500" fontSize="11.797"
                wordSpacing="0" textAnchor="middle" fill="#fff">
                <tspan y="483.729" x="35.954">{intlMessages['toothChart.toothNum.1']}</tspan>
              </text>
            </g>
            <g className={toothClassNameMapping[1]} stroke="#25323A">
              <path className="ToothChart__top-view-top-right" d="M 49.125787,444.16729 A 13.153366,13.153366 0 0 0 35.946934,431.01404 l 0,13.1532 l 13.4532,0" strokeWidth="2.022" />
              <path className="ToothChart__top-view-bottom-right" d="m 35.946934,457.32086 a 13.153366,13.153366 0 0 0 13.178853,-13.15357 l -13.1532,0 l 0 13.4532" strokeWidth="1.685" />
              <path className="ToothChart__top-view-bottom-left" d="m 22.801787,444.16729 a 13.153366,13.153366 0 0 0 13.145147,13.15357 l 0,-13.1532 l -13.4532,0" strokeWidth="1.685" />
              <path className="ToothChart__top-view-top-left" d="m 35.946934,431.01404 a 13.153366,13.153366 0 0 0 -13.145147,13.15325 l 13.1532,0 l 0,-13.4532" strokeWidth="1.685" />
              <circle className="ToothChart__top-view-circle" r="6.577" cy="444.167" cx="-35.952" transform="scale(-1 1)" strokeWidth="1.685" />
            </g>
            <g className="ToothChart__highlighter">
              <rect id="Rectangle-10" x="14" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
            </g>
          </g>
          <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth2">
            <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
              <path className="ToothChart__tooth-drawing-component" d="m 103.45338,351.40668 c -0.33684,-6.57492 -1.26575,-12.21302 -2.65277,-16.10028 -1.17394,-3.28999 -2.0382,-7.24466 -1.92059,-8.78815 0.12015,-1.5773 -0.61125,-3.71791 -1.670195,-4.88802 -1.93465,-2.13778 -4.604079,-2.8065 -4.604079,-1.15336 0,0.51067 -0.276177,1.64822 -0.613767,2.52792 -0.399244,1.04041 -0.05824,1.59945 0.975592,1.59945 2.611794,0 3.682837,3.45547 3.682837,11.88187 0,9.74071 1.457052,18.04542 4.268702,24.33015 2.72301,6.08688 3.23144,4.19901 2.53427,-9.40958 z"
                id="frootsup2" />
              <path className="ToothChart__tooth-drawing-component" d="m 120.98356,378.57736 c -0.5644,-4.93684 -1.46342,-13.66961 -1.9978,-19.40593 -1.10545,-11.86704 -4.28354,-20.02462 -11.39004,-29.23599 -6.84611,-8.87369 -8.85286,-6.08145 -4.83711,6.73065 1.1206,3.57513 2.31326,9.76332 2.65038,13.75135 0.69873,8.26593 -0.3491,18.99539 -1.76933,18.11771 -1.54096,-0.95253 -5.16753,-8.64782 -6.645547,-14.10139 -0.753595,-2.78068 -1.663212,-10.06108 -2.021356,-16.1788 -0.358136,-6.11754 -1.126461,-11.43234 -1.707395,-11.81064 -1.917985,-1.249 -4.862451,1.29588 -7.038131,6.08306 -1.777268,3.91052 -2.149552,6.70369 -2.178284,16.345 -0.02694,9.0597 1.378703,35.43594 1.88554,35.37998 0.05428,0 2.252009,-0.66468 4.883848,-1.46317 6.382179,-1.93669 23.717965,0.0743 27.899775,3.23709 1.48821,1.12542 2.83765,1.92931 2.99874,1.78674 0.1611,-0.14147 -0.1689,-4.2988 -0.73329,-9.23566 z"
                id="froot2" />
              <path className="ToothChart__tooth-drawing-component" d="m 119.59934,413.63144 c 5.83131,-3.55559 6.95042,-14.44955 2.27425,-22.13914 -3.11019,-5.11413 -9.00247,-7.21634 -21.01747,-7.49812 -11.578407,-0.26964 -14.346054,0.94207 -17.741535,7.77958 -3.987648,8.02966 -1.73209,15.39061 5.873498,19.16797 3.586909,1.78134 4.056771,1.79919 7.247825,0.27639 3.796202,-1.81032 5.644122,-1.42879 9.845362,2.03412 3.55926,2.9334 9.07399,3.08776 13.51807,0.37818 z"
                id="fcrown2" />
            </g>
            <g className="ToothChart__tooth-label" id="label2">
              <circle className="ToothChart__tooth-label-circle"  id="path8780-5-1-3-7" cx="101.246" cy="479.427" r="11.797" fill="#c8d2da"
                strokeWidth=".5" />
              <text className="labelNumber" style={{lineHeight: "100%"
              }} x="101.246" y="483.728" id="text8790-3" fontWeight="500" fontSize="11.797"
              wordSpacing="0" textAnchor="middle" fill="#fff">
              <tspan id="tspan8792-3" x="101.246" y="483.728">{intlMessages['toothChart.toothNum.2']}</tspan>
            </text>
          </g>
          <g className={toothClassNameMapping[2]} stroke="#000" strokeWidth="1.685">
            <circle className="ToothChart__top-view-circle"  r="6.577" cy="444.167" cx="-101.245" id="center2" transform="scale(-1 1)" />
            <path className="ToothChart__top-view-top-left"  d="m 101.23936,431.01404 a 13.153366,13.153366 0 0 0 -13.145153,13.15325 l 6.572581,0 m 6.572572,-6.57662 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 0 -6.572572,6.57662" id="tl2" />
            <path className="ToothChart__top-view-top-right"  d="m 114.41817,444.16729 a 13.153366,13.153366 0 0 0 -13.17881,-13.15325 l 0,6.57663 m 6.60628,6.57662 6.57253,0 m -6.57253,0 a 6.5766829,6.5766829 0 0 0 -6.60628,-6.57662" id="tr2" />
            <path className="ToothChart__top-view-bottom-left"  d="m 88.094207,444.16729 a 13.153366,13.153366 0 0 0 13.145153,13.15357 l 0,-6.57697 m -6.572572,-6.5766 -6.572581,0 m 6.572581,0 a 6.5766829,6.5766829 0 0 0 6.572572,6.5766" id="bl2" />
            <path className="ToothChart__top-view-bottom-right" d="m 101.23936,457.32086 a 13.153366,13.153366 0 0 0 13.17881,-13.15357 l -6.57253,0 m -6.60628,6.5766 0,6.57697 m 0,-6.57697 a 6.5766829,6.5766829 0 0 0 6.60628,-6.5766" id="br2" />
          </g>
          <g className="ToothChart__highlighter">
            <rect id="Rectangle-10" x="76" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
          </g>
        </g>
        <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth3" >
          <g id="topview3" className={toothClassNameMapping[3]} stroke="#000" strokeWidth="1.685">
            <path className="ToothChart__top-view-top-left"   d="m 165.24178,431.01404 a 13.153366,13.153366 0 0 0 -13.14515,13.15325 l 6.57257,0 m 6.57258,-6.57662 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 0 -6.57258,6.57662"
              id="tl3" />
            <path className="ToothChart__top-view-bottom-right"   d="m 165.24178,457.32086 a 13.153366,13.153366 0 0 0 13.17884,-13.15357 l -6.57257,0 m -6.60627,6.5766 0,6.57697 m 0,-6.57697 a 6.5766829,6.5766829 0 0 0 6.60627,-6.5766"
              id="br3" />
            <circle className="ToothChart__top-view-circle"   r="6.577" cy="444.167" cx="-165.247" id="center3" transform="scale(-1 1)"
            />
            <path className="ToothChart__top-view-top-right"   d="m 178.42062,444.16729 a 13.153366,13.153366 0 0 0 -13.17884,-13.15325 l 0,6.57663 m 6.60627,6.57662 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 0 -6.60627,-6.57662"
              id="tr3" />
            <path className="ToothChart__top-view-bottom-left"   d="m 152.09663,444.16729 a 13.153366,13.153366 0 0 0 13.14515,13.15357 l 0,-6.57697 m -6.57258,-6.5766 -6.57257,0 m 6.57257,0 a 6.5766829,6.5766829 0 0 0 6.57258,6.5766"
              id="bl3" />
          </g>
          <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
            <path className="ToothChart__tooth-drawing-component" d="m 168.44313,381.8704 c 11.50877,-0.57905 12.9358,-0.45536 15.95031,1.38393 1.8209,1.11094 3.41349,2.01829 3.53908,2.01629 0.67556,0 0.0454,-38.46675 -0.67903,-41.45407 -1.49595,-6.16693 -5.44462,-12.80085 -9.55908,-16.0595 -7.31398,-5.79267 -8.74013,-3.67424 -5.6076,8.32964 1.0878,4.16835 1.46681,8.70866 1.15178,13.79683 -0.45722,7.38487 -3.11223,16.17865 -4.88472,16.17865 -1.6526,0 -11.11498,-19.93713 -14.98895,-31.58188 -3.9652,-11.9186 -7.23458,-13.75243 -9.26497,-5.19673 -1.17769,4.9628 -0.38391,12.22972 2.80237,25.65579 1.64381,6.92649 2.57436,13.98746 2.67696,20.31263 l 0.1574,9.69506 3.0335,-1.22015 c 1.66841,-0.6714 8.7213,-1.50663 15.67306,-1.85649 z"
              id="froot3" />
            <path className="ToothChart__tooth-drawing-component" d="m 164.37203,412.94855 3.16213,-2.6607 4.88879,2.6607 c 2.68883,1.46351 6.41981,2.65904 8.29101,2.65701 4.47928,0 9.75275,-3.30954 10.58722,-6.63424 0.89395,-3.562 -0.241,-13.77309 -1.99858,-17.97921 -1.92987,-4.61865 -7.16194,-8.20019 -11.70504,-8.01246 -24.86024,1.02769 -26.82043,1.54437 -29.81732,7.85978 -2.94835,6.21328 -4.11925,14.82266 -2.48396,18.26468 3.04618,6.41179 13.51665,8.5221 19.07575,3.84444 z"
              id="fcrown3" />
            <path className="ToothChart__tooth-drawing-component" d="m 171.09199,356.16725 c 0.35872,-1.91246 0.42792,-7.41117 0.1537,-12.21959 -0.5491,-9.62982 -3.12261,-16.18252 -8.97248,-22.8452 -5.46828,-6.22802 -6.97529,-4.69466 -6.97529,7.09714 0,7.93967 2.40098,15.49054 9.22258,29.0041 2.90716,5.75891 3.25153,6.08821 4.53271,4.33621 0.76256,-1.04286 1.67999,-3.46054 2.03871,-5.37266 z"
              id="frootsup3" />
          </g>
          <g className="ToothChart__tooth-label" id="label3">
            <circle className="ToothChart__tooth-label-circle"  id="path8780-5-7-8" cx="165.249" cy="479.427" r="11.797" fill="#c8d2da"
              strokeWidth=".5" />
            <text className="labelNumber" className="labelNumber" id="text8790-3-8" y="483.728" x="165.249" style={{lineHeight:
              "100%" }} fontWeight="500"
              fontSize="11.797"  wordSpacing="0" textAnchor="middle"
              fill="#fff">
              <tspan y="483.728" x="165.249" id="tspan8792-3-2">{intlMessages['toothChart.toothNum.3']}</tspan>
            </text>
          </g>
          <g className="ToothChart__highlighter">
            <rect id="Rectangle-10" x="140" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
          </g>
        </g>
        <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth4">
          <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
            <path className="ToothChart__tooth-drawing-component" id="fcrown4" d="m 225.39585,414.66586 c 1.36477,-0.51872 4.12454,-2.38603 6.13283,-4.1495 4.54119,-3.98701 4.734,-7.32251 1.06074,-18.35229 -7.64506,-22.9558 -25.13872,-18.63141 -27.34969,6.76099 -0.70752,8.12571 1.45391,11.65635 8.88386,14.51055 6.15786,2.36578 7.81094,2.54612 11.27226,1.23025 z"
            />
            <path className="ToothChart__tooth-drawing-component" id="froot4" d="m 227.57888,362.73712 c -2.18167,-17.29732 -4.97058,-31.52628 -8.204,-41.8558 -2.42613,-7.75053 -5.57645,-12.64559 -7.1352,-11.08689 -0.37528,0.37532 -0.74887,13.07528 -0.83019,28.22223 -0.0812,15.1471 -0.52031,30.64488 -0.9755,34.44012 l -0.82766,6.89986 3.43362,-2.09311 c 4.48608,-2.73487 7.75138,-2.64857 12.05701,0.31682 3.34837,2.30748 3.52569,2.32905 3.83872,0.46683 0.18075,-1.07385 -0.43007,-7.96393 -1.3568,-15.31138 z"
            />
          </g>
          <g id="topview4" className={toothClassNameMapping[4]} stroke="#000" strokeWidth="1.685">
            <path className="ToothChart__top-view-top-left"   id="tl4" d="m 218.57064,430.99483 a 13.153366,13.153366 0 0 0 -13.14517,13.15324 l 6.57258,0 m 6.57259,-6.57661 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 0 -6.57259,6.57661"
            />

          <path className="ToothChart__top-view-bottom-left"   id="bl4" d="m 205.42547,444.14807 a 13.153366,13.153366 0 0 0 13.14517,13.15358 l 0,-6.57699 m -6.57259,-6.57659 -6.57258,0 m 6.57258,0 a 6.5766829,6.5766829 0 0 0 6.57259,6.57659"
          />
          <path className="ToothChart__top-view-bottom-right"   id="br4" d="m 218.57064,457.30165 a 13.153366,13.153366 0 0 0 13.17885,-13.15358 l -6.57257,0 m -6.60628,6.57659 0,6.57699 m 0,-6.57699 a 6.5766829,6.5766829 0 0 0 6.60628,-6.57659"
          />
          <path className="ToothChart__top-view-top-right"   id="tr4" d="m 231.74949,444.14807 a 13.153366,13.153366 0 0 0 -13.17885,-13.15324 l 0,6.57663 m 6.60628,6.57661 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 0 -6.60628,-6.57661"
          />
          <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center4" cx="-218.576" cy="444.148"
            r="6.577" />
        </g>
        <g className="ToothChart__tooth-label" id="label4">
          <circle className="ToothChart__tooth-label-circle"   id="path8780-5-1-9-1" cx="218.577" cy="479.427" r="11.797" fill="#c8d2da"
            strokeWidth=".5" />
          <text className="labelNumber" style={{lineHeight: "100%"
          }} x="218.577" y="483.728" id="text8790-3-8-8" fontWeight="500" fontSize="11.797"
          wordSpacing="0" textAnchor="middle" fill="#fff">
          <tspan id="tspan8792-3-2-5" x="218.577" y="483.728">{intlMessages['toothChart.toothNum.4']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="195" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth5">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown5" d="m 263.40577,413.63144 c 2.13247,-1.08802 5.12002,-3.45516 6.63907,-5.26043 2.45954,-2.92293 2.71591,-3.91691 2.34243,-9.0806 -0.8147,-11.26304 -8.76988,-22.10541 -16.21902,-22.10541 -7.96698,0 -14.40036,9.54438 -14.41009,21.37839 -0.006,7.63228 1.03448,9.35967 8.53457,14.16542 5.30238,3.39752 7.87523,3.57446 13.11304,0.90263 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot5" d="m 267.6206,365.261 c -1.85971,-23.59133 -6.44119,-44.56027 -10.75228,-49.21201 -2.87478,-3.10193 -7.48261,-3.39307 -9.98254,-0.63068 -1.57694,1.7425 -1.77028,5.1422 -1.92628,33.87436 -0.0954,17.5653 -0.0831,31.93732 0.0268,31.93698 0.11061,0 1.39768,-1.10924 2.86063,-2.46524 5.0828,-4.71032 12.79281,-4.24787 17.77169,1.06646 3.23014,3.44775 3.35193,2.56128 2.00149,-14.56987 z"
        />
      </g>
      <g id="topview5" className={toothClassNameMapping[5]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-left"  id="tl5" d="m 257.33199,431.01404 a 13.153366,13.153366 0 0 0 -13.14515,13.15325 l 6.57259,0 m 6.57256,-6.57662 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 0 -6.57256,6.57662"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl5" d="m 244.18684,444.16729 a 13.153366,13.153366 0 0 0 13.14515,13.15357 l 0,-6.57697 m -6.57256,-6.5766 -6.57259,0 m 6.57259,0 a 6.5766829,6.5766829 0 0 0 6.57256,6.5766"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br5" d="m 257.33199,457.32086 a 13.153366,13.153366 0 0 0 13.17884,-13.15357 l -6.57255,0 m -6.60629,6.5766 0,6.57697 m 0,-6.57697 a 6.5766829,6.5766829 0 0 0 6.60629,-6.5766"
        />
        <path className="ToothChart__top-view-top-right"  id="tr5" d="m 270.51083,444.16729 a 13.153366,13.153366 0 0 0 -13.17884,-13.15325 l 0,6.57663 m 6.60629,6.57662 6.57255,0 m -6.57255,0 a 6.5766829,6.5766829 0 0 0 -6.60629,-6.57662"
        />
        <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center5" cx="-257.337" cy="444.167"
          r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label5">
        <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="479.427" cx="257.339" id="path8780-5-2-3" fill="#c8d2da"
          strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8" y="483.728" x="257.339" style={{lineHeight:
          "100%" }} fontWeight="500"
          fontSize="11.797"  wordSpacing="0" textAnchor="middle"
          fill="#fff">
          <tspan y="483.728" x="257.339" id="tspan8792-3-2-5-0">{intlMessages['toothChart.toothNum.5']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="233" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth6">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="path6319-5" d="m 310.41102,411.51743 c 4.71065,-4.136 7.14211,-10.65835 6.29646,-16.88983 -1.23211,-9.07928 -4.7512,-17.64012 -9.13385,-22.22035 -5.25846,-5.49536 -8.9493,-6.32923 -13.73059,-3.10193 -4.95757,3.34595 -7.69139,8.84466 -9.7003,19.51076 -2.53758,13.47312 -1.96785,15.24366 6.76688,21.02821 9.09497,6.02317 14.06178,6.44919 19.5014,1.67314 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="path6317-4" d="m 309.49639,354.61781 c -3.26382,-29.54564 -7.54346,-48.36061 -13.19258,-58.00009 -2.59317,-4.42495 -7.04773,-8.22561 -8.24154,-7.0318 -0.35551,0.35553 -0.45509,3.03356 -0.2211,5.9512 0.23391,2.9176 0.35743,21.35108 0.2747,40.96348 l -0.15077,35.65855 3.47205,-3.04831 c 5.59368,-4.91157 13.93332,-3.532 18.02881,2.98194 1.79291,2.85181 1.80045,-1.45206 0.0305,-17.47497 z"
        />
      </g>
      <g id="topview6" className={toothClassNameMapping[6]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-left"  id="tl6" d="m 298.4888,431.01404 a 13.153366,13.153366 0 0 0 -13.14512,13.15325 l 6.57257,0 m 6.57255,-6.57662 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 0 -6.57255,6.57662"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl6" d="m 285.34368,444.16729 a 13.153366,13.153366 0 0 0 13.14512,13.15357 l 0,-6.57697 m -6.57255,-6.5766 -6.57257,0 m 6.57257,0 a 6.5766829,6.5766829 0 0 0 6.57255,6.5766"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br6" d="m 298.4888,457.32086 a 13.153366,13.153366 0 0 0 13.17886,-13.15357 l -6.57258,0 m -6.60628,6.5766 0,6.57697 m 0,-6.57697 a 6.5766829,6.5766829 0 0 0 6.60628,-6.5766"
        />
        <path className="ToothChart__top-view-top-right"  id="tr6" d="M 311.66766,444.16729 A 13.153366,13.153366 0 0 0 298.4888,431.01404 l 0,6.57663 m 6.60628,6.57662 6.57258,0 m -6.57258,0 a 6.5766829,6.5766829 0 0 0 -6.60628,-6.57662"
        />
        <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center6" cx="-298.494" cy="444.167"
          r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label6">
        <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="479.427" cx="298.496" id="path8780-5-1-2" fill="#c8d2da"
          strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8" y="483.728" x="298.496" style={{lineHeight:
          "100%" }} fontWeight="500"
          fontSize="11.797"  wordSpacing="0" textAnchor="middle"
          fill="#fff">
          <tspan y="483.728" x="298.496" id="tspan8792-3-2-5-0-6">{intlMessages['toothChart.toothNum.6']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="274" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" id="tooth7" className={cn('ToothChart__tooth-column')} >
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown7" d="m 352.92342,412.35096 c 1.95768,-2.79519 1.99129,-7.52846 0.12305,-17.29328 -1.72753,-9.02903 -4.39893,-14.6373 -8.69376,-18.25121 -4.18018,-3.51717 -7.17685,-3.3483 -11.40906,0.64312 -4.2441,4.00286 -5.54417,8.30504 -6.40331,21.19132 -0.72406,10.85993 -0.25313,12.17678 5.20568,14.55574 1.66862,0.72733 6.76202,1.34012 11.31863,1.36201 7.43894,0.0337 8.44551,-0.18881 9.85886,-2.2077 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot7" d="m 348.96472,374.40429 c -4.70828,-41.3693 -8.72926,-56.57789 -16.7407,-63.31906 -1.73845,-1.4628 -3.62483,-2.65965 -4.19198,-2.65965 -1.20614,0 -0.70823,6.79475 1.14843,15.67307 0.88841,4.24825 1.14572,13.24189 0.85831,29.99976 l -0.41043,23.93258 2.90609,-2.44532 c 4.24436,-3.57145 9.6092,-3.33549 13.36816,0.58815 1.59845,1.66845 3.06433,3.0335 3.25751,3.0335 0.19317,0 0.105,-2.1612 -0.19543,-4.80303 z"
        />
      </g>
      <g id="topview7" className={toothClassNameMapping[7]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-left"  id="tl7" d="m 339.91048,431.03326 a 13.153366,13.153366 0 0 0 -13.14514,13.15324 l 6.57257,0 m 6.57257,-6.57663 0,-6.57661 m 0,6.57661 a 6.5766829,6.5766829 0 0 0 -6.57257,6.57663"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl7" d="m 326.76534,444.1865 a 13.153366,13.153366 0 0 0 13.14514,13.15356 l 0,-6.57696 m -6.57257,-6.5766 -6.57257,0 m 6.57257,0 a 6.5766829,6.5766829 0 0 0 6.57257,6.5766"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br7" d="m 339.91048,457.34006 a 13.153366,13.153366 0 0 0 13.17881,-13.15356 l -6.57254,0 m -6.60627,6.5766 0,6.57696 m 0,-6.57696 a 6.5766829,6.5766829 0 0 0 6.60627,-6.5766"
        />
        <path className="ToothChart__top-view-top-right"  id="tr7" d="m 353.08929,444.1865 a 13.153366,13.153366 0 0 0 -13.17881,-13.15324 l 0,6.57661 m 6.60627,6.57663 6.57254,0 m -6.57254,0 a 6.5766829,6.5766829 0 0 0 -6.60627,-6.57663"
        />
        <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center7" cx="-339.916" cy="444.187"
          r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label7">
        <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="479.427" cx="340.897" id="path8780-5-9" fill="#c8d2da"
          strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8-9" y="483.728" x="340.897" style={{lineHeight:
          "100%" }} fontWeight="500"
          fontSize="11.797"  wordSpacing="0" textAnchor="middle"
          fill="#fff">
          <tspan y="483.728" x="340.897" id="tspan8792-3-2-5-0-6-3">{intlMessages['toothChart.toothNum.7']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="315" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth8">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown8" d="m 393.10906,412.47567 c 1.90637,-1.02025 2.01981,-1.70449 1.3836,-8.34212 -0.89158,-9.30237 -4.06509,-23.9919 -5.9722,-27.64391 -1.96443,-3.76153 -6.54559,-8.01887 -10.05615,-9.34518 -3.82742,-1.4463 -8.54143,0.59018 -12.50916,5.40367 -4.20301,5.09897 -5.41936,9.46687 -6.09112,21.87184 -0.47319,8.73781 -0.27638,11.24484 1.09112,13.8887 1.9902,3.84882 3.18914,4.18453 16.29358,4.56304 5.56141,0.16172 10.93788,0.38729 11.94766,0.50355 1.00981,0.11462 2.77053,-0.28985 3.91267,-0.89959 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot8" d="m 387.38473,356.73587 c -2.84773,-25.02012 -6.50049,-39.13486 -12.28957,-47.48853 -3.95654,-5.70922 -5.96246,-6.53726 -8.0646,-3.329 -1.44642,2.20754 -1.59547,4.30342 -1.02592,14.42618 0.61365,10.90642 -0.47921,36.90804 -2.00653,47.74 l -0.64158,4.55023 3.5375,-3.36617 c 4.75349,-4.52294 10.33345,-5.33289 15.35594,-2.22895 1.99059,1.23025 4.14478,3.21889 4.78704,4.41879 1.9861,3.711 2.0651,0.3664 0.34772,-14.72255 z"
        />
      </g>
      <g id="topview8" className={toothClassNameMapping[8]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-left"  id="tl8" d="m 377.25048,430.99483 a 13.153366,13.153366 0 0 0 -13.14516,13.15324 l 6.57259,0 m 6.57257,-6.57661 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 0 -6.57257,6.57661"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl8" d="m 364.10532,444.14807 a 13.153366,13.153366 0 0 0 13.14516,13.15358 l 0,-6.57699 m -6.57257,-6.57659 -6.57259,0 m 6.57259,0 a 6.5766829,6.5766829 0 0 0 6.57257,6.57659"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br8" d="m 377.25048,457.30165 a 13.153366,13.153366 0 0 0 13.17885,-13.15358 l -6.57257,0 m -6.60628,6.57659 0,6.57699 m 0,-6.57699 a 6.5766829,6.5766829 0 0 0 6.60628,-6.57659"
        />
        <path className="ToothChart__top-view-top-right"  id="tr8" d="m 390.42933,444.14807 a 13.153366,13.153366 0 0 0 -13.17885,-13.15324 l 0,6.57663 m 6.60628,6.57661 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 0 -6.60628,-6.57661"
        />
        <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center8" cx="-377.256" cy="444.148"
          r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label8">
        <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="479.428" cx="377.257" id="path8780-0" fill="#c8d2da"
          strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8-9-3" y="483.728" x="377.257" style={{lineHeight:
          "100%" }} fontWeight="500"
          fontSize="11.797"  wordSpacing="0" textAnchor="middle"
          fill="#fff">
          <tspan y="483.728" x="377.257" id="tspan8792-3-2-5-0-6-3-7">{intlMessages['toothChart.toothNum.8']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="352" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth9">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown9" d="m 435.11957,412.00142 c 4.27874,-0.8834 5.65865,-5.71946 5.01631,-17.58145 -0.67174,-12.40497 -1.8881,-16.77254 -6.09111,-21.87152 -3.96776,-4.81346 -8.68177,-6.84995 -12.50919,-5.40399 -3.51053,1.32631 -8.0917,5.58365 -10.05612,9.34554 -1.9058,3.64928 -5.08046,18.33915 -5.97056,27.62704 -0.59751,6.23451 -0.45396,7.34679 1.05758,8.1928 1.66236,0.93028 23.70537,0.69197 28.55309,-0.30672 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot9" d="m 420.78531,365.4521 c 4.12501,-1.03509 8.7497,0.42267 12.42325,3.91624 l 3.43484,3.26674 -0.64162,-4.55023 c -1.51756,-10.76284 -2.61168,-36.83491 -2.01565,-48.03068 0.59635,-11.20146 0.48939,-12.32622 -1.3915,-14.63502 l -2.03747,-2.50101 -3.3024,3.51219 c -6.84773,7.28271 -10.78598,20.09532 -14.09267,45.84899 -1.12163,8.73546 -2.03933,17.01789 -2.03933,18.40558 0,2.49385 0.0412,2.48173 3.55836,-1.03546 1.95711,-1.95693 4.70402,-3.8458 6.10419,-4.19734 z"
        />
      </g>
      <g id="topview9" className={toothClassNameMapping[9]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-right"  id="tr9" d="m 422.75025,430.28228 a 13.153366,13.153366 0 0 1 13.14516,13.15358 l -6.57258,0 m -6.57258,-6.57662 0,-6.57696 m 0,6.57696 a 6.5766829,6.5766829 0 0 1 6.57258,6.57662"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br9" d="m 435.89541,443.43586 a 13.153366,13.153366 0 0 1 -13.14516,13.15325 l 0,-6.57664 m 6.57258,-6.57661 6.57258,0 m -6.57258,0 a 6.5766829,6.5766829 0 0 1 -6.57258,6.57661"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl9" d="M 422.75025,456.58911 A 13.153366,13.153366 0 0 1 409.5714,443.43586 l 6.57258,0 m 6.60627,6.57661 0,6.57664 m 0,-6.57664 a 6.5766829,6.5766829 0 0 1 -6.60627,-6.57661"
        />
        <path className="ToothChart__top-view-top-left"  id="tl9" d="m 409.5714,443.43586 a 13.153366,13.153366 0 0 1 13.17885,-13.15358 l 0,6.57663 m -6.60627,6.57695 -6.57258,0 m 6.57258,0 a 6.5766829,6.5766829 0 0 1 6.60627,-6.57695"
        />
        <circle className="ToothChart__top-view-circle"  id="center9" cx="422.745" cy="443.436" r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label9">
        <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="479.427" cx="-422.741"
          id="path8780-0-6" fill="#c8d2da" strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8-9-3-3" y="483.728" x="422.741" style={{lineHeight:
          "100%" }} fontWeight="500"
          fontSize="11.797"  wordSpacing="0" textAnchor="middle"
          fill="#fff">
          <tspan y="483.728" x="422.741" id="tspan8792-3-2-5-0-6-3-7-7">{intlMessages['toothChart.toothNum.9']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="398" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth10">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown10" d="m 466.54041,413.98028 c 1.69944,-0.34008 4.09357,-1.55313 5.32032,-2.6961 2.11101,-1.96672 2.19507,-2.62465 1.57003,-12.29275 -0.86002,-13.3022 -2.10741,-17.51741 -6.37439,-21.54184 -4.23223,-3.99142 -7.22887,-4.16029 -11.40907,-0.64312 -4.29485,3.61391 -6.96625,9.22218 -8.6938,18.25121 -1.86829,9.76482 -1.83466,14.49809 0.12306,17.29328 1.3828,1.97414 2.47342,2.24714 8.97409,2.24714 4.07001,0 8.79045,-0.27637 10.48987,-0.61782 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot10" d="m 460.89323,373.14032 c 2.51584,0 4.69339,0.86455 6.94008,2.75476 l 3.274,2.75511 -0.67382,-6.57663 c -1.03634,-10.11537 -0.74672,-43.37479 0.42219,-48.48049 1.73985,-7.60002 2.30855,-15.16749 1.13981,-15.16749 -2.17765,0 -7.46537,5.48702 -9.88034,10.25267 -4.311,8.50749 -8.32216,28.51963 -11.01361,54.94845 l -0.61589,6.04779 3.3707,-3.26708 c 2.53955,-2.46153 4.27474,-3.26709 7.03688,-3.26709 z"
        />
      </g>
      <g id="topview10" className={toothClassNameMapping[10]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-right"  id="tr10" d="m 460.09025,430.32071 a 13.153366,13.153366 0 0 1 13.14516,13.15357 l -6.57257,0 m -6.57259,-6.57662 0,-6.57695 m 0,6.57695 a 6.5766829,6.5766829 0 0 1 6.57259,6.57662"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br10" d="m 473.23541,443.47428 a 13.153366,13.153366 0 0 1 -13.14516,13.15326 l 0,-6.57663 m 6.57259,-6.57663 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 1 -6.57259,6.57663"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl10" d="m 460.09025,456.62754 a 13.153366,13.153366 0 0 1 -13.17881,-13.15326 l 6.57254,0 m 6.60627,6.57663 0,6.57663 m 0,-6.57663 a 6.5766829,6.5766829 0 0 1 -6.60627,-6.57663"
        />
        <path className="ToothChart__top-view-top-left"  id="tl10" d="m 446.91144,443.47428 a 13.153366,13.153366 0 0 1 13.17881,-13.15357 l 0,6.57695 m -6.60627,6.57662 -6.57254,0 m 6.57254,0 a 6.5766829,6.5766829 0 0 1 6.60627,-6.57662"
        />
        <circle className="ToothChart__top-view-circle"  id="center10" cx="460.085" cy="443.474" r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label10">
        <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="479.426" cx="-459.102"
          id="path8780-5-9-4" fill="#c8d2da" strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8-9-3-3-1" y="483.728" x="459.102" style={{lineHeight:
          "100%" }} fontWeight="500"
          fontSize="11.797"  wordSpacing="0" textAnchor="middle"
          fill="#fff">
          <tspan y="483.728" x="459.102" id="tspan8792-3-2-5-0-6-3-7-7-3">{intlMessages['toothChart.toothNum.10']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="436" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth11">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown11" d="m 509.09036,409.84429 c 8.73467,-5.78455 9.30446,-7.55509 6.76688,-21.02821 -2.00892,-10.6661 -4.74274,-16.16481 -9.70034,-19.51076 -4.78127,-3.2273 -8.47215,-2.39343 -13.73063,3.10193 -4.38265,4.58023 -7.90171,13.14107 -9.13382,22.22035 -1.36892,10.08704 5.66475,19.9705 14.21227,19.9705 3.5593,0 5.78824,-0.91443 11.58564,-4.75381 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot11" d="m 494.42749,368.3137 c 4.31888,-3.33348 9.78194,-3.02539 14.13529,0.79714 l 3.47205,3.04831 -0.15078,-35.65855 c -0.083,-19.6124 0.0408,-38.04588 0.27471,-40.96348 0.23393,-2.91764 0.13423,-5.59567 -0.2211,-5.9512 -1.19383,-1.19381 -5.64835,2.60685 -8.24154,7.0318 -5.59791,9.55209 -9.28833,25.29213 -12.72579,54.27698 -2.35612,19.8667 -2.47257,23.4378 -0.69174,21.21087 0.67751,-0.84736 2.54451,-2.55387 4.1489,-3.79187 z"
        />
      </g>
      <g id="topview11" className={toothClassNameMapping[11]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-right"  id="tr11" d="m 501.51194,430.3015 a 13.153366,13.153366 0 0 1 13.14515,13.15357 l -6.57257,0 m -6.57258,-6.57662 0,-6.57695 m 0,6.57695 a 6.5766829,6.5766829 0 0 1 6.57258,6.57662"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br11" d="m 514.65709,443.45507 a 13.153366,13.153366 0 0 1 -13.14515,13.15326 l 0,-6.57663 m 6.57258,-6.57663 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 1 -6.57258,6.57663"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl11" d="m 501.51194,456.60833 a 13.153366,13.153366 0 0 1 -13.17886,-13.15326 l 6.57258,0 m 6.60628,6.57663 0,6.57663 m 0,-6.57663 a 6.5766829,6.5766829 0 0 1 -6.60628,-6.57663"
        />
        <path className="ToothChart__top-view-top-left"  id="tl11" d="m 488.33308,443.45507 a 13.153366,13.153366 0 0 1 13.17886,-13.15357 l 0,6.57695 m -6.60628,6.57662 -6.57258,0 m 6.57258,0 a 6.5766829,6.5766829 0 0 1 6.60628,-6.57662"
        />
        <circle className="ToothChart__top-view-circle"  id="center11" cx="501.507" cy="443.455" r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label11">
        <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="479.426" cx="-501.503"
          id="path8780-5-1-2-8" fill="#c8d2da" strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8-9-3-3-1-9" y="483.728" x="501.503" style={{lineHeight:
          "100%" }} fontWeight="500"
          fontSize="11.797"  wordSpacing="0" textAnchor="middle"
          fill="#fff">
          <tspan y="483.728" x="501.503" id="tspan8792-3-2-5-0-6-3-7-7-3-6">{intlMessages['toothChart.toothNum.11']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="478" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth12">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown12" d="m 549.70725,412.72881 c 7.50006,-4.80575 8.54084,-6.53314 8.53453,-14.16542 -0.0107,-11.83401 -6.44309,-21.37839 -14.41005,-21.37839 -7.44914,0 -15.40432,10.84237 -16.21903,22.10541 -0.37335,5.16167 -0.11663,6.15799 2.33765,9.07487 3.23764,3.84782 9.02882,7.13612 12.67875,7.19917 1.42047,0.0337 4.60564,-1.25149 7.07815,-2.83564 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot12" d="m 541.94872,375.62409 c 3.75618,-0.70476 10.50455,1.80056 11.44381,4.24825 0.28649,0.74656 0.81348,1.35731 1.1711,1.35731 0.35756,3.4e-4 0.57217,-14.37168 0.4768,-31.93698 -0.15614,-28.73216 -0.34932,-32.13186 -1.92626,-33.87436 -2.49991,-2.76239 -7.10776,-2.47125 -9.98252,0.63068 -5.06922,5.46977 -11.55807,40.52273 -11.66421,63.01037 l -0.0165,3.39111 3.51748,-3.08843 c 1.93458,-1.69878 5.07549,-3.38067 6.97984,-3.73795 z"
        />
      </g>
      <g id="topview12" className={toothClassNameMapping[12]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-right"  id="tr12" d="m 542.66876,430.3015 a 13.153366,13.153366 0 0 1 13.14513,13.15357 l -6.57257,0 m -6.57256,-6.57662 0,-6.57695 m 0,6.57695 a 6.5766829,6.5766829 0 0 1 6.57256,6.57662"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br12" d="m 555.81389,443.45507 a 13.153366,13.153366 0 0 1 -13.14513,13.15326 l 0,-6.57663 m 6.57256,-6.57663 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 1 -6.57256,6.57663"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl12" d="m 542.66876,456.60833 a 13.153366,13.153366 0 0 1 -13.17882,-13.15326 l 6.57254,0 m 6.60628,6.57663 0,6.57663 m 0,-6.57663 a 6.5766829,6.5766829 0 0 1 -6.60628,-6.57663"
        />
        <path className="ToothChart__top-view-top-left"  id="tl12" d="m 529.48994,443.45507 a 13.153366,13.153366 0 0 1 13.17882,-13.15357 l 0,6.57695 m -6.60628,6.57662 -6.57254,0 m 6.57254,0 a 6.5766829,6.5766829 0 0 1 6.60628,-6.57662"
        />
        <circle className="ToothChart__top-view-circle"  id="center12" cx="542.664" cy="443.455" r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label12">
        <circle className="ToothChart__tooth-label-circle"  transform="scale(-1 1)" r="11.797" cy="479.426" cx="-542.66" id="path8780-5-2-3-5"
          fill="#c8d2da" strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8-9-3-3-1-9-4" y="483.728" x="542.66" style={{lineHeight:
          "100%" }} fontWeight="500"
          fontSize="11.797"  wordSpacing="0" textAnchor="middle"
          fill="#fff">
          <tspan y="483.728" x="542.66" id="tspan8792-3-2-5-0-6-3-7-7-3-6-2">{intlMessages['toothChart.toothNum.12']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="519" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth13">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown13" d="m 585.58729,413.48582 c 7.74191,-2.89666 9.89144,-6.30865 9.17292,-14.56076 -2.21094,-25.3924 -19.70465,-29.71679 -27.34968,-6.76099 -4.29382,12.89337 -3.13412,17.11532 6.00426,21.85834 3.97069,2.06076 5.39812,1.99807 12.1725,-0.53659 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot13" d="m 580.98045,375.16637 c 1.40031,0.003 4.09111,0.94579 5.97956,2.09716 l 3.43357,2.09311 -0.82762,-6.89986 c -0.45519,-3.79524 -0.89419,-19.29302 -0.97547,-34.44012 -0.0812,-15.14695 -0.45493,-27.84691 -0.83025,-28.22223 -2.75035,-2.75036 -8.12431,10.94924 -11.75916,29.97727 -2.18494,11.43814 -5.11421,33.00866 -5.11421,37.66069 l 0,2.85891 3.7738,-2.56431 c 2.07562,-1.41026 4.91952,-2.56264 6.31978,-2.56062 z"
        />
      </g>
      <g id="topview13" className={toothClassNameMapping[13]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-right"   id="tr13" d="m 581.43012,430.28228 a 13.153366,13.153366 0 0 1 13.14515,13.15358 l -6.57259,0 m -6.57256,-6.57662 0,-6.57696 m 0,6.57696 a 6.5766829,6.5766829 0 0 1 6.57256,6.57662"
        />
        <path className="ToothChart__top-view-bottom-right"   id="br13" d="m 594.57527,443.43586 a 13.153366,13.153366 0 0 1 -13.14515,13.15325 l 0,-6.57664 m 6.57256,-6.57661 6.57259,0 m -6.57259,0 a 6.5766829,6.5766829 0 0 1 -6.57256,6.57661"
        />
        <path className="ToothChart__top-view-bottom-left"   id="bl13" d="m 581.43012,456.58911 a 13.153366,13.153366 0 0 1 -13.17886,-13.15325 l 6.57257,0 m 6.60629,6.57661 0,6.57664 m 0,-6.57664 a 6.5766829,6.5766829 0 0 1 -6.60629,-6.57661"
        />
        <path className="ToothChart__top-view-top-left"   id="tl13" d="m 568.25126,443.43586 a 13.153366,13.153366 0 0 1 13.17886,-13.15358 l 0,6.57696 m -6.60629,6.57662 -6.57257,0 m 6.57257,0 a 6.5766829,6.5766829 0 0 1 6.60629,-6.57662"
        />
        <circle className="ToothChart__top-view-circle"  id="center13" cx="581.425" cy="443.436" r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label13">
        <circle className="ToothChart__tooth-label-circle"  transform="scale(-1 1)" r="11.797" cy="479.426" cx="-581.421"
          id="path8780-5-1-9-1-6" fill="#c8d2da" strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8-9-3-3-1-9-4-0" y="483.728" x="581.421" style={{lineHeight:
          "100%" }} fontWeight="500"
          fontSize="11.797"  wordSpacing="0" textAnchor="middle"
          fill="#fff">
          <tspan y="483.728" x="581.421" id="tspan8792-3-2-5-0-6-3-7-7-3-6-2-2">{intlMessages['toothChart.toothNum.13']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="557" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth14">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown14" d="m 627.57698,412.94855 4.88878,-2.6607 3.16218,2.6607 c 5.55908,4.67766 16.02953,2.56735 19.07568,-3.84444 1.63531,-3.44202 0.46444,-12.0514 -2.48394,-18.26468 -2.9969,-6.31541 -4.95708,-6.83209 -29.81729,-7.85978 -4.54308,-0.18881 -9.77516,3.39381 -11.70504,8.01246 -1.75744,4.20612 -2.89249,14.41721 -1.99857,17.97921 0.83446,3.3247 6.10796,6.62918 10.58718,6.63424 1.87123,0.002 5.60218,-1.1935 8.29102,-2.65701 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot14" d="m 615.6065,383.25433 c 3.01451,-1.83929 4.44153,-1.96298 15.9503,-1.38393 6.95174,0.34986 14.00463,1.18509 15.67304,1.85649 l 3.0335,1.22015 0.15739,-9.69506 c 0.10274,-6.32517 1.03317,-13.38614 2.67697,-20.31263 3.18626,-13.42607 3.98003,-20.69299 2.80237,-25.65579 -2.03293,-8.56645 -5.339,-6.70318 -9.22086,5.19673 -2.83591,8.69383 -13.66559,31.58188 -14.94313,31.58188 -1.85251,0 -4.50759,-8.63467 -4.97466,-16.17865 -0.31515,-5.08817 0.0641,-9.62848 1.15181,-13.79683 3.13251,-12.00388 1.70634,-14.12231 -5.60758,-8.32964 -4.12208,3.26472 -8.07702,9.91682 -9.5482,16.0595 -0.71776,2.99675 -1.35767,41.44361 -0.68994,41.45407 0.1257,0.003 1.71816,-0.90535 3.53907,-2.01629 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="frootsup14" d="m 635.58219,356.99978 c 6.71688,-13.30593 9.11973,-20.89422 9.11973,-28.80018 0,-11.7918 -1.507,-13.32516 -6.97528,-7.09714 -6.24841,7.11669 -8.47217,13.11627 -8.98107,24.23084 -0.3536,7.72394 -0.0887,11.0072 1.12254,13.90352 0.87201,2.08569 1.82967,3.79187 2.12813,3.79187 0.29829,0 1.9121,-2.71297 3.58595,-6.02891 z"
        />
      </g>
      <g id="topview14" className={toothClassNameMapping[14]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-right"  id="tr14" d="m 634.75897,430.3015 a 13.153366,13.153366 0 0 1 13.14516,13.15357 l -6.57257,0 m -6.57259,-6.57662 0,-6.57695 m 0,6.57695 a 6.5766829,6.5766829 0 0 1 6.57259,6.57662"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br14" d="m 647.90413,443.45507 a 13.153366,13.153366 0 0 1 -13.14516,13.15326 l 0,-6.57663 m 6.57259,-6.57663 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 1 -6.57259,6.57663"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl14" d="m 634.75897,456.60833 a 13.153366,13.153366 0 0 1 -13.17885,-13.15326 l 6.57256,0 m 6.60629,6.57663 0,6.57663 m 0,-6.57663 a 6.5766829,6.5766829 0 0 1 -6.60629,-6.57663"
        />
        <path className="ToothChart__top-view-top-left"  id="tl14" d="m 621.58012,443.45507 a 13.153366,13.153366 0 0 1 13.17885,-13.15357 l 0,6.57661 m -6.60629,6.57696 -6.57256,0 m 6.57256,0 a 6.5766829,6.5766829 0 0 1 6.60629,-6.57696"
        />
        <circle className="ToothChart__top-view-circle"  id="center14" cx="634.754" cy="443.455" r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label14">
        <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="479.426" cx="-634.75" id="path8780-5-7-8-2"
          fill="#c8d2da" strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8-9-3-3-1-9-4-0-9" y="483.728" x="634.75" style={{lineHeight:
          "100%" }} fontWeight="500"
          fontSize="11.797"  wordSpacing="0" textAnchor="middle"
          fill="#fff">
          <tspan y="483.728" x="634.75" id="tspan8792-3-2-5-0-6-3-7-7-3-6-2-2-5">{intlMessages['toothChart.toothNum.14']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="611" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth15">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown15" d="m 693.91864,413.25359 c 4.20125,-3.46288 6.04916,-3.84443 9.84539,-2.03446 3.19107,1.52179 3.66093,1.50394 7.24782,-0.27638 7.60559,-3.77705 9.86115,-11.138 5.8735,-19.16766 -3.38551,-6.81726 -6.1519,-8.04482 -17.63109,-7.82202 -11.95292,0.23257 -17.9944,2.38803 -21.12791,7.54058 -2.72066,4.47375 -3.62461,11.26505 -2.15312,16.17663 2.22415,7.42364 12.01679,10.47095 17.94541,5.58433 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot15" d="m 694.293,382.64561 c 8.79567,-1.13923 10.88928,-1.12069 14.47289,0.12799 2.30435,0.80284 4.46164,1.46652 4.79395,1.47461 0.92187,0.0337 2.43015,-22.29249 2.39116,-35.37631 -0.0303,-9.64096 -0.40105,-12.43445 -2.17829,-16.34497 -2.17569,-4.78718 -5.12013,-7.33193 -7.03814,-6.08293 -0.58092,0.37829 -1.34924,5.69294 -1.70741,11.81051 -0.35811,6.11771 -1.26776,13.39812 -2.02133,16.1788 -1.47802,5.45391 -5.10458,13.1492 -6.64555,14.10137 -1.42022,0.87803 -2.46807,-9.85143 -1.76934,-18.11736 0.33712,-3.98805 1.52979,-10.17655 2.65039,-13.75171 2.406,-7.67608 2.64954,-12.0617 0.66977,-12.0617 -1.78276,0 -8.73221,9.0771 -12.09829,15.80202 -3.48346,6.95967 -4.47794,11.67169 -5.70219,27.01715 -0.56996,7.14423 -1.3306,14.8301 -1.69029,17.07961 l -0.65404,4.09014 3.12175,-2.30779 c 2.33006,-1.72271 5.72945,-2.64591 13.40496,-3.63988 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="frootsup15" d="m 700.44695,357.40828 c 1.50633,-4.23611 2.28754,-9.43752 2.64874,-17.63658 0.53718,-12.19431 1.30884,-15.16746 3.93665,-15.16746 1.03389,0 1.37485,-0.55904 0.9756,-1.59945 -0.33759,-0.8797 -0.61376,-2.01725 -0.61376,-2.52792 0,-1.65314 -2.66941,-0.98442 -4.60407,1.15336 -1.05893,1.17011 -1.79037,3.31072 -1.67018,4.88802 0.1177,1.54349 -0.7537,5.49816 -1.93615,8.78815 -1.56873,4.36485 -2.28168,9.26279 -2.63712,18.11622 -0.54174,13.49399 0.23627,14.28909 3.90029,3.98566 z"
        />
      </g>
      <g id="topview15" className={toothClassNameMapping[15]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-right"  id="tr15" d="m 698.76137,430.3015 a 13.153366,13.153366 0 0 1 13.14515,13.15357 l -6.57255,0 m -6.5726,-6.57662 0,-6.57695 m 0,6.57695 a 6.5766829,6.5766829 0 0 1 6.5726,6.57662"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br15" d="m 711.90652,443.45507 a 13.153366,13.153366 0 0 1 -13.14515,13.15326 l 0,-6.57663 m 6.5726,-6.57663 6.57255,0 m -6.57255,0 a 6.5766829,6.5766829 0 0 1 -6.5726,6.57663"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl15" d="m 698.76137,456.60833 a 13.153366,13.153366 0 0 1 -13.1788,-13.15326 l 6.57253,0 m 6.60627,6.57663 0,6.57663 m 0,-6.57663 a 6.5766829,6.5766829 0 0 1 -6.60627,-6.57663"
        />
        <path className="ToothChart__top-view-top-left"  id="tl15" d="m 685.58257,443.45507 a 13.153366,13.153366 0 0 1 13.1788,-13.15357 l 0,6.57695 m -6.60627,6.57662 -6.57253,0 m 6.57253,0 a 6.5766829,6.5766829 0 0 1 6.60627,-6.57662"
        />
        <circle className="ToothChart__top-view-circle"  id="center15" cx="698.756" cy="443.455" r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label15">
        <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="479.426" cx="-698.753"
          id="path8780-5-1-3-7-5" fill="#c8d2da" strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8-9-3-3-1-9-4-0-9-7" y="483.728" x="698.753"
          style={{lineHeight: "100%"
          }} fontWeight="500" fontSize="11.797"  wordSpacing="0"
          textAnchor="middle" fill="#fff">
          <tspan y="483.728" x="698.753" id="tspan8792-3-2-5-0-6-3-7-7-3-6-2-2-5-4">{intlMessages['toothChart.toothNum.15']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="676" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -4.003 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth16">
      <g transform="translate(4.86)" className="ToothChart__facial-view" stroke="#000"
        strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="fcrown16" d="m 754.31719,413.08137 c 4.50409,-3.05643 6.63305,-3.16765 9.70228,-0.50558 6.27647,5.44342 14.39267,-1.62292 14.30875,-12.45825 -0.0502,-6.48021 -2.14831,-9.45103 -9.2331,-13.07368 -8.91921,-4.5607 -16.88734,-3.51953 -24.54847,3.20707 -9.88107,8.67548 -8.35593,25.35835 2.31816,25.35835 2.30833,0 5.14544,-0.9623 7.45238,-2.52791 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="froot16" d="m 753.4756,383.22773 c 4.83768,-1.59664 10.61106,-0.75265 16.79324,2.45477 l 4.99209,2.58992 -0.78393,-4.78517 c -1.47708,-9.01689 -0.72059,-26.47669 1.55851,-35.97087 2.82934,-11.78629 1.98988,-13.24777 -4.39097,-7.64524 -5.03661,4.42233 -10.40981,15.89975 -11.90647,25.43266 -0.67669,4.31026 -1.5297,7.83652 -1.8956,7.83652 -2.33091,0 -2.56178,-16.65826 -0.3347,-24.15706 0.86765,-2.92195 1.42158,-6.30327 1.23098,-7.51481 -0.31953,-2.03077 -0.64901,-1.88717 -4.22869,1.84252 -7.5465,7.86246 -11.95544,20.82124 -13.36876,39.2932 l -0.68538,8.95791 4.6591,-3.55627 c 2.56253,-1.95592 6.32481,-4.10599 8.36064,-4.77808 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="frootsup16" d="m 758.91241,360.75355 c 1.51293,-7.2797 5.21501,-14.92041 9.55904,-19.72867 3.33442,-3.69076 4.87312,-8.33132 2.76254,-8.33132 -4.9249,0 -13.06049,15.17235 -13.97319,26.05924 -0.33704,4.02006 -0.35155,7.30937 -0.0337,7.30937 0.31919,0 1.07702,-2.38871 1.68384,-5.30862 z"
        />
      </g>
      <g id="topview16" className={toothClassNameMapping[16]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-right"  id="tr16" d="m 764.0538,430.3015 a 13.153366,13.153366 0 0 1 13.14516,13.15357 l -6.57257,0 m -6.57259,-6.57662 0,-6.57695 m 0,6.57695 a 6.5766829,6.5766829 0 0 1 6.57259,6.57662"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br16" d="m 777.19896,443.45507 a 13.153366,13.153366 0 0 1 -13.14516,13.15326 l 0,-6.57663 m 6.57259,-6.57663 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 1 -6.57259,6.57663"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl16" d="m 764.0538,456.60833 a 13.153366,13.153366 0 0 1 -13.17884,-13.15326 l 6.57256,0 m 6.60628,6.57663 0,6.57663 m 0,-6.57663 a 6.5766829,6.5766829 0 0 1 -6.60628,-6.57663"
        />
        <path className="ToothChart__top-view-top-left"  id="tl16" d="M 750.87496,443.45507 A 13.153366,13.153366 0 0 1 764.0538,430.3015 l 0,6.57695 m -6.60628,6.57662 -6.57256,0 m 6.57256,0 a 6.5766829,6.5766829 0 0 1 6.60628,-6.57662"
        />
        <circle className="ToothChart__top-view-circle"  id="center16" cx="764.048" cy="443.455" r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label16">
        <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="479.426" cx="-764.045"
          id="path8780-5-1-9-8-4-2" fill="#c8d2da" strokeWidth=".5" />
        <text className="labelNumber" id="text8790-3-8-8-8-8-9-3-3-1-9-4-0-9-7-6" y="483.728" x="764.045"
          style={{lineHeight: "100%"
          }} fontWeight="500" fontSize="11.797"  wordSpacing="0"
          textAnchor="middle" fill="#fff">
          <tspan y="483.728" x="764.045" id="tspan8792-3-2-5-0-6-3-7-7-3-6-2-2-5-4-4">{intlMessages['toothChart.toothNum.16']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="740" y="300" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -4.003 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth17">
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="froot17" d="m 775.38525,709.77113 c -0.31849,-1.25114 -1.64125,-3.97295 -2.93968,-6.04806 -2.53124,-4.04565 -6.76941,-16.05907 -7.85462,-22.2645 -1.09688,-6.27237 1.88002,-4.68941 6.02872,3.20531 6.59817,12.55624 18.42576,23.3376 25.60251,23.3376 4.10549,0 3.64683,-2.83886 -1.31592,-8.14447 -2.60588,-2.78588 -5.80889,-7.43947 -7.11782,-10.34167 -3.17705,-7.04373 -4.93076,-19.49421 -4.0579,-28.80931 l 0.69868,-7.4557 -3.0765,1.59097 c -4.23567,2.19053 -13.19156,1.998 -20.10414,-0.43159 -3.45737,-1.215 -8.346,-2.02218 -12.24823,-2.02218 l -6.49426,0 0.6831,7.53861 c 1.3792,15.21994 6.89518,30.43525 14.37361,39.64827 3.46994,4.275 14.7051,12.47177 17.09445,12.47177 0.8527,0 1.10569,-0.7907 0.728,-2.27505 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="fcrown17" d="m 782.05212,652.75378 c 4.83714,-3.14639 6.70315,-12.77441 3.62829,-18.72031 -1.29871,-2.5114 -2.07888,-2.99373 -4.19017,-2.58993 -1.42689,0.27302 -4.1068,-0.13485 -5.95544,-0.90836 -4.06136,-1.69708 -9.5168,-1.811 -12.90006,-0.26965 -1.94804,0.88746 -3.54049,0.77726 -7.30584,-0.50559 -8.96171,-3.0537 -14.57888,-1.91513 -16.40558,3.32471 -1.09984,3.15517 -0.90625,4.75484 1.51013,12.47714 l 1.50291,4.80293 5.98172,0 c 3.28992,0 8.59989,0.87935 11.79992,1.95435 3.20005,1.0748 6.72825,2.18143 7.84053,2.45854 3.32322,0.82849 11.96384,-0.37824 14.49359,-2.02404 z"
        />
      </g>
      <g id="topview17" className={toothClassNameMapping[17]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-right"  id="tr17" d="m 764.0538,580.19599 a 13.153366,13.153366 0 0 1 13.14516,13.15359 l -6.57257,0 m -6.57259,-6.57696 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 1 6.57259,6.57696"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br17" d="m 777.19896,593.34958 a 13.153366,13.153366 0 0 1 -13.14516,13.15324 l 0,-6.57662 m 6.57259,-6.57662 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 1 -6.57259,6.57662"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl17" d="m 764.0538,606.50282 a 13.153366,13.153366 0 0 1 -13.17884,-13.15357 l 6.57256,0 m 6.60628,6.57695 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 1 -6.60628,-6.57695"
        />
        <path className="ToothChart__top-view-top-left"  id="tl17" d="m 750.87496,593.34958 a 13.153366,13.153366 0 0 1 13.17884,-13.15359 l 0,6.57663 m -6.60628,6.57663 -6.57256,0 m 6.57256,0 a 6.5766829,6.5766829 0 0 1 6.60628,-6.57663"
        />
        <circle className="ToothChart__top-view-circle"  id="center17" cx="764.048" cy="593.349" r="6.577" />
      </g>
      <g className="toothLabel" id="label17">
        <circle   transform="scale(-1 1)" r="11.797" cy="556.949" cx="-764.047"
          id="path8780-5-1-9-8-2" fill="#c8d2da" strokeWidth=".5" />
        <text className="labelNumber" style={{lineHeight: "100%"
        }} x="764.045" y="561.251" id="text9524" fontWeight="500" fontSize="11.797"
        wordSpacing="0" textAnchor="middle" fill="#fff">
        <tspan id="tspan9526" x="764.045" y="561.251">{intlMessages['toothChart.toothNum.17']}</tspan>
      </text>
    </g>
    <g className="ToothChart__highlighter">
      <rect id="Rectangle-10" x="740" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
    </g>
      </g>
      <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth18">
        <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
          <path className="ToothChart__tooth-drawing-component" id="froot18" d="m 703.34951,716.26788 c 0,-1.01462 -1.13257,-5.17524 -2.5169,-9.24518 -1.38427,-4.06982 -3.22436,-10.82487 -4.08903,-15.01115 -1.35932,-6.58084 -1.37745,-7.80602 -0.13381,-9.04952 1.24349,-1.24335 1.77848,-0.98044 3.95175,1.94179 1.38243,1.85896 4.24632,6.56506 6.36419,10.45812 8.29597,15.24948 15.6754,23.76963 17.82713,20.5827 1.46811,-2.17394 0.33367,-7.97649 -3.08046,-15.75499 -4.61254,-10.50945 -5.85711,-20.15854 -4.31736,-33.47333 0.68624,-5.93441 1.51831,-12.15482 1.84897,-13.82336 l 0.60133,-3.03348 -2.41365,2.14875 c -5.31738,4.73408 -10.44889,5.60185 -17.79549,3.00991 -3.11148,-1.09774 -5.0576,-1.17385 -8.70251,-0.33978 -4.39708,1.00612 -7.7185,0.53894 -13.45093,-1.89192 -2.29811,-0.97426 5.97427,37.91856 10.77615,50.66489 1.42517,3.78306 3.89205,7.68475 6.55283,10.3645 4.50016,4.53191 8.57796,5.69787 8.57796,2.45205 z"
          />
          <path className="ToothChart__tooth-drawing-component" id="fcrown18" d="m 717.25304,649.89361 c 2.88933,-3.11537 3.28628,-4.22306 3.28628,-9.16994 0,-6.09767 -1.91976,-10.92563 -4.9998,-12.57418 -3.10449,-1.66133 -9.40845,-1.21037 -13.70676,0.98116 -5.13244,2.61656 -6.20145,2.55456 -10.79273,-0.62724 -5.26533,-3.64897 -11.83036,-4.32982 -14.39721,-1.4935 -4.39867,4.86035 -3.75981,18.9597 1.06422,23.48592 2.26582,2.1258 3.60376,2.41802 11.82357,2.5816 5.09778,0.1014 11.31627,0.57969 13.8189,1.06301 6.10864,1.17965 9.96251,0 13.90353,-4.24683 z"
          />
        </g>
        <g id="topview18" className={toothClassNameMapping[18]} stroke="#000" strokeWidth="1.685">
          <path className="ToothChart__top-view-top-right"  id="tr18" d="m 698.76137,580.19599 a 13.153366,13.153366 0 0 1 13.14515,13.15359 l -6.57255,0 m -6.5726,-6.57696 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 1 6.5726,6.57696"
          />
          <path className="ToothChart__top-view-bottom-right"  id="br18" d="m 711.90652,593.34958 a 13.153366,13.153366 0 0 1 -13.14515,13.15324 l 0,-6.57662 m 6.5726,-6.57662 6.57255,0 m -6.57255,0 a 6.5766829,6.5766829 0 0 1 -6.5726,6.57662"
          />
          <path className="ToothChart__top-view-bottom-left"  id="bl18" d="m 698.76137,606.50282 a 13.153366,13.153366 0 0 1 -13.1788,-13.15324 l 6.57253,0 m 6.60627,6.57662 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 1 -6.60627,-6.57662"
          />
          <path className="ToothChart__top-view-top-left"  id="tl18" d="m 685.58257,593.34958 a 13.153366,13.153366 0 0 1 13.1788,-13.15359 l 0,6.57663 m -6.60627,6.57696 -6.57253,0 m 6.57253,0 a 6.5766829,6.5766829 0 0 1 6.60627,-6.57696"
          />
          <circle className="ToothChart__top-view-circle"  id="center18" cx="698.756" cy="593.349" r="6.577" />
        </g>
        <g className="ToothChart__tooth-label" id="label18">
          <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="556.949" cx="-698.754"
            id="path8780-5-1-3-1" fill="#c8d2da" strokeWidth=".5" />
          <text className="labelNumber" style={{lineHeight: "100%"
          }} x="698.753" y="561.251" id="text9520" fontWeight="500" fontSize="11.797"
          wordSpacing="0" textAnchor="middle" fill="#fff">
          <tspan id="tspan9522" x="698.753" y="561.251">{intlMessages['toothChart.toothNum.18']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="675" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth19">
      <g transform="translate(-3.24)" className="ToothChart__facial-view" stroke="#000"
        strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="froot19" d="m 668.9699,723.28944 c 0,-2.15817 -1.11222,-6.87912 -2.4716,-10.49057 -4.13425,-10.98395 -5.91247,-22.9422 -5.82802,-39.19311 l 0.0761,-14.6781 -7.29299,0.36812 c -4.20931,0.2124 -9.20468,-0.19439 -11.81417,-0.96581 -2.48668,-0.73333 -9.0948,-1.35584 -14.68478,-1.38292 l -10.16358,-0.0476 0.77883,5.58138 c 0.43181,3.09406 0.31448,9.47807 -0.26324,14.3265 -1.57725,13.23807 0.79698,27.54885 6.01426,36.25157 2.21386,3.69291 9.47235,11.12297 10.86596,11.12297 2.90713,0 3.20101,-6.63742 0.52785,-11.92177 -4.34553,-8.59049 -4.57647,-22.16033 -0.42955,-25.2386 3.20206,-2.3773 4.25059,-1.4535 8.89321,7.83662 5.29991,10.60448 11.5206,21.29962 14.04791,24.15175 2.50229,2.82422 9.64022,8.2054 10.88395,8.2054 0.4727,0 0.85945,-1.76625 0.85945,-3.92441 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="fcrown19" d="m 662.30706,652.89938 c 1.14868,-2.30511 2.12566,-6.89483 2.40964,-11.31951 0.40363,-6.28885 0.18344,-7.76751 -1.42086,-9.54009 -1.44856,-1.60068 -2.74084,-1.98491 -5.45254,-1.62123 -2.62907,0.35257 -4.46523,-0.14828 -7.0552,-1.92223 -4.37453,-2.99742 -11.30854,-3.21483 -15.76979,-0.49445 -3.08773,1.88278 -3.14082,1.87908 -7.07816,-0.50423 -10.60868,-6.42158 -19.43338,0.64815 -16.59861,13.29718 0.5481,2.44572 1.99677,6.50581 3.21934,9.02186 2.58267,5.31603 5.1816,6.2231 15.22435,5.3123 3.54315,-0.32014 7.22397,-0.0807 8.7474,0.56812 3.16802,1.35211 16.98574,2.52452 19.82002,1.68114 1.11228,-0.33027 2.89178,-2.34625 3.95441,-4.47886 z"
        />
      </g>
      <g id="topview19" className={toothClassNameMapping[19]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-right"  id="tr19" d="m 634.75897,580.19599 a 13.153366,13.153366 0 0 1 13.14516,13.15326 l -6.57257,0 m -6.57259,-6.57663 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 1 6.57259,6.57663"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br19" d="m 647.90413,593.34925 a 13.153366,13.153366 0 0 1 -13.14516,13.15357 l 0,-6.57662 m 6.57259,-6.57695 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 1 -6.57259,6.57695"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl19" d="m 634.75897,606.50282 a 13.153366,13.153366 0 0 1 -13.17885,-13.15357 l 6.57256,0 m 6.60629,6.57695 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 1 -6.60629,-6.57695"
        />
        <path className="ToothChart__top-view-top-left"  id="tl19" d="m 621.58012,593.34925 a 13.153366,13.153366 0 0 1 13.17885,-13.15326 l 0,6.57663 m -6.60629,6.57663 -6.57256,0 m 6.57256,0 a 6.5766829,6.5766829 0 0 1 6.60629,-6.57663"
        />
        <circle className="ToothChart__top-view-circle"  id="center19" cx="634.754" cy="593.349" r="6.577" />
      </g>
      <g className="ToothChart__tooth-label" id="label19">
        <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="556.949" cx="-634.752"
          id="path8780-5-7-0" fill="#c8d2da" strokeWidth=".5" />
        <text className="labelNumber" style={{lineHeight: "100%"
        }} x="634.75" y="561.251" id="text9516" fontWeight="500" fontSize="11.797"
        wordSpacing="0" textAnchor="middle" fill="#fff">
        <tspan id="tspan9518" x="634.75" y="561.251">{intlMessages['toothChart.toothNum.19']}</tspan>
      </text>
    </g>
    <g className="ToothChart__highlighter">
      <rect id="Rectangle-10" x="613" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
    </g>
        </g>
        <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth20">
          <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
            <path className="ToothChart__tooth-drawing-component" id="froot20" d="m 587.48445,728.98334 c 0.28076,-1.80742 0.70876,-9.20181 0.95099,-16.43143 0.24233,-7.22994 1.59147,-20.1981 2.99829,-28.81824 1.40682,-8.62027 2.85828,-17.94821 3.22547,-20.72897 l 0.66765,-5.05586 -3.18342,3.22911 c -6.17647,6.26467 -13.29359,6.52691 -19.5553,0.72094 l -2.83367,-2.6275 0.58577,8.43926 c 2.52769,36.41592 3.60852,43.23776 8.70867,54.96774 2.16233,4.97301 5.59821,9.59106 7.1359,9.59106 0.43403,0 1.01884,-1.4786 1.29965,-3.28611 z"
            />
            <path className="ToothChart__tooth-drawing-component" id="fcrown20" d="m 589.05461,661.86642 c 4.6603,-3.92151 7.34044,-9.96572 7.90472,-17.82614 0.48474,-6.75288 0.45037,-6.86715 -3.04061,-10.11164 -1.94198,-1.8046 -5.57433,-4.3716 -8.07183,-5.70399 l -4.54094,-2.42275 -4.87104,2.42275 c -2.6791,1.33239 -6.21631,3.76459 -7.8605,5.40435 -2.79774,2.79014 -2.95018,3.34155 -2.37678,8.59488 1.2262,11.23408 3.87788,16.55405 10.16096,20.38539 4.42449,2.69826 8.91916,2.43499 12.69602,-0.74285 z"
            />
          </g>
          <g id="topview20" className={toothClassNameMapping[20]} stroke="#000" strokeWidth="1.685">
            <path className="ToothChart__top-view-top-right"   id="tr20" d="m 581.43012,580.17678 a 13.153366,13.153366 0 0 1 13.14515,13.15358 l -6.57259,0 m -6.57256,-6.57696 0,-6.57662 m 0,6.57662 a 6.5766829,6.5766829 0 0 1 6.57256,6.57696"
            />
            <path className="ToothChart__top-view-bottom-right"   id="br20" d="m 594.57527,593.33036 a 13.153366,13.153366 0 0 1 -13.14515,13.15325 l 0,-6.57662 m 6.57256,-6.57663 6.57259,0 m -6.57259,0 a 6.5766829,6.5766829 0 0 1 -6.57256,6.57663"
            />
            <path className="ToothChart__top-view-bottom-left"   id="bl20" d="m 581.43012,606.48361 a 13.153366,13.153366 0 0 1 -13.17886,-13.15325 l 6.57257,0 m 6.60629,6.57663 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 1 -6.60629,-6.57663"
            />
            <path className="ToothChart__top-view-top-left"   id="tl20" d="m 568.25126,593.33036 a 13.153366,13.153366 0 0 1 13.17886,-13.15358 l 0,6.57662 m -6.60629,6.57696 -6.57257,0 m 6.57257,0 a 6.5766829,6.5766829 0 0 1 6.60629,-6.57696"
            />
            <circle className="ToothChart__top-view-circle"   id="center20" cx="581.425" cy="593.331" r="6.577" />
          </g>
          <g className="ToothChart__tooth-label" id="label20">
            <circle className="ToothChart__tooth-label-circle"  transform="scale(-1 1)" r="11.797" cy="556.949" cx="-581.423"
              id="path8780-5-1-9-5" fill="#c8d2da" strokeWidth=".5" />
            <text className="labelNumber" style={{lineHeight: "100%"
            }} x="581.421" y="561.251" id="text9512" fontWeight="500" fontSize="11.797"
            wordSpacing="0" textAnchor="middle" fill="#fff">
            <tspan id="tspan9514" x="581.421" y="561.251">{intlMessages['toothChart.toothNum.20']}</tspan>
          </text>
        </g>
        <g className="ToothChart__highlighter">
          <rect id="Rectangle-10" x="559" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
        </g>
      </g>
      <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth21">
        <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
          <path className="ToothChart__tooth-drawing-component" id="froot21" d="m 552.05618,720.35043 c -0.77878,-13.19668 -0.59766,-46.80072 0.29695,-55.10536 l 0.84789,-7.87117 -2.74058,2.54003 c -5.27505,4.88857 -12.56931,4.36865 -18.23859,-1.30103 l -2.65109,-2.65089 0.59254,4.53266 c 3.6522,27.93708 5.02287,35.35528 9.43165,51.04587 2.85161,10.1488 4.57878,14.0792 7.67416,17.46367 1.90108,2.07866 2.24891,2.14668 3.6736,0.72058 1.24228,-1.24337 1.47679,-3.21759 1.11351,-9.37436 z"
          />
          <path className="ToothChart__tooth-drawing-component" id="fcrown21" d="m 548.93602,658.83977 c 5.6344,-4.74111 9.27568,-15.40275 7.07503,-20.7159 -1.39603,-3.37011 -12.15084,-12.02671 -14.94202,-12.02671 -1.99947,0 -9.18311,5.29885 -12.56785,9.27038 -2.33843,2.74393 -2.23615,7.60767 0.30268,14.3933 4.0796,10.9036 13.11896,14.98004 20.13219,9.07893 z"
          />
        </g>
        <g id="topview21" className={toothClassNameMapping[21]} stroke="#000" strokeWidth="1.685">
          <path className="ToothChart__top-view-top-right"  id="tr21" d="m 542.66876,580.19599 a 13.153366,13.153366 0 0 1 13.14513,13.15359 l -6.57257,0 m -6.57256,-6.57696 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 1 6.57256,6.57696"
          />
          <path className="ToothChart__top-view-bottom-right"  id="br21" d="m 555.81389,593.34958 a 13.153366,13.153366 0 0 1 -13.14513,13.15324 l 0,-6.57662 m 6.57256,-6.57662 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 1 -6.57256,6.57662"
          />
          <path className="ToothChart__top-view-bottom-left"  id="bl21" d="m 542.66876,606.50282 a 13.153366,13.153366 0 0 1 -13.17882,-13.15324 l 6.57254,0 m 6.60628,6.57662 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 1 -6.60628,-6.57662"
          />
          <path className="ToothChart__top-view-top-left"  id="tl21" d="m 529.48994,593.34958 a 13.153366,13.153366 0 0 1 13.17882,-13.15359 l 0,6.57663 m -6.60628,6.57696 -6.57254,0 m 6.57254,0 a 6.5766829,6.5766829 0 0 1 6.60628,-6.57696"
          />
          <circle className="ToothChart__top-view-circle"  id="center21" cx="542.664" cy="593.349" r="6.577" />
        </g>
        <g className="ToothChart__tooth-label" id="label21">
          <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="556.949" cx="-542.662"
            id="path8780-5-2-5" fill="#c8d2da" strokeWidth=".5" />
          <text className="labelNumber" style={{lineHeight: "100%"
          }} x="542.66" y="561.251" id="text9508" fontWeight="500" fontSize="11.797"
          wordSpacing="0" textAnchor="middle" fill="#fff">
          <tspan id="tspan9510" x="542.66" y="561.251">{intlMessages['toothChart.toothNum.21']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="520" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
          </g>
          <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth22">
            <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
              <path className="ToothChart__tooth-drawing-component" id="froot22" d="m 511.80469,742.63399 c -1.35018,-5.58688 -1.81168,-40.33355 -0.69495,-52.32777 2.24848,-24.15016 2.3545,-26.41907 1.14606,-24.52076 -1.65605,2.60125 -6.49078,4.80295 -10.54673,4.80295 -2.54067,0 -4.58295,-0.90336 -7.1623,-3.16791 -1.98457,-1.74257 -3.73415,-2.99377 -3.88803,-2.78075 -0.64947,0.8992 2.4195,36.60713 3.94406,45.88973 2.64467,16.1018 5.88491,25.09136 10.94947,30.37693 5.03768,5.25766 7.25343,5.87 6.25242,1.72758 z"
              />
              <path className="ToothChart__tooth-drawing-component" id="fcrown22" d="m 510.28859,664.61119 c 5.83634,-6.44024 8.85948,-20.87802 5.66499,-27.05551 -2.3018,-4.45113 -12.5304,-13.48085 -15.27084,-13.48085 -3.62811,0 -10.62641,5.71646 -12.76493,10.42679 -2.57861,5.6798 -2.45316,11.39911 0.4238,19.32509 3.0069,8.28415 4.9655,11.27753 9.03955,13.8159 4.50662,2.80765 8.45583,1.88011 12.90743,-3.03142 z"
              />
            </g>
            <g id="topview22" className={toothClassNameMapping[22]} stroke="#000" strokeWidth="1.685">
              <path className="ToothChart__top-view-top-right"  id="tr22" d="m 501.51194,580.19599 a 13.153366,13.153366 0 0 1 13.14515,13.15359 l -6.57257,0 m -6.57258,-6.57696 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 1 6.57258,6.57696"
              />
              <path className="ToothChart__top-view-bottom-right"  id="br22" d="m 514.65709,593.34958 a 13.153366,13.153366 0 0 1 -13.14515,13.15324 l 0,-6.57662 m 6.57258,-6.57662 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 1 -6.57258,6.57662"
              />
              <path className="ToothChart__top-view-bottom-left"  id="bl22" d="m 501.51194,606.50282 a 13.153366,13.153366 0 0 1 -13.17886,-13.15324 l 6.57258,0 m 6.60628,6.57662 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 1 -6.60628,-6.57662"
              />
              <path className="ToothChart__top-view-top-left"  id="tl22" d="m 488.33308,593.34958 a 13.153366,13.153366 0 0 1 13.17886,-13.15359 l 0,6.57663 m -6.60628,6.57696 -6.57258,0 m 6.57258,0 a 6.5766829,6.5766829 0 0 1 6.60628,-6.57696"
              />
              <circle className="ToothChart__top-view-circle"  id="center22" cx="501.507" cy="593.349" r="6.577" />
            </g>
            <g className="ToothChart__tooth-label" id="label22">
              <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="556.949" cx="-501.504"
                id="path8780-5-1-38" fill="#c8d2da" strokeWidth=".5" />
              <text className="labelNumber" style={{lineHeight: "100%"
              }} x="501.503" y="561.251" id="text9504" fontWeight="500" fontSize="11.797"
              wordSpacing="0" textAnchor="middle" fill="#fff">
              <tspan id="tspan9506" x="501.503" y="561.251">{intlMessages['toothChart.toothNum.22']}</tspan>
            </text>
          </g>
          <g className="ToothChart__highlighter">
            <rect id="Rectangle-10" x="477" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
          </g>
        </g>
        <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth23">
          <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
            <path className="ToothChart__tooth-drawing-component" id="froot23" d="m 461.06603,725.9497 c 0.28683,-2.36355 0.74747,-10.66772 1.02353,-18.4537 0.45231,-12.75821 2.72433,-38.03905 4.21663,-46.91883 l 0.57796,-3.43883 -3.1855,2.68046 c -4.02355,3.38547 -6.51691,3.38673 -9.65121,0 l -2.47967,-2.67589 0.0165,12.02886 c 0.0337,24.68496 3.02248,53.38852 6.17594,59.28093 1.57508,2.94281 2.75346,2.04921 3.30667,-2.5081 z"
            />
            <path className="ToothChart__tooth-drawing-component" id="fcrown23" d="m 463.65165,657.64896 c 3.22885,-3.22872 4.63397,-8.57142 5.63872,-21.44015 0.42161,-5.39995 0.26325,-9.64279 -0.42589,-11.41368 l -1.09695,-2.81879 -9.36383,0.28986 -9.36384,0.28988 0.0566,11.12281 c 0.0604,11.86944 1.79728,19.32471 5.64054,24.21058 2.63372,3.34845 5.39911,3.27318 8.91473,-0.24258 z"
            />
          </g>
          <g id="topview23" className={toothClassNameMapping[23]} stroke="#000" strokeWidth="1.685">
            <path className="ToothChart__top-view-top-right"  id="tr23" d="m 460.09025,580.21521 a 13.153366,13.153366 0 0 1 13.14516,13.15358 l -6.57257,0 m -6.57259,-6.57695 0,-6.57663 m 0,6.57663 a 6.5766829,6.5766829 0 0 1 6.57259,6.57695"
            />
            <path className="ToothChart__top-view-bottom-right"  id="br23" d="m 473.23541,593.36879 a 13.153366,13.153366 0 0 1 -13.14516,13.15324 l 0,-6.57662 m 6.57259,-6.57662 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 1 -6.57259,6.57662"
            />
            <path className="ToothChart__top-view-bottom-left"  id="bl23" d="m 460.09025,606.52203 a 13.153366,13.153366 0 0 1 -13.17881,-13.15324 l 6.57254,0 m 6.60627,6.57662 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 1 -6.60627,-6.57662"
            />
            <path className="ToothChart__top-view-top-left"  id="tl23" d="m 446.91144,593.36879 a 13.153366,13.153366 0 0 1 13.17881,-13.15358 l 0,6.57663 m -6.60627,6.57695 -6.57254,0 m 6.57254,0 a 6.5766829,6.5766829 0 0 1 6.60627,-6.57695"
            />
            <circle className="ToothChart__top-view-circle"  id="center23" cx="460.085" cy="593.368" r="6.577" />
          </g>
          <g className="ToothChart__tooth-label" id="label23">
            <circle className="ToothChart__tooth-label-circle"  transform="scale(-1 1)" r="11.797" cy="556.949" cx="-459.104"
              id="path8780-5-6" fill="#c8d2da" strokeWidth=".5" />
            <text className="labelNumber" style={{lineHeight: "100%"
            }} x="459.102" y="561.251" id="text9500" fontWeight="500" fontSize="11.797"
            wordSpacing="0" textAnchor="middle" fill="#fff">
            <tspan id="tspan9502" x="459.102" y="561.251">{intlMessages['toothChart.toothNum.23']}</tspan>
          </text>
        </g>
        <g className="ToothChart__highlighter">
          <rect id="Rectangle-10" x="436" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
        </g>
      </g>
      <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth24">
        <g id="topview24" className={toothClassNameMapping[24]} stroke="#000" strokeWidth="1.685">
          <path className="ToothChart__top-view-top-right"   id="tr24" d="m 422.75025,580.17678 a 13.153366,13.153366 0 0 1 13.14516,13.15325 l -6.57258,0 m -6.57258,-6.57663 0,-6.57662 m 0,6.57662 a 6.5766829,6.5766829 0 0 1 6.57258,6.57663"
          />
          <path className="ToothChart__top-view-bottom-right"   id="br24" d="m 435.89541,593.33003 a 13.153366,13.153366 0 0 1 -13.14516,13.15358 l 0,-6.57662 m 6.57258,-6.57696 6.57258,0 m -6.57258,0 a 6.5766829,6.5766829 0 0 1 -6.57258,6.57696"
          />
          <path className="ToothChart__top-view-bottom-left"   id="bl24" d="M 422.75025,606.48361 A 13.153366,13.153366 0 0 1 409.5714,593.33003 l 6.57258,0 m 6.60627,6.57696 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 1 -6.60627,-6.57696"
          />
          <path className="ToothChart__top-view-top-left"   id="tl24" d="m 409.5714,593.33003 a 13.153366,13.153366 0 0 1 13.17885,-13.15325 l 0,6.57662 m -6.60627,6.57663 -6.57258,0 m 6.57258,0 a 6.5766829,6.5766829 0 0 1 6.60627,-6.57663"
          />
          <circle className="ToothChart__top-view-circle"   id="center24" cx="422.745" cy="593.331" r="6.577" />
        </g>
        <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
          <path className="ToothChart__tooth-drawing-component" id="froot24" d="m 425.33944,719.54751 c 0.33756,-0.87993 0.6336,-6.45395 0.65785,-12.38697 0.0235,-5.9332 0.45877,-15.56502 0.96565,-21.40452 2.48905,-28.67579 2.51658,-29.96132 0.60076,-28.04544 -2.29878,2.29911 -5.39412,2.21474 -9.44704,-0.25623 l -3.2863,-2.00336 0.004,5.79893 c 0.0107,11.19729 2.12297,39.0351 3.51774,46.32486 2.04501,10.68835 5.32352,16.30638 6.98662,11.97273 z"
          />
          <path className="ToothChart__tooth-drawing-component" id="fcrown24" d="m 427.43767,655.45678 c 2.34563,-2.59195 4.4176,-11.49633 5.23633,-22.50357 0.8544,-11.48684 0.50481,-11.91188 -9.79542,-11.91188 -4.33555,0 -8.60257,0.27641 -9.48224,0.6138 -3.85297,1.47867 -0.15469,29.14348 4.52087,33.81902 2.53263,2.5328 7.22035,2.5239 9.52046,0 z"
          />
        </g>
        <g className="ToothChart__tooth-label" id="label24">
          <circle className="ToothChart__tooth-label-circle"   transform="scale(-1 1)" r="11.797" cy="556.951" cx="-422.743"
            id="path8780-1" fill="#c8d2da" strokeWidth=".5" />
          <text className="labelNumber" style={{lineHeight: "100%"
          }} x="422.741" y="561.251" id="text9496" fontWeight="500" fontSize="11.797"
          wordSpacing="0" textAnchor="middle" fill="#fff">
          <tspan id="tspan9498" x="422.741" y="561.251">{intlMessages['toothChart.toothNum.24']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="399" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
          </g>
      <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth25">
        <g id="topview25" className={toothClassNameMapping[25]} stroke="#000" strokeWidth="1.685">
          <path className="ToothChart__top-view-top-left"  id="tl25" d="m 377.25048,580.88933 a 13.153366,13.153366 0 0 0 -13.14516,13.15323 l 6.57259,0 m 6.57257,-6.57662 0,-6.57661 m 0,6.57661 a 6.5766829,6.5766829 0 0 0 -6.57257,6.57662"
          />
          <path className="ToothChart__top-view-bottom-left"  id="bl25" d="m 364.10532,594.04256 a 13.153366,13.153366 0 0 0 13.14516,13.15323 l 0,-6.57661 m -6.57257,-6.57662 -6.57259,0 m 6.57259,0 a 6.5766829,6.5766829 0 0 0 6.57257,6.57662"
          />
          <path className="ToothChart__top-view-bottom-right"  id="br25" d="m 377.25048,607.19579 a 13.153366,13.153366 0 0 0 13.17885,-13.15323 l -6.57257,0 m -6.60628,6.57662 0,6.57661 m 0,-6.57661 a 6.5766829,6.5766829 0 0 0 6.60628,-6.57662"
          />
          <path className="ToothChart__top-view-top-right"   id="tr25" d="m 390.42933,594.04256 a 13.153366,13.153366 0 0 0 -13.17885,-13.15323 l 0,6.57661 m 6.60628,6.57662 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 0 -6.60628,-6.57662"
          />
          <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center25" cx="-377.256" cy="594.043"
            r="6.577" />
        </g>
        <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
          <path className="ToothChart__tooth-drawing-component" id="froot25" d="m 374.66055,719.54654 c -0.33756,-0.87936 -0.63361,-6.45331 -0.65783,-12.38657 -0.0236,-5.933 -0.4588,-15.56484 -0.96567,-21.40436 -2.48905,-28.67559 -2.51658,-29.9611 -0.60076,-28.04544 2.2988,2.29869 5.39412,2.21493 9.44705,-0.25602 l 3.28628,-2.00378 -0.004,5.79935 c -0.0107,11.19687 -2.12298,39.03492 -3.51775,46.32442 -2.04502,10.68842 -5.32353,16.30669 -6.98662,11.97289 z"
          />
          <path className="ToothChart__tooth-drawing-component" id="fcrown25" d="m 372.56231,655.45595 c -2.34563,-2.59152 -4.41757,-11.49612 -5.23633,-22.50308 -0.85439,-11.48683 -0.50479,-11.91187 9.79543,-11.91187 4.33555,0 8.60257,0.27639 9.48225,0.6138 3.85295,1.4783 0.15471,29.14339 -4.52089,33.81914 -2.53263,2.5326 -7.22032,2.52391 -9.52046,0 z"
          />
        </g>
        <g className="ToothChart__tooth-label" id="label25">
          <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="556.951" cx="377.256" id="path8780" fill="#c8d2da"
            strokeWidth=".5" />
          <text className="labelNumber" style={{lineHeight: "100%"
          }} x="377.257" y="561.251" id="text9492" fontWeight="500" fontSize="11.797"
          wordSpacing="0" textAnchor="middle" fill="#fff">
          <tspan id="tspan9494" x="377.257" y="561.251">{intlMessages['toothChart.toothNum.25']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="353" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth26">
      <g id="topview26" className={toothClassNameMapping[26]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-left"  id="tl26" d="m 339.91048,580.92775 a 13.153366,13.153366 0 0 0 -13.14514,13.15323 l 6.57257,0 m 6.57257,-6.57662 0,-6.57661 m 0,6.57661 a 6.5766829,6.5766829 0 0 0 -6.57257,6.57662"
        />
        <path className="ToothChart__top-view-bottom-left"  id="bl26" d="m 326.76534,594.08098 a 13.153366,13.153366 0 0 0 13.14514,13.15324 l 0,-6.57663 m -6.57257,-6.57661 -6.57257,0 m 6.57257,0 a 6.5766829,6.5766829 0 0 0 6.57257,6.57661"
        />
        <path className="ToothChart__top-view-bottom-right"  id="br26" d="m 339.91048,607.23422 a 13.153366,13.153366 0 0 0 13.17881,-13.15324 l -6.57254,0 m -6.60627,6.57661 0,6.57663 m 0,-6.57663 a 6.5766829,6.5766829 0 0 0 6.60627,-6.57661"
        />
        <path className="ToothChart__top-view-top-right"  id="tr26" d="m 353.08929,594.08098 a 13.153366,13.153366 0 0 0 -13.17881,-13.15323 l 0,6.57661 m 6.60627,6.57662 6.57254,0 m -6.57254,0 a 6.5766829,6.5766829 0 0 0 -6.60627,-6.57662"
        />
        <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center26" cx="-339.916" cy="594.081"
          r="6.577" />
      </g>
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="froot26" d="m 338.93395,725.94945 c -0.28683,-2.36387 -0.74747,-10.66788 -1.02353,-18.45386 -0.45228,-12.75847 -2.72431,-38.03926 -4.2166,-46.91903 l -0.57796,-3.43863 3.1855,2.68025 c 4.02352,3.38568 6.51689,3.38671 9.6512,0 l 2.47965,-2.67549 -0.0165,12.02845 c -0.0337,24.68519 -3.02248,53.38891 -6.17592,59.28091 -1.57511,2.94313 -2.75349,2.04929 -3.30669,-2.50777 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="fcrown26" d="m 336.34836,657.64854 c -3.22887,-3.2289 -4.63399,-8.57163 -5.63875,-21.44005 -0.42158,-5.39996 -0.26322,-9.64282 0.42592,-11.41371 l 1.09695,-2.81879 9.3638,0.28989 9.36385,0.28986 -0.0566,11.12281 c -0.0604,11.86895 -1.79728,19.32464 -5.64056,24.21073 -2.63372,3.34823 -5.39911,3.27275 -8.91473,-0.2428 z"
        />
      </g>
      <g className="ToothChart__tooth-label" id="label26">
        <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="556.95" cx="340.895" id="path8780-5" fill="#c8d2da"
          strokeWidth=".5" />
        <text className="labelNumber" style={{lineHeight: "100%"
        }} x="340.897" y="561.251" id="text9488" fontWeight="500" fontSize="11.797"
        wordSpacing="0" textAnchor="middle" fill="#fff">
        <tspan id="tspan9490" x="340.897" y="561.251">{intlMessages['toothChart.toothNum.26']}</tspan>
      </text>
    </g>
    <g className="ToothChart__highlighter">
      <rect id="Rectangle-10" x="314" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
    </g>
      </g>
      <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth27">
        <g id="topview27" className={toothClassNameMapping[27]} stroke="#000" strokeWidth="1.685">
          <path className="ToothChart__top-view-top-left"  id="tl27" d="m 298.4888,580.90854 a 13.153366,13.153366 0 0 0 -13.14512,13.15322 l 6.57257,0 m 6.57255,-6.5766 0,-6.57662 m 0,6.57662 a 6.5766829,6.5766829 0 0 0 -6.57255,6.5766"
          />
          <path className="ToothChart__top-view-bottom-left"  id="bl27" d="m 285.34368,594.06176 a 13.153366,13.153366 0 0 0 13.14512,13.15325 l 0,-6.57662 m -6.57255,-6.57663 -6.57257,0 m 6.57257,0 a 6.5766829,6.5766829 0 0 0 6.57255,6.57663"
          />
          <path className="ToothChart__top-view-bottom-right"  id="br27" d="m 298.4888,607.21501 a 13.153366,13.153366 0 0 0 13.17886,-13.15325 l -6.57258,0 m -6.60628,6.57663 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 0 6.60628,-6.57663"
          />
          <path className="ToothChart__top-view-top-right"  id="tr27" d="M 311.66766,594.06176 A 13.153366,13.153366 0 0 0 298.4888,580.90854 l 0,6.57662 m 6.60628,6.5766 6.57258,0 m -6.57258,0 a 6.5766829,6.5766829 0 0 0 -6.60628,-6.5766"
          />
          <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center27" cx="-298.494" cy="594.062"
            r="6.577" />
        </g>
        <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
          <path className="ToothChart__tooth-drawing-component" id="froot27" d="m 288.1953,742.63359 c 1.35018,-5.58737 1.81167,-40.33401 0.69494,-52.32778 -2.24845,-24.14995 -2.35448,-26.41907 -1.14605,-24.52076 1.65607,2.60104 6.49078,4.80314 10.54672,4.80314 2.54069,0 4.58299,-0.90333 7.16233,-3.16831 1.98455,-1.74236 3.73413,-2.99378 3.88804,-2.78077 0.64946,0.89921 -2.41954,36.60749 -3.94409,45.88997 -2.64468,16.1018 -5.88489,25.09119 -10.94947,30.37676 -5.03765,5.25774 -7.25344,5.86992 -6.25242,1.72775 z"
          />
          <path className="ToothChart__tooth-drawing-component" id="fcrown27" d="m 289.71142,664.61037 c -5.83635,-6.43963 -8.85949,-20.87782 -5.66502,-27.05501 2.30181,-4.45149 12.53044,-13.48085 15.27085,-13.48085 3.62814,0 10.6264,5.7161 12.76496,10.42679 2.5786,5.6793 2.45315,11.39882 -0.42382,19.325 -3.00691,8.28395 -4.96549,11.27731 -9.03956,13.8157 -4.50663,2.80784 -8.45583,1.8803 -12.90741,-3.03163 z"
          />
        </g>
        <g className="ToothChart__tooth-label" id="label27">
          <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="556.95" cx="298.494" id="path8780-5-1" fill="#c8d2da"
            strokeWidth=".5" />
          <text className="labelNumber" style={{lineHeight: "100%"
          }} x="298.496" y="561.251" id="text9484" fontWeight="500" fontSize="11.797"
          wordSpacing="0" textAnchor="middle" fill="#fff">
          <tspan id="tspan9486" x="298.496" y="561.251">{intlMessages['toothChart.toothNum.27']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="273" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
      <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth28">
        <g id="topview28" className={toothClassNameMapping[28]} stroke="#000" strokeWidth="1.685">
          <path className="ToothChart__top-view-top-left"  id="tl28" d="m 257.33199,580.90854 a 13.153366,13.153366 0 0 0 -13.14515,13.15322 l 6.57257,0 m 6.57258,-6.5766 0,-6.57662 m 0,6.57662 a 6.5766829,6.5766829 0 0 0 -6.57258,6.5766"
          />
          <path className="ToothChart__top-view-bottom-left"  id="bl28" d="m 244.18684,594.06176 a 13.153366,13.153366 0 0 0 13.14515,13.15325 l 0,-6.57662 m -6.57258,-6.57663 -6.57257,0 m 6.57257,0 a 6.5766829,6.5766829 0 0 0 6.57258,6.57663"
          />
          <path className="ToothChart__top-view-bottom-right"  id="br28" d="m 257.33199,607.21501 a 13.153366,13.153366 0 0 0 13.1788,-13.15325 l -6.57251,0 m -6.60629,6.57663 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 0 6.60629,-6.57663"
          />
          <path className="ToothChart__top-view-top-right"  id="tr28" d="m 270.51079,594.06176 a 13.153366,13.153366 0 0 0 -13.1788,-13.15322 l 0,6.57662 m 6.60629,6.5766 6.57251,0 m -6.57251,0 a 6.5766829,6.5766829 0 0 0 -6.60629,-6.5766"
          />
          <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center28" cx="-257.337" cy="594.062"
            r="6.577" />
        </g>
        <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
          <path className="ToothChart__tooth-drawing-component" id="froot28" d="m 247.94383,720.35027 c 0.77878,-13.19669 0.59766,-46.80077 -0.29694,-55.10541 l -0.84792,-7.87137 2.74057,2.53983 c 5.27505,4.88896 12.56934,4.36843 18.23862,-1.30083 l 2.65109,-2.65089 -0.59254,4.53244 c -3.6522,27.93746 -5.02287,35.35578 -9.43165,51.04641 -2.85164,10.14857 -4.57881,14.07873 -7.67419,17.46296 -1.90104,2.07857 -2.24888,2.14708 -3.67359,0.72089 -1.24228,-1.24329 -1.47677,-3.21719 -1.11349,-9.37403 z"
          />
          <path className="ToothChart__tooth-drawing-component" id="fcrown28" d="m 251.06397,658.83894 c -5.63437,-4.74089 -9.2757,-15.40254 -7.07501,-20.71527 1.39601,-3.37059 12.15085,-12.02685 14.94202,-12.02685 1.99944,0 9.18311,5.29887 12.56784,9.27036 2.33843,2.74408 2.23616,7.60741 -0.30267,14.39303 -4.07963,10.90362 -13.11896,14.98003 -20.13219,9.07873 z"
          />
        </g>
        <g className="ToothChart__tooth-label" id="label28">
          <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="556.95" cx="257.337" id="path8780-5-2" fill="#c8d2da"
            strokeWidth=".5" />
          <text className="labelNumber" style={{lineHeight: "100%"
          }} x="257.339" y="561.251" id="text9480" fontWeight="500" fontSize="11.797"
          wordSpacing="0" textAnchor="middle" fill="#fff">
          <tspan id="tspan9482" x="257.339" y="561.251">{intlMessages['toothChart.toothNum.28']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="226" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
      </g>
      <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth29">
        <g id="topview29" className={toothClassNameMapping[29]} stroke="#000" strokeWidth="1.685">
          <path className="ToothChart__top-view-top-left"  id="tl29" d="m 218.57062,580.88933 a 13.153366,13.153366 0 0 0 -13.14515,13.15323 l 6.57258,0 m 6.57257,-6.57662 0,-6.57661 m 0,6.57661 a 6.5766829,6.5766829 0 0 0 -6.57257,6.57662"
          />
          <path className="ToothChart__top-view-bottom-left"  id="bl29" d="m 205.42547,594.04256 a 13.153366,13.153366 0 0 0 13.14515,13.15323 l 0,-6.57661 m -6.57257,-6.57662 -6.57258,0 m 6.57258,0 a 6.5766829,6.5766829 0 0 0 6.57257,6.57662"
          />
          <path className="ToothChart__top-view-bottom-right"  id="br29" d="m 218.57062,607.19579 a 13.153366,13.153366 0 0 0 13.17887,-13.15323 l -6.5726,0 m -6.60627,6.57662 0,6.57661 m 0,-6.57661 a 6.5766829,6.5766829 0 0 0 6.60627,-6.57662"
          />
          <path className="ToothChart__top-view-top-right"  id="tr29" d="m 231.74949,594.04256 a 13.153366,13.153366 0 0 0 -13.17887,-13.15323 l 0,6.57661 m 6.60627,6.57662 6.5726,0 m -6.5726,0 a 6.5766829,6.5766829 0 0 0 -6.60627,-6.57662"
          />
          <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center29" cx="-218.576" cy="594.043"
            r="6.577" />
        </g>
        <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
          <path className="ToothChart__tooth-drawing-component" id="froot29" d="m 212.51556,728.98302 c -0.28076,-1.80791 -0.70875,-9.20173 -0.95101,-16.43159 -0.24234,-7.22978 -1.59147,-20.19802 -2.9983,-28.81816 -1.40679,-8.62028 -2.85825,-17.94822 -3.22545,-20.72898 l -0.66763,-5.05567 3.18341,3.22893 c 6.17647,6.26446 13.29359,6.52711 19.5553,0.72093 l 2.83367,-2.62772 -0.58576,8.43928 c -2.5277,36.41604 -3.60853,43.23805 -8.70868,54.96778 -2.16236,4.97309 -5.59821,9.59131 -7.13589,9.59131 -0.43404,0 -1.01885,-1.47893 -1.29966,-3.28611 z"
          />
          <path className="ToothChart__tooth-drawing-component" id="fcrown29" d="m 210.94538,661.86581 c -4.66029,-3.92132 -7.34044,-9.96532 -7.9047,-17.82594 -0.48475,-6.75314 -0.45039,-6.8674 3.04061,-10.11155 1.94196,-1.80461 5.57431,-4.3716 8.07181,-5.70434 l 4.54096,-2.42274 4.87104,2.42274 c 2.67909,1.33274 6.21631,3.76459 7.86049,5.40435 2.79773,2.79049 2.95018,3.34191 2.37678,8.59534 -1.22621,11.23389 -3.87787,16.55406 -10.16096,20.38541 -4.42448,2.69803 -8.91914,2.43476 -12.69603,-0.74327 z"
          />
        </g>
        <g className="ToothChart__tooth-label" id="label29">
          <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="556.95" cx="218.576" id="path8780-5-1-9" fill="#c8d2da"
            strokeWidth=".5" />
          <text className="labelNumber" style={{lineHeight: "100%"
          }} x="218.577" y="561.251" id="text9472" fontWeight="500" fontSize="11.797"
          wordSpacing="0" textAnchor="middle" fill="#fff">
          <tspan id="tspan9474" x="218.577" y="561.251">{intlMessages['toothChart.toothNum.29']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="188" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
      </g>
      <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth30">
        <g id="topview30" className={toothClassNameMapping[30]} stroke="#000" strokeWidth="1.685">
          <path className="ToothChart__top-view-top-left"  id="tl30" d="m 165.24178,580.90854 a 13.153366,13.153366 0 0 0 -13.14517,13.15322 l 6.57259,0 m 6.57258,-6.5766 0,-6.57662 m 0,6.57662 a 6.5766829,6.5766829 0 0 0 -6.57258,6.5766"
          />
          <path className="ToothChart__top-view-bottom-left"  id="bl30" d="m 152.09661,594.06176 a 13.153366,13.153366 0 0 0 13.14517,13.15325 l 0,-6.57662 m -6.57258,-6.57663 -6.57259,0 m 6.57259,0 a 6.5766829,6.5766829 0 0 0 6.57258,6.57663"
          />
          <path className="ToothChart__top-view-bottom-right"  id="br30" d="m 165.24178,607.21501 a 13.153366,13.153366 0 0 0 13.17884,-13.15325 l -6.57257,0 m -6.60627,6.57663 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 0 6.60627,-6.57663"
          />
          <path className="ToothChart__top-view-top-right"  id="tr30" d="m 178.42062,594.06176 a 13.153366,13.153366 0 0 0 -13.17884,-13.15322 l 0,6.57662 m 6.60627,6.5766 6.57257,0 m -6.57257,0 a 6.5766829,6.5766829 0 0 0 -6.60627,-6.5766"
          />
          <circle className="ToothChart__top-view-circle"  transform="scale(-1 1)" id="center30" cx="-165.247" cy="594.062"
            r="6.577" />
        </g>
        <g transform="translate(3.24)" className="ToothChart__facial-view" stroke="#000"
          strokeWidth="2.022">
          <path className="ToothChart__tooth-drawing-component" id="froot30" d="m 131.0301,723.28904 c 0,-2.15849 1.11222,-6.87928 2.4716,-10.49081 4.13424,-10.98369 5.91248,-22.94196 5.82802,-39.19309 l -0.0761,-14.6781 7.293,0.36832 c 4.20932,0.21241 9.20467,-0.19646 11.81419,-0.96558 2.48664,-0.73376 9.09477,-1.35606 14.68477,-1.38294 l 10.16356,-0.0476 -0.77882,5.58115 c -0.43181,3.09429 -0.31448,9.47767 0.26323,14.32627 1.57724,13.23807 -0.79697,27.54919 -6.01425,36.25175 -2.21387,3.69259 -9.47235,11.12273 -10.86598,11.12273 -2.90708,0 -3.20097,-6.63693 -0.52783,-11.92128 4.34553,-8.59072 4.57647,-22.16063 0.42954,-25.23903 -3.20205,-2.37703 -4.25055,-1.45309 -8.89317,7.83635 -5.29994,10.60518 -11.52063,21.30048 -14.04793,24.15261 -2.5023,2.82374 -9.64022,8.20484 -10.88395,8.20484 -0.47271,0 -0.85945,-1.76593 -0.85945,-3.92442 z"
          />
          <path className="ToothChart__tooth-drawing-component" id="fcrown30" d="m 137.69294,652.89917 c -1.14868,-2.30509 -2.12566,-6.89481 -2.40964,-11.31971 -0.40361,-6.28877 -0.18336,-7.76743 1.42086,-9.54036 1.44856,-1.60065 2.74083,-1.98458 5.45253,-1.62087 2.62907,0.35253 4.46524,-0.14829 7.05521,-1.92258 4.37454,-2.99744 11.30853,-3.21483 15.7698,-0.49413 3.08773,1.88279 3.14081,1.87909 7.07815,-0.50422 10.60867,-6.42157 19.43339,0.64781 16.59863,13.29711 -0.5481,2.44573 -1.9968,6.5056 -3.21937,9.02205 -2.58268,5.31604 -5.1816,6.22269 -15.22433,5.3119 -3.54315,-0.32014 -7.22397,-0.0807 -8.74742,0.56832 -3.16801,1.35232 -16.98572,2.52411 -19.82002,1.68115 -1.11229,-0.33027 -2.89176,-2.34646 -3.9544,-4.47866 z"
          />
        </g>
        <g className="ToothChart__tooth-label" id="label30">
          <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="556.95" cx="165.247" id="path8780-5-7" fill="#c8d2da"
            strokeWidth=".5" />
          <text className="labelNumber" style={{lineHeight: "100%"
          }} x="165.249" y="561.251" id="text9468" fontWeight="500" fontSize="11.797"
          wordSpacing="0" textAnchor="middle" fill="#fff">
          <tspan id="tspan9470" x="165.249" y="561.251">{intlMessages['toothChart.toothNum.30']}</tspan>
        </text>
      </g>
      <g className="ToothChart__highlighter">
        <rect id="Rectangle-10" x="140" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
      </g>
    </g>
    <g transform="matrix(1.235 0 0 1.23346 -.002 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth31">
      <g id="topview31" className={toothClassNameMapping[31]} stroke="#000" strokeWidth="1.685">
        <path className="ToothChart__top-view-top-left"   id="tl31" d="m 101.23936,580.90854 a 13.153366,13.153366 0 0 0 -13.145153,13.15322 l 6.572581,0 m 6.572572,-6.5766 0,-6.57662 m 0,6.57662 a 6.5766829,6.5766829 0 0 0 -6.572572,6.5766"
        />
        <path className="ToothChart__top-view-bottom-left"   id="bl31" d="m 88.094207,594.06176 a 13.153366,13.153366 0 0 0 13.145153,13.15325 l 0,-6.57662 m -6.572572,-6.57663 -6.572581,0 m 6.572581,0 a 6.5766829,6.5766829 0 0 0 6.572572,6.57663"
        />
        <path className="ToothChart__top-view-bottom-right"   id="br31" d="m 101.23936,607.21501 a 13.153366,13.153366 0 0 0 13.17881,-13.15325 l -6.57253,0 m -6.60628,6.57663 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 0 6.60628,-6.57663"
        />
        <path className="ToothChart__top-view-top-right"   id="tr31" d="m 114.41817,594.06176 a 13.153366,13.153366 0 0 0 -13.17881,-13.15322 l 0,6.57662 m 6.60628,6.5766 6.57253,0 m -6.57253,0 a 6.5766829,6.5766829 0 0 0 -6.60628,-6.5766"
        />
        <circle className="ToothChart__top-view-circle"   transform="scale(-1 1)" id="center31" cx="-101.245" cy="594.062"
          r="6.577" />
      </g>
      <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
        <path className="ToothChart__tooth-drawing-component" id="froot31" d="m 96.650481,716.26755 c 0,-1.01494 1.132576,-5.17515 2.516899,-9.24517 1.38429,-4.06988 3.22437,-10.82521 4.08905,-15.01138 1.35931,-6.58069 1.37744,-7.80587 0.13381,-9.04963 -1.24347,-1.24336 -1.77847,-0.98045 -3.95174,1.94218 -1.38244,1.85882 -4.246318,6.56519 -6.36421,10.45812 -8.295934,15.24927 -15.675384,23.76934 -17.827111,20.58256 -1.468111,-2.17434 -0.333685,-7.97648 3.080448,-15.75514 4.612566,-10.5093 5.857103,-20.15837 4.317371,-33.47339 -0.686245,-5.93419 -1.518296,-12.15462 -1.848986,-13.82294 l -0.601302,-3.03348 2.41362,2.14874 c 5.317374,4.73387 10.448909,5.60185 17.79549,3.00951 3.11149,-1.09776 5.05762,-1.17366 8.70254,-0.33939 4.39707,1.00614 7.71849,0.53894 13.45091,-1.89189 2.29811,-0.9749 -5.97427,37.91834 -10.77616,50.66479 -1.42516,3.78271 -3.89204,7.6848 -6.55281,10.36447 -4.50016,4.53199 -8.577948,5.69738 -8.577948,2.45204 z"
        />
        <path className="ToothChart__tooth-drawing-component" id="fcrown31" d="m 82.746961,649.89299 c -2.889331,-3.11517 -3.286286,-4.22305 -3.286286,-9.16973 0,-6.09758 1.919765,-10.92589 4.999812,-12.57445 3.104476,-1.66133 9.40845,-1.21 13.706753,0.98153 5.13243,2.61653 6.20144,2.55452 10.79274,-0.62728 5.26533,-3.64895 11.83036,-4.33014 14.3972,-1.49383 4.39867,4.86069 3.75981,18.95975 -1.06422,23.48598 -2.26581,2.12621 -3.60375,2.41843 -11.82359,2.58161 -5.09775,0.10126 -11.31625,0.57969 -13.818889,1.06301 -6.108615,1.17963 -9.962504,0 -13.90352,-4.24684 z"
        />
      </g>
      <g className="ToothChart__tooth-label" id="label31">
        <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="556.95" cx="101.245" id="path8780-5-1-3" fill="#c8d2da"
          strokeWidth=".5" />
        <text className="labelNumber" style={{lineHeight: "100%"
        }} x="101.246" y="561.251" id="text9464" fontWeight="500" fontSize="11.797"
        wordSpacing="0" textAnchor="middle" fill="#fff">
        <tspan id="tspan9466" x="101.246" y="561.251">{intlMessages['toothChart.toothNum.31']}</tspan>
      </text>
    </g>
    <g className="ToothChart__highlighter">
      <rect id="Rectangle-10" x="76" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
    </g>
            </g>
            <g transform="matrix(1.235 0 0 1.23346 4 -67.509)" className={cn('ToothChart__tooth-column')} id="tooth32">
              <g id="topview32" className={toothClassNameMapping[32]} stroke="#000" strokeWidth="1.685">
                <path className="ToothChart__top-view-top-left"   id="tl32" d="m 35.946934,580.90854 a 13.153366,13.153366 0 0 0 -13.145147,13.15322 l 6.572574,0 m 6.572573,-6.5766 0,-6.57662 m 0,6.57662 a 6.5766829,6.5766829 0 0 0 -6.572573,6.5766"
                />
                <path className="ToothChart__top-view-bottom-left"   id="bl32" d="m 22.801787,594.06176 a 13.153366,13.153366 0 0 0 13.145147,13.15325 l 0,-6.57662 m -6.572573,-6.57663 -6.572574,0 m 6.572574,0 a 6.5766829,6.5766829 0 0 0 6.572573,6.57663"
                />
                <path className="ToothChart__top-view-bottom-right"   id="br32" d="m 35.946934,607.21501 a 13.153366,13.153366 0 0 0 13.178853,-13.15325 l -6.572574,0 m -6.606279,6.57663 0,6.57662 m 0,-6.57662 a 6.5766829,6.5766829 0 0 0 6.606279,-6.57663"
                />
                <path className="ToothChart__top-view-top-right"   id="tr32" d="M 49.125787,594.06176 A 13.153366,13.153366 0 0 0 35.946934,580.90854 l 0,6.57662 m 6.606279,6.5766 6.572574,0 m -6.572574,0 a 6.5766829,6.5766829 0 0 0 -6.606279,-6.5766"
                />
                <circle className="ToothChart__top-view-circle"   transform="scale(-1 1)" id="center32" cx="-35.952" cy="594.062"
                  r="6.577" />
              </g>
              <g className="ToothChart__facial-view" stroke="#000" strokeWidth="2.022">
                <path className="ToothChart__tooth-drawing-component" id="froot32" d="m 24.614739,709.77073 c 0.318518,-1.25139 1.641257,-3.9732 2.939693,-6.04832 2.53125,-4.04537 6.769415,-16.05921 7.854629,-22.26424 1.096878,-6.27261 -1.880026,-4.68995 -6.028735,3.20531 -6.598156,12.55636 -18.425755,23.33776 -25.6025007,23.33776 -4.1054994,0 -3.6468348,-2.83903 1.3159305,-8.14452 2.6058738,-2.78597 5.8088752,-7.4396 7.1177942,-10.34151 3.177082,-7.044 4.930778,-19.4947 4.057907,-28.8096 l -0.698681,-7.45569 3.076503,1.59097 c 4.235671,2.19013 13.191562,1.99799 20.104157,-0.4314 3.457341,-1.21542 8.345988,-2.02238 12.248208,-2.02238 l 6.494278,0 -0.68311,7.53861 c -1.379195,15.22014 -6.895168,30.43527 -14.373613,39.6484 -3.469948,4.27472 -14.705106,12.47166 -17.094455,12.47166 -0.852681,0 -1.105676,-0.79055 -0.728005,-2.27505 z"
                />
                <path className="ToothChart__tooth-drawing-component" id="fcrown32" d="m 17.947891,652.75357 c -4.837145,-3.14681 -6.70315,-12.7744 -3.628298,-18.72045 1.298708,-2.51139 2.078888,-2.99371 4.190169,-2.59026 1.426888,0.27302 4.106814,-0.13485 5.955426,-0.90835 4.061379,-1.69675 9.516816,-1.81065 12.900073,-0.26966 1.948044,0.88781 3.540495,0.77757 7.305838,-0.50557 8.961722,-3.0534 14.57888,-1.91515 16.405583,3.32505 1.099845,3.15482 0.906241,4.75483 -1.510141,12.47704 l -1.502893,4.80315 -5.981719,0 c -3.289927,0 -8.599893,0.87934 -11.799927,1.95414 -3.200035,1.07499 -6.728259,2.18121 -7.840541,2.45854 -3.323194,0.82807 -11.963837,-0.37825 -14.49357,-2.02403 z"
                />
              </g>
              <g className="ToothChart__tooth-label" id="label32">
                <circle className="ToothChart__tooth-label-circle"   r="11.797" cy="556.95" cx="35.952" id="path8780-5-1-9-8" fill="#c8d2da"
                  strokeWidth=".5" />
                <text className="labelNumber" id="text8790-3-9" y="561.251" x="35.952" style={{lineHeight:
                  "100%" }} fontWeight="500"
                  fontSize="11.797"  wordSpacing="0" textAnchor="middle"
                  fill="#fff">
                  <tspan y="561.251" x="35.952" id="tspan8792-3-1">{intlMessages['toothChart.toothNum.32']}</tspan>
                </text>
              </g>
              <g className="ToothChart__highlighter">
                <rect id="Rectangle-10" x="17" y="530" width="48" height="210" rx="24" opacity="0.154664855" />
              </g>
            </g>
          </g>
        </svg>
    )
  }
}
