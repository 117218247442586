import {patientRequestsConstants} from '../_constants';

const initState = {
  patient: {},
  patientIsLoading: false,
  patientIsError: false,
  imageSet: {},
  notes: [],
  imageSetIsLoading: false,
  imageSetIsError: false,
  notesIsLoading: false,
  notesIsError: false,
  patientRequests: [],
  patientRequestsIsLoading: false,
  patientRequestsIsError: false,
  patientRequestImages: [],
  patientRequestImagesIsLoading: false,
  patientRequestImagesIsError: false,
  currentRequest: {},
  currentRequestIsLoading: false,
  currentRequestIsError: false,
}

export function patientRequests(state=initState, action){
  switch (action.type){
    case patientRequestsConstants.GET_PATIENTDETAILS_REQUEST:
      return {
        ...state,
        patientIsLoading:true,
        patientIsError: false,
        patient: initState.patient
      }
    case patientRequestsConstants.GET_PATIENTDETAILS_SUCCESS:
      return {
        ...state,
        patientIsLoading:false,
        patientIsError: false,
        patient: action.data.patient
      }
    case patientRequestsConstants.GET_PATIENTDETAILS_FAILURE:
      return {
        ...state,
        patientIsLoading:false,
        patientIsError: true
      }
    case patientRequestsConstants.GET_PATIENTREQUESTS_REQUEST:
      return {
        ...state,
        patientRequestsIsLoading:true,
        patientRequestsIsError: false,
        patientRequests: initState.patientRequests
      }
    case patientRequestsConstants.GET_PATIENTREQUESTS_SUCCESS:
      return {
        ...state,
        patientRequestsIsLoading:false,
        patientRequestsIsError: false,
        patientRequests: action.data.requests_list
      }
    case patientRequestsConstants.GET_PATIENTREQUESTS_FAILURE:
      return {
        ...state,
        patientRequestsIsLoading:false,
        patientRequestsIsError: true
      }
    case patientRequestsConstants.GET_PATIENTREQUESTIMAGES_REQUEST:
        return {
          ...state,
          patientRequestImagesIsLoading:true,
          patientRequestImagesIsError: false,
          patientRequestImages: initState.patientRequestImages
        }
    case patientRequestsConstants.GET_PATIENTREQUESTIMAGES_SUCCESS:
        return {
          ...state,
          patientRequestImagesIsLoading:false,
          patientRequestImagesIsError: false,
          patientRequestImages: action.data.request_images
        }
    case patientRequestsConstants.GET_PATIENTREQUESTIMAGES_FAILURE:
        return {
          ...state,
          patientRequestImagesIsLoading:false,
          patientRequestImagesIsError: true
        }
    case patientRequestsConstants.GET_PATIENTIMAGESET_REQUEST:
      return {
        ...state,
        imageSetIsLoading:true,
        imageSetIsError: false,
        imageSet: initState.imageSet
      }
    case patientRequestsConstants.GET_PATIENTIMAGESET_SUCCESS:
      return {
        ...state,
        imageSetIsLoading:false,
        imageSetIsError: false,
        imageSet: action.data.image_set
      }
    case patientRequestsConstants.GET_PATIENTIMAGESET_FAILURE:
      return {
        ...state,
        imageSetIsLoading:false,
        imageSetIsError: true
      }
    case patientRequestsConstants.GET_PATIENTNOTES_REQUEST:
      return {
        ...state,
        notesIsLoading:true,
        notesIsError: false,
        notes: initState.notes
      }
    case patientRequestsConstants.GET_PATIENTNOTES_SUCCESS:
      return {
        ...state,
        notesIsLoading:false,
        notesIsError: false,
        notes: action.data.notes
      }
    case patientRequestsConstants.GET_PATIENTNOTES_FAILURE:
      return {
        ...state,
        notesIsLoading:false,
        notesIsError: true
      }
    case patientRequestsConstants.UPDATE_PATIENTNOTES:
      return {
        ...state,
        notes: [
          action.data.new_note,
          ...state.notes
        ]
      }
    case patientRequestsConstants.GET_PATIENTREQUESTDETAILS_REQUEST:
      return {
        ...state,
        currentRequest: initState.currentRequest,
        currentRequestIsLoading: true,
        currentRequestIsError: false,
      }
    case patientRequestsConstants.GET_PATIENTREQUESTDETAILS_SUCCESS:
      return {
        ...state,
        currentRequest: action.data.request,
        currentRequestIsLoading: false,
        currentRequestIsError: false,
      }
    case patientRequestsConstants.GET_PATIENTREQUESTDETAILS_FAILURE:
      return {
        ...state,
        currentRequest: {},
        currentRequestIsLoading: false,
        currentRequestIsError: true,
      }
    case patientRequestsConstants.EDIT_REQUESTMESSAGE_REQUEST:
      return {
        ...state,
      }
    case patientRequestsConstants.EDIT_REQUESTMESSAGE_SUCCESS:
    return {
      ...state,
    }
    case patientRequestsConstants.EDIT_REQUESTMESSAGE_FAILURE:
      return {
        ...state,
      }
    case patientRequestsConstants.UPDATE_CONSULTREQUESTDETAILS:
      return {
        ...state,
        patientRequests: action.data.patientRequests,
        currentRequest: {
          ...state.currentRequest,
          ...action.data.currentRequest
        }
      }
    case patientRequestsConstants.UPDATE_CONSULTREQUESTMESSAGES_TREATMENTPLAN:
      return {
        ...state,
        currentRequest: {
          ...state.currentRequest,
          messages: action.data.currentRequestMessages
        }
      }
    case patientRequestsConstants.UPDATE_CONSULTREQUESTMESSAGES:
      return {
        ...state,
        currentRequest: {
          ...state.currentRequest,
          messages: [
            ...state.currentRequest.messages,
            action.data.new_message
          ]
        }
      }
    case patientRequestsConstants.UPDATE_MESSAGEATTACHMENT:
      // action.data.messages is the updated
      return {
        ...state,
        currentRequest: {
          ...state.currentRequest,
          messages: action.data.currentRequestMessages
        }
      }
    case patientRequestsConstants.UPDATE_IMAGESET:
      return {
        ...state,
        imageSet: {
          ...state.imageSet,
          ...action.data
        }
      }
    case patientRequestsConstants.CHANGE_PATIENTIMAGESETSTATUS_REQUEST:
      return {
        ...state,
        imageSet: {
          ...state.imageSet,
          [action.imageKey]: {
            ...state.imageSet[action.imageKey],
            changingStatus: true
          }
        }
      }
    case patientRequestsConstants.CHANGE_PATIENTIMAGESETSTATUS_SUCCESS:
      return {
        ...state,
        imageSet: {
          ...state.imageSet,
          ...action.data
        }
      }
    case patientRequestsConstants.CHANGE_PATIENTIMAGESETSTATUS_FAILURE:
      return {
        ...state,
      }
    default:
      return state;
  }
};
