import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { history } from '../_helpers';
import { connect } from 'react-redux';
import moment from 'moment';

import cn from 'classnames';

import { upperFirst, toUpper, transform, pickBy, map, forEach } from 'lodash';
import { localeFormatDate } from '../_helpers/date';

import { API_URLS, REQUIRED_IMAGES, requestImageFields, requestImageFieldList } from '../_config';

import {  authHeader, handleResponse, isRequiredImageError, zipMultiple } from '../_helpers';

import Lightbox from 'react-image-lightbox';
import DropzoneComponent from '../DropzoneComponent';
import '../../css/dropzone.scss';

import '../../css/imageSetUpload.style.scss';
import 'react-image-lightbox/style.css';

import Add from '@material-ui/icons/Add';
import Cancel from '@material-ui/icons/Cancel';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { patientRequestsActions  } from '../_actions';

const baseCssClassName = "ImageSetUpload";

const imagesHeaderCssClassName = `${baseCssClassName}__ImagesHeader`;
const imageHeaderStatusCssClassName = `${imagesHeaderCssClassName}__status`;
const formCssClassName = `${baseCssClassName}__form`;
const canceledformCssClassName = `${formCssClassName}__Cancel`;
const completedformCssClassName = `${formCssClassName}__Complete`;
const imagesCssClassName = `${formCssClassName}__images`;
const imagesRowCssClassName = `${imagesCssClassName}__row`;
const formSubmitCssClassName = `${formCssClassName}__actions`;
const submitButtonCssClassName = `${formSubmitCssClassName}__submitButton`;

const singleImageCssClassName = "SingleImageUploadBox";
const singleImageLoadingCssClassName = `${singleImageCssClassName}__loading`;
const singleImageHeaderCssClassName = `${singleImageCssClassName}__header`;
const singleImageDetailCssClassName = `${singleImageCssClassName}__detail`;
const dropzoneCssClassName = `${singleImageCssClassName}__dropzone`;
const dropzoneRemoveiconCssClassName= 'removeIcon';
const dropzoneImagepreviewCssClassName = 'dz-image__customImagePreview';
const dropzoneDetailsCssClassName = 'dz-details__customImageDetails';
const dropzoneSizeCssClassName = 'dz-size__customImageSize';
const dropzoneFilenameCssClassName = 'dz-filename__customFilename';
const dropzoneSuccessmarkCssClassName = 'dz-success-mark__customSuccessMark';
const dropzoneErrormarkCssClassName = 'dz-success-mark__customErrorMark';

const imageActionButtonsCssClassName = `${baseCssClassName}__ImageActions`;

// TODO: can i import loaders from the components/Loaders.jsx
const LoadingEllipsis = (width, height) => {
  return (
    <svg width="120px"  height="30px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 30 100 50" preserveAspectRatio="xMidYMid">
      <circle cx="84" cy="50" r="0" fill="#a3a3ac">
        <animate attributeName="r" values="10;0;0;0;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cx" values="84;84;84;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
      <circle cx="49.9762" cy="50" r="10" fill="#b8babd">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-1.05s"></animate>
        <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-1.05s"></animate>
      </circle>
      <circle cx="16" cy="50" r="9.99299" fill="#d8dddf">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-0.525s"></animate>
        <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="-0.525s"></animate>
      </circle>
      <circle cx="84" cy="50" r="0.00701046" fill="#f3f3f3">
        <animate attributeName="r" values="0;10;10;10;0" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cx" values="16;16;50;84;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
      <circle cx="83.9762" cy="50" r="10" fill="#a3a3ac">
        <animate attributeName="r" values="0;0;10;10;10" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
        <animate attributeName="cx" values="16;16;16;50;84" keyTimes="0;0.25;0.5;0.75;1" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" calcMode="spline" dur="2.1s" repeatCount="indefinite" begin="0s"></animate>
      </circle>
    </svg>
  )
}

const LoadingCircle = () => {
  return(
    <svg width="100px"  height="100px"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{background:'none'}}>
      <circle cx="50" cy="50" fill="none"
          stroke="#f3f3f3" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138"
          transform="rotate(113.506 50 50)">
        <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
      </circle>
    </svg>
  )
}

function readableBytes(bytes) {
    var i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
}

const ImagePreviewTemplateIcons = ({ isLoading , uploadId, imageKey, onClickGood, onClickBad}) => {
  if (isLoading === true) {
    return (
      <div className="removeIcon">
        <div className="iconWrapper" >
          <LoadingEllipsis />
        </div>
      </div>
    )
  } else {
    return (
      <div className="removeIcon">
        <div className="iconWrapper iconWrapper__left" onClick={onClickGood}>
          <CheckCircle />
        </div>
        <div className="iconWrapper iconWrapper__right" onClick={onClickBad}>
          <svg className="MuiSvgIcon-root-1" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
            <path fill="none" d="M0 0h24v24H0z"></path>
          </svg>
        </div>
      </div>
    )
  }
}

class ImagePreviewTemplate extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    const { thumbnailUrl, fileUrl, uploadId, imageGroup, consultRequestStatus,
            imageKey, isStaff, fileSize, fileType, fileName,
            changingStatus, onDelete, onClickImage, onClickGood, onClickBad} = this.props;
    const isDisabled = (consultRequestStatus === -1) || (consultRequestStatus === 4);

    if (isStaff === true){
      return(
        <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete">
          <div  className={cn("dz-image dz-image__customImagePreview",
                              {"previewBlank": fileType.indexOf("image") === -1})}
                style={(fileType.indexOf("image") !== -1) ? {backgroundImage: `url("${thumbnailUrl}")`} : {} }
                onClick = {() => onClickImage(imageGroup)}
          >
            {fileType.indexOf("image") === -1 ? imageGroup : undefined}
          </div>
          <div className="dz-details dz-details__customImageDetails">
            { (consultRequestStatus !== -1) &&
              <ImagePreviewTemplateIcons
                isLoading = {changingStatus}
                uploadId = {uploadId}
                imageKey = {imageKey}
                onClickGood = {onClickGood}
                onClickBad = {onClickBad}
              />
            }
            {/*<div className="dz-size dz-size__customImageSize" data-dz-size="">
              <strong>{readableBytes(fileSize)}</strong>
            </div>
            <div className="dz-filename dz-filename__customFilename">
              <span data-dz-name="true">{fileName}</span>
            </div>*/}
          </div>
          <div className="dz-progress"></div>
          <div className="dz-error-message">
            <span data-dz-errormessage="true"></span>
          </div>
        </div>
      )
    } else {
      return(
        <div className="dz-preview dz-processing dz-image-preview dz-success dz-complete">
          <div className={cn("dz-image dz-image__customImagePreview",
                              {"previewBlank": fileType.indexOf("image") === -1})}
                style={(fileType.indexOf("image") !== -1) ? {backgroundImage: `url("${thumbnailUrl}")`} : {} }
                onClick = {() => onClickImage(imageGroup)}
          >
            {fileType.indexOf("image") === -1 ? imageGroup : undefined}
          </div>
          <div className="dz-details dz-details__customImageDetails">
            <div className="removeIcon">
              <div className="iconWrapper iconWrapper__right" onClick={ onDelete } data-dz-remove="true">
              <svg className="MuiSvgIcon-root-1" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                <path fill="none" d="M0 0h24v24H0z"></path></svg>
              </div>
            </div>
                {/*
                  <div className="dz-size dz-size__customImageSize" data-dz-size="">
                    <strong>{readableBytes(fileSize)}</strong>
                  </div>
                  <div className="dz-filename dz-filename__customFilename">
                    <span data-dz-name="true">{fileName}</span>
                  </div>
                */}

                </div>
                  <div className="dz-progress"></div>
                <div className="dz-error-message">
                  <span data-dz-errormessage="true"></span>
                </div>
        </div>
      )

    }
  }
}

class SingleImageUploadBoxLoading extends React.Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){
    // deal with initialization
  }

  render() {

    const { imageKey, intl } = this.props;

    return (
      <div className={cn(singleImageCssClassName, `${singleImageCssClassName}-${imageKey}`, singleImageLoadingCssClassName )}>
        <div className={singleImageHeaderCssClassName}>
          <span>{intl.messages[`newRequestForm.${imageKey}`]} {/*(REQUIRED_IMAGES.indexOf(imageKey) != -1) ? " *" : "" */}</span>
        </div>
        <div className={singleImageDetailCssClassName}>
            <LoadingCircle />
        </div>
      </div>
    )
  }
}

class PreSingleImageUploadBox extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      uploaded: false,
      upload_id: null
    }
  }

  onDeleteInitialPreview = () => {
    const { imageKey, image, consultRequestId, onDelete } = this.props;
    onDelete(imageKey, image.upload_id[0], consultRequestId);
  }

  removePreview = () => {
    if (this.dropzone) {
      this.dropzone.removeAllFiles();
    }
  }

  render() {
    const { requiredImages } = this.props.imageSet;
    const { imageKey, imageTypes, label, intl, patientId,
            image, isStaff, onChangeStatus, consultRequestStatus,
            consultRequestId, onSuccess, onDelete, onImageClick, handleComplete } = this.props;
    /* setup for react-dropzone-component */
    const postUrl = API_URLS.createPatientUpload.url.replace("{patientId}", patientId);
    var componentConfig = {
      postUrl: postUrl
    };
    var djsConfig = {
      //addRemoveLinks: true,
      acceptedFiles: imageTypes, //"image/jpeg,image/png,image/gif, image/jfif", "",
      parallelUploads: 1,
      autoProcessQueue: true,
      thumbnailWidth: 200,
      thumbnailHeight: 200,
      headers: {"Cache-Control": "", "X-Requested-With": "", ...authHeader()},
      // renderToStaticMarkup uploads plain HTML, can't put event listeners
      // event listeners are added by dropzone component (3rd party source code)
      previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className="dz-preview dz-file-preview">
          <div className="dz-image dz-image__customImagePreview">
            <img data-dz-thumbnail="true" />
          </div>
          <div className="dz-details dz-details__customImageDetails" >
            <div className={dropzoneRemoveiconCssClassName}>
              <div className="iconWrapper" data-dz-remove="true"><Cancel /></div>
            </div>
            <div className="dz-size dz-size__customImageSize" data-dz-size=""></div>
            <div className="dz-filename dz-filename__customFilename"><span data-dz-name="true"></span></div>
          </div>
          <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
          <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
          <div className="dz-success-mark dz-success-mark__customSuccessMark"><span>✔</span></div>
          <div className="dz-error-mark dz-success-mark__customErrorMark"><span>✘</span></div>
        </div>
      )
    };
    var eventHandlers = {
      init: dz => this.dropzone = dz,
      addedfile: (file) => file.group = imageKey,
      sending: (file, xhr, formData) => {
        formData.append('image_group', imageKey);
        formData.append('request_id', consultRequestId);
      },
      success: (file, response) => {
        this.setState({uploaded:true, upload_id:response.id});
        this.removePreview()
        onSuccess(imageKey, response);
        handleComplete()
      },
      removedfile: (file) => {
        //onDelete(imageKey, image.upload_id && image.upload_id[0], consultRequestId);
      }
    };
    if (consultRequestStatus === -1 || consultRequestStatus === 4){
      eventHandlers.init = dz => {
        this.dropzone = dz;
        this.dropzone.disable();
      }
    }
    // if required, check that image is not null?
    //
    // if needs fixing, user uploads anotehr one, and then status become 0 so self fixing
    const error = requiredImages[imageKey]
    return (
      <div className={cn(singleImageCssClassName, `${singleImageCssClassName}-${imageKey}`,
              {'Error': error || ((REQUIRED_IMAGES.indexOf(imageKey) !== -1) && !(image && image.thumbnail && image.thumbnail[0]) ) || (isStaff && image && image.status === 2)},
              {'Good': (image && image.status === 1)},
              /*{'Fixing': (isStaff && image && (image.status === 3 || image.status === 0))} ,*/
            /*{'Fixing': (!isStaff && image && (image.status === 2))}*/)}>
        <div className={singleImageHeaderCssClassName}>
          <span>{intl.messages[`newRequestForm.${imageKey}`]} {/*(REQUIRED_IMAGES.indexOf(imageKey) != -1) ? " *" : "" */}</span>
          <span className="dot"></span>
        </div>
        <div className={singleImageDetailCssClassName}>
          <DropzoneComponent
            className={cn(dropzoneCssClassName, {'dz-started': (image && image.thumbnail && image.thumbnail.length > 0)})}
            config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          >
            <div className={'dz-message'}>
              <Add />
            </div>
            {image && image.thumbnail && image.thumbnail.length > 0 &&
              <ImagePreviewTemplate
                thumbnailUrl = {image && image.thumbnail && image.thumbnail[0]}
                uploadId = {image.upload_id && image.upload_id[0]}
                isStaff = {isStaff}
                fileName = {image.file_name && image.file_name[0]}
                fileType = {image.file_type}
                fileSize = {image.file_size}
                imageKey = {imageKey}
                imageGroup = {intl.messages[`newRequestForm.${imageKey}`]}
                fileUrl = {image.url && image.url[0]}
                changingStatus = {image.changingStatus}
                onDelete = {this.onDeleteInitialPreview}
                onClickImage = {onImageClick}
                onClickGood = {() => onChangeStatus(image.upload_id && image.upload_id[0], imageKey, 1)}
                onClickBad = {() => onChangeStatus(image.upload_id && image.upload_id[0], imageKey, 2)}
              />
            }
          </DropzoneComponent>
        </div>
      </div>
    )
  }
}

function mapSingleBoxStateToProps(state) {
  const { imageSet } = state;
  return { imageSet };
}

const SingleImageUploadBox = connect(mapSingleBoxStateToProps)(PreSingleImageUploadBox);

class PatientImageSetUpload extends React.Component {
  static propTypes = {
      onSuccess: PropTypes.func.isRequired,
      onDelete: PropTypes.func.isRequired,
      onDeleteInitialPreview: PropTypes.func.isRequired,
      handleCompleteClick: PropTypes.func.isRequired,
      imageSet: PropTypes.object,
  }

  constructor(props){
    super(props);
    this.state = {
      photoIndex: 0,
      isViewerOpen: false,
      lightboxImages: [],
      lightboxTitles: [],
      isDownloading: false,
      isReadyToDownload: false
    }
  }

  downloadFiles = (fileName, imageSet) => {
    this.setState({isDownloading: true})
    var imageList = [];
    forEach(imageSet, function(value, key){
      if ((key !== "request") && (value.url && value.url[0])){
        imageList.push({...value, image_group: key})
      }
    })
    zipMultiple(imageList, fileName);
    this.setState({isDownloading:false});
  }

  onOpenImages = (clickedGroup) => {
    let titlesList = this.state.lightboxTitles;
    let imagesList = this.state.lightboxImages;
    let newIndex = this.state.photoIndex;
    // TODO: update list when image is added or deleted...
    //if (imagesList.length === 0){
    for (var i = 0; i < requestImageFieldList.length; i++){
      console.log(imageGroup)
      let imageGroup = requestImageFieldList[i];
      let value = this.props.patientRequests.imageSet[requestImageFieldList[i]];
      console.log(value)
      if (value && (value.url != null)){
        if (value.file_type.indexOf("image") !== -1) {
          if (imageGroup === clickedGroup){
            newIndex = titlesList.length;
          }
          titlesList.push((this.props.intl.messages[`newRequestForm.${imageGroup}`]));
          imagesList.push(value.url);
        }
      }
    }
      /*map(this.props.patientRequests.imageSet, (imageGroup, value) => {
        // loops through and add images to the lists and image group as title

        // push clicked image to front?
        if (value && (value.url != null)){
          if (value.file_type.indexOf("image") !== -1) {
            if (imageGroup === clickedGroup){
              titlesList.unshift((this.props.intl.messages[`newRequestForm.${imageGroup}`]));
              imagesList.unshift(value.url);
            } else {
              titlesList.push((this.props.intl.messages[`newRequestForm.${imageGroup}`]));
              imagesList.push(value.url);
            }
          }
        }
      })*/
    //}
    this.setState({isViewerOpen: true, lightboxImages: imagesList, lightboxTitles: titlesList, photoIndex:newIndex})
  }

  onCloseImages = () => {
    this.setState({isViewerOpen: false})
  }

  setIndex = () => {

  }

  moveNext = () => {
    this.setState(prevState => ({
      photoIndex: (prevState.photoIndex + 1) % this.state.lightboxImages.length,
    }));
  }

  movePrev = () =>{
    this.setState(prevState => ({
      photoIndex: (prevState.photoIndex + this.state.lightboxImages.length - 1) % this.state.lightboxImages.length,
    }));
  }

  render(){
    const { intl, requiredImages, isStaff,
            onSuccess, onDelete, onChangeStatus, initImageSetId,
            onDeleteInitialPreview, handleCompleteClick} = this.props;
    const { userInfo } = this.props.users;
    const { imageSet, patient, imageSetIsLoading, patientIsLoading } = this.props.patientRequests;

    const consultRequestId = imageSet.request && imageSet.request.id;
    const consultRequestStatus = imageSet.request && imageSet.request.status;
    let consultRequestDocumentStatus = 0;
    // if document is completed or canceled, then document stauts doesn't matter
    if (['CANCELED', 'COMPLETED'].indexOf(consultRequestStatus) !== -1){
      consultRequestDocumentStatus = consultRequestStatus
    } else if (imageSet.request && imageSet.request.document_status) {
      consultRequestDocumentStatus = imageSet.request.document_status;
    }
    const consultRequestDate = imageSet.request && localeFormatDate((imageSet.request.appointment_date, imageSet.request.created_at), intl.locale);
    const patientId = patient.id;
    const patientName = patient ? patient.patient_name : "";
    const requestDate = imageSet.request ? moment(imageSet.request.created_at).format('YYYYMMDD') : "";
    const practiceName = patient ? patient.practice_name : "";
    const fileName = `${practiceName}-${patientName}-${requestDate}`

    const firstRow = requestImageFields[0];
    const secondRow = requestImageFields[1];
    const thirdRow = requestImageFields[2];

    //let titlesList = [];
    //let imagesList = [];

    /*map(imageSet, (imageGroup, value) => {
      // loops through and add images to the lists and image group as title

      // push clicked image to front?
      if (value && (value.url != null)){
        if (value.file_type.indexOf("image") !== -1) {
          titlesList.push((intl.messages[`newRequestForm.${imageGroup}`]))
          imagesList.push(value.url)
        }
      }
    })*/
    const {lightboxImages, lightboxTitles, photoIndex } = this.state;
    return (
      <div className={baseCssClassName}>
        { this.state.isViewerOpen &&
          <Lightbox
            mainSrc={lightboxImages[photoIndex]}
            nextSrc={lightboxImages[(photoIndex + 1) % lightboxImages.length]}
            prevSrc={
              lightboxImages[(photoIndex + lightboxImages.length - 1) % lightboxImages.length]
            }
            onCloseRequest={this.onCloseImages}
            onMovePrevRequest={this.movePrev}
            onMoveNextRequest={this.moveNext}
            imageTitle={lightboxTitles[photoIndex]}
          />
        }
        <div className={imagesHeaderCssClassName}>
          <span>
            {toUpper(intl.messages['patient.imageSet.title']) + ":  " + (consultRequestDate ? consultRequestDate : "" )}
          </span>
          <span className={cn(imageHeaderStatusCssClassName, {'Error': ((consultRequestDocumentStatus === 0) || (consultRequestDocumentStatus === 2))})}>
            {toUpper(intl.messages[`patientRequest.imageUpload.status.${consultRequestDocumentStatus}`])}
          </span>
        </div>
        <div className={cn(formCssClassName,
                            {[completedformCssClassName]:consultRequestStatus === 4},
                            {[canceledformCssClassName]: consultRequestStatus === -1 })}>
          <div className={imagesCssClassName}>
            <div className={imagesRowCssClassName}>
              {
                firstRow.map((element, index) => {
                  if (patientIsLoading || imageSetIsLoading){
                    return (
                      <SingleImageUploadBoxLoading
                        key={element.imageKey}
                        intl = {intl}
                        imageKey = {element.imageKey}
                      />
                    )

                  } else {
                    return (
                      <SingleImageUploadBox
                        key={element.imageKey}
                        onSuccess={onSuccess}
                        intl={intl}
                        isStaff = {userInfo.is_staff}
                        onDelete = {onDelete}
                        onChangeStatus = {onChangeStatus}
                        onDeleteInitialPreview = {onDeleteInitialPreview}
                        onImageClick = {this.onOpenImages}
                        consultRequestId = {consultRequestId}
                        consultRequestStatus = {consultRequestStatus}
                        patientId = {patientId}
                        image = {imageSet[element.imageKey]}
                        handleComplete = {handleCompleteClick}
                        {...element} />
                      )
                    }
                  })
              }
            </div>
            <div className={imagesRowCssClassName}>
              {
                secondRow.map((element, index) =>{
                  if (patientIsLoading || imageSetIsLoading){
                    return (
                      <SingleImageUploadBoxLoading
                        key={element.imageKey}
                        intl = {intl}
                        imageKey = {element.imageKey}
                      />
                    )
                  } else {
                    return (
                      <SingleImageUploadBox
                        key={element.imageKey}
                        onSuccess={onSuccess}
                        intl={intl}
                        isStaff = {userInfo.is_staff}
                        onDelete = {onDelete}
                        onChangeStatus = {onChangeStatus}
                        onDeleteInitialPreview = {onDeleteInitialPreview}
                        onImageClick = {this.onOpenImages}
                        consultRequestId = {consultRequestId}
                        consultRequestStatus = {consultRequestStatus}
                        patientId = {patientId}
                        image = {imageSet[element.imageKey]}
                        handleComplete = {handleCompleteClick}
                        {...element} />
                      )
                    }
                  })
              }
            </div>
            <div className={imagesRowCssClassName}>
              {
                thirdRow.map((element, index) => {
                  if (patientIsLoading || imageSetIsLoading){
                    return (
                      <SingleImageUploadBoxLoading
                        key={element.imageKey}
                        intl = {intl}
                        imageKey = {element.imageKey}
                      />
                    )
                  } else {
                    return (
                      <SingleImageUploadBox
                        key={element.imageKey}
                        onSuccess={onSuccess}
                        intl={intl}
                        isStaff = {userInfo.is_staff}
                        onDelete = {onDelete}
                        onChangeStatus = {onChangeStatus}
                        onDeleteInitialPreview = {onDeleteInitialPreview}
                        onImageClick = {this.onOpenImages}
                        consultRequestId = {consultRequestId}
                        consultRequestStatus = {consultRequestStatus}
                        patientId = {patientId}
                        image = {imageSet[element.imageKey]}
                        handleComplete = {handleCompleteClick}
                        {...element} />
                      )
                    }
                  })
              }
            </div>
          </div>
          { isStaff &&
            <div class={imageActionButtonsCssClassName}>
              <button
                className={'ImageForm__nextButton'}
                onClick={() => this.downloadFiles(fileName, this.props.imageSet)}
                disabled={this.state.isDownloading}
                >
                {toUpper(intl.messages['string.download'])}
              </button>
              {/*<button
                className={'ImageForm__nextButton'}
                onClick={handleCompleteClick}
                >
                {toUpper(intl.messages['string.markGood'])}
              </button>
              <button
                className={'ImageForm__nextButton'}
                onClick={handleFormClose}
                >
                {toUpper(intl.messages['string.close'])}
              </button>*/}
            </div>
          }
        </div>
        {/*}<div className={formSubmitCssClassName}>
          <button
            className={submitButtonCssClassName}
            onClick={handleCompleteClick}
            >
            {toUpper(intl.messages['newRequestForm.submitButton'])}
          </button>
        </div>*/}

      </div>
    )
  }

}

function mapStateToProps(state) {
    const { users, intl, settings, patientRequests } = state;
    console.log("patientimagesetupload mapstatetoprops", state)
    return {
        users,
        intl,
        settings,
        patientRequests,
    };
}

const connectedPatientImageSetUpload = connect(mapStateToProps)(PatientImageSetUpload);
export { connectedPatientImageSetUpload as PatientImageSetUpload };
