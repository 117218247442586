import { patientRequestsConstants } from '../_constants';
import { patientRequestsService, consultRequestsService } from '../_services';
import { API_DATE_FORMAT } from '../_config';
import moment from 'moment';

export const patientRequestsActions = {
  getPatientDetails,
  getPatientRequests,
  getPatientImageSet,
  getPatientRequestImages,
  getPatientNotes,
  getRequestDetails,
  editRequestMessage,
  updateRequestDetails,
  updateImageSet,
  updatePatientNotes,
  updateConsultRequestMessagesList,
  changeImageSetStatus,
  removeMessageAttachment,
  addMessageAttachment,
  updateConsultRequestMessageTreatmentPlan,
  changeRequestStatus
}

function getPatientDetails(id){
  return (dispatch, getState) => {
    dispatch(request());
    const { adminCurrentDentalPractice } = getState().settings;
    patientRequestsService.getPatientDetails(id, adminCurrentDentalPractice && adminCurrentDentalPractice.value)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: patientRequestsConstants.GET_PATIENTDETAILS_REQUEST } }
  function success(data) {
    data.patient.birthdate = moment(data.patient.birthdate, API_DATE_FORMAT);
    return { type:patientRequestsConstants.GET_PATIENTDETAILS_SUCCESS, data}
  }
  function failure(error) { return { type:patientRequestsConstants.GET_PATIENTDETAILS_FAILURE, error} }
}

function getPatientRequests(id){
  return (dispatch, getState) => {
    dispatch(request());
    const { adminCurrentDentalPractice } = getState().settings;
    patientRequestsService.getPatientRequests(id, adminCurrentDentalPractice && adminCurrentDentalPractice.value)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: patientRequestsConstants.GET_PATIENTREQUESTS_REQUEST } }
  function success(data) {
    return { type:patientRequestsConstants.GET_PATIENTREQUESTS_SUCCESS, data}
  }
  function failure(error) { return { type:patientRequestsConstants.GET_PATIENTREQUESTS_FAILURE, error} }
}

function getPatientRequestImages(id){
  return (dispatch, getState) => {
    dispatch(request());
    const { adminCurrentDentalPractice } = getState().settings;
    patientRequestsService.getPatientRequestImages(id, adminCurrentDentalPractice && adminCurrentDentalPractice.value)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: patientRequestsConstants.GET_PATIENTREQUESTIMAGES_REQUEST } }
  function success(data) {
    return { type:patientRequestsConstants.GET_PATIENTREQUESTIMAGES_SUCCESS, data}
  }
  function failure(error) { return { type:patientRequestsConstants.GET_PATIENTREQUESTIMAGES_FAILURE, error} }
}

function getPatientImageSet(id, imgId=null){
  return (dispatch, getState) => {
    dispatch(request());
    const { adminCurrentDentalPractice } = getState().settings;
    patientRequestsService.getPatientImageSet(id, imgId , adminCurrentDentalPractice && adminCurrentDentalPractice.value)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: patientRequestsConstants.GET_PATIENTIMAGESET_REQUEST } }
  function success(data) {
    return { type:patientRequestsConstants.GET_PATIENTIMAGESET_SUCCESS, data}
  }
  function failure(error) { return { type:patientRequestsConstants.GET_PATIENTIMAGESET_FAILURE, error} }
}

function getPatientNotes(id){
  return (dispatch, getState) => {
    dispatch(request());
    const { adminCurrentDentalPractice } = getState().settings;
    patientRequestsService.getPatientNotes(id, adminCurrentDentalPractice && adminCurrentDentalPractice.value)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: patientRequestsConstants.GET_PATIENTNOTES_REQUEST } }
  function success(data) {
    return { type:patientRequestsConstants.GET_PATIENTNOTES_SUCCESS, data}
  }
  function failure(error) { return { type:patientRequestsConstants.GET_PATIENTNOTES_FAILURE, error} }
}

function getRequestDetails(id){
  return (dispatch, getState) => {
    dispatch(request());
    const { adminCurrentDentalPractice } = getState().settings;
    consultRequestsService.getRequestDetails(id, adminCurrentDentalPractice && adminCurrentDentalPractice.value)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: patientRequestsConstants.GET_PATIENTREQUESTDETAILS_REQUEST } }
  function success(data) {
    return { type:patientRequestsConstants.GET_PATIENTREQUESTDETAILS_SUCCESS, data}
  }
  function failure(error) { return { type:patientRequestsConstants.GET_PATIENTREQUESTDETAILS_FAILURE, error} }
}

function updateRequestDetails(id, data){
  return (dispatch, getState) => {
    // TODO: update the patient request list also
    const patientRequests = getState().patientRequests.patientRequests;
    const newPatientRequests = patientRequests.map( (request, requestIndex) => {
      if (request.id == id) { // NOTE: not sure if we send string or integer
        // if request id is found, then update the status
        return {...request, ...data}
      }
      return request
    })
    const newData = {patientRequests: newPatientRequests, currentRequest: data}
    dispatch(update(newData));
  }
  function update(data) { return { type: patientRequestsConstants.UPDATE_CONSULTREQUESTDETAILS, data } }
}

function changeRequestStatus(id, data){
  return (dispatch, getState) => {
    console.log("DEBUG... patientRequests.actions::changeReuquestStatus", id, data)
    // TODO: update the patient request list also
    const patientRequests = getState().patientRequests.patientRequests;
    var currentRequest = getState().patientRequests.currentRequest;
    const newPatientRequests = patientRequests.map( (request, requestIndex) => {
      if (request.id == id) { // NOTE: not sure if we send string or integer
        // if request id is found, then update the status
        return {...request, status: data.status}
      }
      return request
    })
    const msgCount = currentRequest.messages.push(data.new_msg)
    const newData = {patientRequests: newPatientRequests, currentRequest }
    dispatch(update(newData));
  }
  function update(data) { return { type: patientRequestsConstants.UPDATE_CONSULTREQUESTDETAILS, data } }
}

function editRequestMessage(requestId, messageId, data){
  return (dispatch, getState) => {
    dispatch(request());
    consultRequestsService.updateConsultRequestMessage(requestId, messageId, data)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: patientRequestsConstants.EDIT_REQUESTMESSAGE_REQUEST } }
  function success(data) {
    return { type:patientRequestsConstants.EDIT_REQUESTMESSAGE_SUCCESS, data}
  }
  function failure(error) { return { type:patientRequestsConstants.EDIT_REQUESTMESSAGE_FAILURE, error} }
}

function updateImageSet(data){
  // NOTE: doesn't remove, just updates
  return (dispatch, getState) => {
    dispatch(update(data));
  }
  function update(data) { return { type: patientRequestsConstants.UPDATE_IMAGESET, data } }

}

function updatePatientNotes(data){
  return (dispatch, getState) => {
    dispatch(update(data));
  }
  function update(data) { return { type: patientRequestsConstants.UPDATE_PATIENTNOTES, data } }

}

function changeImageSetStatus(data){
  return (dispatch, getState) => {
    dispatch(request(data.image_group));
    patientRequestsService.changePatientImageSetStatus(data)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request(imageKey) { return { type: patientRequestsConstants.CHANGE_PATIENTIMAGESETSTATUS_REQUEST, imageKey} }
  function success(data) {
    return { type:patientRequestsConstants.CHANGE_PATIENTIMAGESETSTATUS_SUCCESS, data}
  }
  function failure(error) { return { type:patientRequestsConstants.CHANGE_PATIENTIMAGESETSTATUS_FAILURE, error} }
}

function updateConsultRequestMessagesList(data){
  return (dispatch, getState) => {
    dispatch(update(data));
  }
  function update(data) { return { type: patientRequestsConstants.UPDATE_CONSULTREQUESTMESSAGES, data } }

}

function updateConsultRequestMessageTreatmentPlan(data){
  console.log("updating message", data)
  return (dispatch, getState) => {
    const currentRequestMessages = getState().patientRequests.currentRequest.messages;
    currentRequestMessages.map((message, messageIndex) => {
      // update the message that matches given id
      if (message.id === data.messageId) {
        // update treatmentplan_status to either True or False
        message.treatmentplan_status = data.treatmentplan_status
        return message;
      }
      // leave other messages alone
      return message;
    })
    const newMessages = [...currentRequestMessages]
    // should have new_message if reject, otherwise empty
    if (data.new_message) {
      newMessages.push(data.new_message)
    }
    const new_data = {newMessages}
    dispatch(update(new_data));
  }
  function update(data) { return { type: patientRequestsConstants.UPDATE_CONSULTREQUESTMESSAGES_TREATMENTPLAN, data } }
}

function removeMessageAttachment(messageId, attachmentId){
  return (dispatch, getState) => {
    const currentRequestMessages = getState().patientRequests.currentRequest.messages;
    currentRequestMessages.map((message, messageIndex) => {
      // update the message that matches given id
      if (message.id === messageId) {
        // remove the attachment that matches given id before returning
        const newAttachments = message.attachments.filter((attachment, attachmentIndex) => {
          if (attachment.id == attachmentId) {
            // remove attachment
            return false;
          }
          // all other attachments stay
          return true;
        })
        message.attachments = newAttachments;
        return message;
      }
      // leave other messages alone
      return message;
    })
    const data = {currentRequestMessages}
    dispatch(update(data));
  }
  function update(data) { return { type: patientRequestsConstants.UPDATE_MESSAGEATTACHMENT, data } }
}

function addMessageAttachment(messageId, new_message){
  return (dispatch, getState) => {
    const currentRequestMessages = getState().patientRequests.currentRequest.messages;
    currentRequestMessages.map((message, messageIndex) => {
      // update the message that matches given id
      if (message.id == messageId) {
        // append the attachment data to attachments
        message.attachments.push(new_message);
        return message;
      }
      // leave other messages alone
      return message;
    })
    const data = {currentRequestMessages}
    dispatch(update(data));
  }
  function update(data) { return { type: patientRequestsConstants.UPDATE_MESSAGEATTACHMENT, data } }
}
