import React from 'react';
import ReactToPrint from 'react-to-print';
import cn from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';


import { localeFormatDate } from '../_helpers/date';
import { getFullNameString } from '../_helpers/utils';
import { toUpper, startCase } from 'lodash';

import { ToothMovementChart, ToothConditionChart } from '../PatientPage/ToothMovementChart';
import PopupDialog, { getFooterCloseButton, PopupDialogContentText } from '../_components/Popup/PopupDialog';

import '../../css/ToothChart.scss';
import './Printer.scss';

const intldata = {
  'workrequestform.dialog.header': 'Print Request',
  'workrequestform.dialog.header.lab.label': 'Dental Lab',
  'workrequestform.dialog.header.lab.name': 'Beforedent Inc.',
  'workrequestform.title': 'Dental Work Request',
  'workrequestform.button.print': 'Print',
  'workrequestform.button.close': 'Close',
  'workrequestform.request_id': 'Request #',
  'workrequestform.clinic_name': 'Clinic Name',
  'workrequestform.patient_name': 'Patient ID',
  'workrequestform.gender': 'Gender',
  'workrequestform.birthdate': 'Birthdate',
  'workrequestform.requestDate': 'Request Date',
  'workrequestform.completeDate': 'Completion Date',
  'workrequestform.description': 'Description of Request',
  'workrequestform.list_products': 'List of Products',
  'workrequestform.chart.movement': 'Movement Request',
  'workrequestform.chart.condition': 'Patient Condition',
}

const baseCssClassName = 'WorkRequestForm';
const actionsRowCssClassName = `${baseCssClassName}__actionsRow`;
const headingCssClassName = `${baseCssClassName}__heading`;
const labNameHeadingCssClassName = `${baseCssClassName}__labname`;
const requestFieldCssClassName = `${baseCssClassName}__requestId`;
const buttonCssClassName = `${baseCssClassName}__button`;
const fieldLabelCssClassName = `${baseCssClassName}__label`;


const tableCssClassName = `${baseCssClassName}__table`;
const tableRowCssClassName = `${tableCssClassName}__row`;
const tableClinicRowCssClassName = `${tableRowCssClassName}-clinic`;
const tablePatientRowCssClassName = `${tableRowCssClassName}-patient`;
const tableDatesRowCssClassName = `${tableRowCssClassName}-dates`;
const tableDescriptionRowCssClassName = `${tableRowCssClassName}-description`;

const dialogClassName = `${baseCssClassName}__dialog`;
const dialogContentClassName = `${dialogClassName}__content`;

const toothChartsCssClassName = `${baseCssClassName}__ToothCharts`;
const headerToothChartsCssClassName = `${toothChartsCssClassName}__heading`;
const chartsToothChartsCssClassName = `${toothChartsCssClassName}__charts`;


class ComponentToPrint extends React.Component {
  render() {

    const { intl, requestId, practiceName,
            patientName, gender, patientBirthdate,
            date_created, date_completed,
            description } = this.props;

    return (
      <div className={baseCssClassName}>
        <div className={headingCssClassName}>{intldata['workrequestform.title']}</div>
        <div className={labNameHeadingCssClassName}>
          <span className={fieldLabelCssClassName}>{intldata['workrequestform.dialog.header.lab.label']}</span>
          <span>{': '}</span>
          <span>{intldata['workrequestform.dialog.header.lab.name']}</span>
        </div>
        <div className={requestFieldCssClassName}>
          <span className={fieldLabelCssClassName}>{intldata['workrequestform.request_id']}</span>
          <span>{' '}</span>
          <span>{requestId}</span>
        </div>
        <table className={cn(tableCssClassName, tableClinicRowCssClassName)}>
          <thead>
            <tr>
              <th>{intldata['workrequestform.clinic_name']}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{practiceName}</td>
            </tr>
          </tbody>
        </table>
        <table className={cn(tableCssClassName, tablePatientRowCssClassName)}>
          <thead>
            <tr>
              <th>{intldata['workrequestform.patient_name']}</th>
              <th>{intldata['workrequestform.gender']}</th>
              <th>{intldata['workrequestform.birthdate']}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{patientName}</td>
              <td>{toUpper(gender)}</td>
              <td>{localeFormatDate(patientBirthdate, intl.locale)}</td>
            </tr>
          </tbody>
        </table>
        <table className={cn(tableCssClassName, tableDatesRowCssClassName)}>
          <thead>
            <tr>
              <th>{intldata['workrequestform.requestDate']}</th>
              <th>{intldata['workrequestform.completeDate']}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{localeFormatDate(date_created, intl.locale)}</td>
              <td>{date_completed}</td>
            </tr>
          </tbody>
        </table>
        <table className={cn(tableCssClassName, tableDescriptionRowCssClassName)}>
          <thead>
            <tr>
              <th>{intldata['workrequestform.description']}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{description}</td>
            </tr>
          </tbody>
        </table>
        <div className={'divider'}></div>
        <div className={toothChartsCssClassName}>
          <div className={chartsToothChartsCssClassName}>
            <ToothMovementChart
              requestId={this.props.requestId}
              chartType={'movement'}
              printMode={true}
            />
            <ToothConditionChart
              requestId={this.props.requestId}
              chartType={'condition'}
              printMode={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

class WorkRequestContent extends React.Component{
  constructor(props){
    super(props);
    this.formRef = React.createRef();
  }

  render(){
    const { intl, currentRequest, patient, onClose } = this.props;

    const { id: requestId, created_at, practice_name, status } = currentRequest;
    const { id: patient_id, gender, birthdate, first_name, last_name } = patient;

    return (
      <div>
        <div className={actionsRowCssClassName}>
          <ReactToPrint
            trigger={() => <button className={buttonCssClassName} href="#">{intldata['workrequestform.button.print']}</button>}
            content={() => this.formRef.current}
          />
          <button
            onClick={onClose}
            className={buttonCssClassName}
          >
            {intldata['workrequestform.button.close']}
          </button>
        </div>
        <ComponentToPrint
          intl= {intl}
          ref={this.formRef}
          requestId={requestId}
          practiceName={practice_name}
          patientName={ patient_id }
          patientBirthdate= {birthdate}
          gender={gender}
          date_created={created_at}
          date_completed={''}
          description='Clear Aligners'
        />
      </div>
    )
  }
}

class WorkRequestForm extends React.Component{
  constructor(props){
    super(props);
  }


  _handleDialogClose = () => {
  }

  render(){

    /*const footerProps = {
      buttons: (dialogInterface) => {
        return [
            getFooterCloseButton(
              dialogInterface,
              {text: toUpper(intldata['workrequestform.button.print'])}
            ),
            getFooterCloseButton(
              dialogInterface,
              {text: toUpper(intldata['workrequestform.button.closen'])}
            )
        ]
      }
    }*/
    const { patientRequests, intl, onClose } = this.props;

    const { currentRequest, patient } = patientRequests

    return (
      <PopupDialog
        cssClassNames={cn(dialogClassName)}
        headerProps={{
          title: startCase(intldata['workrequestform.dialog.header']),
        }}
        content={(dialogInterface) => (
          <PopupDialogContentText>
            <div className={dialogContentClassName} >
              <WorkRequestContent
                onClose={onClose}
                currentRequest={currentRequest}
                intl={intl}
                patient={patient}
              />
            </div>
          </PopupDialogContentText>
        )}
        popupProps={{
          onClose: {}
        }}
      />
    )
  }
}
function mapStateToProps(state) {
    const { authentication, intl, patientRequests } = state;
    const { user } = authentication;
    return {
        user,
        intl,
        patientRequests
    };
}

const connectedWorkRequestForm = withRouter(connect(mapStateToProps)(WorkRequestForm));
export { connectedWorkRequestForm as WorkRequestForm };
