import React, { Component } from 'react';

import  ProgressBar from '../ProgressBar';
import PopupDialog, { PopupDialogContentText } from '../Popup/PopupDialog';

import './LoadingDialog.scss';

const baseCssClassName = 'LoadingDialog';
const progressCssClassName = `${baseCssClassName}__progress`;
const dialogContentClassName = `${baseCssClassName}__content`;

class LoadingDialog extends Component{
  constructor(props){
    super(props);
  }

  _handleLoadingDialogClose = () => {
    return null;
  }

  render(){
    return (
      <PopupDialog
            cssClassNames={baseCssClassName}
  					headerProps={{
  						title:  this.props.title,
  					}}
  					content={(dialogInterface) => (
  						<PopupDialogContentText>
  							<div className={dialogContentClassName} >
  							<div className={progressCssClassName}
  								  dangerouslySetInnerHTML={{
  									__html: this.props.message,
  								}}
  							/>
  							<ProgressBar completed={40}/>
  							</div>
  						</PopupDialogContentText>
  					)}
  					popupProps={{
  						onClose: this._handleLoadingDialogClose,
  					}}
  				/>
    )
  }

}

export default LoadingDialog;
