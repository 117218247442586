import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL } from '../_config';
import { toUpper } from 'lodash';

export const patientService = {
    getPatientPageDetails,
    getPatientDetails,
    editPatientDetails,
    getAppointmentDetails,
    getProcedureCodes,
    getToothRegions,
    createInvoice,
    createLedger,
    addProcedure,
    getInbox,
    getNewInbox,
    sendMessage,
    getMessages,
    getNewMessages,
    getVideoChatToken,
    removeFromSurfaceForm,
    addToSurfaceForm,
    getPatientProcedures,
    completeTreatmentPlan,
    getPatientInsurances,
    addInsurance,
    addNote,
    getToothNumbers
};

function removeFromSurfaceForm(surface, surfaceList){
    for (let i = 0; i < surfaceList.length; i++) {
        let currSurface = surfaceList[i];
        if (currSurface === toUpper(surface.charAt(0))) {
            // delete user
            surfaceList.splice(i, 1);
            break;
        }
    }
    return surfaceList
}

function addToSurfaceForm(surface, surfaceList){
        return [... new Set(surfaceList.concat(toUpper(surface.charAt(0)))) ]
}

function getPatientPageDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/patient/'+id+'/details';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getPatientDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/patient/'+id+'/edit';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function editPatientDetails(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    let url = '/api/d/dental_practice/patient/'+data.id+'/edit';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function createInvoice(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)

    };
    let url = '/api/d/dental_practice/patient/createinvoice';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function addInsurance(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)

    };
    let url = '/api/d/dental_practice/patient/createinsurance';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};


function createLedger(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)

    };
    let url = '/api/d/dental_practice/patient/createledgerentry';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};



function addProcedure(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)

    };
    let url = '/api/d/dental_practice/patient/createprocedure';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function addNote(data){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)

    };
    let url = '/api/d/dental_practice/patient/createnote';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

function getPatientProcedures(patientId, filt){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url_params = "";
    if (filt == "unbilled"){
        url_params = "?unbilled=1"
    }
    let url = '/api/d/dental_practice/patient/'+patientId+'/procedures' + url_params

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

function getPatientInsurances(patientId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/patient/'+patientId+'/insurance'

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

// Load Procedure Codes (change to search in future)

function getProcedureCodes(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/search/procedurecodes';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

// Load Tooth Regions

function getToothRegions(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_config/toothregion';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

// Load Tooth Numbers
function getToothNumbers(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_config/toothnumber';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

// details returns appt details, procedures, patient info
function getAppointmentDetails(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/dental_practice/appointment/'+id+'/details';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

// get video chat email
function getVideoChatToken(sessionID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/msg/videochat/token/'+sessionID

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

// send message
function sendMessage(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)

    };
    let url = '/api/d/msg/reply';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
};

// get message inbox
function getInbox(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/msg/inbox';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

// get chatbox messages
function getMessages(chatID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/msg/view/' + chatID;

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

// get chatbox messages
function getNewMessages(chatID){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/msg/view/' + chatID+'/messages';

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}
// get unread messages for inbox
function getNewInbox(tm){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = '/api/d/msg/inbox?reqtime='+tm;

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

function completeTreatmentPlan(data, patientId){
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    let url = '/api/d/dental_practice/patient/'+patientId+'/procedures/changestatus'

    return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}
