import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {
	CustomButton,
  	CustomDialogTitle,
  	CustomDialogContentText,
  	CustomDialogActions } from '../_components';

import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle} from '@material-ui/core/Dialog';

class NotificationsIcon extends React.Component{
  render(){

    return (
    <svg width="21px" height="30px" viewBox="0 0 21 28" version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" className={this.props.className}>
            <g id="notification" transform="translate(-1146, -24)">
                <g id="Group-32" transform="translate(1140, 21)">
                    <rect id="Rectangle-3" x="0" y="0" width="32" height="32"></rect>
                    <g id="Group-31" transform="translate(7, 3)">
                        <g id="web_alarm" stroke="#FFFFFF" strokeWidth="1.4">
                            <path d="M17.68,15.33 L15.68,13.11 L15.68,10 C15.6737499,7.35596632 14.1039993,4.96602103 11.68,3.91 L11.68,3.34 C11.68,1.87644541 10.4935546,0.69 9.03,0.69 C7.56644541,0.69 6.38,1.87644541 6.38,3.34 L6.38,3.91 C3.95600067,4.96602103 2.38625007,7.35596632 2.38,10 L2.38,13.1 L0.38,15.32 L0.38,18 L5.66,18 C5.88544107,19.6832933 7.32167728,20.94 9.02,20.94 C10.7183227,20.94 12.1545589,19.6832933 12.38,18 L17.66,18 L17.68,15.33 Z" id="Page-1-3"></path>
                            <path d="M2.48,10 L10.68,10" id="Stroke-4"></path>
                        </g>
                        <circle id="notifDot" className="notificationDot" fill="#253238" fillRule="nonzero" cx="17" cy="25" r="3"></circle>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
  }
}

class NavBarSearchInput2 extends React.Component{

	render () {
		const AsyncComponent = false
			? Select.AsyncCreatable
			: Select.Async;
		return (
				<Select.AsyncCreatable
					{...this.props}
					multi={false}
					backspaceRemoves={true}
					promptTextCreator={label => 'Create New Patient '+ label}
				/>
		);
	}
};

class NavBarSearchInput extends React.Component{

	render () {
		const AsyncComponent = false
			? Select.AsyncCreatable
			: Select.Async;
		return (
				<Select.Async
					{...this.props}
					multi={false}
					backspaceRemoves={true}
				/>
		);
	}
};


class AddPatientForm extends React.Component{

  render() {
    const { formOpen, form,
            handleFormChange, handleFormSubmit, handleFormClose } = this.props;

    return (
        <Dialog className="popUpForm"
          open={formOpen}
          onClose={handleFormClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick={true}
        >
          <CustomDialogTitle className="title" id="form-dialog-title"> {"Edit Patient"} </CustomDialogTitle>
          <DialogContent>
              <CustomDialogContentText className="contentText">
                Patient Information
              </CustomDialogContentText>
            <div className = "row">
              <div className="form-group col-md-4" >
                <label>First Name</label>
                <input type="text" className="form-control"
                  name="first_name"
                  value={form.first_name}
                  onChange={handleFormChange}
                />
              </div>
              <div className="form-group col-md-4" >
                <label>Middle Name</label>
                <input type="text" className="form-control"
                  name="middle_name"
                  value={form.middle_name}
                  onChange={handleFormChange}
                />

              </div>
              <div className="form-group col-md-4" >
                <label>Last Name</label>
                <input type="text" className="form-control"
                  name="last_name"
                  value={form.last_name}
                  onChange={handleFormChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12" >
                <label>Address</label>
                  <textarea type="text" rows="3" className="form-control"
                    name="address"
                    value={form.address}
                    onChange={handleFormChange} />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6" >
                <label>Gender</label>
                <Select
                  name="gender"
                  searchable={false}
                  value={form.gender}
                  placeholder={"Select..."}
                  onChange={(selectedOption) => handleFormChange(selectedOption, "gender")}
                  options = { [{value:"FEMALE", label:"FEMALE"}, {value: "MALE", label: "MALE"}] }
                />
              </div>
              <div className="form-group col-md-6" >
                <label>Date of Birth</label>
                  <input type="text" className="form-control"
                    placeholder="YYYY-MM-DD"
                    name="date_of_birth"
                    value={form.date_of_birth}
                    onChange={handleFormChange}
                  />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6" >
                <label>Phone Number</label>
                <input type="text" className="form-control"
                  name="phone_number"
                  value={form.phone_number}
                  onChange={handleFormChange}
                />
              </div>
              <div className="form-group col-md-6" >
                <label>Email Address</label>
                  <input type="text" className="form-control"
                    name="email_address"
                    value={form.email_address}
                    onChange={handleFormChange}
                  />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12" >
                <label>Medical Notes</label>
                  <textarea type="text" rows="3" className="form-control"
                    name="medical_notes"
                    value={form.medical_notes}
                    onChange={handleFormChange}
                  />
              </div>
            </div>
          </DialogContent>
          <CustomDialogActions>
            <CustomButton raised onClick={handleFormClose} color="accent">
              Cancel
            </CustomButton>
            <CustomButton raised onClick={() => handleFormSubmit()} className="primary">
              Save
            </CustomButton>
          </CustomDialogActions>
        </Dialog>
    );
  }
}


export { NavBarSearchInput, AddPatientForm, NotificationsIcon } ;
