import { createPollingActions, getPollingState } from 'redux-polling';
import { createSelector } from 'reselect';
import { authHeader, handleResponse } from '../_helpers';
import { BASE_URL, API_URLS, NOTIFICATION_POLLING_INTERVAL } from '../_config';

const pollingInterval = NOTIFICATION_POLLING_INTERVAL;
const historyLength = 10;

function getActivityLog(){
    const requestUrl = API_URLS.getNotifications; //API_URLS.getActivityLog;
    const requestOptions = {
      method: requestUrl.method,
      headers: { ...authHeader()}
    };
    let url = requestUrl.url;
    return fetch(url, requestOptions).then(handleResponse);
}

function getNewActivityLog(ts){
  const requestUrl = API_URLS.getNewNotifications; //API_URLS.getNewActivityLog;
  const requestOptions = {
    method: requestUrl.method,
    headers: { ...authHeader()}
  };
  let url = requestUrl.url.replace('{ts}', ts);
  return fetch(url, requestOptions).then(handleResponse);
}

export const activityPollingSelectors = {
    isActivityPollingActive: state => getPollingState(state, 'notifications').isActive,
    getActivityHistory: state => getPollingState(state, 'notifications').history,
    getLastEntry: state => getPollingState(state, 'notifications').lastEntry,
    getLastPoint: createSelector(
        state => getPollingState(state, 'notifications').lastEntry,
        lastEntry => (lastEntry ? lastEntry.point : undefined),
    ),
    /*getLastChange: createSelector(
        state => getPollingState(state, 'notifications').history,
        (history) => {
            if (history.length < 2) {
                return undefined;
            }

            const [ { point: current } ] = history.slice(-1);
            const [ { point: prev } ] = history.slice(-2);

            return current - prev;
        },
    ),*/
};

let isInitial = true;
let updateTimestamp = +new Date();
/*async function pollingOld(getState) {
    let activities;
    if (isInitial){
      activities = await getActivityLog()
    } else {
      // only new entires so getting stuff after
      const ts = +new Date();
      activities = await getNewActivityLog(ts)
    }
    isInitial = false;

    const lastEntry = activityPollingSelectors.getLastEntry(getState());
    const lastEntryIndex = lastEntry ? lastEntry.index + 1 : 1;
    // TODO: dispatch updateActivityLog timestamp somewhere ehere
    const entries = activities.activity.map(( act , index) => ({ data: act, index: index + lastEntryIndex }));
    return { multipleEntries: true, entries };
}*/

async function polling(getState) {
    let notifications, lastEntry;
    if (isInitial){
      notifications = await getActivityLog()
      updateTimestamp = +new Date();
    } else {
      // only new entires so getting stuff after
      notifications = await getNewActivityLog(updateTimestamp)
      updateTimestamp = +new Date();
    }
    isInitial = false;

    lastEntry = activityPollingSelectors.getLastEntry(getState());
    const lastEntryIndex = lastEntry ? lastEntry.index + 1 : 1;
    // TODO: dispatch updateActivityLog timestamp somewhere ehere
    const entries = notifications.unread_messages.map(( msg , index) => ({ data: msg, index: index + lastEntryIndex }));
    return { multipleEntries: true, entries };
}

function shouldAddEntry(/* value, getState */) {
    // const isEven = value.point % 2 === 0;
    // if (!isEven) {
    //     console.log(`Filter odd point ${value.point}`);
    // }
    // return isEven;
    return true;
}

function onReset() {
    isInitial = true;
}

const callbacks = { polling, shouldAddEntry, onReset };

export const activityPollingActions = createPollingActions('notifications', callbacks, pollingInterval, historyLength);
