import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import { reduxPollingMiddleware } from 'redux-polling';
import { default as reduxPollingMiddleware} from '../ActivityNotifications/middleware';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';

import { AVAILABLE_LANGUAGES } from '../_config';
import moment from 'moment';
import rootSaga from './saga';
import {history} from './';

// mount it on the Store

// initialize intl state
// internationalization
import {addLocaleData, IntlProvider} from 'react-intl';


import localeData from '../../locales/data.json';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import th from 'react-intl/locale-data/th';


const loggerMiddleware = createLogger();
// create the saga middleware
const sagaMiddleware = createSagaMiddleware()


const language = (localStorage.lang ||
                    navigator.languages && navigator.languages[0]) ||
                    navigator.language ||
                    navigator.userLanguage;

// Split locales with a region code
//const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
let currentLanguage = language || 'en-us'
console.log("CURRENT LANG:", currentLanguage)
if (AVAILABLE_LANGUAGES.map((lang, ind) => lang.value).indexOf(currentLanguage) === -1){
  currentLanguage = 'en-us';
}
const initialIntlState = {
  intl: {
    locale: currentLanguage,
    messages: localeData[currentLanguage]
  }
}

export const store = createStore(
    rootReducer,
    initialIntlState,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware,
        sagaMiddleware,
        reduxPollingMiddleware
    )
);

//run the saga
sagaMiddleware.run(rootSaga)
