import { combineReducers } from 'redux';
//import { reduxPollingNamespace, reduxPollingReducer } from 'redux-polling';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { scheduling } from './scheduling.reducer';
import { appointment } from './appointment.reducer';
import { patient } from './patient.reducer';
import { videochat } from './videochat.reducer';
import { settings } from './settings.reducer';
import { dentalAdmin } from './admin.reducer';
import { forms } from './forms.reducer';
import { patientSearch } from './patientSearch.reducer';
import { insuranceClaims } from './insuranceClaims.reducer';
import { consultRequests } from './consultRequests.reducer';
import { patientRequests } from './patientRequests.reducer';
import { activityLog } from './activityLog.reducer';
import { imageSet } from './imageSet.reducer';
import { default as reduxPollingReducer, reduxPollingNamespace } from '../ActivityNotifications/reducer';

import { intlReducer } from 'react-intl-redux'
import { test } from './test.reducer'

import { reducer as notifications } from 'react-notification-system-redux';

const rootReducer = combineReducers({
  authentication,
  users,
  notifications,
  alert,
  patient,
  consultRequests,
  activityLog,
  patientRequests,
  dentalAdmin,
  imageSet,
  settings,
  intl: intlReducer,
  [reduxPollingNamespace]: reduxPollingReducer
  /*test,
  registration,
  insuranceClaims,
  scheduling,
  appointment,
  videochat,
  forms,
  patientSearch,*/
});

export default rootReducer;
