import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { history, authHeader, handleResponse, convertToAPIValues} from '../_helpers';
import { PAGE_SIZE, URLS, AVAILABLE_LANGUAGES, ADMIN_URLS, ADMIN_API_URLS, AVAILABLE_COUNTRIES, AVAILABLE_CLINIC_TYPES,
        required, noChineseCharacters, composeValidators} from '../_config';

import { NavBarPage, SideBarPage } from '../HomePage';

import { adminService } from '../_services';
import { adminActions, alertActions } from '../_actions';
import { alertConstants } from '../_constants';

import cn from 'classnames';
import { upperFirst, toUpper } from 'lodash';

import Spinner from '../_components/Spinner';

import { SelectAdapter, SelectAdapterNonClearable, errorAlert } from '../_components';
import {default as AutoSave} from '../_components/AutoSave'

import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";
import setFieldData from 'final-form-set-field-data';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

import { FormattedMessage } from 'react-intl';
import localeData from '../../locales/data.json';
import { updateIntl } from 'react-intl-redux';

import max from 'libphonenumber-js/metadata.full.json';

import '../../css/userProfile.style.scss';
import '../../css/organizationSearch.style.scss';
import '../../css/admin.style.scss';

const baseCssClassName = 'UserProfilePage';
const contentWrapperCssClassName = `${baseCssClassName}__ContentWrapper`;
const detailsHeadingCssClassName = `${baseCssClassName}__heading`;
const detailsHeadingTitleCssClassName = `${detailsHeadingCssClassName}__title`;
const detailsHeadingNameCssClassName = `${detailsHeadingCssClassName}__name`;
const formCssClassName = `${baseCssClassName}__form`;
const detailsCssClassName = `${formCssClassName}__details`;
const rowFormCssClassName = `${detailsCssClassName}__row`;
const rowLanguageFormCssClassName = `${rowFormCssClassName}-language`;
const rowEmailFormCssClassName = `${rowFormCssClassName}-emailAddress`;
const rowAddressFormCssClassName = `${rowFormCssClassName}-address`;
const rowPhoneNumberFormCssClassName = `${rowFormCssClassName}-phoneNumber`;

const SavingIndicator = ({ name }) => (
  <Field
    name={name}
    subscription={{ data: true }}
    render={({
      meta: {
        data: { saving }
      }
    }) => (saving ? <div className="saving">Saving</div> : null)}
  />
)

class WholesalerSelect extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        options: [],
      }
    }

    componentDidMount = () => {
      const requestOptions = {
          method: 'GET',
          headers: authHeader()
      };
      let url = ADMIN_API_URLS.getERPWholesalers.url;
      return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((json) => {
          const options = json.search_list.map( (obj, indx) => {
            return {'value': obj.id, 'label': `${obj.name} (${obj.country_id[1]}) - ${obj.email} `}
          })
          this.setState({options}) ;
        });
    }

    loadOptions = () => {
      const requestOptions = {
          method: 'GET',
          headers: authHeader()
      };
      let url = ADMIN_API_URLS.getERPWholesalers.url;
      return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((json) => {
          const options = json.search_list.map( (obj, indx) => {
            console.log(obj)
            return {'value': obj.id, 'label': `${obj.name} (${obj.country[1]}) - ${obj.email} `}
          })
          console.log("selajlkajer slk aiotjionsjkt anjk jkh fjks", options)
          return { options } ;
        });
    }

    render(){
      const { input, meta, className, label, value, disabled, defaultValue, placeholder, intl, name, ...rest } = this.props;
      return(
        <div className={className}>
          <label>{label}</label>
          <Select
            {...input}
            className={ cn({"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
            onChange={inputValue => input.onChange(inputValue)}
            options={this.state.options}
            disabled={disabled}
            clearable={false}
            searchable={false}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
            {...rest}
          />
          <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
            {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
          </div>
        </div>

      )
    }
}


class PhoneAdapter extends React.Component{
  render(){
    const { input, meta, className, label, value, placeholder, intl, name, country, ...rest } = this.props;
    return(
      <div className={className}>
        <label className="Input__Label">{label}</label>
        <PhoneInput
          className={className}
          meta={meta}
          input={input}
          value={input.value}
          displayInitialValueAsLocalNumber={true}
          country={country}
          countries={AVAILABLE_COUNTRIES}
          onChange={value => input.onChange(value)}
          placeholder={placeholder}
          inputClassName={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )}
        />
        <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
          {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
        </div>
        </div>
    )
  }
}

class BaseSearchInput extends React.Component{
  render(){
    return (
      <Select.Async
        {...this.props}
        multi={false}
        backspaceRemoves={true}
      />
    )
  }
}

const orgSearchInputCssClassName = 'OrganizationSearch';

class OrganizationSearchInput extends React.Component{

  handleSearchLoading = (input) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = ADMIN_API_URLS.dentalOrganizationSearch.url+"?q="+input;
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
      return { options: json.search_list };
    });
  }

  render() {
    const { input, meta, className, label, value, placeholder, intl, name, ...rest } = this.props;
    return (
      <div className={className}>
        <label>{label}</label>
        <BaseSearchInput
            className={orgSearchInputCssClassName}
            name={name || "organization"}
            placeholder={placeholder || "organization"}
            value={input.value}
            autoload={false}
            cacheOptions
            defaultOptions
            loadOptions={this.handleSearchLoading}
            onChange={inputValue => input.onChange(inputValue)}
        />
        <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
          {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
        </div>
      </div>
    )
  }
}

const intl8 = {
  messages: {
    'admin.newAccount.button.save': 'create account',
    'admin.newAccount.country': 'Country',
    'admin.newAccount.wholesaler': 'Wholesaler',
    'admin.newAccount.heading.title': 'Dental Account Settings',
    'admin.newAccount.organizationName': 'Clinic Name',
    'admin.newAccount.type': 'Clinic Type',
    'admin.newAccount.email': 'Email',
    'admin.newAccount.firstName': 'First Name',
    'admin.newAccount.lastName': 'Last Name',
    'admin.newAccount.phoneNumber': 'Phone Number',
    'admin.newAccount.street': 'Street',
    'admin.newAccount.street2': 'Street 2',
    'admin.newAccount.city': 'City',
    'admin.newAccount.zip': 'Zipcode',
    'admin.newAccount.creating.title': '',
    'admin.newAccount.creating.message': '',
  }
}

const adminBaseCssClassName = 'AdminSettings';
const adminCountryCssClassName = `${adminBaseCssClassName}__country`;
const adminWholesalerCssClassName = `${adminBaseCssClassName}__wholesaler`;
const adminHalfRowCssClassName = `${adminBaseCssClassName}__halfRow`;
const adminButtonsCssClassName = `${adminBaseCssClassName}__buttons`;
const adminFormSubmitCssClassName = `${adminButtonsCssClassName}__submitButton`;

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

class AdminCreateNewAccountPage extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
  }

  onCreate = async (values) => {
    const { intl } = this.props;
    const convertedValues = convertToAPIValues(values)
    try {
      //this.props.dispatch(adminActions.updateNewAccount(result));
      this.props.dispatch(alertActions.show({type: 'loading'}));
      const result = await adminService.createNewAccount(convertedValues);
      this.props.dispatch(adminActions.updateNewAccount(result));
      this.props.dispatch(alertActions.success("Create Password URl:\n" + result.password_url));
      //this.props.history.push(ADMIN_URLS.accountDetailsPage.replace('{accountId}', result.id));
    } catch (err) {
      if (err.error && err.error.code) {
        return {[FORM_ERROR] : err.error.code }
      }else{
        errorAlert(intl)
        return err
      }
    }
  }

  render(){
    const { intl, dentalAdmin } = this.props;
    const availCountries = AVAILABLE_COUNTRIES.map(ctry => ({'value': ctry, 'label': ctry}))
    //const { userInfo } = users;
    const initialValues = {
      country: availCountries[0]
    }
    return (
      <div className={cn(baseCssClassName, adminBaseCssClassName)}>
        <NavBarPage />
        <SideBarPage location={this.props.location} />
        <div className={contentWrapperCssClassName}>
          <div className={detailsHeadingCssClassName}>
            <div className={detailsHeadingTitleCssClassName}>{toUpper(intl8.messages['admin.newAccount.heading.title'])}</div>
            <div className={detailsHeadingNameCssClassName}>
            </div>
          </div>
          <div className={formCssClassName}>
            <Form
              onSubmit={this.onCreate}
              initialValues = {initialValues}
              mutators={{ setFieldData }}
              render = {({
                submitError,
                formError,
                handleSubmit,
                mutators,
                form,
                reset,
                submitting,
                pristine,
                validating,
                values
              }) => (
                  <form
                    id={'newAccountForm'}
                    onSubmit={handleSubmit}
                  >
                    {/*<AutoSave setFieldData={form.mutators.setFieldData} activeOnChanges={['language']} save={this.onUpdateSettings} />*/}

                    <div className={detailsCssClassName}>
                      <div className={rowFormCssClassName}>
                        <Field
                          className={rowLanguageFormCssClassName}
                          name="country"
                          intl={intl}
                          options={availCountries}
                          label={toUpper(intl8.messages['admin.newAccount.country'])}
                          component={SelectAdapterNonClearable}
                          defaultValue={availCountries[0]}
                          placeholder={upperFirst(intl8.messages['admin.newAccount.country'])}
                          disabled={true}
                          intl={intl}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field
                         className={adminWholesalerCssClassName}
                         name="wholesaler"
                         intl={intl}
                         label={toUpper(intl8.messages['admin.newAccount.wholesaler'])}
                         component={WholesalerSelect}
                         placeholder={upperFirst(intl8.messages['admin.newAccount.wholesaler'])}
                         disabled={false}
                         intl={intl}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field
                         className={rowLanguageFormCssClassName}
                         name="type"
                         intl={intl}
                         label={toUpper(intl8.messages['admin.newAccount.type'])}
                         component={SelectAdapterNonClearable}
                         options ={AVAILABLE_CLINIC_TYPES}
                         placeholder={upperFirst(intl8.messages['admin.newAccount.type'])}
                         disabled={false}
                         intl={intl}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="organization_name" validate={required}>
                        {({ input, meta}) => (
                          <div className={rowEmailFormCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.newAccount.organizationName'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.newAccount.organizationName'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="email" validate={required}>
                        {({ input, meta}) => (
                          <div className={rowAddressFormCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.newAccount.email'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.newAccount.email'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={cn(rowFormCssClassName)}>
                        <Field name="first_name" validate={required}>
                        {({ input, meta}) => (
                          <div className={adminHalfRowCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.newAccount.firstName'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.newAccount.firstName'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                        <Field name="last_name" validate={required}>
                        {({ input, meta}) => (
                          <div className={adminHalfRowCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.newAccount.lastName'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.newAccount.lastName'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="street" validate={required}>
                        {({ input, meta}) => (
                          <div className={rowAddressFormCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.newAccount.street'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.newAccount.street'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="street2">
                        {({ input, meta}) => (
                          <div className={rowAddressFormCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.newAccount.street2'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.newAccount.street2'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={cn(rowFormCssClassName)}>
                        <Field name="city" validate={required}>
                        {({ input, meta}) => (
                          <div className={adminHalfRowCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.newAccount.city'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.newAccount.city'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                        <Field name="zip" validate={required}>
                        {({ input, meta}) => (
                          <div className={adminHalfRowCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.newAccount.zip'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.newAccount.zip'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field
                          className={rowLanguageFormCssClassName}
                          name="phone_number"
                          intl={intl}
                          country={availCountries[0].value}
                          label={toUpper(intl8.messages['admin.newAccount.phoneNumber'])}
                          component={PhoneAdapter}
                          placeholder={upperFirst(intl8.messages['admin.newAccount.phoneNumber'])}
                          validate={required}
                          intl={intl}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <div className={adminButtonsCssClassName}>
                          <button
                            className={adminFormSubmitCssClassName}
                            disabled={submitting || pristine}
                            type="submit"
                          >
                            {toUpper(intl8.messages['admin.newAccount.button.save'])}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className={cn("Error__Label", {"Error": (submitError)} )} >
                      {submitError ? intl.messages[`form.error.${submitError}`] : undefined }
                    </div>
                  </form>
              )}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { intl, users, dentalAdmin } = state;
    return {
        intl,
        dentalAdmin,
        users
    };
}

const connectedAdminCreateNewAccountPage = withRouter(connect(mapStateToProps)(AdminCreateNewAccountPage));
export { connectedAdminCreateNewAccountPage as AdminCreateNewAccountPage };
