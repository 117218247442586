import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

import { history, authHeader, handleResponse, convertToAPIValues, localeFormatDate} from '../_helpers';
import { PAGE_SIZE, URLS, AVAILABLE_LANGUAGES, ADMIN_API_URLS, AVAILABLE_COUNTRIES, AVAILABLE_CLINIC_TYPES,
        required, noChineseCharacters, composeValidators} from '../_config';

import { NavBarPage, SideBarPage } from '../HomePage';

import { adminService } from '../_services';
import { adminActions, alertActions } from '../_actions';

import cn from 'classnames';
import { upperFirst, toUpper, find } from 'lodash';

import Spinner from '../_components/Spinner';

import { SelectAdapter, SelectAdapterNonClearable, errorAlert } from '../_components';
import {default as AutoSave} from '../_components/AutoSave'

import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";
import setFieldData from 'final-form-set-field-data';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';

import { FormattedMessage } from 'react-intl';
import localeData from '../../locales/data.json';
import { updateIntl } from 'react-intl-redux';

import max from 'libphonenumber-js/metadata.full.json';

import '../../css/userProfile.style.scss';
import '../../css/organizationSearch.style.scss';
import '../../css/admin.style.scss';

const baseCssClassName = 'UserProfilePage';
const contentWrapperCssClassName = `${baseCssClassName}__ContentWrapper`;
const detailsHeadingCssClassName = `${baseCssClassName}__heading`;
const detailsHeadingTitleCssClassName = `${detailsHeadingCssClassName}__title`;
const detailsHeadingNameCssClassName = `${detailsHeadingCssClassName}__name`;
const formCssClassName = `${baseCssClassName}__form`;
const detailsCssClassName = `${formCssClassName}__details`;
const rowFormCssClassName = `${detailsCssClassName}__row`;
const rowLanguageFormCssClassName = `${rowFormCssClassName}-language`;
const rowEmailFormCssClassName = `${rowFormCssClassName}-emailAddress`;
const rowAddressFormCssClassName = `${rowFormCssClassName}-address`;
const rowPhoneNumberFormCssClassName = `${rowFormCssClassName}-phoneNumber`;

const SavingIndicator = ({ name }) => (
  <Field
    name={name}
    subscription={{ data: true }}
    render={({
      meta: {
        data: { saving }
      }
    }) => (saving ? <div className="saving">Saving</div> : null)}
  />
)

class PhoneAdapter extends React.Component{
  render(){
    const { input, meta, className, label, value, placeholder, intl, name, country, ...rest } = this.props;
    return(
      <div className={className}>
        <label className="Input__Label">{label}</label>
        <PhoneInput
          className={className}
          meta={meta}
          input={input}
          value={input.value}
          displayInitialValueAsLocalNumber={true}
          country={country}
          countries={AVAILABLE_COUNTRIES}
          onChange={value => input.onChange(value)}
          placeholder={placeholder}
          inputClassName={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )}
        />
        <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
          {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
        </div>
        </div>
    )
  }
}

class BaseSearchInput extends React.Component{
  render(){
    return (
      <Select.Async
        {...this.props}
        multi={false}
        backspaceRemoves={true}
      />
    )
  }
}

const orgSearchInputCssClassName = 'OrganizationSearch';

class OrganizationSearchInput extends React.Component{

  handleSearchLoading = (input) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = ADMIN_API_URLS.dentalOrganizationSearch.url+"?q="+input;
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
      return { options: json.search_list };
    });
  }

  render() {
    const { input, meta, className, label, value, placeholder, intl, name, ...rest } = this.props;
    return (
      <div className={className}>
        <label>{label}</label>
        <BaseSearchInput
            className={orgSearchInputCssClassName}
            name={name || "organization"}
            placeholder={placeholder || "organization"}
            value={input.value}
            autoload={false}
            cacheOptions
            defaultOptions
            loadOptions={this.handleSearchLoading}
            onChange={inputValue => input.onChange(inputValue)}
        />
        <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
          {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
        </div>
      </div>
    )
  }
}


const intl8 = {
  messages: {
    'admin.practice.button.save': 'Save Changes',
    'admin.practice.country': 'Country',
    'admin.practice.heading.title': 'Dental Practice Settings',
    'admin.practice.organization': 'Organization',
    'admin.practice.practice_name': 'Practice name',
    'admin.practice.type': 'Practice Type',
    'admin.practice.location_name': 'Branch Name',
    'admin.practice.address': 'Address',
    'admin.practice.email_address': 'Contact Email',
    'admin.practice.phone_number': 'Contact Phone Number',
    'admin.practice.erp.heading': 'erp status',
    'admin.practice.erp.sync_status': 'status',
    'admin.practice.erp.last_sync': 'last sync',
    'admin.practice.erp.model_name': 'model name',
    'admin.practice.erp.x_reference_id': 'reference id',
    'admin.practice.erp.x_reference_id': 'reference id',
  }
}

const erpCssClassName = 'ERPDescriptionBox';
const erpHeaderCssClassName = `${erpCssClassName}__header`;
const erpRowCssClassName = `${erpCssClassName}__row`;
const erpCellCssClassName = `${erpCssClassName}__cell`;

const ERPBox = ({intl, sync_status, last_sync, model_name, x_reference_id}) => (
  <div className={erpCssClassName}>
    <div className={cn(erpHeaderCssClassName,erpRowCssClassName)}>
      <div className={cn('cell', erpCellCssClassName)}>{toUpper(intl8.messages['admin.practice.erp.sync_status'])}</div>
      <div className={cn('cell', erpCellCssClassName)}>{toUpper(intl8.messages['admin.practice.erp.last_sync'])}</div>
      <div className={cn('cell', erpCellCssClassName)}>{toUpper(intl8.messages['admin.practice.erp.model_name'])}</div>
      <div className={cn('cell', erpCellCssClassName)}>{toUpper(intl8.messages['admin.practice.erp.x_reference_id'])}</div>
    </div>
    <div className={cn(erpRowCssClassName)}>
      <div className={cn('cell', erpCellCssClassName)}>{sync_status? 'Synced' : 'Sync Error'}</div>
      <div className={cn('cell', erpCellCssClassName)}>{localeFormatDate(moment.unix(last_sync), intl.locale)}</div>
      <div className={cn('cell', erpCellCssClassName)}>{model_name}</div>
      <div className={cn('cell', erpCellCssClassName)}>{x_reference_id}</div>
    </div>
  </div>
)


const adminBaseCssClassName = 'AdminSettings';
const adminCountryCssClassName = `${adminBaseCssClassName}__country`;
const adminButtonsCssClassName = `${adminBaseCssClassName}__buttons`;
const adminFormSubmitCssClassName = `${adminButtonsCssClassName}__submitButton`;
const erpRowFormCssClassName = `${adminBaseCssClassName}__erpRow`;

class AdminDentalPracticePage extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    console.log("Admin practice page mount")
    this.props.dispatch(adminActions.getPracticeDetails(this.props.match.params.clinic_id))
  }

  onSave = async (values) => {
    console.log(values);
    const { intl } = this.props;
    const convertedValues = convertToAPIValues(values)
    try {
      this.props.dispatch(alertActions.show({type: 'loading'}));
      const result = await adminService.updatePracticeDetails( this.props.match.params.clinic_id, convertedValues)
      this.props.dispatch(adminActions.updatePracticeDetails(result));
      this.props.dispatch(alertActions.success('Saved', 'saved'));
    } catch (err) {
      this.props.dispatch(alertActions.clear());
      if (err.error && err.error.code) {
        return { [FORM_ERROR] : err.error.code }
      }else{
        return err
      }
    }
  }

  render(){
    const { intl, dentalAdmin } = this.props;
    //const { userInfo } = users;
    const initialValues = {
      type: dentalAdmin.currentPracticeDetails.type,
      practice_name: dentalAdmin.currentPracticeDetails.practice_name,
      location_name: dentalAdmin.currentPracticeDetails.location_name,
      address: dentalAdmin.currentPracticeDetails.address,
      phone_number: dentalAdmin.currentPracticeDetails.phone_number,
      email_address: dentalAdmin.currentPracticeDetails.email_address,
      organization: {value: dentalAdmin.currentPracticeDetails.organization && dentalAdmin.currentPracticeDetails.organization.id,
                    label: dentalAdmin.currentPracticeDetails.organization && dentalAdmin.currentPracticeDetails.organization.organization_name
      }
    }
    const erpOdoo = (dentalAdmin.currentPracticeDetails && dentalAdmin.currentPracticeDetails.erp_model && dentalAdmin.currentPracticeDetails.erp_model.odoo) || {};
    return (
      <div className={cn(baseCssClassName, adminBaseCssClassName)}>
        <NavBarPage />
        <SideBarPage location={this.props.location} />
        <div className={contentWrapperCssClassName}>
          <div className={detailsHeadingCssClassName}>
            <div className={detailsHeadingTitleCssClassName}>{toUpper(intl8.messages['admin.practice.heading.title'])}</div>
            <div className={detailsHeadingNameCssClassName}>
            </div>
          </div>
          <div className={formCssClassName}>
            <Form
              onSubmit={this.onSave}
              initialValues = {initialValues}
              mutators={{ setFieldData }}
              render = {({
                submitError,
                formError,
                handleSubmit,
                mutators,
                form,
                reset,
                submitting,
                pristine,
                validating,
                values
              }) => (
                  <form
                    id={'dentalPracticeForm'}
                    onSubmit={handleSubmit}
                  >
                    {/*<AutoSave setFieldData={form.mutators.setFieldData} activeOnChanges={['language']} save={this.onUpdateSettings} />*/}

                    <div className={detailsCssClassName}>
                      <div className={rowFormCssClassName}>
                        <label className={adminCountryCssClassName}>{toUpper(intl8.messages['admin.practice.country'] + ": " + dentalAdmin.currentPracticeDetails.country)}</label>
                      </div>
                      <div className={erpRowFormCssClassName}>
                        <label>{toUpper(intl8.messages['admin.practice.erp.heading']) + ': ODOO'}</label>
                        <ERPBox
                          intl={intl}
                          sync_status={erpOdoo.sync_status}
                          last_sync={erpOdoo.last_sync}
                          model_name={erpOdoo.model_name}
                          x_reference_id={erpOdoo.x_reference_id}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field
                          className={rowLanguageFormCssClassName}
                          name="organization"
                          intl={intl}
                          label={toUpper(intl8.messages['admin.practice.organization'])}
                          component={OrganizationSearchInput}
                          placeholder={upperFirst(intl8.messages['admin.practice.organization'])}
                          autoload={false}
                          cache={false}
                          validate={required}
                          intl={intl}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field
                          className={rowLanguageFormCssClassName}
                          name="type"
                          intl={intl}
                          label={toUpper(intl8.messages['admin.practice.type'])}
                          component={SelectAdapterNonClearable}
                          placeholder={upperFirst(intl8.messages['admin.practice.type'])}
                          options={AVAILABLE_CLINIC_TYPES}
                          autoload={false}
                          cache={false}
                          validate={required}
                          intl={intl}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="practice_name" validate={required}>
                        {({ input, meta}) => (
                          <div className={rowEmailFormCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.practice.practice_name'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.practice.practice_name'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="location_name">
                        {({ input, meta}) => (
                          <div className={rowAddressFormCssClassName}>
                            <label className="Input__Label">{toUpper(intl8.messages['admin.practice.location_name'])}</label>
                            <input {...input} className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched} )} type="text" placeholder={upperFirst(intl8.messages['admin.practice.location_name'])} />
                            <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                              {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                            </div>
                          </div>
                        )}
                        </Field>
                      </div>
                      <div className={rowFormCssClassName}>
                        <Field name="address" validate={required}>
                          {({ input, meta}) => (
                            <div className={rowAddressFormCssClassName}>
                              <label className="Input__Label">{toUpper(intl8.messages['admin.practice.address'])}</label>
                              <textarea
                                {...input}
                                className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
                                rows={10}
                                type="text"
                                placeholder="" />
                              <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
                              </div>
                            </div>
                          )}
                        </Field>

                      </div>
                      <div className={rowFormCssClassName}>
                        <Field
                          className={rowLanguageFormCssClassName}
                          name="phone_number"
                          intl={intl}

                          country={dentalAdmin.currentPracticeDetails.country}
                          label={toUpper(intl8.messages['admin.practice.phone_number'])}
                          component={PhoneAdapter}
                          placeholder={upperFirst(intl8.messages['admin.practice.phone_number'])}
                          validate={required}
                          intl={intl}
                        />
                      </div>
                      <div className={rowFormCssClassName}>
                        <div className={adminButtonsCssClassName}>
                          <button
                            className={adminFormSubmitCssClassName}
                            disabled={submitting || pristine}
                            type="submit"
                          >
                            {toUpper(intl8.messages['admin.practice.button.save'])}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
              )}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { intl, users, dentalAdmin } = state;
    return {
        intl,
        dentalAdmin,
        users
    };
}

const connectedUserAdminDentalPracticePage = withRouter(connect(mapStateToProps)(AdminDentalPracticePage));
export { connectedUserAdminDentalPracticePage as AdminDentalPracticePage };
