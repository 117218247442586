import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import { history, authHeader, handleResponse,
        extractSelectValues, convertToAPIDate, convertToAPIValues, formatPatientSearchString} from '../_helpers';
import { confirmAlert } from 'react-confirm-alert';
import { upperFirst, toUpper, transform, pickBy, some, mapValues, cloneDeep } from 'lodash';
import moment from 'moment';
import 'moment-timezone';

import Spinner from '../_components/Spinner';
import cn from 'classnames';
import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";

import {ToggleButtonGroup, ToggleButton} from 'react-bootstrap'

import queryString from 'query-string';

import { NavBarPage, SideBarPage, Breadcrumb } from '../HomePage';
import { FormattedMessage } from 'react-intl';

import FontAwesome from 'react-fontawesome';
import TrackVisibility from 'react-on-screen';

import {
  CustomFormControlLabel,
  CustomCheckbox,
  Switch
} from '../_components';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';
// Include the locale utils designed for moment
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import DropzoneComponent from '../DropzoneComponent';
import { ImageSetUpload } from '../ImageSetUpload';
import { SelectAdapter, SelectAdapterNonClearable, errorAlert } from '../_components';

import {
  URLS, BASE_URL, AGE_RANGE, GENDERS, JAW_CHOICES, API_URLS, REQUIRED_IMAGES, MOMENT_DATE_FORMAT, API_DATETIME_FORMAT,
  required, mustBeNumber, minValue, composeValidators, noChineseCharacters,
  initState_formRequiredImages, initState_imageForm } from '../_config';
import { consultRequestsService } from '../_services';

import Add from '@material-ui/icons/Add';
import Cancel from '@material-ui/icons/Cancel';

import '../../css/newConsultRequest.style.scss';
import '../../css/forms.style.scss';

const baseCssClassName = "NewConsultRequest";
const contentWrapperCssClassName = `${baseCssClassName}__ContentWrapper`;
const detailHeaderCssClassName = `${baseCssClassName}__DetailHeader`;
const formCssClassName = `${baseCssClassName}__form`;
const detailsCssClassName = `${formCssClassName}__details`;
const rowFormCssClassName = `${detailsCssClassName}__row`;
const toggleButtonGroupCssClassName = `${formCssClassName}__toggleButtonGroup`;
const toggleButtonCssClassName = `${toggleButtonGroupCssClassName}__toggleButton`;
const checkBoxLabelCssClassName = `${formCssClassName}__Checkbox__Label`;
const leftColumnFormCssClassName = `${detailsCssClassName}__leftColumn`;
const leftColumnFormRowCssClassName = `${leftColumnFormCssClassName}__row`;
const leftColumnFormHalfRowCssClassName = `${leftColumnFormCssClassName}__halfrow`;
const rightColumnFormCssClassName = `${detailsCssClassName}__rightColumn`;
const formSubmitCssClassName = `${formCssClassName}__actions`;
const cancelButtonCssClassName = `${formSubmitCssClassName}__cancelButton`;
const submitButtonCssClassName = `${formSubmitCssClassName}__submitButton`;

const requestTypeFormCssClassName = 'RequestTypeForm';
const requestTypeRowCssClassName = `${requestTypeFormCssClassName}__row`;
const requestTypeButtonRowCssClassName = `${requestTypeRowCssClassName}__buttons`;
const requestTypeTitleCssClassName = `${requestTypeFormCssClassName}__title`;
const requestTypeButtonCssClassName = `${requestTypeFormCssClassName}__button`;
const requestTypeButtonTextCssClassName = `${requestTypeButtonCssClassName}__text`;

function formatDate1(date, format = 'L', locale = 'th-th') {
  const mDate = moment(date)
  const localMDate = mDate.locale("th-th");
  const formattedDate = localMDate.format("L");
  console.log("mDate", mDate);
  console.log("locale", locale)
  console.log("formattedDate", formattedDate)
  return moment(date)
    .locale(locale)
    .format(Array.isArray(format) ? format[0] : format);
}

class PatientSearchInput extends React.Component{
	render () {
		const AsyncComponent = false
			? Select.AsyncCreatable
			: Select.Async;
		return (
				<Select.Async
					{...this.props}
					multi={false}
					backspaceRemoves={true}
				/>
		);
	}
};

const SelectSearchAdapter = ({input, initValue, meta, values, label, intl, className, disabled, loadOptions, onChange, onValueClick, ...rest }) => (
  <div className={className}>
    <label>{label}</label>
    <PatientSearchInput
      {...input}
      className={ cn("SearchInput", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
      loadOptions={loadOptions}
      placeholder={upperFirst(intl.messages['newRequestForm.patient.search'])}
      onChange={inputValue => input.onChange(inputValue || undefined )}
      onValueClick={onValueClick}
      autoload={false}
      cache={false}
      disabled={disabled}
      clearable={true}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
    />
    <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
      { ( (meta.error && meta.touched)  || meta.submitError ) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
    </div>
  </div>
)

const DayPickerAdapter = ({input, meta, items, label, intl, className, disabled, ...rest }) => {
  return (
    <div className={className}>
      <label>{label}</label><br />
      <DayPickerInput
        inputProps = {{...input, autoComplete: "off",disabled: disabled, className:cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}}
        onDayChange={day=> input.onChange(day)}
        formatDate={formatDate}
        parseDate={parseDate}
        format={MOMENT_DATE_FORMAT || "L"}
        dayPickerProps={{
          locale: intl.locale,
          localeUtils: MomentLocaleUtils,
        }}
        locale = {intl.locale}
        value = {input.value}
        className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
        placeholder={`${formatDate(new Date(), MOMENT_DATE_FORMAT || "L", intl.locale)}`}
      />
      <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
        {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
      </div>
    </div>
  )}

const DateTimePickerAdapter = ({input, meta, items, label, intl, className, disabled, ...rest}) => {
  return (
    <div className={className}>
      <label>{label}</label><br />
      <DateTimePicker
        className={cn("bdDateTimePicker", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
        onChange={val=> {input.onChange(val)}}
        value={input.value}
        locale={intl.locale}
      />
      <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
        {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
      </div>
    </div>
  )
}

const TimePickerAdapter = ({input, meta, items, label, intl, className, disabled, ...rest}) => {
  const format = 'h:mm a';
  return (
      <div className={className}>
        <label>{label}</label><br />
        <TimePicker
          showSecond={false}
          className={'bdTimePicker'}
          onChange={value => input.onChange(value)}
          format={format}
          use12Hours
          inputReadOnly
         />
        <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
          {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
        </div>
      </div>
  )}

const SelectAdapter1 = ({input, meta, items, label, intl, className, disabled, ...rest }) => (
  <div className={className}>
    <label>{label}</label>
    <Select
      {...input}
      className={ cn({"Input_Error": meta.error && meta.touched})}
      onChange={inputValue => input.onChange(inputValue)}
      options={items}
      disabled={disabled}
    />
    <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
      {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
    </div>
  </div>

)

const saveDetails = (form, handleResponse) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() }
  };
  let url = '/d/requests';

  return fetch(BASE_URL+url, requestOptions).then(handleResponse);
}

// NOTE: just test
const initState_newRequest = {
  "patient_name": "8763793722",
  "created_at": "2019-01-22T15:40:51.620Z",
  "birthdate_range": "1995",
  "gender": "male",
  "description": "New aligners for this patient.",
  "patient_id": 5,
  "id": 2
}


class NewConsultRequestPage extends React.Component {
  constructor(props){
    super(props);
    const urlParams = queryString.parse(this.props.location.search);
    this.state = {
      detailsSaved: false,
      requestType: "",
      currentPage: -1,
      //currentPage: 1,
      initialPatient: urlParams.pid || undefined,
      newRequest: initState_newRequest,
      imageForm: initState_imageForm,
      requiredImages: initState_formRequiredImages,
      errorImages: [],
      returningPatient: undefined
    }
  }

  componentDidMount(){
    console.log("mounting NEWCONSULTREQUESTPAGE")
  }

  /**
   * check to see all required images have been uploaded
   */
  requiredImageCheck(){
    const currentImageForm = this.state.imageForm;
    const currentRequiredImages = this.state.requiredImages;

    REQUIRED_IMAGES.map(imageGroup => {
      currentRequiredImages[imageGroup] = currentImageForm[imageGroup];
    });

    var errorImages = transform(currentRequiredImages, function(result, value, key){
      if (value === false) result.push(key);
    }, []);
    this.setState({requiredImages: currentRequiredImages, errorImages:errorImages})
    return errorImages;
  }

  /** react-final-form
   * function for react-final-form. goes to next page (image upload) if
   * successful
   */
  onSubmitDetails = async values => {
    const { intl, settings } = this.props;
    const dpid = settings.adminCurrentDentalPractice && settings.adminCurrentDentalPractice.value

    const valuesCopy = cloneDeep(values);
    if (valuesCopy.appointment_date){
      //const newDate = moment.tz(moment(values.appointment_date.toISOString()).format('YYYY-MM-DD HH:mm'), "Asia/Bangkok")
      let newDate = moment(valuesCopy.appointment_date).format(API_DATETIME_FORMAT);
      valuesCopy.appointment_date = newDate
    }

    const convertedValues = convertToAPIValues(valuesCopy)
    var result;
    /* bypass validation checks for now...
    // TODO: if patient_id is valid, then rest of form doesn't need to be filled out
    var return_error = {};
    return_error['description'] = required(convertedValues.description)
    if (!((typeof convertedValues.id === "number") || convertedValues.first_name ||
          convertedValues.last_name || convertedValues.birthdate ||convertedValues.gender)){
      // check patient field
      return_error['first_name'] = required(convertedValues.first_name)
      return_error['last_name'] = required(convertedValues.last_name)
      return_error['birthdate'] = required(convertedValues.birthdate)
      return_error['gender'] = required(convertedValues.gender)
      return_error['id'] = 'select_patient'
    } else if ( (convertedValues.first_name || convertedValues.last_name ||
                convertedValues.birthdate ||convertedValues.gender) && ((typeof values.id) !== "number") ){
      // check new patient fields since no id is picked
      return_error['first_name'] = required(convertedValues.first_name)
      return_error['last_name'] = required(convertedValues.last_name)
      return_error['birthdate'] = required(convertedValues.birthdate)
      return_error['gender'] = required(convertedValues.gender)
    }
    if (some(return_error, (o) => {return (o !== undefined) })){
      console.log("THERE WAS AN ERROR. returning error now.")
      return return_error
    }
    */

    // API call
    try{
      result = await consultRequestsService.createNewRequest(convertedValues, dpid)
      console.log("submit result",result)
      this.setState({currentPage: 1, newRequest: result.new_request });
      return
    } catch (err) {
      if (err.error && err.error.code) {
        return {[FORM_ERROR] : err.error.code }
      }else{
        errorAlert(intl)
        return err
        //return err
        /*confirmAlert({
          title: 'Creation Failed',
          message: 'There was an unexpected error on our end. Please try again.',
          buttons: [
            {
              label: 'OK. I will',
            }
          ]
        })*/
      }

      // 1) patient already has an active request
      // 2) form error

    }
  }

  /** IMAGE UPLOAD PAGE
   * Called after image thumbnail has been removed from upload
   **/
  onDelete = (imageKey, uploadId) => {
    const { intl } = this.props;
    const data ={upload_id: uploadId};
    consultRequestsService.removeUpload(data)
      .then(
        data => {
          // don't need to confirm delete really,
          const currentImageForm = this.state.imageForm;
          currentImageForm[imageKey] = false;
          this.setState({ imageForm: currentImageForm });
        },
        error => {
          // if there was an error, it would have to pop back up
          // maybe do it on reload.
        }
      )
  }

  /** IMAGE UPLOAD PAGE
   * Called when image has been uploaded
   **/
  handleImageUpload = (imageKey) => {
    const currentImageForm = this.state.imageForm;
    currentImageForm[imageKey] = true;
    this.setState({ imageForm: currentImageForm });
  }

  handleSearchChange = (value) => {
    console.log(this.props)
    console.log("searchChange, ", value)
    //history.push(this.props.location)
    //window.location.href = "/"+value.value
    this.setState({query: value})
    //this.props.dispatch(settingsActions.setSettingValue({adminCurrentDentalPractice: value}));
    //history.push("/")
  }

  handleSearchClick = (value, event) => {
    console.log(value)
    //this.props.dispatch(settingsActions.setSettingValue({adminCurrentDentalPractice: value}))
    //window.location.href= optionVal;
  }

  handleSearchLoading = (input) => {
    const { intl } = this.props;
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    let url = API_URLS.dentalPatientSearch.url+"?q="+input;
    return fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        const options = json.search_list.map((obj, ind) => {
          return {value: obj.value,
                  label:formatPatientSearchString(intl.messages['string.patientSearch'],
                                                  obj.first_name,
                                                  obj.last_name,
                                                  obj.birthdate,
                                                  intl.locale)
          }
        })
        console.log("search options", options)
        return { options };
      });
  }

  handlePatientToggle = (val) => {
    this.setState({returningPatient: val})
  }

  /** IMAGE UPLOAD PAGE
   * Click on button to complete form and change status
   * from initialized->requested
   **/
  handleCompleteClick = () => {
    // need to check to see if all required images have been uploaded
    // this submits form as active
    // request goes from incomplete: 0 -> requested
    const errorImages = this.requiredImageCheck();
    console.log("current staet of images", this.state.requiredImages)



    //if (errorImages.length > 0) {
      // there are errors
    if (errorImages.length > 0){
      // NOTE: changes state and highlights boxes
      return
    } else {
      // no errors so send request to server
      consultRequestsService.updateRequestStatus(this.state.newRequest.id, {status: 1})
        .then(
          data => {
            // params tb={tab}, newly created request?,
            const tab = 'reports';
            history.push(URLS.patientPage.replace('{patientId}', this.state.newRequest.patient_id),+`?tb=${tab}`);
          },
          error => {
            // TODO: then i dunno, retry

          }
        )
    }
  }

  _renderRequestTypeForm = () => {
    const { intl } = this.props;

    return (
      <div className={cn(formCssClassName, requestTypeFormCssClassName)}>
        <div className={requestTypeRowCssClassName}>
          <div className={requestTypeTitleCssClassName}>
            {upperFirst(intl.messages['newRequestForm.request.type.title'])}
          </div>
        </div>
        <div className={requestTypeButtonRowCssClassName}>
            <button
              className={cn('Button', requestTypeButtonCssClassName)}
              onClick={() => this.handleTypeClick('ALIGNERS_LITE')}>
              <div className={requestTypeButtonTextCssClassName}>
                {toUpper(intl.messages['newRequestForm.request.type.lite'])}
              </div>
            </button>
            <button
              className={cn('Button', requestTypeButtonCssClassName)}
              onClick={() => this.handleTypeClick('ALIGNERS')}>
              <div className={requestTypeButtonTextCssClassName}>
                {toUpper(intl.messages['newRequestForm.request.type.pro'])}
              </div>
            </button>
            {/*}<button
              className={cn('Button', requestTypeButtonCssClassName)}
              onClick={this.handleTypeClick('RETAINERS')} >
              {toUpper(intl.messages['newRequestForm.request.type.retainer'])}
            </button>*/}
          </div>
      </div>
    )
  }

  /**
   * Render when currentPage = 0, enter patient details form and send to server
   * upon completion
   */
  _renderDetailsForm = () => {
    const { intl, currentPatient }  = this.props;
    const initialValue = {jaw: 'BOTH', type: this.state.requestType};
    const cancelUrl = (this.state.initialPatient) ? URLS.patientPage.replace('{patientId}',this.state.initialPatient) : URLS.home;
    // setting up intial patient stuff
    if (parseInt(this.state.initialPatient)){
      if (currentPatient.id === parseInt(this.state.initialPatient)){
        initialValue['id'] = {value: currentPatient.id, label: formatPatientSearchString(intl.messages['string.patientSearch'], currentPatient.first_name, currentPatient.last_name, currentPatient.birthdate , intl.locale)}
      }
    }
    if (this.state.requestType === 'ALIGNERS_LITE'){
      return (
        <div>
          <div className={formCssClassName}>
            <Form
              onSubmit={this.onSubmitDetails}
              initialValues = {initialValue}
              //mutators={{ setFieldData }}
              render = {({
                submitError,
                formError,
                handleSubmit,
                mutators,
                form,
                reset,
                submitting,
                pristine,
                validating,
                values
              }) => (
                <form
                  id={'detailsForm'}
                  onSubmit={handleSubmit}
                >
                  <div className={detailsCssClassName}>
                    {/*<AutoSave form={form} setFieldData={form.mutators.setFieldData} save={this.save} />*/}
                    {validating && <Spinner />}
                    <div className={rowFormCssClassName}>
                      {/*<label className={checkBoxLabelCssClassName}>{toUpper(intl.messages['string.newRequestForm.attachments'])}</label>
                      <Field
                        name="aligner_attachments"
                        component="input"
                        type="checkbox"
                      />*/}
                      <Field name="type">
                        {({ input, meta}) => (
                          <div className="">
                            <input {...input} type="hidden" />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className={rowFormCssClassName}>
                      <div className={leftColumnFormCssClassName}>
                        <div className={leftColumnFormHalfRowCssClassName}>
                          <div className={''}>
                            <label>{toUpper(intl.messages['newRequestForm.patient.returningPatient.label'])}</label>
                            <ToggleButtonGroup
                              type='radio'
                              name="returningPatient"
                              value={this.state.returningPatient}
                              className={toggleButtonGroupCssClassName}
                              onChange={this.handlePatientToggle}>
                              <ToggleButton value={true} className={toggleButtonCssClassName}>{toUpper(intl.messages['string.yes'])}</ToggleButton>
                              <ToggleButton value={false} className={toggleButtonCssClassName}>{toUpper(intl.messages['string.no'])}</ToggleButton>
                            </ToggleButtonGroup>
                          </div>
                          <Field
                            className={leftColumnFormRowCssClassName}
                            name="id"
                            intl={intl}
                            values = {values}
                            disabled={this.state.returningPatient === false}
                            initValue = {values.id}
                            label={toUpper(intl.messages['newRequestForm.patient.search.label'])}
                            component={SelectSearchAdapter}
                            placeholder={intl.messages['newRequestForm.patient.search']}
                            intl={intl}
                            loadOptions={this.handleSearchLoading}
                            onChange={this.handleSearchChange}
                            onValueClick={this.handleSearchClick}
                          />
                        </div>
                        <div className={leftColumnFormHalfRowCssClassName}>
                          <Field name="first_name" parse={v => v}>
                            {({ input, meta}) => (
                              <div className={''}>
                                <label className="Input__Label">{toUpper(intl.messages['newRequestForm.patient.first_name'])}</label>
                                <input {...input} disabled={this.state.returningPatient === true} className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} type="text" placeholder={upperFirst(intl.messages['newRequestForm.patient.first_name'])} />
                                <div className={cn("Error__Label", {"Error": (meta.error && meta.touched || meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                                </div>
                              </div>
                            )}
                          </Field>
                          <Field name="last_name">
                            {({ input, meta}) => (
                              <div className={''}>
                                <label className="Input__Label">{toUpper(intl.messages['newRequestForm.patient.last_name'])}</label>
                                <input {...input} disabled={this.state.returningPatient === true} className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} type="text" placeholder={upperFirst(intl.messages['newRequestForm.patient.last_name'])} />
                                <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                                </div>
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className={leftColumnFormHalfRowCssClassName}>
                          <Field name="phone_number">
                            {({ input, meta}) => (
                              <div className={''}>
                                <label className="Input__Label">{toUpper(intl.messages['userProfile.phoneNumber'])}</label>
                                <input {...input} disabled={this.state.returningPatient === true} className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} type="text" placeholder={upperFirst(intl.messages['userProfile.phoneNumber'])} />
                                <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                                </div>
                              </div>
                            )}
                          </Field>
                          <Field name="email_address">
                            {({ input, meta}) => (
                              <div className={''}>
                                <label className="Input__Label">{toUpper(intl.messages['userProfile.emailAddress'])}</label>
                                <input {...input} disabled={this.state.returningPatient === true} className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} type="text" placeholder={upperFirst(intl.messages['userProfile.emailAddress'])} />
                                <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                                </div>
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className={leftColumnFormHalfRowCssClassName}>
                          <Field
                            className={leftColumnFormRowCssClassName}
                            name="birthdate"
                            intl={intl}
                            autoComplete={"off"}
                            disabled={this.state.returningPatient === true}
                            label={toUpper(intl.messages['newRequestForm.patient.birthdate'])}
                            component={DayPickerAdapter}
                            placeholder={upperFirst(intl.messages['newRequestForm.patient.birthdate'])}
                          />
                          <Field
                            className={leftColumnFormRowCssClassName}
                            name="gender"
                            items={GENDERS}
                            intl={intl}
                            disabled={this.state.returningPatient === true}
                            label={toUpper(intl.messages['string.gender'])}
                            component={SelectAdapterNonClearable}
                            placeholder={upperFirst(intl.messages['string.gender'])}
                          />
                        </div>
                        <div className={leftColumnFormHalfRowCssClassName}>
                          <Field
                            className={''}
                            name="appointment_date"
                            intl={intl}
                            autoComplete={"off"}
                            label={toUpper(intl.messages['newRequestForm.appointment_date'])}
                            component={DateTimePickerAdapter}
                            placeholder={upperFirst(intl.messages['newRequestForm.appointment_date'])}
                          />

                        </div>
                      </div>
                      <div className={rightColumnFormCssClassName}>
                        <Field name="jaw">
                          {({ input, meta}) => (
                            <div>
                              <label>{toUpper(intl.messages['newRequestForm.jaw.label'])}</label><br />
                              <ToggleButtonGroup
                                type='radio'
                                name='jaw'
                                value={input.value}
                                className={toggleButtonGroupCssClassName}
                              >
                                {JAW_CHOICES.map( (option, index) => (
                                  <Field key={option.value} type={'radio'} name={'jaw'} value={option.value}>
                                    {({input}) => (
                                      <ToggleButton
                                        value={option.value}
                                        name={'jaw'}
                                        className={cn(toggleButtonCssClassName, `${toggleButtonCssClassName}-three` )}
                                        checked={input.checked}
                                        onChange={input.onChange}
                                        type={'radio'}
                                      >
                                        {toUpper(intl.messages[`newRequestForm.jaw.${option.intlKey}`])}
                                      </ToggleButton>
                                    )}
                                  </Field>
                                ))}
                              </ToggleButtonGroup>
                              <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
                              </div>
                            </div>
                          )}
                        </Field>
                        <Field name="description">
                          {({ input, meta}) => (
                            <div>
                              <label className="Input__Label">{toUpper(intl.messages['string.newRequestForm.information'])}</label>
                              <textarea
                                {...input}
                                className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
                                rows={10}
                                type="text"
                                placeholder="" />
                              <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
                              </div>
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className={cn("Error__Label", {"Error": (submitError)} )} >
                    {submitError ? intl.messages[`form.error.${submitError}`] : undefined }
                  </div>
                </form>

              )}
            />
          </div>
          <div className={formSubmitCssClassName}>
            <Link to={cancelUrl}>
              <button
                className={cancelButtonCssClassName}>
                {toUpper(intl.messages['string.cancel'])}
                </button>
            </Link>
            <button
              className={submitButtonCssClassName}
              onClick={() => document.getElementById('detailsForm').dispatchEvent(new Event('submit', { cancelable: true }))} >
              {toUpper(intl.messages['newRequestForm.submitDetailsButton'])}
            </button>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className={formCssClassName}>
            <Form
              onSubmit={this.onSubmitDetails}
              initialValues = {initialValue}
              //mutators={{ setFieldData }}
              render = {({
                submitError,
                formError,
                handleSubmit,
                mutators,
                form,
                reset,
                submitting,
                pristine,
                validating,
                values
              }) => (
                <form
                  id={'detailsForm'}
                  onSubmit={handleSubmit}
                >
                  <div className={detailsCssClassName}>
                    {/*<AutoSave form={form} setFieldData={form.mutators.setFieldData} save={this.save} />*/}
                    {validating && <Spinner />}
                    <div className={rowFormCssClassName}>
                      {/*<label className={checkBoxLabelCssClassName}>{toUpper(intl.messages['string.newRequestForm.attachments'])}</label>
                      <Field
                        name="aligner_attachments"
                        component="input"
                        type="checkbox"
                      />*/}
                      <CustomFormControlLabel
                        label={toUpper(intl.messages['string.newRequestForm.attachments'])}
                        labelPlacement = {'start'}
                        control={
                          <Field
                            name="aligner_attachments"
                            component={CustomCheckbox}
                            type="checkbox"
                          />
                        }
                      />
                    </div>
                    <div className={rowFormCssClassName}>
                      <div className={leftColumnFormCssClassName}>
                        <div className={leftColumnFormHalfRowCssClassName}>
                          <div className={''}>
                            <label>{toUpper(intl.messages['newRequestForm.patient.returningPatient.label'])}</label>
                            <ToggleButtonGroup
                              type='radio'
                              name="returningPatient"
                              value={this.state.returningPatient}
                              className={toggleButtonGroupCssClassName}
                              onChange={this.handlePatientToggle}>
                              <ToggleButton value={true} className={toggleButtonCssClassName}>{toUpper(intl.messages['string.yes'])}</ToggleButton>
                              <ToggleButton value={false} className={toggleButtonCssClassName}>{toUpper(intl.messages['string.no'])}</ToggleButton>
                            </ToggleButtonGroup>
                          </div>
                          <Field
                            className={leftColumnFormRowCssClassName}
                            name="id"
                            intl={intl}
                            values = {values}
                            disabled={this.state.returningPatient === false}
                            initValue = {values.id}
                            label={toUpper(intl.messages['newRequestForm.patient.search.label'])}
                            component={SelectSearchAdapter}
                            placeholder={intl.messages['newRequestForm.patient.search']}
                            intl={intl}
                            loadOptions={this.handleSearchLoading}
                            onChange={this.handleSearchChange}
                            onValueClick={this.handleSearchClick}
                          />
                        </div>
                        <div className={leftColumnFormHalfRowCssClassName}>
                          <Field name="first_name" parse={v => v}>
                            {({ input, meta}) => (
                              <div className={''}>
                                <label className="Input__Label">{toUpper(intl.messages['newRequestForm.patient.first_name'])}</label>
                                <input {...input} disabled={this.state.returningPatient === true} className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} type="text" placeholder={upperFirst(intl.messages['newRequestForm.patient.first_name'])} />
                                <div className={cn("Error__Label", {"Error": (meta.error && meta.touched || meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                                </div>
                              </div>
                            )}
                          </Field>
                          <Field name="last_name">
                            {({ input, meta}) => (
                              <div className={''}>
                                <label className="Input__Label">{toUpper(intl.messages['newRequestForm.patient.last_name'])}</label>
                                <input {...input} disabled={this.state.returningPatient === true} className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} type="text" placeholder={upperFirst(intl.messages['newRequestForm.patient.last_name'])} />
                                <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched  || meta.submitError && !meta.dirtySinceLastSubmit) && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                                </div>
                              </div>
                            )}
                          </Field>
                        </div>
                        <div className={leftColumnFormHalfRowCssClassName}>
                          <Field
                            className={leftColumnFormRowCssClassName}
                            name="birthdate"
                            intl={intl}
                            autoComplete={"off"}
                            disabled={this.state.returningPatient === true}
                            label={toUpper(intl.messages['newRequestForm.patient.birthdate'])}
                            component={DayPickerAdapter}
                            placeholder={upperFirst(intl.messages['newRequestForm.patient.birthdate'])}
                          />
                          <Field
                            className={leftColumnFormRowCssClassName}
                            name="gender"
                            items={GENDERS}
                            intl={intl}
                            disabled={this.state.returningPatient === true}
                            label={toUpper(intl.messages['string.gender'])}
                            component={SelectAdapterNonClearable}
                            placeholder={upperFirst(intl.messages['string.gender'])}
                          />
                        </div>
                      </div>
                      <div className={rightColumnFormCssClassName}>
                        <Field name="jaw">
                          {({ input, meta}) => (
                            <div>
                              <label>{toUpper(intl.messages['newRequestForm.jaw.label'])}</label><br />
                              <ToggleButtonGroup
                                type='radio'
                                name='jaw'
                                value={input.value}
                                className={toggleButtonGroupCssClassName}
                              >
                                {JAW_CHOICES.map( (option, index) => (
                                  <Field key={option.value} type={'radio'} name={'jaw'} value={option.value}>
                                    {({input}) => (
                                      <ToggleButton
                                        value={option.value}
                                        name={'jaw'}
                                        className={cn(toggleButtonCssClassName, `${toggleButtonCssClassName}-three` )}
                                        checked={input.checked}
                                        onChange={input.onChange}
                                        type={'radio'}
                                      >
                                        {toUpper(intl.messages[`newRequestForm.jaw.${option.intlKey}`])}
                                      </ToggleButton>
                                    )}
                                  </Field>
                                ))}
                              </ToggleButtonGroup>
                              <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
                              </div>
                            </div>
                          )}
                        </Field>
                        <Field name="description">
                          {({ input, meta}) => (
                            <div>
                              <label className="Input__Label">{toUpper(intl.messages['string.newRequestForm.information'])}</label>
                              <textarea
                                {...input}
                                className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
                                rows={10}
                                type="text"
                                placeholder="" />
                              <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
                                  {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
                              </div>
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                  <div className={cn("Error__Label", {"Error": (submitError)} )} >
                    {submitError ? intl.messages[`form.error.${submitError}`] : undefined }
                  </div>
                </form>

              )}
            />
          </div>
          <div className={formSubmitCssClassName}>
            <Link to={cancelUrl}>
              <button
                className={cancelButtonCssClassName}>
                {toUpper(intl.messages['string.cancel'])}
                </button>
            </Link>
            <button
              className={submitButtonCssClassName}
              onClick={() => document.getElementById('detailsForm').dispatchEvent(new Event('submit', { cancelable: true }))} >
              {toUpper(intl.messages['newRequestForm.submitDetailsButton'])}
            </button>
          </div>
        </div>
      )
    }
  }



  /**
   * Render when currentPage = 1, upload all required images. Requires request_id
   * that the was created on from previous patient details step
   */
  _renderImageUploadForm = () => {
    const { intl } = this.props;

    return (
      <ImageSetUpload
        intl = {intl}
        onSuccess = {this.handleImageUpload}
        onDelete = {this.onDelete}
        handleCompleteClick = {this.handleCompleteClick}
        consultRequestId = {this.state.newRequest.id}
        patientId = {this.state.newRequest.patient_id}
        errorImages = {this.state.errorImages}
        className={'ImageSetUpload'}
      />
    )
  }

  handleTypeClick = (requestType) => {
    this.setState({requestType: requestType, currentPage: 0})
  }

  render(){
    const { intl }  = this.props;
    console.log("gettinga ll props", this.props);
    console.log("getting all the state",  this.state);
    // probably should move required outside of render. get intl.message another way
    return (
      <div className={baseCssClassName}>
				<NavBarPage />
				<SideBarPage location={this.props.location} />
        <div className={contentWrapperCssClassName}>
          <div className={''}>
            <Breadcrumb
              currentStep={this.state.currentPage}
            />
          </div>
          { (this.state.currentPage === -1) &&  this._renderRequestTypeForm() }
          { (this.state.currentPage === 0) &&  this._renderDetailsForm() }
          { (this.state.currentPage === 1) && this._renderImageUploadForm() }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { intl, patientRequests, settings } = state;

    return {
        intl,
        settings,
        currentPatient: patientRequests.patient
    };
}

const connectedNewConsultRequestPage = withRouter(connect(mapStateToProps)(NewConsultRequestPage));
export { connectedNewConsultRequestPage as NewConsultRequestPage };
