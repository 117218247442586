import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Add from '@material-ui/icons/Add';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { withStyles } from '@material-ui/core/styles';

// default is based on nav bar avatar
const stylesCustomAvatar = {
  default: {
    width: 130,
    height: 130,
    color: "#273238",
    marginRight: 15,
    display: "inline-block"
  },
  square: {
    width: 130,
    height: 130,
    color: "#273238",
    marginRight: 15,
    display: "inline-block"
  },
  mini: {
    width: 40,
    height: 40,
    color: "#273238",
    marginRight: 6,
    float: "left",
    display: "inline-block"
  }
}

class CustomAvatar extends Component {
  render() {
    const { classes, name, src, size } = this.props;
    console.log("avatar", src)
    if (src != "" || src !== undefined || src !== null) {
      let names = name.split(" ");
      return (
        <Avatar className={classes[size]}>{names[0][0] + " " + names[names.length - 1][0]}</Avatar>
      )
    } else {
      return (
        <Avatar className={classes[size]} src={src} alt={name}></Avatar>
      )
    }
  }
}

const stylesCustomButton = {
	root: {
  		borderRadius: 30
	},
	label: {
		fontSize: 12,
		fontWeight: 600
	}
}

class CustomButton extends Component {
	render(){
		return(
			<Button {...this.props } disableRipple>
				{this.props.children}
			</Button>
		)
	}
}

const stylesCustomDialog = {
  paper: {
    backgroundColor: "#FAFAFA",
    width: 700,
    padding: "15px 25px"
  }
}

const stylesCustomDialogLarge = {
  paper: {
    backgroundColor: "#FAFAFA",
    width: 1000,
    padding: "15px 25px"
  }
}

class CustomDialog extends Component {
  render(){
    return <Dialog maxWidth={""} {...this.props}>{this.props.children}</Dialog>
  }
}

const stylesCustomDialogTitle = {
	root: {
		fontSize:18,
		minWidth:350,
    backgroundColor: "#FAFAFA"
	}
}

class CustomDialogTitle extends Component {
	render(){
		return <DialogTitle {...this.props}>{this.props.children}</DialogTitle>
	}
}

const stylesCustomDialogContent = {
	root: {
    paddingBottom:0
	}
}

class CustomDialogContent extends Component {
	render(){
		return <DialogContent {...this.props}>{this.props.children}</DialogContent>
	}
}

const stylesCustomDialogContentText = {
	root: {
		fontSize:14,
		marginBottom:10,
    paddingBottom:0
	}
}

class CustomDialogContentText extends Component {
	render(){
		return <DialogContentText {...this.props}>{this.props.children}</DialogContentText>
	}
}

const stylesCustomDialogActions = {
	root: {
		paddingRight:24,
		paddingBottom:10
	}
}

class CustomDialogActions extends Component {
	render(){
		return <DialogActions {...this.props}>{this.props.children}</DialogActions>
	}
}

const stylesProcedureChip = {
	svgIcon: {
		backgroundColor: '#526A74',
		fontSize:14
	},
	chip: {
		backgroundColor: '#768F99',
		fontSize:14,
		color: 'white',
		marginRight:20,
		marginBottom: 15
	},
	label: {
		padding: '0 20px'
	}
}

class ProcedureChip extends Component{
	render(){
		const { classes, svgLabel, ...restProps }  = this.props;
		return (
			<Chip
		        avatar={<Avatar className={classes.svgIcon}>{svgLabel}</Avatar>}
		        className={classes.chip}
		        {...restProps }
      		/>
      	)
	}
}

const stylesSelectedProcedureChip = {
	svgIcon: {
		backgroundColor: '#3B70FF',
		fontSize:14
	},
	chip: {
		backgroundColor: '#768F99',
		fontSize:14,
		color: 'white',
		marginRight:20,
		marginBottom: 15
	},
	label: {
		padding: '0 20px'
	}
}

class SelectedProcedureChip extends Component{
	render(){
		const { classes, svgLabel, ...restProps }  = this.props;
		return (
			<Chip
		        avatar={<Avatar className={classes.svgIcon}>{svgLabel}</Avatar>}
		        className={classes.chip}
		        {...restProps }
      		/>
      	)
	}
}

const stylesAddProcedureChip = {
	svgIcon: {
		backgroundColor: '#DADADA',
		fontSize:14
	},
	chip: {
		backgroundColor: '#F5F5F5',
		fontSize:14,
		color: 'black',
		marginRight:20,
		marginBottom: 20
	}
}

class AddProcedureChip extends Component{
	render(){
		const { classes, ...restProps }  = this.props;
		return (
			<Chip
		        avatar={<Avatar className={classes.svgIcon} ><Add /></Avatar>}
		        className={classes.chip}
		        { ...restProps }
      		/>
      	)
	}
}

const stylesCustomListItem = {
	root: {
		fontSize:16,
		//backgroundColor: '#FCFEFF'
	},
}

class CustomListItem extends Component{
	render(){
		const { classes, selected, onClick,  ...restProps }  = this.props;
		var style = {};
		if (selected){
			style = {backgroundColor:'#FCFEFF'}
		}
		return (
			<ListItem button disableRipple
				classes={classes}
				style={style}
				onClick={onClick}
			>
				<ListItemText
					disableTypography
					{...restProps}
				/>
			</ListItem>
		)
	}
}

const stylesCustomFormControlLabel = {
  label: {
    fontFamily:'Proxima Nova Semibold',
  	fontSize:14,
    letterSpacing: 0.9,
    color: '#7a8f99'
    //backgroundColor: '#FCFEFF'
  }
}

class CustomFormControlLabel extends Component{
  render(){
    const {classes, ...restProps} = this.props;

    return (
      <FormControlLabel
				classes={classes}
        disableTypography
        {...restProps}
      />
    )
  }
}

const stylesCustomCheckbox = {
  label: {
    fontFamily:'Proxima Nova',
  	fontSize:14,
    //backgroundColor: '#FCFEFF'
  },
}

const blueSecondaryTheme = createMuiTheme({
  palette: {
    secondary: {
      main: '#367fff'
    }
  }
})

class CustomCheckbox extends Component{
  render(){
    const {classes, ...restProps} = this.props;

    return (
      <MuiThemeProvider theme={blueSecondaryTheme}>
        <Checkbox
          classes={classes}
          {...restProps}
        />
      </MuiThemeProvider>
    )
  }
}


const CustomButtonwithStyle =  withStyles(stylesCustomButton)(CustomButton);
const CustomDialogwithStyle =  withStyles(stylesCustomDialog)(CustomDialog);
const CustomDialogwithStyleLarge =  withStyles(stylesCustomDialogLarge)(CustomDialog);
const CustomDialogTitlewithStyle =  withStyles(stylesCustomDialogTitle)(CustomDialogTitle);
const CustomDialogContentwithStyle =  withStyles(stylesCustomDialogContent)(CustomDialogContent);
const CustomDialogContentTextwithStyle =  withStyles(stylesCustomDialogContentText)(CustomDialogContentText);
const CustomDialogActionswithStyle =  withStyles(stylesCustomDialogActions)(CustomDialogActions);
const ProcedureChipwithStyle =  withStyles(stylesProcedureChip)(ProcedureChip);
const SelectedProcedureChipwithStyle =  withStyles(stylesSelectedProcedureChip)(SelectedProcedureChip);
const AddProcedureChipwithStyle =  withStyles(stylesAddProcedureChip)(AddProcedureChip);
const CustomListItemwithStyle =  withStyles(stylesCustomListItem)(CustomListItem);
const CustomAvatarwithStyle = withStyles(stylesCustomAvatar)(CustomAvatar);
const CustomFormControlLabelwithStyle = withStyles(stylesCustomFormControlLabel)(CustomFormControlLabel);
const CustomCheckboxwithStyle = withStyles(stylesCustomCheckbox)(CustomCheckbox);
export {
  CustomAvatarwithStyle as CustomAvatar,
	CustomButtonwithStyle as CustomButton,
	CustomDialogwithStyle as CustomDialog,
  CustomDialogwithStyleLarge as CustomDialogLarge,
	CustomDialogTitlewithStyle as CustomDialogTitle,
	CustomDialogContentwithStyle as CustomDialogContent,
	CustomDialogContentTextwithStyle as CustomDialogContentText,
	CustomDialogActionswithStyle as CustomDialogActions,
	ProcedureChipwithStyle as ProcedureChip,
	SelectedProcedureChipwithStyle as SelectedProcedureChip,
	AddProcedureChipwithStyle as AddProcedureChip,
	CustomListItemwithStyle as CustomListItem,
  CustomFormControlLabelwithStyle as CustomFormControlLabel,
	CustomCheckboxwithStyle as CustomCheckbox
}
