import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { history, localeFormatDate } from '../_helpers';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import moment from 'moment';
import urijs from 'urijs';
import Modal from 'react-modal';
import { patientActions, userActions, settingsActions, formsActions } from '../_actions';
import { ProcedureChip, AddProcedureChip, SelectedProcedureChip } from '../_components';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Avatar } from 'react-avatar';
import Checkbox from '@material-ui/core/Checkbox';
//import { BPicture, BVideo } from '../VideoPlayer/BigVideo';
import { FormattedMessage } from 'react-intl';
import { Player, ControlBar } from 'video-react';
import Lightbox from 'react-image-lightbox';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import '../../css/scheduling.style.scss';
import '../../css/noteForm.style.scss';
import 'react-image-lightbox/style.css';
import DatePicker from 'react-datepicker';

import queryString from 'query-string';

import { RequestDetailLoader } from '../_components/Loaders';

import { isRequiredImageError, zipMultiple, getFullNameString } from '../_helpers';

import { startCase, upperFirst, toUpper, toLower, transform, pickBy, forEach, map, camelCase } from 'lodash';

import { ToothMovementChart, ToothConditionChart } from './ToothMovementChart';

import ToothChartNoActions from './ToothChartNoActions';
import { NavBarPage, SideBarPage } from '../HomePage';
import { ConsultRequestMessages } from './ConsultRequestMessages';
import { PatientImageSetUpload } from '../ImageSetUpload';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";
import {
  URLS, BASE_URL, API_URLS,
  AGE_RANGE, GENDERS, REQUIRED_IMAGES, requestImageFields,
  initState_formRequiredImages, initState_imageForm } from '../_config';
import { patientRequestsService, consultRequestsService  } from '../_services';
import { patientRequestsActions, imageSetActions  } from '../_actions';
import Add from '@material-ui/icons/Add';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { TabContentLoader, RequestListLoader } from '../_components/Loaders';
import {
  CustomButton,
  CustomDialog,
  CustomDialogLarge,
  CustomDialogContent,
  CustomDialogActions
} from '../_components/Parts';

import {WorkRequestForm} from '../WorkRequestForm';

import '../../css/patient.style.scss';

//------------ FROM MAIN -----------------
//header images
import headerNotifications from '../../images/shared/header-notifications.png';
import userPhoto from '../../images/shared/user-photo.png';

//ImagingPhoto images

import loadingGIF from '../../images/loading.gif';
const currencyFormat = { style: 'currency', currency: 'USD' }

const baseCssClassName = 'Patient';

class TabContent extends Component {
  constructor(props){
    super(props);

  }

  componentDidMount(){
    this.props.onMount()
  }

  render() {
    const  { intl, isLoading, isError, patient, patientId ,currentTab,
              handleContentViewChange, handlePatientFormOpen} = this.props;
    let intlMessages = intl.messages;

    const urlParams = queryString.parse(this.props.location.search);
    const imagingUrl = urlParams.reqId ? urijs(URLS.patientPageTabImaging
                        .replace('{patientId}', patientId)).addQuery("reqId", urlParams.reqId)
                        : urijs(URLS.patientPageTabImaging
                                            .replace('{patientId}', patientId))
    const reportsUrl = urlParams.reqId ? urijs(URLS.patientPageTabReports
                        .replace('{patientId}', patientId)).addQuery("reqId", urlParams.reqId)
                        : urijs(URLS.patientPageTabReports
                                            .replace('{patientId}', patientId))
    if (isLoading || patient === undefined){
      return (

        <div className="TabContent">
          <div style={{marginBottom:20, float:'left'}}>
            <TabContentLoader />
          </div>
        </div>
      )
    } else {
      return (

        <div className="TabContent">
          <div style={{marginBottom:20, float:'left'}}>
          { !isLoading && !isError &&
          <section className="TabContent__left-section">
            <h1 className="TabContent__patient-summary-heading" style={{paddingLeft:0}}>{toUpper(intlMessages['patient.patientSummary'])}</h1>

            <div className="TabContent__patient-summary-middle-wrapper" tooltip={toUpper(getFullNameString(intlMessages['string.usersFullName'], patient.first_name, patient.last_name) || "")} tooltip-position="bottom">
              <p className="TabContent__patient-name">{toUpper(getFullNameString(intlMessages['string.usersFullName'], patient.first_name, patient.last_name) || "")}</p>
            </div>
            <table className="TabContent__patient-details-table">
              <tbody>

                <tr className="TabContent__patient-details-table-row">
                  <td className="TabContent__patient-details-table-cell TabContent__patient-details-table-cell__label">{toUpper(intlMessages['newRequestForm.patient.birthdate'])}</td>
                  <td className="TabContent__patient-details-table-cell">{localeFormatDate(patient.birthdate, intl.locale) || "-"}</td>
                </tr>
                <tr className="TabContent__patient-details-table-row">
                  <td className="TabContent__patient-details-table-cell TabContent__patient-details-table-cell__label">{toUpper(intlMessages['string.gender'])}</td>
                  <td className="TabContent__patient-details-table-cell">{toUpper(patient.gender || "-")}</td>
                </tr>
                <tr className="TabContent__patient-details-table-row">
                  <td className="TabContent__patient-details-table-cell TabContent__patient-details-table-cell__label">{toUpper(intlMessages['string.newRequestForm.hospital'])}</td>
                  <td className="TabContent__patient-details-table-cell">{toUpper(patient.practice_name || "-")}</td>
                </tr>
              </tbody>
            </table>
            {/*<button className="TabContent__more-details-button" onClick={handlePatientFormOpen}>{intlMessages['string.edit'] + " " + intlMessages['string.patient'] + "  "}<FontAwesome name="plus" /></button>
            */}
          </section>
          }
        </div>
          <section className="TabContent__page-selector">
            {/* on click tab, show different data for page content */}
            <Link to={imagingUrl.href()}>
              <button
                className={cn("TabContent__page-button", {"TabContent__page-button--active": currentTab === "imaging"} )}
                //onClick = { () => handleContentViewChange("charting")}
              >
                {toUpper(intlMessages['patient.tab.charting'])}
              </button>
            </Link>
            <Link to={reportsUrl.href()}>
              <button
                className={cn("TabContent__page-button", {"TabContent__page-button--active": currentTab === "reports"} )}
                //onClick = { () => handleContentViewChange("reports")}
              >
                {toUpper(intlMessages['patient.tab.reports'])}
              </button>
            </Link>
          </section>
        </div>
      )
    }
  }
}

class MedicalHistoryItem extends Component {
  render() {
    return (
      <li className="MedicalHistoryItem">
        <span></span>
        <span className="MedicalHistoryItem__name">Oral Cancer</span>
        <span className="MedicalHistoryItem__dentist">Dr. Jennie Newman</span>
        <span className="MedicalHistoryItem__date">24 Sep 2012</span>
      </li>
    )
  }
}

class PageContent extends Component {
  render() {
    // TODO: replace with real data
    const {selectToothRegion, procedureList, notesObj, toothNotesObj, notes, patientId,
      handleNoteFormSuccess, intl,
      handleProcedurePopupOpen ,handleProcFormOpen, handleProcedureClick,
      handleNoteFormOpen} = this.props;

    let intlMessages = intl.messages;

    return (
      <section className="PageContent">
        <div className="PageContent__left-section">
          <ToothChartNoActions
            intl={intl}
            />
          {/*<h1 className="PageContent__descriptions-heading">
            {intlMessages['string.procedures']}
          </h1>
          <TagList
            type="PageContent"
            intl = {intl}
            intlLocale = {intlLocale}
          />
          <p className="PageContent__descriptions-body"></p>*/}
        </div>
        <PageContentSidebar
          notes = {notes}
          intl = {intl}
          patientId = {patientId}
          onSuccess = {handleNoteFormSuccess}
        />
      </section>
    )
  }
}

class ConsultRequestCard extends React.Component {
	/*
		Patient Card (Search Result)

		patient informatino and handle clicks (need id for patient page, patient messaging)
	*/

	constructor(props, refs){
		super(props, refs);
	}

	render() {
		const {
			handleRequestClick, intl, request, onRequestClick
		} = this.props;

    let intlMessages = intl.messages;

		return (
				<div className="ConsultRequestCard row clickable" onClick={() => onRequestClick(request.id)}>
					<div className="cell requestHospital">
						<span>{request.dental_practice.practice_name}</span>
					</div>
          <div className="cell requestDentist">
						<span>{request.dentist_name}</span>
					</div>
          <div className="cell requestDate">
						<span>{localeFormatDate(request.created_at, intl.locale, 'l')}</span>
					</div>
          <div className="cell requestStatus">
						<span>{upperFirst(intlMessages[`patientRequest.status.${request.status}`])}</span>
					</div>
          <div className="cell requestStartDate">
						<span>{localeFormatDate(request.started_at, intl.locale, 'l')}</span>
					</div>
          <div className="cell requestNextVisit">
						<span>{localeFormatDate(request.next_visit, intl.locale, 'l')}</span>
					</div>
					{/*<div className="cell requestStatus">
						<span>{request.status}</span>
					</div>*/}
				</div>
    )
	}
}

class PageContent_Reports extends Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: true,
      currentRequest: {}
    }
  }

  async componentDidMount(){
    var currentRequest = {};
    if (this.props.currentPage !== "list"){
      //const req_details = await consultRequestsService.getRequestDetails(this.props.requestId, this.props.adminCurrentDentalPractice && this.props.adminCurrentDentalPractice.value)
      //this.setState({isLoading: false, currentRequest: req_details.request})
      //currentRequest = req_details.request
    }
    this.props.onMount();
    this.setState({isLoading:false, ...currentRequest})

  }

  _renderList = () => {

    const { patient, patientId, patientRequests, intl, isLoading,
            handleNewRequestClick, handleRequestClick, handleListClick } = this.props;
    const intlMessages = intl.messages;
    const currentPatientUrl = URLS.patientPageReport.replace('{patientId}', patientId );
    const currentRequests = [];
    const pastRequests = [];
    for (var i = 0; i < patientRequests.length; i++){
      if ((patientRequests[i].status == 'CANCELED' ) || (patientRequests[i].status == 'COMPLETED')){
        pastRequests.push(patientRequests[i]);
      } else {
        currentRequests.push(patientRequests[i]);
      }
    }
    return(
        <section className="PageContent PageContent__Reports">
          <div className="PageContent__Reports__header">
            <div className="PageContent__Reports__header__title">{toUpper(intlMessages['patientRequests.title'])}</div>
          </div>
          <div className="PageContent__Reports__newRequestBar">
            <button
              className="PageContent__Reports__newRequestBar__requestButton"
              onClick = {handleNewRequestClick}
            >
              <Add />
              <span className="PageContent__Reports__newRequestBar__requestButton__text">
                {toUpper(intl.messages['string.addNewRequest'])}
              </span>
            </button>
          </div>
          <div className="ConsultRequestContent">
            <div className="ConsultRequestList tbl">
              <div className="ConsultRequestList__Headers row">
                <div className="cell requestHospital">{toUpper(intlMessages['patientRequests.hospital'])}</div>
                <div className="cell requestDentist">{toUpper(intlMessages['patientRequests.dentist'])}</div>
                <div className="cell requestDate">{toUpper(intlMessages['patientRequests.requestDate'])}</div>
                <div className="cell requestStatus">{toUpper(intlMessages['string.requestStatus'])}</div>
                <div className="cell requestStartDate">{toUpper(intlMessages['patientRequests.dateStart'])}</div>
                <div className="cell requestNextVisit">{toUpper(intlMessages['patientRequests.nextVisit'])}</div>
              </div>
              {isLoading && <RequestListLoader/>}
              {currentRequests.map( (request, index) =>
                <Link
                  key = {request.id}
                  to={currentPatientUrl.replace('{requestId}', request.id)}
                  >
                  <ConsultRequestCard
                    intl = {intl}
                    onRequestClick={handleRequestClick}
                    request = {request}
                  />
                </Link>
              )}
              {
                currentRequests.length == 0 &&
                <div className="noResults">{intlMessages['consultRequests.noResults']}</div>
              }
            </div>
          </div>

          <div className="PageContent__Reports__header PageContent__Reports__header__archived">
            <div className="PageContent__Reports__header__title">{toUpper(intlMessages['patientRequests.title.archived'])}</div>
          </div>
          <div className="ConsultRequestContent">
            <div className="ConsultRequestList tbl">
              <div className="ConsultRequestList__Headers row">
                <div className="cell requestHospital">{toUpper(intlMessages['patientRequests.hospital'])}</div>
                <div className="cell requestDentist">{toUpper(intlMessages['patientRequests.dentist'])}</div>
                <div className="cell requestDate">{toUpper(intlMessages['patientRequests.requestDate'])}</div>
                <div className="cell requestStatus">{toUpper(intlMessages['string.requestStatus'])}</div>
                <div className="cell requestStartDate">{toUpper(intlMessages['patientRequests.dateStart'])}</div>
                <div className="cell requestNextVisit">{toUpper(intlMessages['patientRequests.nextVisit'])}</div>
              </div>
              {isLoading && <RequestListLoader/>}
              {pastRequests.map( (request, index) =>
                <Link
                  key = {request.id}
                  to={currentPatientUrl.replace('{requestId}', request.id)}
                  >
                  <ConsultRequestCard
                    intl = {intl}
                    onRequestClick={handleRequestClick}
                    request = {request}
                  />
                </Link>
              )}
              {
                pastRequests.length == 0 &&
                <div className="noResults">{intlMessages['consultRequests.noResults']}</div>
              }
            </div>
          </div>
        </section>
    )
  }

  _renderDetail = () => {

    const { intl, requestId, patient, patientId, msgId,
            currentRequest, currentRequestIsError, currentRequestIsLoading,
            handleListClick, onSubmit
          } = this.props;

    const intlMessages = intl.messages;

    const currentPatientUrl = URLS.patientPageTabReports.replace('{patientId}', patientId);
    return (
      <section className="PageContent PageContent__Reports"  style={{flexDirection: 'column'}}>
        {/*<div className="PageContent__Reports__header">
          <div className={"PageContent__Reports__header__title"}>
            {startCase(camelCase(intl.messages['patientRequests.detailTitle']))}
            {currentRequest &&
              <span>{currentRequest.status}{upperFirst(intl.messages[`patientRequest.status.${currentRequest.status}`])}</span>
            }
          </div>
          <div
            className="PageContent__Reports__header__title"
            >
            <Link to={currentPatientUrl}>
              <ArrowBackIos /> {toUpper(intlMessages['patientRequests.backtoRequests'])}
            </Link>
          </div>
        </div>*/}
        <div className="PageContent__Reports__newRequestBar">
        </div>
        <div className="ConsultRequestContent">
          {/*this.state.isLoading && <RequestDetailLoader />  */}
          {!this.state.isLoading &&
            <ConsultRequestMessages
              key = {requestId}
              intl = {intl}
              msgId = {msgId}
              patientId = {patientId}
              currentRequest = {this.state.currentRequest} // TODO: i dont think this does anything
              onSubmit = {onSubmit}
              requestId = {requestId}
            />
          }
        </div>
      </section>
    )
  }

  render(){
    if (this.props.currentPage === "list") {
      return this._renderList();
    } else {
      return this._renderDetail();
    }
  }
}

/*
 * Shows tooth after tooth click
 *  - get from activeToothView?
 *  -
 *
 */

const required = value => (value ? undefined : 'required');
const noteFormCssClassName = 'NoteForm';
const noteFormFieldsCssClassName = `${noteFormCssClassName}__Inputs`;
const noteFormActionsCssClassName = `${noteFormCssClassName}__Actions`;

class NoteForm extends Component{
  constructor(props){
    super(props);
    // NOTE: not currently implemented
    this.state = {
      editView: false
    }
  }

  handleSaveMessage = (values) => {
    const { intl, patientId } = this.props;

    patientRequestsService.createPatientNote(patientId, values)
      .then(
        data => {
          this.props.onSuccess(data)
          return undefined;
        },
        error => {
          return { [FORM_ERROR]: intl.messages['form.error.general_error'] };
        }
      )
  }

  render(){

    const { intl, onCancel } = this.props;

    return(
      <div className={noteFormCssClassName}>
        <Form
          onSubmit={ this.handleSaveMessage }
          render = {({
            submitError,
            formError,
            handleSubmit,
            mutators,
            form,
            reset,
            submitting,
            pristine,
            validating,
            values
          }) => (
            <form
              id={'noteForm'}
              onSubmit={handleSubmit}
            >
              <div className={noteFormFieldsCssClassName}>
                <Field name="description" validate={required}>
                  {({ input, meta}) => (
                    <div>
                      <label className="Input__Label">{toUpper(intl.messages['patient.addNoteForm.title'])}</label>
                      <textarea
                        {...input}
                        className="form-control Input_Text"
                        rows={4}
                        type="text"
                        placeholder={toUpper(intl.messages['patient.addNoteForm.placeholder'])} />
                      <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                          {(meta.error && meta.touched) ? intl.messages[`form.error.${meta.error}`] : undefined}
                      </div>
                    </div>
                  )}
                </Field>
              </div>
              <div className={noteFormActionsCssClassName}>
                <button
                  onClick={onCancel}
                >
                  {toUpper(intl.messages['patient.addNoteForm.cancel'])}
                </button>
                <button
                  type={'submit'}
                >
                  {toUpper(intl.messages['patient.addNoteForm.save'])}
                </button>
              </div>
            </form>
          )}
        />
      </div>
    )
  }
}

class PageContentSidebar extends Component {
  constructor(props){
    super(props);
    this.state = {
      noteFormisOpen: false
    }
  }

  handleNoteFormOpen = () => {
    this.setState({noteFormisOpen: true})
  }

  handleNoteFormClose = () => {
    this.setState({noteFormisOpen: false})
  }

  onSuccess = (data) => {
    this.setState({noteFormisOpen: false})
    this.props.onSuccess(data)
  }

  render() {
    const { intl, notes, patientId } = this.props;

    let intlMessages = intl.messages;

    return (
      <div className="PageContentSidebar">
          <h2 className="PageContentSidebar__notes-heading">{toUpper(intlMessages['string.notes'])}
            <button onClick={this.handleNoteFormOpen} className="PageContentSidebar__notes-button">{toUpper(intlMessages['string.add']) + " +"} </button>
          </h2>
          {this.state.noteFormisOpen &&
            <NoteForm
              intl = {intl}
              patientId = {patientId}
              onCancel = {this.handleNoteFormClose}
              onSuccess = {this.onSuccess}
            />
          }
          {notes.map((note, i) => {
            let noteDateMoment = moment(note.date).locale(intl.locale);
            return(
              <p key={note.id} className="PageContentSidebar__notes">
                {noteDateMoment.format("l")}<br />
                <br/>{note.description}
              </p>
            )
          }
          )}
        {/*<button className="PageContentSidebar__show-hide-button"><FontAwesome name="chevron-right" /></button>*/}
      </div>
    )
  }
}

/* NOTE: Tags not in use currently*/
class TagList extends Component {
  render() {
    const {type, intl, procedureList, chartingCurrentProcedure, chartingCurrentTooth, handleProcFormOpen, handleProcedureClick} = this.props;
    let intlMessages = intl.messages;
    return (
      <div className={"TagList" + (type == "PageContent" ? " TagList--page-content" : " TagList--page-content-sidebar")}>
        {/* modify above className code if more taglist types are required */}
        {/* also check out https://github.com/JedWatson/classnames for cleaner code */}
        {/* loop through the procedures and create code tag */}
        { procedureList.map((proc, index) => {
          let label;
          if (proc.condition){ //2 is condition
            label = toUpper(proc.condition.name)
          } else if (proc.procedure){
            label = proc.procedure.code
          } else{
            label = ""
          }
          if (proc.id == (chartingCurrentProcedure && chartingCurrentProcedure.id)){
            return (
              <SelectedProcedureChip
                key = {proc.id}
                label={label}
                svgLabel={proc.tooth || " "}
                onDelete = {() => console.log("deleted "+ proc.id)}
                onClick = {() => handleProcedureClick(proc.id, proc.tooth)}
              />
            )
          } else {
            return (
              <ProcedureChip
                key = {proc.id}
                label={label}
                svgLabel={proc.tooth || " "}
                onDelete = {() => console.log("deleted "+ proc.id)}
                onClick = {() => handleProcedureClick(proc.id, proc.tooth)}
              />
            )
          }
        })}
        <AddProcedureChip label={intlMessages['string.procedure_code']} onClick={() => handleProcFormOpen(chartingCurrentTooth)} />
      </div>
    )
  }
}

class Tag extends Component {
  render() {
    const {modifier, name, handleProcFormOpen} = this.props;
    return (
      <li className={"Tag" + (modifier ? " Tag--" + modifier : "")} onClick={handleProcFormOpen}>{name}</li>
    )
  }
}

class ImagingList extends Component{
  render() {
    const {uploads, invisibleImages} = this.props;

    if (uploads.length > 0){
      return
    } else {
      return
    }
  }
}

const toothChartsCssClassName = 'ImagingToothCharts';
const headerToothChartsCssClassName = `${toothChartsCssClassName}__heading`;
const chartsToothChartsCssClassName = `${toothChartsCssClassName}__charts`;

class ToothCharts extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return (
      <div className={toothChartsCssClassName}>
        <div className={headerToothChartsCssClassName}>
          <span>{toUpper('Charts')}</span>
        </div>
        <div className={chartsToothChartsCssClassName}>
          <ToothMovementChart
            requestId={this.props.requestId}
            chartType={'movement'}
          />
          <ToothConditionChart
            requestId={this.props.requestId}
            chartType={'condition'}
          />
        </div>
      </div>
    )
  }

}

const pageContentCssClassName = 'PageContent';
const pageContentImagingCssClassName = `${pageContentCssClassName}__Imaging`

class PageContent_Imaging extends Component{
   constructor(props){
     super(props);
   }

   componentDidMount(){
     //this.props.initialize(this.props.patientId, this.props.initImageSetId)
     this.props.onMount()
   }

   componentDidUpdate(prevProps){
     if (prevProps.initImageSetId !== this.props.initImageSetId){
       this.props.initialize(this.props.patientId, this.props.initImageSetId)
     }
   }

   render(){

    const { handleImageSetOpen, intl, patientRequestImages, locationPath,
        pathname, imageSetId, imageSetIsError, imageSet, initialize,
        isStaff, patient, patientId, initImageSetId,
      onSuccess, onDelete, onChangeStatus, onDeleteInitialPreview, requiredImages,
       handleCompleteClick, handleFormSubmit} = this.props;

    return (
       <div className={cn(pageContentCssClassName, pageContentImagingCssClassName)}>
        <PatientImageSetUpload
          key={imageSetId}
          locationPath = {locationPath}
          initialize = {initialize}
          imageSetError = {imageSetIsError}
          intl = {intl}
          isStaff = {isStaff}
          patient = {patient}
          patientId = {patientId}
          initImageSetId = {imageSetId}
          imageSet = {imageSet}
          onSuccess = {onSuccess}
          onDelete = {onDelete}
          onChangeStatus = {onChangeStatus}
          onDeleteInitialPreview = {onDeleteInitialPreview}
          handleCompleteClick = {handleCompleteClick}
          requiredImages = {requiredImages}
          handleFormSubmit = {null/*just 'done' button*/}
        />
        {(imageSet.request && imageSet.request.id) &&
          <ToothCharts
            intl={intl}
            requestId={imageSet.request.id}
          />
        }
        <Imaging
          handleImageSetOpen={handleImageSetOpen}
          intl={intl}
          request = {imageSet.request}
          patientRequestImages={patientRequestImages}
          locationPath={locationPath}
        />
       </div>
     )
   }
 }

class Imaging extends Component {
  constructor(props){
    super(props);
    this.state = {
      uploadsIndex: 0,
      uploadsNumberOf: 5
    }
  }

  handleNextClick = () => {
    const newUploadsIndex = this.state.uploadsIndex + this.state.uploadsNumberOf
    this.setState({uploadsIndex: newUploadsIndex})
  }

  handlePrevClick = () => {
    const newUploadsIndex = Math.max(this.state.uploadsIndex - this.state.uploadsNumberOf, 0)
    this.setState({uploadsIndex: newUploadsIndex})
  }

  render() {
    const { handleImageSetOpen, intl, patientRequestImages, locationPath, request } = this.props;
    const { uploadsIndex, uploadsNumberOf } = this.state;
    var prevButton, nextButton;
    let intlMessages = intl.messages;
    //const uploadsNumberOf = patientRequestImages.length
    prevButton = (uploadsIndex == 0) ? false : true;
    nextButton = (patientRequestImages && patientRequestImages[uploadsIndex + uploadsNumberOf] == undefined) ? false : true;
    const uploadsLength = (patientRequestImages && patientRequestImages.length) || undefined;
    const uploadsViewLength = uploadsNumberOf - (patientRequestImages && patientRequestImages.slice(Math.max(0,uploadsIndex),uploadsIndex+uploadsNumberOf).length)
    const invisibleImages = [];
    if (uploadsLength === undefined){
      return (
        <div className="Imaging">
          <div className="Imaging__heading">
            <span>{toUpper(intlMessages['patient.imaging.header'])}</span>
            <button
              className="PageContentSidebar__notes-button"
              style={{float:'none', marginLeft:15}}
              onClick={handleImageSetOpen}
              >
              {toUpper(intlMessages['string.edit']) + " +"}
            </button>
          </div>
          <div className="Imaging__slider">
            <span style={{color: '#76909A'}}>{upperFirst(toLower(intlMessages['patient.imagingList.no_images']))}</span>
          </div>
        </div>
      )
    } else{
      for (var i=0; i < uploadsViewLength; i++){
        invisibleImages.push(i);
      }
      return (
        <div className="Imaging">
          <div className="Imaging__heading">
            <span>{toUpper(intlMessages['patient.imaging.header'])}</span>
          </div>
          <div className="Imaging__slider">
            <button className="Imaging__slider-button" onClick={ () => this.handlePrevClick() } >
            { prevButton &&
              <FontAwesome name="chevron-left" />
            }
            </button>
            <ul className="Imaging__image-list">
              { uploadsLength &&
                  patientRequestImages.slice(Math.max(0,uploadsIndex), Math.min(uploadsIndex+uploadsNumberOf, uploadsLength)).map((image, index) =>
                  <ImagingPhoto
                    intl = {intl}
                    key = {image.id}
                    id = {image.id}
                    date = {(request && request.appointment_date) ||image.created_at}
                    locationPath = {locationPath}
                  />
                ) }
              {
                invisibleImages.map( (a, index) =>
                  <BlankImagingPhoto
                    key = {index+"a"}
                  />
                )
              }
            </ul>
            <button className="Imaging__slider-button" onClick={ () => this.handleNextClick() } >
            { nextButton &&
              <FontAwesome name="chevron-right"  />
            }
            </button>
          </div>
        </div>
      )
    }
  }
}


class BlankImagingPhoto extends Component {
  render(){
    return (
      <li className="ImagingPhoto" style={{visibility:"hidden"}}>
        <img className="ImagingPhoto__photo"  style={{width:140}} />
        <p className="ImagingPhoto__title"></p>
      </li>
    )
  }
}

class ImagingPhoto extends Component {

  render(){
    const { intl, id, date, locationPath } = this.props;
    const fileName = localeFormatDate(date, intl.locale)
    const linkTo = urijs(locationPath).addQuery("reqId", id)
    return (
      <li className="ImagingPhoto">
        <span>
          <Link to={linkTo.href()}>
            <div className="ImagingPhoto__photo">
              {startCase(toLower(intl.messages['patient.imaging.images.title']))}
            </div>
          </Link>
          <p className="ImagingPhoto__title">{fileName}</p>
        </span>
      </li>
    )
  }
}

/*
  Need to add a complete button or something in chat.
  To mark appointment as complete
*/

class ImageSetDialogActions extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      isDownloading: false,
      isReadyToDownload: false //use this later to make sure downloadable
    }
  }

  downloadFiles = (fileName, imageSet) => {
    this.setState({isDownloading: true})
    var imageList = [];
    forEach(imageSet, function(value, key){
      if ((key !== "request") && (value.url && value.url[0])){
        imageList.push({...value, image_group: key})
      }
    })
    zipMultiple(imageList, fileName);
    this.setState({isDownloading:false});
  }

  render(){
    const { isStaff, handleFormClose, handleCompleteClick,
            intl, patient, imageSet } = this.props;
    const patientName = patient ? patient.patient_name : "";
    const requestDate = imageSet.request ? moment((imageSet.request.appointment_date || imageSet.request.created_at)).format('YYYYMMDD') : "";
    const practiceName = patient ? patient.practice_name : "";
    const fileName = `${practiceName}-${patientName}-${requestDate}`
    if (isStaff) {
      return (
        <CustomDialogActions>
            <button
              className={'ImageForm__nextButton'}
              onClick={() => this.downloadFiles(fileName, this.props.imageSet)}
              disabled={this.state.isDownloading}
              >
              {toUpper(intl.messages['string.download'])}
            </button>
            {/*<button
              className={'ImageForm__nextButton'}
              onClick={handleCompleteClick}
              >
              {toUpper(intl.messages['string.markGood'])}
            </button>*/}
            <button
              className={'ImageForm__nextButton'}
              onClick={handleFormClose}
              >
              {toUpper(intl.messages['string.close'])}
            </button>
        </CustomDialogActions>
      )
    } else {
      return (
        <CustomDialogActions>
            <button
              className={'ImageForm__nextButton'}
              onClick={handleCompleteClick}
              >
              {toUpper(intl.messages['string.done'])}
            </button>
        </CustomDialogActions>
      )
    }


  }
}

class ImageSetDialog extends React.Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){
    //this.props.initialize(this.props.patientId, this.props.initImageSetId)
  }

  componentDidUpdate(prevProps){
    if (prevProps.initImageSetId !== this.props.initImageSetId){
      this.props.initialize(this.props.patientId, this.props.initImageSetId)
    }
  }

  render() {
    const { intl, formOpen, consultRequestId, initImageSetId, isStaff, imageSet, patient, patientId,
            onSuccess, onChangeStatus, onDelete, onDeleteInitialPreview, requiredImages,
            locationPath, imageSetError, handleFormSubmit, handleFormClose, handleCompleteClick,
            } = this.props;
    if (imageSetError){
      return(
        <CustomDialog
          className="popUpForm ImageForm"
          open = {formOpen}
          disabledBackdropClick={true}
        >
          <CustomDialogContent>
            <div className="ImageForm__Header">
              {toUpper(intl.messages['patient.imageForm.error.header'])}
            </div>
            <div>{upperFirst(intl.messages['patient.imageForm.error.content'])}</div>
          </CustomDialogContent>
          <CustomDialogActions>
            <Link
              to={locationPath}
            >
              <button
                className={'ImageForm__Error__closeButton'}
                >
                {toUpper(intl.messages['string.close'])}
              </button>
            </Link>
          </CustomDialogActions>
        </CustomDialog>
      )
    } else {
      return (
          <CustomDialogLarge className="popUpForm ImageForm"
            open={formOpen}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={true}
          >
            <CustomDialogContent>
              <PatientImageSetUpload
                initImageSetId = {initImageSetId}
                onSuccess = {onSuccess}
                onDelete = {onDelete}
                onChangeStatus = {onChangeStatus}
                onDeleteInitialPreview = {onDeleteInitialPreview}
                requiredImages = {requiredImages}
                handleCompleteClick = {handleCompleteClick}
              />
            </CustomDialogContent>
            <ImageSetDialogActions
              intl = {intl}
              imageSet = {imageSet}
              patient = {patient}
              isStaff = {isStaff}
              handleFormClose = {handleFormClose}
              handleCompleteClick = {handleCompleteClick}
            />
          </CustomDialogLarge>
      );
    }
  }
}

class PatientPage extends Component {
  static propTypes = {
    patient: PropTypes.object,
    imageSet: PropTypes.object,
    patientRequests: PropTypes.object
  }
  constructor(props, refs){
    super(props, refs);
    const urlParams = queryString.parse(this.props.location.search);
    this.state = {
      initialTab: this.props.match.params.tab || "imaging",
      intialPage: urlParams.tabPg || "list",
      initialRequest: urlParams.reqId,
      initialImageSet: urlParams.reqId,
      //imageSetOpen: (urlParams.imgId !== undefined) || false,
      imageSetOpen: (urlParams.reqId !== undefined) || false,
      imageForm: initState_imageForm,
      requiredImages: initState_formRequiredImages,
      adminMarkedError: false,
      adminChangedStatus: false,
      userChangedUpload: false
    }
  }

  _fetchNetworkData = (patientId, imgSetId=null) => {
    // get first image for each consult request
    //this.props.dispatch(patientRequestsActions.getPatientDetails(patientId));
    //this.props.dispatch(patientRequestsActions.getPatientRequests(patientId));
    //this.props.dispatch(patientRequestsActions.getPatientRequestImages(patientId));
    //this.props.dispatch(patientRequestsActions.getPatientImageSet(patientId, imgSetId));
    // TODO: entirely delete notes, dont have use for it right now.
    this.props.dispatch(patientRequestsActions.getPatientNotes(patientId));
  }

  _getPatientRequests = () => {
    this.props.dispatch(patientRequestsActions.getPatientRequests(this.props.match.params.patient_id));
  }

  _getPatientDetails = () => {
    this.props.dispatch(patientRequestsActions.getPatientDetails(this.props.match.params.patient_id));
  }

  _getPatientImaging = () => {
    const urlParams = queryString.parse(this.props.location.search);
    this.props.dispatch(patientRequestsActions.getPatientRequestImages(this.props.match.params.patient_id));
    this.props.dispatch(patientRequestsActions.getPatientImageSet(this.props.match.params.patient_id, urlParams.reqId));
  }

  componentDidMount() {
    // get requests and patient info
    const urlParams = queryString.parse(this.props.location.search);
    this.setState({imageSetOpen: (urlParams.reqId !== undefined) || false});
    this._fetchNetworkData(this.props.match.params.patient_id, urlParams.reqId);
  }

  componentDidUpdated(prevProps){
    if ((prevProps.match.params.patient_id !== this.props.match.params.patient_id) ||
        (prevProps.settings.adminCurrentDentalPractice !== this.props.settings.adminCurrentDentalPractice)){
      // and initialize the network calls
      //this._fetchNetworkData(this.props.match.params.patient_id);
    }

  }

  handleContentViewChange = (newTab) => {
    //this.setState({currentTab: newTab})
    // TODO: remove this? using <Link> precludes need for onClick
  }

  handlePatientFormOpen = () => {
    // TODO: add patient form? can't change ID, neccessary?
  }

  handleNewRequestClick = () => {
    // TODO: clicks are done through link.. then change url params or url props
    history.push(URLS.newConsultRequest+`?pid=${this.props.patientRequests.patient.id}`);
  }

  handleRequestClick = (id) => {
    // TODO: clicks vs <Links
    // dispatch to get new details, message thread of report
    //this.props.dispatch(patientRequestsActions.getRequestDetails(id))
    //this.setState({currentPage: "detail"})

  }

  handleListClick = () => {
    //this.setState({currentPage: "list"})
  }

  handleImageSetOpen = () => {
    // handle open image upload form
    // any image that needs to be open
    this.setState({imageSetOpen: false});
    //this.setState({imageSetOpen: true});
  }

  handleImageSetClose = () => {
    this.setState({imageSetOpen: false});
  }

  /**
   * check to see all required images have been uploaded/corrected
   */

  _postImageCheck(){

  }

  requiredImageCheck(){
    var isError;
    const currentImages = this.props.patientRequests.imageSet;
    const requiredImages = this.props.imageSet.requiredImages;
    var isError;
    // TODO: change to lodash when possible
    return false
    /* TODO: add required check
    REQUIRED_IMAGES.map(imageGroup => {
      let imageError = isRequiredImageError(imageGroup, currentImages);
      requiredImages[imageGroup] = imageError;
      isError = isError || imageError
    });
    this.setState({requiredImages})
    this.props.dispatch(imageSetActions.updateRequiredImages(requiredImages))
    return isError;*/
  }

  handleImageFormClose = () => {
    // handle open image upload form
    // any image that needs to be open
    if (this.state.adminChangedStatus === true){
      consultRequestsService.updateRequestStatus(this.props.patientRequests.imageSet.request.id, {document_status: 2})
        .then(
          data => {
            // close image form upon submission
            this.props.history.push(this.props.location.pathname)
          },
          error => {
            // TODO: then i dunno, retry
          }
        )
    } else {

      this.props.history.push(this.props.location.pathname)
    }
  }

  /** IMAGE UPLOAD PAGE
   * Called when image has been uploaded
   **/
  handleImageUpload = (imageKey, data) => {
    const currentImages = this.props.patientRequests.imageSet;
    const currentRequiredImages = this.state.requiredImages;

    currentRequiredImages[imageKey] = false //isRequiredImageError(imageKey, currentImages)
    this.setState({ requiredImages: currentRequiredImages, userChangedUpload: true });
    this.props.dispatch(patientRequestsActions.updateImageSet(data));
  }

  handleNoteFormSuccess = (data) => {
    this.props.dispatch(patientRequestsActions.updatePatientNotes(data));
  }

  onChangeImageStatus = (uploadId, imageKey , status) => {
    const uploadData = {
      upload_id: uploadId,
      image_group: imageKey,
      status: status
    }
    if (status === 2){
      this.setState({adminMarkedError: true, adminChangedStatus: true})
    } else {
      this.setState({adminChangedStatus: true})
    }
    this.props.dispatch(patientRequestsActions.changeImageSetStatus(uploadData));
  }

  onDeleteInitialPreview = (imageKey, uploadId, requestId) => {
    // NOTE: unnecessary, preview will delete once imageSet is updated...
  }

  /** IMAGE UPLOAD PAGE
   * Called after image thumbnail has been removed from upload
   **/
  onDelete = (imageKey, uploadId, requestId) => {
    const { intl, patient } = this.props;
    const uploadData ={
      request_id: requestId,
      upload_id: uploadId,
      image_group: imageKey
    };
    patientRequestsService.deletePatientUpload(this.props.match.params.patient_id, uploadData)
      .then(
        data => {
          // don't need to confirm delete really,
          const currentImages = this.props.patientRequests.imageSet;
          const currentRequiredImages = this.state.requiredImages;
          currentRequiredImages[imageKey] = true;
          // TODO: remove image thumbnail and upload_id from imageSet
          this.props.dispatch(patientRequestsActions.updateImageSet(data))
          this.setState({ requiredImages: currentRequiredImages });
        },
        error => {
          // if there was an error, it would have to pop back up
          // maybe do it on reload.
        }
      )
  }

  // TODO: might need a on delete of initial image check

  loadImages = (patientId, imageSetId) => {
        this.props.dispatch(patientRequestsActions.getPatientImageSet(patientId, imageSetId))
  }

  /** IMAGE UPLOAD PAGE
   * Click on button to complete form and do a check of
   * required fields or fields that needed updates
   **/
  handleCompleteClick = () => {
    const isError = this.requiredImageCheck();
    if (isError !== true && this.state.userChangedUpload === true) {
      // TODO: there are errors, so highlight errors?

      //consultRequestsService.updateRequestStatus(this.props.patientRequests.imageSet.request.id, {document_status: 1})
        /*.then(
          data => {
            // close image form upon submission
            this.props.history.push(this.props.location.pathname);
          },
          error => {
            // TODO: then i dunno, retry

          }
        )*/
    /*} else if (isError !== true) {
      this.props.history.push(this.props.location.pathname);*/
    } else {
      //this.props.history.push(this.props.location.pathname);
    }
  }

  // TODO: copy over image from from new consult request
  // image uploads are locked unless state is requireNew or something

  /*
      render should have patient page
        load notes, requests, patient information, images,

   */
  render(){

    const {
      patient, patientIsLoading, patientIsError,
      imageSet, imageSetIsLoading, imageSetIsError,
      patientRequests, patientRequestsIsLoading, patientRequestsIsError,
      patientRequestImages, patientRequestImagesIsLoading, patientRequestImagesIsError,
      notes, patientNotesIsLoading, patientNotesIsError,
      //currentRequest, currentRequestIsLoading, currentRequestIsError,
    } = this.props.patientRequests;
    const { userInfo } = this.props.users;
    const { adminCurrentDentalPractice } = this.props.settings;
    const { intl } = this.props;
    const urlParams = queryString.parse(this.props.location.search);
    const currentTab = this.props.match.params.tab || 'imaging';
    const requestId =  urlParams.reqId || '';
    const imageSetId = urlParams.reqId || '';
    const imageSetOpen = (imageSetId !== undefined) || false;
    const currentView = (requestId !== '') ? 'detail' : 'list';
    const msgId = urlParams.msgId
    // initialize tab, tage, etc
    return (
      <div className={baseCssClassName} key={adminCurrentDentalPractice || 'default'}>
        <NavBarPage />
        <SideBarPage location={this.props.location} />
        {/*imageSetOpen &&
          <ImageSetDialog
            key={imageSetId}
            locationPath = {this.props.location.pathname}
            initialize = {this.loadImages}
            imageSetError = {imageSetIsError}
            intl = {intl}
            isStaff = {userInfo.is_staff}
            patient = {patient}
            patientId = {this.props.match.params.patient_id}
            initImageSetId = {imageSetId}
            imageSet = {imageSet}
            onSuccess = {this.handleImageUpload}
            onDelete = {this.onDelete}
            onChangeStatus = {this.onChangeImageStatus}
            onDeleteInitialPreview = {this.onDeleteInitialPreview}
            handleCompleteClick = {this.handleCompleteClick}
            requiredImages = {this.state.requiredImages}
            formOpen = {imageSetOpen}
            handleFormClose = {this.handleImageFormClose}
            handleFormSubmit = {null}
          />
        */}
        <div className="ContentWrapper" key={`Patient__${this.props.match.params.patient_id}`}>
        <div className={cn("Loader", {"Loader__show": ( patientIsLoading || imageSetIsLoading || patientRequestsIsLoading) })} ></div>
          <TabContent
            key={`Patient__${this.props.match.params.patient_id}`}
            onMount={this._getPatientDetails}
            isLoading={patientIsLoading}
            isError={patientIsError}
            patient={patient}
            location={this.props.location}
            patientId={this.props.match.params.patient_id}
            intl={this.props.intl}
            currentTab = {currentTab}
            handleContentViewChange = {this.handleContentViewChange}
            handlePatientFormOpen = {this.handlePatientFormOpen}
          />
          { (currentTab === "imaging") &&
            <PageContent_Imaging
              intl = {this.props.intl}
              onMount = {this._getPatientImaging}
              handleImageSetOpen = {this.handleImageSetOpen}
              patientRequestImages = {patientRequestImages}
              isLoading = {patientRequestImagesIsLoading}
              locationPath = {this.props.location.pathname}
              requestId = {requestId}
              imageSetId={imageSetId}
              locationPath = {this.props.location.pathname}
              initialize = {this.loadImages}
              imageSetError = {imageSetIsError}
              intl = {intl}
              isStaff = {userInfo.is_staff}
              patient = {patient}
              patientId = {this.props.match.params.patient_id}
              initImageSetId = {imageSetId}
              imageSet = {imageSet}
              onSuccess = {this.handleImageUpload}
              onDelete = {this.onDelete}
              onChangeStatus = {this.onChangeImageStatus}
              onDeleteInitialPreview = {this.onDeleteInitialPreview}
              handleCompleteClick = {this.handleCompleteClick}
              requiredImages = {this.state.requiredImages}
              handleFormSubmit = {null/*just 'done' button*/}
            />
          }
          {(currentTab === "reports") &&
            <PageContent_Reports
                patient = {patient}
                onMount = {this._getPatientRequests}
                patientId={this.props.match.params.patient_id}
                msgId = {msgId}
                isLoading = {patientRequestsIsLoading}
                patientRequests = {patientRequests}
                handleNewRequestClick = {this.handleNewRequestClick}
                handleRequestClick = {this.handleRequestClick}
                handleListClick = {this.handleListClick}
                currentPage = {currentView}
                requestId = {requestId}
                adminCurrentDentalPractice = {adminCurrentDentalPractice}
                //currentRequest = {currentRequest}
                //currentRequestIsLoading = {currentRequestIsLoading}
                //currentRequestIsError = {currentRequestIsError}
                intl = {this.props.intl}
            />
          }
        </div>
      </div>
    )
  }
}

PatientPage.contextTypes = {
    intl: PropTypes.object
};

function mapStateToProps(state) {
    const { patient, users, authentication, settings, forms,
            intl, patientRequests, consultRequests, imageSet } = state;
    const { user } = authentication;
    return {
        user,
        users,
        intl,
        patient,
        settings,
        forms,
        imageSet,
        patientRequests,
        consultRequests
    };
}

const connectedPatientPage = withRouter(connect(mapStateToProps)(PatientPage));
export { connectedPatientPage as PatientPage };
