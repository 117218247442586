import { MOMENT_DATE_FORMAT, API_DATE_FORMAT } from '../_config';
import { mapValues } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import {localeFormatDate} from './date';

function isNew(data, user){
  if (user.is_staff === true){
    // 3 or 0
    if ((data.status === 3) || (data.status === 0)) {
      return true;
    } else {
      return false;
    }
  } else {
    // 2
    if ( (data.status === 0) || (data.status === 2)){
      return true;
    } else {
      return false;
    }
  }
}

function getFullNameString(nameFormatString, firstName, lastName){
  return nameFormatString.replace('{givenName}', firstName).replace('{familyName}', lastName)
}

function formatPatientSearchString(nameFormatString, firstName, lastName, birthdate, locale){
  return nameFormatString.replace('{givenName}', firstName)
                          .replace('{familyName}', lastName)
                          .replace('{birthdate}',localeFormatDate(birthdate, locale))
}

function extractSelectValues(values){
  return mapValues(values, (obj)=>{
    if(obj.value === undefined){
      return obj
    }  else {
      return obj.value
    }
  })
}

function convertToAPIDate(values) {
  return mapValues(values, (obj)=>{
    if(typeof obj.value === undefined){
      return obj
    }  else {
      return obj.value
    }
  })
}

function convertToAPIValues(values, locale){
  return mapValues(values, (obj)=>{
    if (moment.isMoment(obj)){
      return obj.format(API_DATE_FORMAT)
    }
    if (moment.isDate(obj)){
      return moment(obj).format(API_DATE_FORMAT)
    }
    if (obj == null){
      return obj
    }
    if(obj.value === undefined){
      return obj
    } else {
      return obj.value
    }
  })
}

export {isNew, getFullNameString, extractSelectValues, convertToAPIDate, formatPatientSearchString, convertToAPIValues};
