import { adminConstants } from '../_constants';
import { adminService } from '../_services';
import { history } from '../_helpers';
import moment from 'moment';

export const adminActions = {
	getDentalAccountInfo,
	getDentalAccounts,
	openUserAccountForm,
	closeUserAccountForm,
	changeUserAccountForm,
    changeContentView,
    cancelDentalAccountForm,
    changeDentalAccountForm,
    editDentalAccount,
    changeDentalAvailability,
    editDentalAvailability,
    getDentalPracticeLocations,
    getDentalLocationInfo,
    cancelDentalLocationForm,
    changeDentalLocationForm,
    editDentalLocation,

  updateNewAccount,
  getPracticeDetails,
  updatePracticeDetails,
  getAccountDetails,
  updateAccountDetails,
  doModelSearch,
  clearModelSearch
}

function updateNewAccount(data){
  return (dispatch) => {
    dispatch(request(data));
    dispatch(update(data));
  }
  // send user to new dental account page
  function request() { return { type: adminConstants.CREATE_NEWACCOUNT_REQUEST}}
  function update(data) { return { type: adminConstants.CREATE_NEWACCOUNT_SUCCESS, data}}
}

function clearModelSearch(){
  return (dispatch) => {
    dispatch(clear());
  }

  function clear() {
    return { type: adminConstants.SEARCH_MODELS_CLEAR}
  }
}

function doModelSearch(modelType, searchInput, init=0){
  return (dispatch, getState) => {
		//const { consultRequestSearchInput, consultRequestLoadMoreOffset } = getState().consultRequests;
    //const { adminCurrentDentalPractice } = getState().settings;
    let adminCurrentDentalPractice = null;
  	dispatch(request());
		const currentOffset = init;
    if (modelType == 'clinics'){
		   adminService.doPracticeSearch(searchInput, currentOffset, adminCurrentDentalPractice && adminCurrentDentalPractice.value)
			    .then(
				    data => dispatch(success({...data, model: 'clinics'})),
				    error => dispatch(failure(error))
			);
    } else { // only other one is accounts for now
		   adminService.doAccountSearch(searchInput, currentOffset, adminCurrentDentalPractice && adminCurrentDentalPractice.value)
			    .then(
				    data => dispatch(success({...data, model: 'accounts'})),
				    error => dispatch(failure(error))
			);
    }
	}
    function request() { return { type: adminConstants.SEARCH_MODELS_REQUEST } }
    function success(data) {
    	return { type:adminConstants.SEARCH_MODELS_SUCCESS, data}
    }
    function failure(error) { return { type:adminConstants.SEARCH_MODELS_FAILURE, error} }
};

function getPracticeDetails(clinicId){
  return (dispatch) => {
    dispatch(request());
    adminService.getPracticeDetails(clinicId)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: adminConstants.GET_PRACTICEDETAILS_REQUEST }}
  function success(data) { return { type: adminConstants.GET_PRACTICEDETAILS_SUCCESS, data }}
  function failure(error) { return { type: adminConstants.GET_PRACTICEDETAILS_FAILURE, error }}
}

function updatePracticeDetails(data){
  return (dispatch) => {
    dispatch(update(data));
  }

  function update(data) { return { type: adminConstants.UPDATE_PRACTICEDETAILS, data}}
}

function getAccountDetails(accountId){
  return (dispatch) => {
    dispatch(request());
    adminService.getAccountDetails(accountId)
      .then(
        data => dispatch(success(data)),
        error => dispatch(failure(error))
      )
  }
  function request() { return { type: adminConstants.GET_ACCOUNTDETAILS_REQUEST }}
  function success(data) { return { type: adminConstants.GET_ACCOUNTDETAILS_SUCCESS, data }}
  function failure(error) { return { type: adminConstants.GET_ACCOUNTDETAILS_FAILURE, error }}
}

function updateAccountDetails(data){
  return (dispatch) => {
    dispatch(update(data));
  }

  function update(data) { return { type: adminConstants.UPDATE_ACCOUNTDETAILS, data}}
}

function handleGotoDentalAccount(){
	return ""
}

function changeContentView(contentView){
	return (dispatch, getState) => {
		dispatch(change(contentView))
	}

	function change(contentView){ return {type: adminConstants.CHANGE_PAGECONTENT, contentView}}
}

function getDentalAccounts(){
	return dispatch => {
		dispatch(request());
		adminService.getDentalAccounts()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	}

	function request() { return {type: adminConstants.GET_USERACCOUNTS_REQUEST} }
	function success(data) {
		return {type:adminConstants.GET_USERACCOUNTS_SUCCESS, data}
	}
    function failure(error) { return { type: adminConstants.GET_USERACCOUNTS_FAILURE, error } }
}

function getDentalPracticeLocations(){
	return dispatch => {
		dispatch(request());
		adminService.getDentalPracticeLocations()
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	}

	function request() { return {type: adminConstants.GET_PRACTICELOCATIONS_REQUEST} }
	function success(data) {
		return {type:adminConstants.GET_PRACTICELOCATIONS_SUCCESS, data}
	}
    function failure(error) { return { type: adminConstants.GET_PRACTICELOCATIONS_FAILURE, error } }
}

function getDentalAccountInfo(id){
	return dispatch => {
		dispatch(request());
		adminService.getDentalAccountInfo(id)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	}

	function request() { return {type: adminConstants.GET_USERACCOUNTINFO_REQUEST} }
	function success(data) {
		return {type:adminConstants.GET_USERACCOUNTINFO_SUCCESS, data}
	}
    function failure(error) { return { type: adminConstants.GET_USERACCOUNTINFO_FAILURE, error } }
}

function getDentalLocationInfo(id){
	return dispatch => {
		dispatch(request());
		adminService.getDentalLocationInfo(id)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			)
	}

	function request() { return {type: adminConstants.GET_PRACTICELOCATIONINFO_REQUEST} }
	function success(data) {
		return {type:adminConstants.GET_PRACTICELOCATIONINFO_SUCCESS, data}
	}
    function failure(error) { return { type: adminConstants.GET_PRACTICELOCATIONINFO_FAILURE, error } }
}


function cancelDentalLocationForm(){
	return dispatch => {
		dispatch(cancel())
	}
	function cancel() { return {type: adminConstants.CANCEL_PRACTICELOCATIONFORM} }
}


function changeDentalLocationForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change() { return {type: adminConstants.CHANGE_PRACTICELOCATIONFORM, form} }
};


function openUserAccountForm(){
	return dispatch => {
		dispatch(open());
	}
	function open() { return {type: adminConstants.OPEN_USERACCOUNTFORM } }
};

function closeUserAccountForm(){
	return dispatch => {
		dispatch(close());
	}
	function close() { return {type: adminConstants.CLOSE_USERACCOUNTFORM} }
};

function changeUserAccountForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change() { return {type: adminConstants.CHANGE_USERACCOUNTFORM, form} }
};

function cancelDentalAccountForm(){
	return dispatch => {
		dispatch(cancel())
	}
	function cancel() { return {type: adminConstants.CANCEL_DENTALACCOUNTFORM} }
}

function changeDentalAccountForm(form){
	return dispatch => {
		dispatch(change(form));
	}
	function change(data) { return {type: adminConstants.CHANGE_DENTALACCOUNTFORM, data} }
};


//helper for removing/adding to list
function removeFromList(item, itemList){
    for (let i = 0; i < itemList.length; i++) {
        let currItem = itemList[i];
        if (currItem === item) {
            // delete user
            itemList.splice(i, 1);
            break;
        }
    }
    return itemList
}

function addToList(item, itemList){
        return [... new Set([...itemList, item]) ]
}

function changeDentalAvailability(day, hour, checked){
	return (dispatch, getState) => {
		const form = {};
		const availabilityForm = getState().dentalAdmin.availabilityForm;
		if (checked){
			form[day] = addToList(hour, availabilityForm[day])
		} else {
			form[day] = removeFromList(hour, availabilityForm[day])
		}
		dispatch(change(form));
	}
	function change(data) {
		return {type: adminConstants.CHANGE_DENTALACCOUNTAVAILABILITY, data}
	}
}

function editDentalAvailability(){
	return (dispatch, getState) => {
		dispatch(request());
		const form = {
			...getState().dentalAdmin.availabilityForm
		}
		adminService.editDentalAvailability(form, getState().dentalAdmin.dentalAccountInfo.id)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: adminConstants.EDIT_DENTALACCOUNT_AVAILABILITY_REQUEST } }
    function success(data) {
    	return { type: adminConstants.EDIT_DENTALACCOUNT_AVAILABILITY_SUCCESS, data }
    }
    function failure(error) { return { type: adminConstants.EDIT_DENTALACCOUNT_AVAILABILITY_FAILURE, error } }
}

function editDentalAccount(){
	return (dispatch, getState) => {
		dispatch(request());
		const form = {
			...getState().dentalAdmin.dentalAccountForm
		}
		adminService.editDentalAccount(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: adminConstants.EDIT_DENTALACCOUNT_INFO_REQUEST } }
    function success(data) {
    	return { type: adminConstants.EDIT_DENTALACCOUNT_INFO_SUCCESS, data }
    }
    function failure(error) { return { type: adminConstants.EDIT_DENTALACCOUNT_INFO_FAILURE, error } }
};


function editDentalLocation(){
	return (dispatch, getState) => {
		dispatch(request());
		const form = {
			...getState().dentalAdmin.practiceLocationForm
		}
		adminService.editDentalLocation(form)
			.then(
				data => dispatch(success(data)),
				error => dispatch(failure(error))
			);
	};

    function request() { return { type: adminConstants.EDIT_PRACTICELOCATION_INFO_REQUEST } }
    function success(data) {
    	return { type: adminConstants.EDIT_PRACTICELOCATION_INFO_SUCCESS, data }
    }
    function failure(error) { return { type: adminConstants.EDIT_PRACTICELOCATION_INFO_FAILURE, error } }
};
