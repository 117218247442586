import React from 'react';
import {localeFormatDate, getFullNameString} from '../_helpers';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { URLS, ADMIN_URLS } from '../_config';
const baseCssClassName = 'ConsultRequestCard';
const newConsultRequestCssClassName = `${baseCssClassName}__New`

class DentalPracticeCard extends React.Component {
	/*
		Admin Card (Search Result)

		patient informatino and handle clicks (need id for patient page, patient messaging)
	*/
	constructor(props, refs){
		super(props, refs);
	}

	render() {
		const {	intl, intlLocale, clinic} = this.props;
    let intlMessages = intl.messages;
    let url = ADMIN_URLS.home;
    url = ADMIN_URLS.clinicDetailsPage
              .replace('{clinicId}', clinic.id )
		return (
      <Link
        to = {url}
      >
				<div
          className={cn(`${baseCssClassName} row clickable`)}
          >
					<div className="cell organization">
						<span>{clinic.organization.organization_name}</span>
					</div>
          <div className="cell practiceName">
						<span>{clinic.practice_name}</span>
					</div>
          <div className="cell email">
						<span>{clinic.email_address}</span>
					</div>
          <div className="cell phoneNumber">
						<span>{clinic.phone_number}</span>
					</div>
          <div className="cell date">
						<span>{localeFormatDate(clinic.created_at, intl.locale)}</span>
					</div>
				</div>
      </Link>
    )
	}
}

export { DentalPracticeCard };
