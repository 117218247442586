import {forEach} from 'lodash';
import JSZip from 'jszip';
import moment from 'moment';
import { saveAs } from 'file-saver';

function zipMultiple(imageList, fileName){
  var zip = new JSZip();
  var count = 0;
  console.log("zipping files", imageList, fileName)
  forEach(imageList, async function(value){
    let singleFileName = value.image_group+"."+value.file_name[0].split(".").slice(-1)[0];
    let resp = await fetch(value.url, {
      method: 'GET'
    });
    const downloadedFile = await resp.blob();
    console.log("response",downloadedFile)
    console.log("response",resp)
    zip.file(singleFileName, downloadedFile, {binary:true} )
    count++;
    if (count == imageList.length) {
      zip.generateAsync({type:'blob'}).then(function(content) {
        saveAs(content, fileName);
      });
    }
  })
/*
  [].forEach(function(file){
    var filename = file.name;
    // loading a file and add it in a zip file
    JSZipUtils.getBinaryContent(file.url, function (err, data) {
      if(err) {
        throw err; // or handle the error
      }
      zip.file(filename, data, {binary:true});
      count++;
      if (count == urls.length) {
        zip.generateAsync({type:'blob'}).then(function(content) {
          saveAs(content, fileName);
        });
      }
    });
  });*/
}
export { zipMultiple };
