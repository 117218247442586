import React, {Fragment} from 'react';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import cn from 'classnames';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";
import {
  SelectAdapter,
  ReinitializeForm,
  LoadingEllipsis,
  ConfirmPopUp,
  PopUpCustomUIChangeStatus,
  PopUpCustomUIText,
  PopUpCustomUI,
  ConfirmAlertUrlUI,
  errorAlert
} from '../_components';

import { URLS, STATUS_CHOICES, MOMENT_DATE_FORMAT, API_DATETIME_FORMAT,
        required, mustBeNumber, minValue, composeValidators, noChineseCharacters } from '../_config';

import { confirmAlert } from 'react-confirm-alert';
import '../../css/react-custom-confirm-alert.css';

import '../../css/consultRequestsMessages.style.scss';
import '../../css/consultRequestsDetail.style.scss';

import { filter, upperFirst, toUpper, transform, pickBy, forEach, startCase, camelCase, find, cloneDeep } from 'lodash';

import { WorkRequestForm } from '../WorkRequestForm';
import { RequestDetailLoader } from '../_components/Loaders';
import { MessageAttachment, MessageAttachmentUpload, NewAttachmentDropzone } from './MessageAttachment';

import { consultRequestsService  } from '../_services';
import { patientRequestsActions  } from '../_actions';
import { localeFormatDate, convertToAPIValues } from '../_helpers';

import Add from '@material-ui/icons/Add';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

/*
 *  CSS variable declarations for ConsultRequestMessages
 */
const baseCssClassName= 'ConsultRequestMessages';
const replyCssClassName = `${baseCssClassName}__Reply`;
const rowCssClassName = `${baseCssClassName}__row`;
const navRowCssClassName = `${baseCssClassName}__navRow`;
const staffReplyCssClassName = `${baseCssClassName}__staffReply`;
const staffReplyButtonCssClassName = `${staffReplyCssClassName}__button`;
const messageCssClassName = `${baseCssClassName}__Message`;
const messageNewCssClassName = `${messageCssClassName}-new`;
const cardCssClassName = `${baseCssClassName}__Card`;
const triangleCssClassName = `${messageCssClassName}__triangle`;
const formCssClassName = `${messageCssClassName}__form`;
const editRowCssClassName = `${formCssClassName}__editRow`;
const detailsCssClassName = `${formCssClassName}__details`;
const firstRowCssClassName = `${detailsCssClassName}__firstRow`;
const firstRowInputCssClassName = `${firstRowCssClassName}__input`;
const secondRowCssClassName = `${detailsCssClassName}__secondRow`;
const attachmentsContainerCssClassName = `${messageCssClassName}__attachmentsContainer`;
const attachmentsCssClassName = `${messageCssClassName}__attachments`;
const attachmentFileCssClassName = `${attachmentsCssClassName}__file`;

const messageActionsContainerCssClassName = `${messageCssClassName}__actionsContainer`;
const messageActionsCssClassName = `${messageCssClassName}__actions`;
const messageRejectCssClassName = `${messageActionsCssClassName}__actionButton-reject`;
const messageAcceptCssClassName = `${messageActionsCssClassName}__actionButton-accept`;

const attachmentsMessageCssClassName= `${messageCssClassName}-attachments`;
const editMessageCssClassName= `${messageCssClassName}-edit`;

/*
 *  CSS variable declarations for ConsultRequestMessages
 */
const detailCssClassName = 'ConsultRequestDetail';
const detailCanceledCssClassName = `${detailCssClassName}-canceled`;
const detailCompleteCssClassName = `${detailCssClassName}-complete`;
const detailsRowCssClassName = `${detailCssClassName}__row`;
const titleRowCssClassName = `${detailsRowCssClassName}__title`;
const statusButtonRowCssClassName = `${detailsRowCssClassName}__statusButton`;
const statusCancelButtonCssClassName = `${statusButtonRowCssClassName}-cancel`;
const statusCompleteButtonCssClassName = `${statusButtonRowCssClassName}-complete`;
const statusPrintButtonCssClassName = `${statusButtonRowCssClassName}-print`;
const detailsColumnCssClassName = `${detailsRowCssClassName}__column`;

/*
 *  REGEX for form validators
 *  TODO: should already be moved to config, import from ther
 */


// TODO: this was a test, should be able to delete now
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const textareaRows = 5;

const DateTimePickerAdapter = ({input, meta, items, label, intl, className, disabled, ...rest}) => {
  return (
    <div className={className}>
      <label>{label}</label><br />
      <DateTimePicker
        className={cn("bdDateTimePicker", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
        onChange={val=> {input.onChange(val)}}
        value={input.value}
        locale={intl.locale}
        disabled={disabled}
      />
      <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
        {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
      </div>
    </div>
  )
}

const DayPickerAdapter = ({input, meta, items, label, intl, className, disabled, ...rest }) => {
  return (
    <div className={className}>
      <label>{label}</label><br />
      <DayPickerInput
        inputProps = {{...input, autoComplete: "off",disabled: disabled, className:cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}}
        onDayChange={day=> input.onChange(day)}
        formatDate={formatDate}
        parseDate={parseDate}
        format={MOMENT_DATE_FORMAT || "L"}
        dayPickerProps={{
          locale: intl.locale,
          localeUtils: MomentLocaleUtils,
        }}
        locale = {intl.locale}
        value = {input.value}
        className={cn("form-control Input_Text", {"Input_Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)})}
        placeholder={`${formatDate(new Date(), MOMENT_DATE_FORMAT || "L", intl.locale)}`}
      />
      <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
        {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
      </div>
    </div>
  )}

const TimePickerAdapter = ({input, meta, items, label, intl, className, disabled, ...rest}) => {
  const format = 'h:mm a';
  return (
      <div className={className}>
        <label>{label}</label><br />
        <TimePicker
          showSecond={false}
          className={'bdTimePicker'}
          onChange={value => input.onChange(value)}
          format={format}
          disabled={disabled}
          use12Hours
          inputReadOnly
         />
        <div className={cn("Error__Label", {"Error": (meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit)} )} >
          {(meta.error && meta.touched) || (meta.submitError && !meta.dirtySinceLastSubmit) ? intl.messages[`form.error.${meta.error || meta.submitError}`] : undefined}
        </div>
      </div>
  )}

/*
 * ConsultRequestReplyButton
 * reply component of messages at bottom of all messages
 */
const ConsultRequestReplyButton = ({isStaff, onRequestClick, requestButtonText, onReportClick, reportButtonText}) => {
  if(isStaff){
    return (
      <div className={staffReplyCssClassName}>
        <button
          className={staffReplyButtonCssClassName}
          onClick={onRequestClick}>
          <Add />
          <span>
            {toUpper(requestButtonText)}
          </span>
        </button>
        <button
          className={staffReplyButtonCssClassName}
          onClick={onReportClick}>
          <Add />
          <span>
            {toUpper(reportButtonText)}
          </span>
        </button>
      </div>
    )
  } else {
    return (
      <button
        className={replyCssClassName}
        onClick={onRequestClick}>
        <Add />
        <span>
          {toUpper(requestButtonText)}
        </span>
      </button>
    )
  }
}

class BeginTreatmentButton extends React.Component {
  constructor(props){
    super(props);
  }

  handleClick = () => {
    const { intl, onSuccess, requestId, messageId } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <PopUpCustomUI
            title={startCase(intl.messages['patient.reportReply.treatmentplan.accept.popUp.title'])}
            message={upperFirst(intl.messages['patient.reportReply.treatmentplan.accept.popUp.message'])}
            onClose={onClose}
            cancelText={intl.messages['string.cancel']}
            confirmText={intl.messages['string.confirm']}
            intl={intl}
            requestId = {requestId}
            handleSubmit={this.handleSubmit}
            onSuccess = {this.onSuccess}
          />
        )
      }
    })
  }

  handleSubmit = async () => {
    const { requestId, messageId } = this.props;
    consultRequestsService.confirmTreatmentPlan(requestId, messageId);
  }

  // added messageId and treatmentplan_status fields to data before passing to reducer
  onSuccess = (data) => {
    data.messageId = this.props.messageId;
    data.treatmentplan_status = true;
    this.props.onSuccess(data)
  }

  render(){
    const { intl, buttonClass, disabled } = this.props;

    return (
      <button
        onClick={this.handleClick}
        className={buttonClass}
        disabled={disabled}
      >
        {toUpper(intl.messages['patient.reportReply.treatmentplan.accept'])}
      </button>
    )
  }

}

class RejectTreatmentButton extends React.Component {
  constructor(props){
    super(props);
  }

  handleSubmit = async (values) => {
    const { requestId, messageId } = this.props;
    consultRequestsService.rejectTreatmentPlan(requestId, messageId, values);
  }

  // added messageId and treatmentplan_status fields to data before passing to reducer
  onSuccess = (data) => {
    data.messageId = this.props.messageId;
    data.treatmentplan_status = false;
    this.props.onSuccess(data)
  }

  handleClick = () => {
    const { intl, onSuccess, requestId, messageId } = this.props;
    confirmAlert({
      customUI: ({ onClose }) =>
        <PopUpCustomUIText
          title={startCase(intl.messages['patient.reportReply.treatmentplan.reject.popUp.title'])}
          message={upperFirst(intl.messages['patient.reportReply.treatmentplan.reject.popUp.message'])}
          onClose={onClose}
          cancelText={intl.messages['string.cancel']}
          confirmText={intl.messages['string.confirm']}
          intl={intl}
          requestId = {requestId}
          handleSubmit={this.handleSubmit}
          onSuccess = {this.onSuccess}
        />
    })
  }

  render(){
    const { intl, buttonClass, disabled } = this.props;

    return (
      <button
        onClick={this.handleClick}
        className={buttonClass}
        disabled={disabled}
      >
        {toUpper(intl.messages['patient.reportReply.treatmentplan.reject'])}
      </button>
    )
  }
}

class UpdateRequestStatusButton extends React.Component {
  constructor(props){
    super(props);
  }

  handleSubmit = async (values) => {
    const { requestId } = this.props;

    const convertedValues = convertToAPIValues(values);
    return consultRequestsService.changeRequestStatus(requestId, convertedValues);
  }

  // added messageId and treatmentplan_status fields to data before passing to reducer
  onSuccess = (data) => {
    this.props.onSuccess(data)
  }

  handleClick = () => {
    const { intl, onSuccess, requestId, currentStatus, messageId } = this.props;
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) =>
        <PopUpCustomUIChangeStatus
          title={startCase(intl.messages['popUp.updateStatus.title'])}
          message={upperFirst(intl.messages['popUp.updateStatus.message'])}
          onClose={onClose}
          cancelText={intl.messages['popUp.updateStatus.cancel']}
          confirmText={intl.messages['popUp.updateStatus.confirm']}
          currentStatus={currentStatus}
          intl={intl}
          requestId = {requestId}
          items = {STATUS_CHOICES.map((choice, index) => { return {...choice, label: upperFirst(choice.label)}} )}
          handleSubmit={this.handleSubmit}
          onSuccess = {this.onSuccess}
        />
    })
  }

  render(){
    const { intl, buttonClass, disabled } = this.props;

    return (
      <button
        onClick={this.handleClick}
        className={buttonClass}
        disabled={disabled}
      >
        {toUpper(intl.messages['popUp.updateStatus.init'])}
      </button>
    )
  }
}

class CancelRequestButton extends React.Component {
  constructor(props){
    super(props);
  }

  handleClick = () => {
    const { intl } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <PopUpCustomUI
            title={intl.messages['popUp.cancel.title']}
            message={intl.messages['popUp.cancel.message']}
            onClose={onClose}
            handleSubmit={this.handleSubmit}
            cancelText={intl.messages['string.cancel']}
            confirmText={intl.messages['string.confirm']}
          />
        )
      }
    })
  }

  handleSubmit = () => {
    this.props.handleSubmit()
  }

  render(){
    const { intl, buttonClass, disabled } = this.props;

    return (
      <button
        onClick={this.handleClick}
        className={buttonClass}
        disabled={disabled}
      >
        {toUpper(intl.messages['popUp.cancel.init'])}
      </button>
    )
  }
}

class CompleteRequestButton extends React.Component {
  constructor(props){
    super(props);
  }

  handleClick = () => {
    const { intl, buttonClass } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <PopUpCustomUI
            title={intl.messages['popUp.complete.title']}
            message={intl.messages['popUp.complete.message']}
            onClose={onClose}
            handleSubmit={this.handleSubmit}
            cancelText={intl.messages['string.cancel']}
            confirmText={intl.messages['string.confirm']}
          />
        )
      }
    })
  }

  handleSubmit = () => {
    this.props.handleSubmit()
  }

  render(){
    const { intl, buttonClass, disabled } = this.props;

    return (
      <button
        className={buttonClass}
        onClick={this.handleClick}
        disabled={disabled}
      >
        {toUpper(intl.messages['popUp.complete.init'])}
      </button>
    )
  }
}

/**
 * NewConsultRequestMessageForm
 * new message form triggered by ConsultRequestReplyButton
 * either opens report or message form, dependent on user, button trigger
 */
class NewConsultRequestMessageForm extends React.Component {
  static propTypes = {
      onCancel: PropTypes.func.isRequired,
      onSuccess: PropTypes.func.isRequired,
      requestId: PropTypes.number.isRequired
  }

  constructor(props){
    super(props);
    this.state = {
      uploadIds: {}
    }
  }

  handleSaveMessage = (values) => {
    const { intl, requestId, initialValues, onSuccess } = this.props;

    consultRequestsService.replyConsultRequestMessage(requestId, values)
      .then(
        data => {
          this.props.onSuccess(data);
          return undefined;
        },
        error => {
          return { [FORM_ERROR]: intl.messages['form.error.general_error'] };
        }
      )
  }

  handleSaveReport = (values) => {
    const { intl, requestId, initialValues, onSuccess } = this.props;
    values.attachments = Object.values(this.state.uploadIds);
    values.type = 'REPORT'
    consultRequestsService.replyConsultRequestMessage(requestId, values)
      .then(
        data => {
          this.props.onSuccess(data);
          return undefined;
        },
        error => {
          return { [FORM_ERROR]: intl.messages['form.error.general_error'] };
        }
      )
  }

  handleUploadSuccess = (fileKey, response) => {
    // add item to array
    let currentIds = this.state.uploadIds;
    currentIds[fileKey] = response.id;
    this.setState({uploadIds: currentIds});
  }

  handleUploadRemove = (fileKey) => {
    let currentIds = this.state.uploadIds;
    consultRequestsService.deleteMessageAttachment(this.props.requestId, {upload_id: currentIds[fileKey]})
      .then(
        data => {
          delete currentIds[fileKey];
          this.setState({uploadIds: currentIds});
        },
        error => {
          // dont do anything
        }
      )
  }

  render(){
    const { intl, initialValues, requestId, formType, userInfo, onCancel } = this.props;
    if (formType === 'report' && userInfo.is_staff == true){
      return (
        <div className={cn(messageCssClassName, editMessageCssClassName, attachmentsMessageCssClassName)}>
          <div className={formCssClassName}>
            <Form
              onSubmit={this.handleSaveReport}
              initialValues={initialValues}
              //mutators={{ setFieldData }}
              render = {({
                handleSubmit,
                mutators,
                form,
                reset,
                submitting,
                pristine,
                validating,
                values,
              }) => (
                <form
                  className={detailsCssClassName}
                  id={`requestMessageForm__new`}
                  onSubmit={handleSubmit}
                >
                  <div className={editRowCssClassName}>
                    <button
                      type="button"
                      onClick={onCancel}
                    >
                      {toUpper(intl.messages['patient.addRequestReply.cancel'])}
                    </button>
                    <button
                      type="submit"
                    >
                      {toUpper(intl.messages['patient.addRequestReply.save'])}
                    </button>
                  </div>
                  <div className={firstRowCssClassName}>
                    <Field name="practice_name" validate={composeValidators(required, noChineseCharacters)}>
                      {({ input, meta}) => (
                        <div className={firstRowInputCssClassName}>
                          <label className="Input__Label">{toUpper(intl.messages['patientRequests.hospital'])}</label>
                          <input
                            {...input}
                            className="form-control Input_Text"
                            type="text"
                            disabled={true}
                            placeholder={upperFirst(intl.messages['patientRequests.hospital'])}
                          />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                            {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="dentist_name" validate={required}>
                      {({ input, meta}) => (
                        <div className={firstRowInputCssClassName}>
                          <label className="Input__Label">{toUpper(intl.messages['patientRequests.dentist'])}</label>
                          <input
                            {...input}
                            className="form-control Input_Text" type="text"
                            placeholder={upperFirst(intl.messages['patientRequests.dentist'])}
                          />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                            {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="sent_at" validate={required}>
                      {({ input, meta}) => (
                        <div className={firstRowInputCssClassName}>
                          <label className="Input__Label">{toUpper(intl.messages['string.date'])}</label>
                          <input
                            {...input}
                            value = {localeFormatDate(input.value, intl.locale)}
                            className="form-control Input_Text" type="text"
                            disabled={true}
                            placeholder={upperFirst(intl.messages['string.date'])}
                          />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                            {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className={secondRowCssClassName}>
                    <Field name="body" validate={required}>
                      {({ input, meta}) => (
                        <div>
                          <label className="Input__Label">{toUpper(intl.messages['string.note'])}</label>
                          <textarea
                            {...input}
                            className="form-control Input_Text"
                            rows={textareaRows}
                            type="text"
                            placeholder={toUpper(intl.messages['string.note'])} />
                          <div className={cn("Error__Label",{"Error": meta.error && meta.touched} )} >
                              {(meta.error && meta.touched) ? intl.messages[`form.error.${meta.error}`] : undefined}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                </form>
              )}
            />
            <MessageAttachmentUpload
              intl = {intl}
              className = {attachmentsContainerCssClassName}
              requestId = {requestId}
              onSuccess = {this.handleUploadSuccess}
              onDelete = {this.handleUploadRemove}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className={cn(messageCssClassName, editMessageCssClassName)}>
          <div className={formCssClassName}>
            <div className={editRowCssClassName}>
              <button
                onClick={onCancel}
              >
                {toUpper(intl.messages['patient.addRequestReply.cancel'])}
              </button>
              <button
                onClick={() => {
                  document
                    .getElementById(`requestMessageForm__new`)
                    .dispatchEvent(new Event('submit', { cancelable: true }))
                }}
              >
                {toUpper(intl.messages['patient.addRequestReply.save'])}
              </button>
            </div>
            <Form
              onSubmit={this.handleSaveMessage}
              initialValues={initialValues}
              //mutators={{ setFieldData }}
              render = {({
                handleSubmit,
                mutators,
                form,
                reset,
                submitting,
                pristine,
                validating,
                values,
              }) => (
                <form
                  className={detailsCssClassName}
                  id={`requestMessageForm__new`}
                  onSubmit={handleSubmit}
                >
                  <div className={firstRowCssClassName}>
                    <Field name="practice_name" validate={composeValidators(required, noChineseCharacters)}>
                      {({ input, meta}) => (
                        <div className={firstRowInputCssClassName}>
                          <label className="Input__Label">{toUpper(intl.messages['patientRequests.hospital'])}</label>
                          <input
                            {...input}
                            className="form-control Input_Text"
                            type="text"
                            disabled={true}
                            placeholder={upperFirst(intl.messages['patientRequests.hospital'])}
                          />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                            {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="dentist_name" validate={required}>
                      {({ input, meta}) => (
                        <div className={firstRowInputCssClassName}>
                          <label className="Input__Label">{toUpper(intl.messages['patientRequests.dentist'])}</label>
                          <input
                            {...input}
                            className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched})}type="text"
                            placeholder={upperFirst(intl.messages['patientRequests.dentist'])}
                          />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                            {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="sent_at" validate={required}>
                      {({ input, meta}) => (
                        <div className={firstRowInputCssClassName}>
                          <label className="Input__Label">{toUpper(intl.messages['string.date'])}</label>
                          <input
                            {...input}
                            value = {localeFormatDate(input.value, intl.locale)}
                            className="form-control Input_Text" type="text"
                            disabled={true}
                            placeholder={upperFirst(intl.messages['string.date'])}
                          />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                            {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className={secondRowCssClassName}>
                    <Field name="body" validate={required}>
                      {({ input, meta}) => (
                        <div>
                          <label className="Input__Label">{toUpper(intl.messages['string.note'])}</label>
                          <textarea
                            {...input}
                            className={cn("form-control Input_Text", {"Input_Error": (meta.error || meta.submitError) && meta.touched})}
                            rows={textareaRows}
                            type="text"
                            placeholder={toUpper(intl.messages['string.note'])} />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                              {(meta.error && meta.touched) ? intl.messages[`form.error.${meta.error}`] : undefined}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      )
    }
  }
}

const MessageSubmitButton = ({
  id,
  editView,
  editViewLoading,
  cancelButtonText,
  buttonText,
  handleClick,
  form,
  handleCancelClick
  }) => {
    if (editViewLoading === true){
      return (
        <LoadingEllipsis
          width='60px'
          height='20px'
        />
      )
    }
    if (editView === true){
      return (
        <Fragment>
          <button
            type="button"
            onClick={() => {
              form.reset();
              handleCancelClick();
            }}
          >
            {toUpper(cancelButtonText)}
          </button>
          <button
            onClick={() => form.submit()}
            >
            {toUpper(buttonText)}
          </button>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <button
            type="button"
            onClick={() => handleClick()}
          >
            {toUpper(buttonText)}
          </button>
        </Fragment>
      )
    }
  }

const ConsultRequestSubmitButton = ({
  editView,
  editViewLoading,
  cancelButtonText ,
  buttonText,
  handleClick,
  form,
  handleCancelClick
  }) => {
    if (editViewLoading === true){
      return (
        <LoadingEllipsis
          width='60px'
          height='20px'
        />
      )
    }
    if (editView === true){
      return (
        <Fragment>
          <button
            type="button"
            onClick={() => {
              //form.reset();
              handleCancelClick();
            }}
          >
            {toUpper(cancelButtonText)}
          </button>
          <button
            onClick={() => form.submit()}
            >
            {toUpper(buttonText)}
          </button>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <button
            type="button"
            onClick={() => handleClick()}
          >
            {toUpper(buttonText)}
          </button>
        </Fragment>
      )
    }
  }

class ConsultRequestDetail extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      editView: false,
      editViewLoading: false
    }
  }

  handleEditViewClick = async () => {
    this.setState({editView:true, editViewLoading: false});
  }

  handleCancelClick = async () => {
    this.setState({editView:false, editViewLoading: false});
  }

  handleSaveConsultRequest = async (values) => {
    // handle edit message
    const { intl, requestId } = this.props;
    this.setState({editViewLoading: true})
    const valuesCopy = cloneDeep(values);
    if (valuesCopy.appointment_date){
      //const newDate = moment.tz(moment(values.appointment_date.toISOString()).format('YYYY-MM-DD HH:mm'), "Asia/Bangkok")
      let newDate = moment(valuesCopy.appointment_date).format(API_DATETIME_FORMAT);
      valuesCopy.appointment_date = newDate
    }
    const convertedValues = convertToAPIValues(valuesCopy)

    consultRequestsService.updateRequest(requestId, convertedValues)
      .then(
        data => {
          this.setState({editView: false, editViewLoading: false});
          return undefined;
        },
        error => {
          return { [FORM_ERROR]: intl.messages['form.error.general_error'] };
        }
      )
  }

  render(){
    const { intl, isStaff, requestId, request} = this.props;
    const { editView, editViewLoading } = this.state;
    const buttonText = editView ? intl.messages['string.save'] : intl.messages['string.edit'];
    const initValues = {
      steps: request.steps,
      current_step: request.current_step,
      started_at:request.started_at,
      appointment_date: request.appointment_date
    }

    let editable;
    if ((request.status == 'COMPLETED') || (request.status == 'CANCELED')) {
      editable = false;
    } else {
      editable = true;
    }

    return(
      <div className={cn(detailCssClassName,
        {[detailCanceledCssClassName]: (request.status == 'CANCELED')},
        {[detailCompleteCssClassName]: (request.status == 'COMPLETED')})}
      >
        <Form
          onSubmit={this.handleSaveConsultRequest}
          initialValues={initValues}
          render = {({
            handleSubmit,
            form,
            reset,
            submitting,
            pristine,
            validating,
            values,
          }) => (
            <form
              id={'consultRequestForm'}
              onSubmit={handleSubmit}
            >
              <div className={editRowCssClassName}>
              {editable &&
                <ConsultRequestSubmitButton
                  editView = {editView}
                  editViewLoading = {editViewLoading}
                  buttonText = {buttonText}
                  cancelButtonText = {intl.messages['string.cancel']}
                  handleClick = {this.handleEditViewClick}
                  handleCancelClick = {this.handleCancelClick}
                  form = {form}
                  initValues = {initValues}
                />
              }
              </div>
              <div className={detailsRowCssClassName}>
                <Field
                  className={detailsColumnCssClassName}
                  name="appointment_date"
                  intl={intl}
                  autoComplete={"off"}
                  disabled={ !isStaff || !editView }
                  label={toUpper(intl.messages['newRequestForm.appointment_date'])}
                  component={DateTimePickerAdapter}
                  placeholder={upperFirst(intl.messages['newRequestForm.appointment_date'])}
                />
                <Field name="steps" validate={mustBeNumber}>
                  {({ input, meta}) => (
                    <div className={detailsColumnCssClassName}>
                      <label className="Input__Label">{toUpper(intl.messages['patientRequests.totalSteps'])}</label>
                      <input
                        {...input}
                        className="form-control Input_Text"
                        type="text"
                        disabled={ !isStaff || !editView }
                        placeholder={upperFirst(intl.messages['patientRequests.totalSteps'])}
                      />
                      <div className={cn("Error__Label", {"Error": meta.error && meta.touched})}>
                        {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="completed_at">
                  {({ input, meta}) => (
                    <div className={detailsColumnCssClassName}>
                      <label className="Input__Label">{toUpper(intl.messages['patientRequests.dateCompleted'])}</label>
                      <input
                        {...input}
                        className="form-control Input_Text"
                        type="text"
                        disabled = {!editView}
                        placeholder={upperFirst(intl.messages['patientRequests.dateCompleted'])}
                      />
                      <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )}>
                        {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="current_step">
                  {({ input, meta}) => (
                    <div className={detailsColumnCssClassName}>
                      <label className="Input__Label">{toUpper(intl.messages['patientRequests.currentStep'])}</label>
                      <input
                        {...input}
                        className="form-control Input_Text"
                        type="text"
                        disabled = {!editView}
                        placeholder={upperFirst(intl.messages['patientRequests.currentStep'])}
                      />
                      <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                        {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                      </div>
                    </div>
                  )}
                </Field>
              </div>
              <div className={detailsRowCssClassName}>
              </div>
            </form>
          )}
        />
      </div>
  )}
}

const consultRequestContentCssClassName = 'ConsultRequestContent';
const consultRequestlistCssClassName = 'ConsultRequestList';
const consultRequestListHeadersCssClassName = `${consultRequestlistCssClassName}__Headers`;
const consultRequestProgressCssClassName = 'ConsultRequestProgress';
const consultRequestProgressCardCssClassName = `${consultRequestProgressCssClassName}__card`;


class ConsultRequestProgress extends React.Component{
  constructor(props){
    super(props);
  }

  render(){
    const {messages, intl} = this.props;

    return (
      <div className={cn(consultRequestContentCssClassName,consultRequestProgressCssClassName)} >
        <div className={cn(consultRequestlistCssClassName, 'tbl')}>
          <div className={cn(consultRequestListHeadersCssClassName, 'row')}>
            <div className="cell two">{intl.messages['string.messageDate']}</div>
            <div className="cell two">{intl.messages['string.messageSender']}</div>
            <div className="cell two">{intl.messages['string.messageStatus']}</div>
            <div className="cell four">{intl.messages['string.messageBody']}</div>
          </div>
          {messages &&
            messages.map( (message, ind) => (
            <div class={cn(consultRequestProgressCardCssClassName, 'row')}>
              <div className={'cell two'}>
                <span>{localeFormatDate(message.sent_at, intl.locale , 'LLL' )}</span>
              </div>
              <div className={'cell two'}>
                <span>{message.practice_name}</span>
              </div>
              <div className={'cell two'}>
                <span>{toUpper(intl.messages[`patientRequest.status.${message.extra_fields && message.extra_fields.system_message}`])}</span>
              </div>
              <div className={'cell four'}>
                <span>{message.body}</span>
              </div>
            </div>
          ))}

        </div>
      </div>
    )
  }
}

class ConsultRequestMessage extends React.Component {
  constructor(props){
    super(props);
    this.ref = React.createRef()
    this.state = {
      editView: false,
      editViewLoading: false
    }
  }

  componentDidMount(){
    if (this.props.isSelected){
      this.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    }
  }

  handleEditViewClick = () => {
    this.setState({editView:true, editViewLoading: false});
  }

  handleCancelClick = () => {
    this.setState({editView:false, editViewLoading: false});
  }

  handleSaveMessage = (values) => {
    // save new message
    const { intl, requestId } = this.props;
    this.setState({editViewLoading: true})

    consultRequestsService.replyConsultRequestMessage(requestId, values)
      .then(
        data => {
          this.setState({editView: false, editViewLoading: false});
          return undefined;
        },
        error => {
          return { [FORM_ERROR]: intl.messages['form.error.general_error'] };
        }
      )
  }

  handleEditMessage = (values) => {
    // handle edit message
    const { intl, requestId, message } = this.props;

    consultRequestsService.updateConsultRequestMessage(requestId, message.id, values)
      .then(
        data => {
          this.setState({editView: false});
          return undefined;
        },
        error => {
          return { [FORM_ERROR]: intl.messages['form.error.general_error'] };
        }
      )
  }

  handleAddAttachment = (data) => {
    const { message, reduxDispatch } = this.props;

    reduxDispatch(patientRequestsActions.addMessageAttachment(message.id, data))
  }

  handleRemoveAttachment = (uploadId) => {
    const { intl, requestId, message, reduxDispatch } = this.props;

    consultRequestsService.deleteMessageAttachment(requestId, {upload_id: uploadId} )
      .then(
        data => {
          // TODO: update message's message attachments and remove this id

          reduxDispatch(patientRequestsActions.removeMessageAttachment(message.id, uploadId));
        },
        error => {
          // dont do anything
        }
    )
  }

  render(){
    const { intl, intlLocale, onSuccess, onTreatmentPlanSuccess,
            message, userInfo, requestId, requestStatus,
            reduxDispatch, onAddAttachment } = this.props;
    const { editView, editViewLoading } = this.state;
    var editable;
    var requestEditable = true;
    if ((requestStatus == 4) || (requestStatus == -1)){
      editable = false;
      requestEditable = false;
    } else if (message.sender.dental_practice.id == userInfo.dental_practice.id) {
      editable = true;
    } else {
      editable = false;
    }
    const buttonText = editView ? intl.messages['string.save'] : intl.messages['string.edit'];
    const hasAttachments = message && ((message.attachments.length > 0) || (message.type == 'REPORT'))
    return (
      <Fragment>
        <div className={cn({[editMessageCssClassName] : editView}, messageCssClassName,
                 {[messageNewCssClassName]: message.unread} , {[attachmentsMessageCssClassName]: hasAttachments})}
          ref={this.ref}
        >
          { !editable &&
            <div className={triangleCssClassName}>
            </div>
          }
          <div className= {formCssClassName}>
            <Form
              onSubmit={this.handleEditMessage}
              initialValues={message}
              //mutators={{ setFieldData }}
              render = {({
                handleSubmit,
                mutators,
                form,
                reset,
                submitting,
                pristine,
                validating,
                values,
              }) => (
                <form
                  className={detailsCssClassName}
                  id={`requestMessageForm__${message.id}`}
                  onSubmit={handleSubmit}
                >
                  <div className={editRowCssClassName}>
                    {editable &&
                      <MessageSubmitButton
                        id = {message.id}
                        editView = {editView}
                        editViewLoading = {editViewLoading}
                        buttonText = {buttonText}
                        cancelButtonText = {intl.messages['string.cancel']}
                        handleClick = {this.handleEditViewClick}
                        handleCancelClick = {this.handleCancelClick}
                        form = {form}
                        initValues = {message}
                      />
                    }
                  </div>
                  <div className={firstRowCssClassName}>
                    <Field name="practice_name" validate={composeValidators(required, noChineseCharacters)}>
                      {({ input, meta}) => (
                        <div className={firstRowInputCssClassName}>
                          <label className="Input__Label">{toUpper(intl.messages['patientRequests.hospital'])}</label>
                          <input
                            {...input}
                            className="form-control Input_Text"
                            type="text"
                            disabled={true}
                            placeholder={upperFirst(intl.messages['patientRequests.hospital'])}
                          />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                            {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="dentist_name" validate={required}>
                      {({ input, meta}) => (
                        <div className={firstRowInputCssClassName}>
                          <label className="Input__Label">{toUpper(intl.messages['patientRequests.dentist'])}</label>
                          <input
                            {...input}
                            className="form-control Input_Text" type="text"
                            disabled={!editView}
                            placeholder={upperFirst(intl.messages['patientRequests.dentist'])}
                          />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                            {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                          </div>
                        </div>
                      )}
                    </Field>
                    <Field name="sent_at" validate={required}>
                      {({ input, meta}) => (
                        <div className={firstRowInputCssClassName}>
                          <label className="Input__Label">{toUpper(intl.messages['patientRequests.dateCreated'])}</label>
                          <input
                            {...input}
                            value = {localeFormatDate(input.value, intl.locale)}
                            className="form-control Input_Text" type="text"
                            disabled={true}
                            placeholder={upperFirst(intl.messages['patientRequests.dateCreated'])}
                          />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                            {meta.error && meta.touched && intl.messages[`form.error.${meta.error}`]}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className={secondRowCssClassName}>
                    <Field name="body" validate={required}>
                      {({ input, meta}) => (
                        <div>
                          <label className="Input__Label">{toUpper(intl.messages['string.note'])}</label>
                          <textarea
                            {...input}
                            className="form-control Input_Text"
                            rows={textareaRows}
                            type="text"
                            disabled={!editView}
                            placeholder={toUpper(intl.messages['string.note'])} />
                          <div className={cn("Error__Label", {"Error": meta.error && meta.touched} )} >
                              {(meta.error && meta.touched) ? intl.messages[`form.error.${meta.error}`] : undefined}
                          </div>
                        </div>
                      )}
                    </Field>
                  </div>
                </form>
              )}
            />
            <div className={attachmentsContainerCssClassName}>
              <div className={attachmentsCssClassName}>
                {message.attachments &&
                  message.attachments.map( (attachment, index ) => {
                    return (
                      <MessageAttachment
                        key = {index}
                        index= {index}
                        isStaff = {userInfo.is_staff}
                        editView = {editView}
                        editViewLoading = {editViewLoading}
                        editable = {editable}
                        attachment = {attachment}
                        onRemove = {this.handleRemoveAttachment}
                      />
                    )
                  })
                }
                { editable && editView &&
                    <NewAttachmentDropzone
                      intl = { intl }
                      requestId = {requestId}
                      messageId = {message.id}
                      onSuccess = {onAddAttachment}
                      /*onSuccess = {this.handleAddAttachment}*/
                    />
                }
              </div>
            </div>
          </div>
        </div>
        { (message.type === 'REPORT') && !((message.treatmentplan_status === true || message.treatmentplan_status === false)) &&
          <div className={messageActionsContainerCssClassName}>
            <div className={messageActionsCssClassName}>
              <RejectTreatmentButton
                intl = {intl}
                buttonClass = {messageRejectCssClassName}
                handleSubmit = {this.handleCompleteRequest}
                requestId = {requestId}
                messageId = {message.id}
                onSuccess = {onTreatmentPlanSuccess}
                disabled={(message.treatmentplan_status === true || message.treatmentplan_status === false)}
              />
              <BeginTreatmentButton
                intl = {intl}
                buttonClass = {messageAcceptCssClassName}
                handleSubmit = {this.handleCompleteRequest}
                requestId = {requestId}
                messageId = {message.id}
                onSuccess = {onTreatmentPlanSuccess}
                disabled={(message.treatmentplan_status === true || message.treatmentplan_status === false)}

              />
            </div>
          </div>
        }
      </Fragment>
    )
  }
}

class ConsultRequestMessages extends React.Component {
  static propTypes = {
    requestId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired
  }

  constructor(props){
    super(props);
    this.state = {
      requestFormIsOpen: false,
      requestFormType: 'request',
      printFormIsOpen: false
    }
  }

  componentDidMount(){
    this.props.dispatch(patientRequestsActions.getRequestDetails(this.props.requestId))
  }

  handleRequestFormClose = () => {
    this.setState({requestFormIsOpen : false, requestFormType: 'request'})
  }

  handleRequestFormOpen = () => {
    this.setState({requestFormIsOpen : true, requestFormType: 'request'})
  }

  handleReportFormClose = () => {
    this.setState({requestFormIsOpen : false, requestFormType: 'request'})
  }

  handleReportFormOpen = () => {
    this.setState({requestFormIsOpen : true, requestFormType: 'report'})
  }

  handleOnSuccess = (data) => {
    this.setState({requestFormIsOpen:false})
    this.props.dispatch(patientRequestsActions.updateConsultRequestMessagesList(data));
  }

  handleTreatmentPlanOnSuccess = (data) => {
    this.props.dispatch(patientRequestsActions.updateConsultRequestMessageTreatmentPlan(data));
  }

  handleAddAttachment = (messageId, data) => {
    try{
      this.props.dispatch(patientRequestsActions.addMessageAttachment(messageId, data));
    } catch {
      this.props.dispatch(patientRequestsActions.addMessageAttachment(messageId, data));
    }
  }

  handleCancelRequest = async () => {
    const { intl, requestId, patientId, history } = this.props;
    const requestUrl = URLS.patientPageTabReports.replace('{patientId}', patientId);
    const onClick = () => {
      history.push(requestUrl)
    }
    consultRequestsService.changeRequestStatus(requestId, {status: 'CANCELED'})
      .then(
        data => {
          this.props.dispatch(patientRequestsActions.updateRequestDetails(requestId, {status: 'CANCELED'}));
          confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
              return (
                <ConfirmAlertUrlUI
                  title={intl.messages['popUp.cancel.title']}
                  message={intl.messages['popUp.cancel.message']}
                  cancelText={intl.messages['string.close']}
                  confirmText={intl.messages['string.confirm']}
                  onClick={onClick}
                  onClose={onClose}
                />
              )
            }
          })
        },
        error => {
          errorAlert(intl)
        }
      )
  }

  handleCompleteRequest = async () => {
    const { intl, requestId, patientId, history } = this.props;
    const requestUrl = URLS.patientPageTabReports.replace('{patientId}', patientId);
    const onClick = () => {
      history.push(requestUrl)
    }
    consultRequestsService.changeRequestStatus(requestId, {status: 'COMPLETED'})
      .then(
        data => {
          this.props.dispatch(patientRequestsActions.updateRequestDetails(requestId, {status: 'COMPLETED'}));
          confirmAlert({
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
              return (
                <ConfirmAlertUrlUI
                  title={intl.messages['popUp.cancel.title']}
                  message={intl.messages['popUp.cancel.message']}
                  cancelText={intl.messages['string.close']}
                  confirmText={intl.messages['string.confirm']}
                  onClick={onClick}
                  onClose={onClose}
                />
              )
            }
          })
        },
        error => {
          errorAlert(intl)
        }
      )
  }

  handleUpdateRequestStatus = async () => {
    const { intl, requestId, patientId,  history } = this.props;
    const requestUrl = URLS.patientPageTabReports.replace('{patientId}', patientId);
    const onClick = () => {
      return
    }
    consultRequestsService.changeRequestStatus(requestId, {status: 4})
      .then(
        data => {
          this.props.dispatch(patientRequestsActions.updateRequestDetails(requestId, {status: 4}));
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <ConfirmAlertUrlUI
                  title={intl.messages['popUp.updateStatus.title']}
                  message={intl.messages['popUp.updateStatus.message']}
                  cancelText={intl.messages['string.close']}
                  confirmText={intl.messages['string.confirm']}
                  onClick={onClick}
                  onClose={onClose}
                />
              )
            }
          })
        },
        error => {
          errorAlert(intl)
        }
      )
  }

  handleChangeRequestStatusOnSuccess = (data) => {
    this.props.dispatch(patientRequestsActions.changeRequestStatus(this.props.requestId, data));
  }

  handlePrintRequestClick = () => {
    this.setState( (state) => ({ printFormIsOpen: (state.printFormIsOpen) ? false : true } ))
  }

  render(){

    const {
      currentRequest, currentRequestIsLoading, currentRequestIsError
    } = this.props.patientRequests;
    const { userInfo } = this.props.users;
    const { msgId, patientId } = this.props;

    const {
      intl, intlLocale, requestId, onSubmit
    } = this.props;

    const currentPatientUrl = URLS.patientPageTabReports.replace('{patientId}', patientId);
    const dentistName = intl.messages['string.usersFullName'].replace('{familyName}', userInfo.last_name).replace('{givenName}', userInfo.first_name)
    const initialValues = {
      sent_at: "-",
      dentist_name: dentistName,
      practice_name: userInfo.dental_practice && userInfo.dental_practice.practice_name || ""
    }

    return (
      <div className={baseCssClassName}>
      {
        this.state.printFormIsOpen &&
        <WorkRequestForm
          onClose={this.handlePrintRequestClick}
        />
      }
        <div className={''}>
          {/* dependent on edit mode */}
          <div className={navRowCssClassName}>
            <Link to={currentPatientUrl}>
              <ArrowBackIos /> {toUpper(intl.messages['patientRequests.backtoRequests'])}
            </Link>
          </div>
          <div className={rowCssClassName}>
            <div className={'sectionTitle'}>
              {toUpper(intl.messages['patientRequests.detailTitle'])}
              <span className={'sectionTitle__statusLabel'}>{toUpper(intl.messages['string.date'])}</span>
              <span className={''}>{localeFormatDate(currentRequest.appointment_date || currentRequest.created_at, intl.locale)}</span>
              <span className={'sectionTitle__statusLabel'}>{toUpper(intl.messages['string.requestStatus'])}</span>
              <span className={`sectionTitle__status-${intl.messages[`patientRequest.status.${currentRequest.status}`]}`}>{toUpper(intl.messages[`patientRequest.status.${currentRequest.status}`])}</span>
            </div>
            <div className={statusButtonRowCssClassName}>
              { (currentRequest.status !== 'COMPLETED' && currentRequest.status !== 'CANCELED') &&
                <Fragment>
                  <UpdateRequestStatusButton
                    intl = {intl}
                    buttonClass = {statusCancelButtonCssClassName}
                    requestId = {requestId}
                    currentStatus = {currentRequest.status}
                    onSuccess = {this.handleChangeRequestStatusOnSuccess}
                    disabled={(currentRequest.status == 'CANCELED') || (currentRequest.status == 'COMPLETED')}
                  />
                  <CancelRequestButton
                    intl = {intl}
                    buttonClass = {statusCancelButtonCssClassName}
                    handleSubmit = {this.handleCancelRequest}
                    disabled={(currentRequest.status == 'CANCELED') || (currentRequest.status == 'COMPLETED')}
                  />
                  <CompleteRequestButton
                    intl = {intl}
                    buttonClass = {statusCompleteButtonCssClassName}
                    handleSubmit = {this.handleCompleteRequest}
                    disabled={(currentRequest.status == 'CANCELED') || (currentRequest.status == 'COMPLETED')}
                  />
                </Fragment>
              }
              <button
                onClick={this.handlePrintRequestClick}
                className={statusPrintButtonCssClassName}
                disabled={/*currentRequest.status !== 4*/ false}
              >
                {toUpper(intl.messages['string.print'])}
              </button>
            </div>
          </div>
          {currentRequestIsLoading && <RequestDetailLoader /> }
          {!currentRequestIsLoading &&
            <ConsultRequestDetail
              intl = {intl}
              requestId = {requestId}
              request = {currentRequest}
              requestUrl = {currentPatientUrl}
              reduxDispatch = {this.props.dispatch}
              history = {this.props.history}
              isStaff = {userInfo.is_staff}
            />
          }
          <div className={rowCssClassName}>
            <div className={'sectionTitle'}>
              {toUpper(intl.messages['patientRequests.updates'])}
            </div>
          </div>
          { /* system messages of status changes */
            currentRequest.messages &&

            <ConsultRequestProgress
              intl={intl}
              createdDate = {currentRequest.created_at}
              messages= {filter(currentRequest.messages, {'type' : 'SYSTEM'})}
            />
          }
          <div className={rowCssClassName}>
            <div className={'sectionTitle'}>
              {toUpper(intl.messages['patientRequests.messages'])}
            </div>
          </div>
          { currentRequest.messages &&
            currentRequest.messages.map((message, index) => {

              if (["UPLOAD", "SYSTEM"].indexOf(message.type) !== -1 && !userInfo.is_staff) {
                return
              } else {
                return (
                  <ConsultRequestMessage
                    key = {message.id}
                    index = {index}
                    reduxDispatch = {this.props.dispatch}
                    onAddAttachment = {this.handleAddAttachment}
                    isSelected = {msgId ? msgId == message.id : index === currentRequest.messages.length-1}
                    intl = {intl}
                    intlLocale = {intlLocale}
                    message = {message}
                    requestId = {requestId}
                    requestStatus = {currentRequest.status}
                    userInfo = {userInfo}
                    onSuccess = {this.handleOnSuccess}
                    onTreatmentPlanSuccess = {this.handleTreatmentPlanOnSuccess}
                  />
                )
              }
            })
          }
          { !(this.state.requestFormIsOpen || currentRequest.status === 'COMPLETED' || currentRequest.status === 'CANCELED') &&
            <ConsultRequestReplyButton
              isStaff = {userInfo.is_staff}
              onRequestClick = {this.handleRequestFormOpen}
              requestButtonText = {intl.messages['patient.addRequestReply.button']}
              onReportClick = {this.handleReportFormOpen}
              reportButtonText = {intl.messages['patient.addReportReply.button']}
            />
          }
          { (this.state.requestFormIsOpen && currentRequest.status !== 'COMPLETED' && currentRequest.status !== 'CANCELED') &&
            <NewConsultRequestMessageForm
              intl = {intl}
              requestId = {requestId}
              formType = {this.state.requestFormType}
              initialValues = {initialValues}
              userInfo = {userInfo}
              onCancel = {this.handleRequestFormClose}
              onSuccess = {this.handleOnSuccess}
            />
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state){
  const { patientRequests, users, intl } = state;
  return { patientRequests, users, intl};
}

const connectedConsultRequestMessages = withRouter(connect(mapStateToProps)(ConsultRequestMessages));
export { connectedConsultRequestMessages as ConsultRequestMessages };
