import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { history } from '../_helpers';

import cn from 'classnames';

import { upperFirst, toUpper, transform, pickBy } from 'lodash';

import { authHeader, handleResponse } from '../_helpers';
import { API_URLS, REQUIRED_IMAGES, requestImageFields } from '../_config';

import DropzoneComponent from '../DropzoneComponent';

import '../../css/dropzone.scss';
import '../../css/imageSetUpload.style.scss';

import Add from '@material-ui/icons/Add';
import Cancel from '@material-ui/icons/Cancel';

const baseCssClassName = "ImageSetUpload";

const imagesHeaderCssClassName = `${baseCssClassName}__ImagesHeader`;
const formContainerCssClassName = `${baseCssClassName}__formContainer`;
const formCssClassName = `${baseCssClassName}__form`;
const imagesCssClassName = `${formCssClassName}__images`;
const imagesRowCssClassName = `${imagesCssClassName}__row`;
const formSubmitCssClassName = `${formCssClassName}__actions`;
const submitButtonCssClassName = `${formSubmitCssClassName}__submitButton`;

const singleImageCssClassName = "SingleImageUploadBox";
const singleImageHeaderCssClassName = `${singleImageCssClassName}__header`;
const singleImageDetailCssClassName = `${singleImageCssClassName}__detail`;
const dropzoneCssClassName = `${singleImageCssClassName}__dropzone`;
const dropzoneRemoveiconCssClassName= 'removeIcon';
const dropzoneImagepreviewCssClassName = 'dz-image__customImagePreview';
const dropzoneDetailsCssClassName = 'dz-details__customImageDetails';
const dropzoneSizeCssClassName = 'dz-size__customImageSize';
const dropzoneFilenameCssClassName = 'dz-filename__customFilename';
const dropzoneSuccessmarkCssClassName = 'dz-success-mark__customSuccessMark';
const dropzoneErrormarkCssClassName = 'dz-success-mark__customErrorMark';

const previewTemplate = (fileUrl, onDelete) => (
    <div class="dz-preview dz-processing dz-image-preview dz-success dz-complete">
      <div class="dz-image dz-image__customImagePreview">
        <img data-dz-thumbnail="true" alt="filename" src={fileUrl} />
      </div>
      <div class="dz-details dz-details__customImageDetails">
        <div class="removeIcon"><div class="iconWrapper" onClick={onDelete} data-dz-remove="true">
          <svg class="MuiSvgIcon-root-1" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
            <path fill="none" d="M0 0h24v24H0z"></path></svg></div></div><div class="dz-size dz-size__customImageSize" data-dz-size=""><strong>0.3</strong> MB</div><div class="dz-filename dz-filename__customFilename">
              <span data-dz-name="true">download (1).png</span></div></div><div class="dz-progress">
              <span class="dz-upload" data-dz-uploadprogress="true" style="width: 100%;"></span>
            </div>
            <div class="dz-error-message">
              <span data-dz-errormessage="true"></span>
            </div>
            <div class="dz-success-mark dz-success-mark__customSuccessMark"><span>✔</span></div>
            <div class="dz-error-mark dz-success-mark__customErrorMark"><span>✘</span></div>
    </div>
)


class SingleImageUploadBox extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      uploaded: false,
      upload_id: null
    }
  }

  componentDidMount(){
    // deal with initialization
  }

  render() {

    const { imageKey, imageTypes, label, intl, previewImage, patientId, error,
            consultRequestId, onSuccess, onDelete } = this.props;

    /* setup for react-dropzone-component */
    const postUrl = API_URLS.createConsultRequestUpload.url.replace("{requestId}", consultRequestId);
    var componentConfig = {
      postUrl: postUrl
    };
    var corsHeaders = {"Cache-Control": ""}
    var djsConfig = {
      //addRemoveLinks: true,
      acceptedFiles: imageTypes, //"image/jpeg,image/png,image/gif, image/jfif", "",
      parallelUploads: 1,
      autoProcessQueue: true,
      thumbnailWidth: 150,
      thumbnailHeight: 150,
      headers: {...corsHeaders,  ...authHeader()},
      // renderToStaticMarkup uploads plain HTML, can't put event listeners
      // event listeners are added by dropzone component (3rd party source code)
      previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div className="dz-preview dz-file-preview">
          <div className="dz-image dz-image__customImagePreview">
            <img data-dz-thumbnail="true" />
          </div>
          <div className="dz-details dz-details__customImageDetails" >
            <div className={dropzoneRemoveiconCssClassName}>
              <div className="iconWrapper" data-dz-remove="true"><Cancel /></div>
            </div>
            <div className="dz-size dz-size__customImageSize" data-dz-size=""></div>
            <div className="dz-filename dz-filename__customFilename"><span data-dz-name="true"></span></div>
          </div>
          <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
          <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
          <div className="dz-success-mark dz-success-mark__customSuccessMark"><span>✔</span></div>
          <div className="dz-error-mark dz-success-mark__customErrorMark"><span>✘</span></div>
        </div>
      )
    };
    var eventHandlers = {
      init: dz => this.dropzone = dz,
      addedfile: (file) => file.group = imageKey,
      sending: (file, xhr, formData) => {
        formData.append('image_group', imageKey);
        formData.append('patient_id', patientId);
      },
      success: (file, response) => {
        this.setState({uploaded:true, upload_id:response.id});
        onSuccess(imageKey);
      },
      removedfile: (file) => {
        onDelete(imageKey, this.state.upload_id);
      }
    };

    var spanParams = {};
    //TODO: undo this at some point
    //if ((imageTypes !== null) && (imageTypes.indexOf("image/") === -1)){
    //  spanParams = {tooltip: intl.messages['tooltip.imageUpload.fileType'].replace('{fileType}', toUpper(imageTypes)) , 'tooltip-position': 'top' };
    //}

    // status (need reupload or not)
    // preview... already has preview image

    return (
      <div className={cn(singleImageCssClassName, `${singleImageCssClassName}-${imageKey}`, {'Error':error})}>
        <div className={singleImageHeaderCssClassName} >
          <span {...spanParams}>{intl.messages[`newRequestForm.${imageKey}`] }</span>
        </div>
        <div className={singleImageDetailCssClassName}>
          <DropzoneComponent
            className={dropzoneCssClassName}
            config={componentConfig}
            eventHandlers={eventHandlers}
            djsConfig={djsConfig}
          >
            <div className="dz-message">
              <Add viewBox={'0 0 30 30'} style={{height:20, width:20, viewBox:'0 0 30 30'}} />
            </div>
            {/* previewImage &&

            */}
          </DropzoneComponent>
        </div>
      </div>
    )
  }
}

class ImageSetUpload extends React.Component {
  static propTypes = {
      onSuccess: PropTypes.func.isRequired,
      onDelete: PropTypes.func.isRequired,
      handleCompleteClick: PropTypes.func.isRequired,
      consultRequestId: PropTypes.number.isRequired,
      patientId: PropTypes.number.isRequired,
      imageSet: PropTypes.object,
  }

  constructor(props){
    super(props);
  }

  componentDidMount(){
    //TODO: load images and inital state?
  }

  render(){
    const { intl, consultRequestId, patientId, errorImages,
          onSuccess, onDelete, handleCompleteClick} = this.props;
    const firstRow = requestImageFields[0];
    const secondRow = requestImageFields[1];
    const thirdRow = requestImageFields[2];
    return (
      <div className={baseCssClassName}>
        <div className={formContainerCssClassName}>
        <div className={formCssClassName}>
          <div className={imagesCssClassName}>
            <div className={imagesRowCssClassName}>
              {
                firstRow.map((element, index) =>
                  <SingleImageUploadBox
                    key={element.imageKey}
                    onSuccess={onSuccess}
                    intl={intl}
                    onDelete = {onDelete}
                    consultRequestId = {consultRequestId}
                    patientId = {patientId}
                    error = {errorImages.indexOf(element.imageKey) !== -1}
                    {...element} />)
              }
            </div>
            <div className={imagesRowCssClassName}>
              {
                secondRow.map((element, index) =>
                  <SingleImageUploadBox
                    key={element.imageKey}
                    onSuccess={onSuccess}
                    intl={intl}
                    onDelete = {onDelete}
                    consultRequestId = {consultRequestId}
                    patientId = {patientId}
                    error = {errorImages.indexOf(element.imageKey) !== -1}
                    {...element} />)
              }
            </div>
            <div className={imagesRowCssClassName}>
              {
                thirdRow.map((element, index) =>
                  <SingleImageUploadBox
                    key={element.imageKey}
                    onSuccess={onSuccess}
                    intl={intl}
                    onDelete = {onDelete}
                    consultRequestId = {consultRequestId}
                    patientId = {patientId}
                    error = {errorImages.indexOf(element.imageKey) !== -1}
                    {...element} />)
              }
            </div>
          </div>
        </div>
        </div>
        <div className={formSubmitCssClassName}>
          <button
            className={submitButtonCssClassName}
            onClick={handleCompleteClick}
            >
            {toUpper(intl.messages['newRequestForm.submitButton'])}
          </button>
        </div>
      </div>
    )
  }

}

export { ImageSetUpload };
