import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import cn from 'classnames';

import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from "final-form";

import { LanguageBar } from '../HomePage';

import { toUpper, upperFirst } from 'lodash';

import { URLS } from '../_config';

import { userConstants } from '../_constants';
import { userActions } from '../_actions';
import { userService } from '../_services';

import '../../css/login.style.scss';

// components are from ai project
import Spinner from '../_components/Spinner';
import Alignment from "../_components/Alignment/Alignment";

import Button from '../_components/Button/Button';

const baseCssClassName = 'Login';
const formCssClassName = `${baseCssClassName}__Form`;
const formHeaderCssClassName = `${formCssClassName}__Header`;
const formErrorCssClassName = `${formCssClassName}__FormError`;
const formLogoCssClassName = `${formCssClassName}__Logo`;
const formMainCssClassName = `${formCssClassName}__Form-main`;
const formSecondaryCssClassName = `${formCssClassName}__Form-secondary`;
const formButtonCssClassName = `${formCssClassName}__FormButton`;
const messageCssClassName = `${formCssClassName}__Message`;
const messageTextCssClassName = `${formCssClassName}__MessageText`;
const formHelpCssClassName = `${formCssClassName}__Help`;
const formForgotTextCssClassName = `${formCssClassName}__forgotText`;
const formRegisterTextCssClassName = `${formCssClassName}__registerText`;
const formRegisterTextOneCssClassName = `${formRegisterTextCssClassName}__LineOne`;
const formRegisterTextTwoCssClassName = `${formRegisterTextCssClassName}__LineTwo`;

const languageBarCssClassName = ''

/* form helpers */
const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

const required = value => (value ? undefined : 'required')
const mustBeNumber = value => (isNaN(value) ? 'must_be_number' : undefined)
const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)
const noChineseCharacters = str => (REGEX_CHINESE.test(str) ? 'no_chinese_characters' : undefined)


class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // 'isLoading' is for giving user feedback, gives user sense that website
      // is working and doing stuff
      isLoading: false,
      user: null,
      verificationCode: "",
    };
  }

  // handles form submission
  handleLogin = async (values) => {
    const { intl } = this.props;
    try{
      const user = await userService.login(values);
      this.props.dispatch({ type: userConstants.LOGIN_SUCCESS, user })
      if (this.props.history.location.state != undefined){
          this.props.history.push(this.props.history.location.state.from.pathname);
      } else {
          this.props.history.push("/");
      }
    } catch (err){
      return {
        ...err,
        [FORM_ERROR] : upperFirst(intl.messages['loginForm.generalError'])

      }
    }
  }

  /*_renderNewPasswordForm(){
    return (
      <Alignment
        horizontal={Alignment.horizontal.CENTER}
        vertical={Alignment.horizontal.CENTER}
      >
        <div className={formCssClassName}>
          <Form
            onSubmit={this.onSubmitDetails}
            initialValues = {{patient_name:this.state.initialPatient}}
            //mutators={{ setFieldData }}
            render = {({
              submitError,
              formError,
              handleSubmit,
              mutators,
              form,
              reset,
              submitting,
              pristine,
              validating,
              values
            }) => (
              <form>
                <div className={formLogoCssClassName} >
                  <span className={`${formLogoCssClassName}__Main`} onClick={this.props.onClickLogo}>
                    BEFOREDENT.<span className={`${formLogoCssClassName}__Secondary`}>AI</span>
                  </span>
                </div>
                <div className={formErrorCssClassName}>{this.state.errorMessage}</div>
                <div className={formMainCssClassName}>
                  <FormLabel
                    element={
                      (
                        <FormInput
                          name={'newPassword'}
                          value={this.state.newPassword}
                          type={'password'}
                          isInvalid={this.state.wrongFields.indexOf('newPassword') !== -1}
                          errorMessage={'Can\t be blank'}
                          onChange={this.handleChange}
                        />
                      )
                    }
                    text={tempi18n['labels.newPassword']}
                    textPosition={FormLabel.textPosition.TOP}
                  />
                  <FormLabel
                    element={
                      (
                        <FormInput
                          name={'confirmPassword'}
                          value={this.state.confirmPassword}
                          type={'password'}
                          isInvalid={this.state.wrongFields.indexOf('confirmPassword') !== -1}
                          errorMessage={'Can\t be blank'}
                          onChange={this.handleChange}
                        />
                      )
                    }
                    text={tempi18n['labels.confirmPassword']}
                    textPosition={FormLabel.textPosition.TOP}
                  />
                </div>
                <div className={formButtonCssClassName}>
                  <Button
                    size={Button.AVAILABLE_SIZES.LARGE}
                    theme={Button.AVAILABLE_THEMES.PRIMARY}
                    title={tempi18n['button.login']}
                    onClick={ this.handleChangePassword }
                    disabled={!this.validateChangePassword}
                  >
                    {tempi18n['button.changePassword']}
                  </Button>
                </div>
                </form>
              </div>
              <div className={formHelpCssClassName}>
                <div className={formHelpTextCssClassName}>
                  <span onClick={this.handleForgotClick}>{tempi18n['help.forgot']}</span>
                </div>
                <div className={formHelpTextCssClassName}>
                  <span onClick={this.handleRegisterClick}>{tempi18n['help.register']}</span>
                </div>
              </div>
            )}
          />
        </div>
      </Alignment>
    )
  }
  */
  _renderLoginForm(){
    const { intl } = this.props;
    return (
      <Alignment
        horizontal={Alignment.horizontal.CENTER}
        vertical={Alignment.horizontal.CENTER}
      >
        <div className={formCssClassName}>
        <Form
          onSubmit={this.handleLogin}
          //mutators={{ setFieldData }}
          render = {({
            submitError,
            formError,
            handleSubmit,
            mutators,
            form,
            reset,
            submitting,
            pristine,
            validating,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              >
              <div className={formLogoCssClassName} >
                <span className={`${formLogoCssClassName}__Main`}>
        					BEFOREDENT<span className={`${formLogoCssClassName}__Secondary`}></span>

        				</span>
              </div>
              <div className={formHeaderCssClassName}>
                <span>{toUpper(intl.messages['loginForm.login'])}</span>
                <div className={cn(formErrorCssClassName)}>
                  {submitError && <div className="Error">{submitError}</div>}
                </div>
              </div>
              <div className={formMainCssClassName}>
                {validating && <Spinner />}
                <Field name="email" validate={composeValidators(required)}>
                  {({ input, meta}) => (
                    <div className="">
                      <input {...input} className="form-control Input_Text" type="email" placeholder={upperFirst(intl.messages['loginForm.email'])} />
                      <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                        {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="password" validate={composeValidators(required)}>
                  {({ input, meta}) => (
                    <div className="">
                      <input {...input} className="form-control Input_Text" type="password" placeholder={upperFirst(intl.messages['loginForm.password'])} />
                      <div className={cn("Error__Label", {"Error": (meta.error || meta.submitError) && meta.touched} )} >
                        {(meta.error || meta.submitError) && meta.touched && intl.messages[`form.error.${(meta.error || meta.submitError)}`]}
                      </div>
                    </div>
                  )}
                </Field>
              </div>
              <div className={formButtonCssClassName}>
                <Button
                    disabled={validating}
                    type={"submit"}
                  >
                  {toUpper(intl.messages['loginForm.login'])}
                </Button>
              </div>
            </form>
          )}
        />
          <div className={formHelpCssClassName}>
            <div className={formForgotTextCssClassName}>
              <Link
                to={URLS.forgotPassword}
              >
                <span>{intl.messages['loginForm.forgot']}</span>
              </Link>
            </div>
          </div>
          <div className={formHelpCssClassName}>
            <div className={formRegisterTextCssClassName}>
                <span className={formRegisterTextOneCssClassName}>{intl.messages['loginForm.registerText']}</span>
              <Link
                to={'/'}
                className={formRegisterTextTwoCssClassName}
              >
                <span>{toUpper(intl.messages['loginForm.register'])}</span>
              </Link>
            </div>
          </div>
        </div>
      </Alignment>
    )
  }

  render() {
    return (
      <div className={baseCssClassName}>
        <LanguageBar />
        {this._renderLoginForm()}
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { intl } = state;
    return {
        intl
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
