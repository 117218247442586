import React, {Fragment} from 'react';
import { Router, withRouter } from 'react-router-dom';
import { history } from '../_helpers';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Async } from 'react-select';
import ReactTooltip from 'react-tooltip';
import LoadingDialog from '../_components/LoadingDialog/LoadingDialog';
import { NavBarSearchInput, AddPatientForm, NotificationsIcon } from './NavBarParts';
import { CustomAvatar } from '../_components/Parts';
//import { ActivityLog } from '../ActivityLog';
import { ActivityNotifications } from '../ActivityNotifications';
import { Avatar } from 'react-avatar';
import { userActions, settingsActions, formsActions, alertActions } from '../_actions';
import { reduxPollingNamespace } from '../ActivityNotifications/reducer';
import { filter } from 'lodash';

import Idle from 'react-idle';

import { FormattedMessage } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem  from '@material-ui/core/MenuItem';
import Popover  from '@material-ui/core/Popover';

//header images
import headerNotifications from '../../images/shared/header-notifications.png';
import userPhoto from '../../images/shared/user-photo.png';

// for fetch
import { authHeader } from '../_helpers';
import { BASE_URL, URLS, API_URLS } from '../_config';

import localeData from '../../locales/data.json';
import { updateIntl } from 'react-intl-redux'

import cn from 'classnames';


const headerMenuDropdownCssClassName = "Header__menu__dropdown__menu";
const headerMenuDropdownItemsCssClassName = `${headerMenuDropdownCssClassName}__item`

class BDIdle extends React.Component{
  constructor(props){
    super(props);

    this.onIdle = this.onIdle.bind(this);
  }

  onIdle(){
      alert("You will be logged out")
  }

  render(){
    const {onChange} = this.props;
    return (
      <Idle
        timeout={1000 * 60 * 10} // in milliseconds
        onChange={({idle}) => {
          if (idle) {
            this.onIdle()
          }
        }}
      />
    )
  }
}


class NavBarPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          query: "",
          anchorEl: null,
          anchorElNotification: null
        }

        this.handleLogOut = this.handleLogOut.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleSearchLoading = this.handleSearchLoading.bind(this);
    }

    componentDidMount() {
      // since navbar mounts every different page (url) then only getInfo on initialization
      // userInfo === {}
        this.props.users && !this.props.users.userInfo.id && this.props.dispatch(userActions.getInfo());
        //this.props.dispatch(settingsActions.preloadFormData());
    }

    handleSearchChange(value){
      console.log(this.props)
      console.log("searchChange, ", value)
      //history.push(this.props.location)
      //window.location.href = "/"+value.value
      this.setState({query: value})
      this.props.dispatch(settingsActions.setSettingValue({adminCurrentDentalPractice: value}));
      history.push("/");
    }

    handleSearchClick(value, event){
      console.log(value);
      this.props.dispatch(settingsActions.setSettingValue({adminCurrentDentalPractice: value}));
      //window.location.href= optionVal;
    }

    handleSearchLoading(input){
      if (!input) {
        return Promise.resolve({ options: [] });
      }
      const requestOptions = {
          method: 'GET',
          headers: authHeader()
      };
      let url = API_URLS.dentalPracticeSearch.url+"?q="+input;
      return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((json) => {
        return { options: json.search_list };
      });
    }

    handleNotificationClick = (event) => {
      console.log("notification clicked: ", event)
    }

    handleNotificationClose = () => {
      this.setState({anchorElNotification: null});
    }

    handleMenuClick = (event) => {
      this.setState({anchorEl: event.currentTarget});
    }

    handleMenuClose = () => {
      this.setState({anchorEl: null});
    }

    handleMenuLinkClick = (item) => {
      console.log("clicked on a menu clickl")
      if (item == "settings"){
      } else if (item == "changeLanguage") {
        if (this.props.intl.locale == "en-us") {
            this.props.dispatch(settingsActions.setLanguage('th-th'))
        } else {
          this.props.dispatch(settingsActions.setLanguage('en-us'))
        }
      } else if (item === "testNotification"){
          this.props.dispatch(alertActions.show({type: 'general.error500'}))
      } else {
        this.handleMenuClose();
      }
    }

    showMenu = () => {

    }

    handleLogOut(){
      console.log("clicked on logout")
      this.props.dispatch(userActions.logout());
      history.push("/login");
    }

    render() {
        const { user, users, loggedIn, forms, intl, settings, activityNotifications } = this.props;
        const { anchorEl, anchorElNotification } = this.state;
        const { userInfo, loadingProfile } = users;
        const { user_role, is_staff, first_name, last_name, avatar } = userInfo;
        const notificationAlert = null;//not used yet
        var userType, userName, avatarUserName;
        if (user_role == "DEN"){
          userType = "Dentist"
        }else if (user_role == "HYG"){
          userType = "Hygenist"
        }else if (user_role == "ADM"){
          userType = "Admin"
        } else {
          userType=""
        }
        avatarUserName = last_name && first_name && intl.messages['string.usersFullName'].replace('{familyName}', last_name).replace('{givenName}', first_name)
        if (loggedIn == true){
            return (
              <Fragment>
                <header className="Header">

                  <ReactTooltip
                    id={'header_notification'}
                    globalEventOff='click'
                    className="Header__notifications__dropdown"
                    place='bottom'
                    effect='solid'
                    offset={{right:70, top: 4}}
                    afterShow={this.handleNotificationClick}
                    type='light'
                    clickable={true}
                    getContent = {(dataTip) => <ActivityNotifications history={this.props.history}/>}
                  />
                  <ReactTooltip
                    id={'header_menu'}
                    globalEventOff='click'
                    className="Header__menu__dropdown"
                    place='bottom'
                    offset={{left:72}}
                    effect='solid'
                    type='light'
                    clickable={true}
                    afterShow={this.showMenu}
                    getContent = {(dataTip) => (
                      <div className={headerMenuDropdownCssClassName}>
                        <Link to={URLS.userSettingsPage}>
                          <div className={headerMenuDropdownItemsCssClassName}>
                            <FormattedMessage
                                id={'navbar.menu.settings'}
                                description= {'Settings'}
                                defaultMessage = {intl.messages['string.settings']}
                            />
                          </div>
                        </Link>
                        {/*<div className={headerMenuDropdownItemsCssClassName}
                          onClick={() => this.handleMenuLinkClick("changeLanguage")}>
                          <FormattedMessage
                              id={'string.change_language'}
                              description= {'Settings Language'}
                              defaultMessage = {'Change Language'}
                          />
                        </div>
                        <div className={headerMenuDropdownItemsCssClassName}
                          onClick={() => this.handleMenuLinkClick("changeLanguage")}>
                          <FormattedMessage
                              id={'test'}
                              description= {'Test'}
                              defaultMessage = {'Test Dialog'}
                          />
                        </div>*/}
                        <div className={headerMenuDropdownItemsCssClassName}
                          onClick={this.handleLogOut}>
                          <FormattedMessage
                            id={'navbar.menu.logout'}
                            description= {'Logout Button'}
                            defaultMessage = {intl.messages['navbar.logout']}
                          />
                        </div>
                      </div>
                    )}
                  />
                  <span className="Header__logo" href="/"><Link to="/">BEFOREDENT</Link></span>
                  <div className="Header__right-wrapper">
                    { is_staff &&
                      <span className="Header__search-wrapper">
                        <FontAwesome className="Header__search-icon" name="search" />
                        <NavBarSearchInput
                            className="Header__search"
                            name="practice_search"
                            placeholder={intl.messages['navbar.search.placeholder']}
                            value={this.props.settings.adminCurrentDentalPractice}
                            autoload={false}
                            cache={false}
                            loadOptions={this.handleSearchLoading}
                            onChange={this.handleSearchChange}
                            onValueClick={this.handleSearchClick}
                        />
                      </span>
                    }
                    <span className="Header__divider"></span>
                    <div className={cn("Header__notifications", {"Header__notifications--active": activityNotifications && (filter(activityNotifications.history, (obj) => {return obj.data.message.unread}).length > 0)} )}
                      data-tip='header_notification'
                      data-for='header_notification'
                      data-event='click focus'
                      >
                      <NotificationsIcon
                        className="Header__notifications__button"
                      />
                    </div>
                    <span className="Header__user-info">
                      {/*<CustomAvatar name={avatarUserName || "B D"} src={avatar} type={"mini"} class={"Header__user-photo"} />*/}
                      <Avatar color="#4F595C" className="Header__user-photo" round={true} name={avatarUserName || ""} src={avatar} textSizeRatio={2.5} size={32} />
                      <span className="Header__user-info-right-wrapper">
                        <span className="Header__user-id">
                          <FormattedMessage
                            id={'string.usersFullName'}
                            description={'given users name'}
                            defaultMessage={intl.messages['string.usersFullName']}
                            values={{
                              givenName: first_name,
                              familyName: last_name
                            }}
                          />
                        </span>
                        <span className="Header__user-type">{userType}</span>
                      </span>
                      <div style={{display:'flex'}}
                      data-tip='header_menu'
                      data-for='header_menu'
                      data-event='click focus'>
                        <button
                          className="Header__menu"
                          style={{width:30, height:50 ,alignSelf:'center'}}
                        >
                          <FontAwesome
                            className="Header__menu__icon"
                            name="ellipsis-v"
                            onClick={null}
                          />
                        </button>



                        {/*<Menu
                          id="long-menu"
                          anchorEl={anchorEl}
                          className={'Header__menu__popup'}
                          open={Boolean(anchorEl)}
                          onClose={this.handleMenuClose}
                          getContentAnchorEl = {null}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          PaperProps={{
                            style: {width:200 }
                          }}
                        >
                          <MenuItem selected={true} style={{display:'none'}}> </MenuItem>
                          <Link to={URLS.userSettingsPage}>
                            <MenuItem className={'Header__menu__popup__text'} selected={false} style={{fontSize:16, fontFamily: "'Proxima Nova', Georgia, sans-serif"}} disableRipple disablefocusripple onClick={() => this.handleMenuLinkClick("settings")}>
                              <FormattedMessage
                                  id={'string.settings'}
                                  description= {'Settings'}
                                  defaultMessage = {'Settings'}
                              />
                            </MenuItem>
                          </Link>
                          <MenuItem selected={false} style={{fontSize:16, fontFamily: "'Proxima Nova', Georgia, sans-serif"}} disableRipple disableFocusRipple onClick={() => this.handleMenuLinkClick("changeLanguage")}>
                            <FormattedMessage
                                id={'string.change_language'}
                                description= {'Settings Language'}
                                defaultMessage = {'Change Language'}
                            />
                          </MenuItem>
                          <MenuItem selected={false} style={{fontSize:16, fontFamily: "'Proxima Nova', Georgia, sans-serif"}} disableRipple disableFocusRipple onClick={this.handleLogOut}>
                            <FormattedMessage
                              id={'string.logout'}
                              description= {'Logout Button'}
                              defaultMessage = {'Logout'}
                            />
                          </MenuItem>
                        </Menu>*/}
                        </div>

                      </span>
                  </div>
                {/*<BDIdle />*/}
                </header>
                {loadingProfile && <LoadingDialog title={''} message={''}/>}
              </Fragment>

            )
        }else {
            return (
              <header className="Header">
                <span className="Header__logo"><Link to="/">BEFOREDENT</Link></span>
              </header>
            );
        }
    }
}

function mapStateToProps(state) {
    const { authentication, users, settings, intl } = state;
    const { user, loggedIn } = authentication;
    const { activityNotifications } = state[reduxPollingNamespace];
    return {
        user,
        users,
        intl,
        settings,
        loggedIn,
        activityNotifications
    };
}

const connectedNavBar= withRouter(connect(mapStateToProps)(NavBarPage));
export { connectedNavBar as NavBarPage };
